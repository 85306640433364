import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_DashboardBox.module.scss';

class DashboardBox extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.string,
  };

  render() {
    const { title, titleClassName } = this.props;

    let className;
    if (titleClassName) {
      className = titleClassName;
    } else {
      className = styles['title'];
    }

    return (
      <div className={styles['wrapper']}>
        <div className={className}>
          <div className={styles['titleText']}>{title}</div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default DashboardBox;
