import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { SectionListItem } from 'ui/SectionList';
import { CardRow } from 'ui/Card';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class ExpenseTypesListItem extends Component {
  static propTypes = {
    expenseType: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._contextMenuItems = [
      {
        label: locale().actions.edit,
        onClick: this._editExpenseType,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editExpenseType = () => this.props.editExpenseType(this.props.expenseType);

  _mobile = () => {
    const { expenseType } = this.props;

    return (
      <SectionListItem
        title={expenseType.name}
        contextMenuItems={this._contextMenuItems}
      />
    );
  };

  _desktop = () => {
    const { expenseType } = this.props;

    return (
      <CardRow rowSize={'small'} contextMenuItems={this._contextMenuItems}>
        {expenseType.name}
      </CardRow>
    );
  };

  render() {
    const { destroyExpenseType } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={destroyExpenseType}
          title={locale().expense_types.destroy_expense_type_head}
          description={locale().expense_types.destroy_expense_type_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.expenseType.id;

  return {
    destroyExpenseType: () => dispatch(actions.destroyExpenseType(id)),
    editExpenseType: expenseType =>
      dispatch(actions.editExpenseType(expenseType)),
  };
};

ExpenseTypesListItem = connect(null, mapDispatchToProps)(ExpenseTypesListItem);

export default ExpenseTypesListItem;
