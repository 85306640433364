import Response from '../_response';
import { createResourceSerializer, DateSerializer } from './../_serializers';

const Serializer = createResourceSerializer({
  created_at: DateSerializer,
  acknowledged_at: DateSerializer,
  seen_at: DateSerializer,
});

class NotificationsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query(data) {
    return this._client
      .fetch('notifications', 'GET', data)
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(notification =>
            Serializer.deserialize(notification)
          ),
        });
      });
  }

  get(id) {
    return this._client
      .fetch(`notifications/${id}`, 'GET')
      .then(({ response, json }) => {
        return NotificationsEndpoint._buildResponse(response, json);
      });
  }

  acknowledge(id) {
    return this._client
      .fetch(`notifications/${id}/acknowledge`, 'POST')
      .then(({ response, json }) => {
        return NotificationsEndpoint._buildResponse(response, json);
      });
  }

  seen(id) {
    return this._client
      .fetch(`notifications/${id}/seen`, 'POST')
      .then(({ response, json }) => {
        return NotificationsEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = Serializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default NotificationsEndpoint;
