import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './LocationSelectUI.module.scss';
import { Select } from 'ui/Form';
import BottomSheet from 'ui/Dialog/BottomSheet';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class LocationSelectUI extends Component {
  static propTypes = {
    value: PropTypes.string,
    locations: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    clearable: PropTypes.bool,
    personalLocation: PropTypes.bool,
    renderOnMobile: PropTypes.bool,
    mobileClearable: PropTypes.bool,
    mobileOpen: PropTypes.bool,
    onMobileClose: PropTypes.func,
  };

  static defaultProps = {
    personalLocation: true,
    renderOnMobile: true,
    clearable: false,
    mobileOpen: false,
    onMobileClose: () => {},
  };

  static optionRenderer = option => (
    <div className={styles['location']}>
      <div className={styles['circle']} style={{ background: option.color }} />
      {option.label}
    </div>
  );

  _options = () => {
    const { locations, personalLocation } = this.props;

    let options = [...locations.sortBy('name')];
    if (personalLocation) {
      options.push({ id: null, name: locale().locations.personal_location });
    }

    return options.map(location => ({
      value: location.id,
      label: location.name,
      color: location.color,
    }));
  };

  _placeholder = () => locale().locations.all_locations;

  _mobile = () => {
    const {
      value,
      mobileOpen,
      onMobileClose,
      onChange,
      mobileClearable,
    } = this.props;

    let options = this._options();
    if (mobileClearable) {
      options = [
        { value: null, label: this._placeholder(), color: '#949bac' },
        ...options,
      ];
    }

    return (
      <BottomSheet
        open={mobileOpen}
        onClose={onMobileClose}
        items={options}
        itemRenderer={LocationSelectUI.optionRenderer}
        onSelect={onChange}
        selectedItem={value}
      />
    );
  };

  _desktop = () => {
    const { value, onChange, clearable } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        placeholder={this._placeholder()}
        searchable={false}
        clearable={clearable}
        options={this._options()}
        optionRenderer={LocationSelectUI.optionRenderer}
      />
    );
  };

  render() {
    const { renderOnMobile, locations } = this.props;

    if (locations.length > 0) {
      if (renderOnMobile) {
        return this._desktop();
      } else {
        return (
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        );
      }
    } else {
      return null;
    }
  }
}

export default LocationSelectUI;
