import React, { Component } from 'react';
import locale, { dateLocale } from 'util/locale';
import RDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import styles from './DatePicker.module.scss';
import './_default.scss';
import Icon from 'ui/Icon';

function _isTouchDevice() {
  return (
    'ontouchstart' in window || navigator.maxTouchPoints // works on most browsers
  ); // works on IE10/11 and Surface
}

function _isSmallDevice() {
  return window.matchMedia('(max-width: 1024px)').matches;
}

function _supportsDateInput() {
  const input = document.createElement('input');
  input.setAttribute('type', 'datetime-local');

  const notADateValue = 'not-a-date';
  input.setAttribute('value', notADateValue);

  return input.value !== notADateValue;
}

function shouldUseNativeControl() {
  return _supportsDateInput() && _isTouchDevice() && _isSmallDevice();
}

const hideStyle = {
  position: 'absolute',
  bottom: '0',
  left: '25%',
  height: '0',
  border: 'none',
  overflow: 'hidden',
  opacity: 0,
};

class DatePicker extends Component {
  static propTypes = {
    value: PropTypes.instanceOf(Date),
    onChange: PropTypes.func.isRequired,
    dateOnly: PropTypes.bool,
    showMonthYearDropdown: PropTypes.bool,
  };

  static defaultProps = {
    value: new Date(),
    dateOnly: false,
    showMonthYearDropdown: false,
  };

  constructor(props) {
    super(props);
    this._mobileInput = React.createRef();
    this._shouldUseNative = shouldUseNativeControl();
  }

  _onChange = newDate => {
    const { onChange } = this.props;
    onChange(newDate);
  };

  _nativeOnChange = e => {
    const { onChange } = this.props;
    const stringValue = e.target.value;

    if (stringValue) {
      const date = Date.fromString(stringValue);
      date.setSeconds(0); // Android is fine, ios is ...
      onChange(date);
    }
  };

  _scrollIntoSelectedTime = () => {
    let elem = document.getElementsByClassName(
      'react-datepicker__time-list-item--selected'
    );
    if (elem && elem[0]) {
      elem[0].scrollIntoView({
        behavior: 'instant',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  _dateFormat = () =>
    this.props.dateOnly ? 'dd. MMM yyyy.' : 'dd. MMMM yyyy. HH:mm';

  _triggerMobileInput = () => {
    if (this._mobileInput) {
      this._mobileInput.current.focus();
      this._mobileInput.current.click();
    }
  };

  _native = () => {
    const { value, dateOnly } = this.props;

    return (
      <div className={styles['mobileInput']} onClick={this._triggerMobileInput}>
        {value ? value.format(this._dateFormat()) : ''}
        <input
          value={new Date()} // Stupid ios requires value here because of submit
          ref={this._mobileInput}
          type={dateOnly ? 'date' : 'datetime-local'}
          onChange={this._nativeOnChange}
          style={hideStyle}
        />
      </div>
    );
  };

  _picker = () => {
    const { value, dateOnly, showMonthYearDropdown } = this.props;

    let monthYearDropDownProps = {};
    if (showMonthYearDropdown) {
      monthYearDropDownProps = {
        peekNextMonth: true,
        showMonthDropdown: true,
        showYearDropdown: true,
        dropdownMode: 'select',
      };
    }

    return (
      <RDatePicker
        selected={value}
        onChange={this._onChange}
        showTimeSelect={!dateOnly}
        timeFormat={'HH:mm'}
        timeIntervals={15}
        dateFormat={this._dateFormat()}
        timeCaption={locale().dates.time_caption}
        locale={dateLocale()}
        {...monthYearDropDownProps}
      />
    );
  };

  render() {
    return (
      <div className={styles['wrapper']} onClick={this._scrollIntoSelectedTime}>
        {this._shouldUseNative ? this._native() : this._picker()}
        <div className={styles['icon']}>
          <Icon icon={'date_range'} size={22} />
        </div>
      </div>
    );
  }
}

export default DatePicker;
