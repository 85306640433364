import Api from 'util/api/api';
import { push } from 'connected-react-router';

const RECEIVE_INCOME_STATS = 'RECEIVE_INCOME_STATS';
const RECEIVE_EXPENSE_STATS = 'RECEIVE_EXPENSE_STATS';
const RECEIVE_DIAGNOSE_STATS = 'RECEIVE_DIAGNOSE_STATS';
const RECEIVE_PATIENT_REFERRAL_STATS = 'RECEIVE_PATIENT_REFERRAL_STATS';
const RECEIVE_PROFIT_AND_LOSS_REPORT = 'RECEIVE_PROFIT_AND_LOSS_REPORT';
const RECEIVE_APPOINTMENT_STATS = 'RECEIVE_APPOINTMENT_STATS';

export default (
  state = {
    income_stats: null,
    expense_stats: null,
    diagnose_stats: [],
    patient_referral_stats: [],
    profit_and_loss_report: null,
    appointment_stats: null,
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_INCOME_STATS:
      return {
        ...state,
        income_stats: action.stats,
      };
    case RECEIVE_EXPENSE_STATS:
      return {
        ...state,
        expense_stats: action.stats,
      };
    case RECEIVE_DIAGNOSE_STATS:
      return {
        ...state,
        diagnose_stats: action.stats,
      };
    case RECEIVE_PATIENT_REFERRAL_STATS:
      return {
        ...state,
        patient_referral_stats: action.stats,
      };
    case RECEIVE_PROFIT_AND_LOSS_REPORT:
      return {
        ...state,
        profit_and_loss_report: action.report,
      };
    case RECEIVE_APPOINTMENT_STATS:
      return {
        ...state,
        appointment_stats: action.stats,
      };
    default:
      return state;
  }
};

// ACTIONS

const openDashboardPage = () => push('/');

const openFinancesPage = () => push('/finance');

const receiveIncomeStats = stats => {
  return {
    type: RECEIVE_INCOME_STATS,
    stats,
  };
};

const fetchIncomeStats = data => {
  return dispatch => {
    return Api.organisationStats.getIncomeStats(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveIncomeStats(response.data()));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const receiveExpenseStats = stats => {
  return {
    type: RECEIVE_EXPENSE_STATS,
    stats,
  };
};

const fetchExpenseStats = data => {
  return dispatch => {
    return Api.organisationStats.getExpenseStats(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveExpenseStats(response.data()));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const receiveDiagnoseStats = stats => {
  return {
    type: RECEIVE_DIAGNOSE_STATS,
    stats,
  };
};

const fetchDiagnoseStats = query => {
  return dispatch => {
    return Api.organisationStats.getDiagnoseStats(query).then(response => {
      if (response.isOk()) {
        dispatch(receiveDiagnoseStats(response.data().resources));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const receivePatientReferralStats = stats => {
  return {
    type: RECEIVE_PATIENT_REFERRAL_STATS,
    stats,
  };
};

const fetchPatientReferralStats = query => {
  return dispatch => {
    return Api.organisationStats
      .getPatientReferralStats(query)
      .then(response => {
        if (response.isOk()) {
          dispatch(receivePatientReferralStats(response.data().resources));
        } else {
          return Promise.reject(response);
        }
      });
  };
};

const receiveProfitAndLossReport = report => {
  return {
    type: RECEIVE_PROFIT_AND_LOSS_REPORT,
    report,
  };
};

const fetchProfitAndLossReport = data => {
  return dispatch => {
    return Api.organisationStats.getProfitAndLossReport(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveProfitAndLossReport(response.data()));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const receiveAppointmentStats = stats => {
  return {
    type: RECEIVE_APPOINTMENT_STATS,
    stats,
  };
};

const fetchAppointmentStats = data => {
  return dispatch => {
    return Api.organisationStats.getAppointmentStats(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveAppointmentStats(response.data()));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openDashboardPage,
  openFinancesPage,
  fetchIncomeStats,
  fetchExpenseStats,
  fetchDiagnoseStats,
  fetchPatientReferralStats,
  fetchProfitAndLossReport,
  fetchAppointmentStats,
};

// SELECTORS

const getIncomeStats = state => state.income_stats;

const getExpenseStats = state => state.expense_stats;

const getDiagnoseStats = state => state.diagnose_stats;

const getPatientReferralStats = state => state.patient_referral_stats;

const getProfitAndLossReport = state => state.profit_and_loss_report;

const getAppointmentStats = state => state.appointment_stats;

export const selectors = {
  getIncomeStats,
  getExpenseStats,
  getDiagnoseStats,
  getPatientReferralStats,
  getProfitAndLossReport,
  getAppointmentStats,
};
