import React, { Component } from 'react';
import locale from 'util/locale';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './_Appointment.module.scss';
import { BirthdayIcon } from 'ui/Icon';
import { connect, actions } from 'util/redux';

class Appointment extends Component {
  static propTypes = {
    appointment: PropTypes.object.isRequired,
    labelExtractor: PropTypes.func.isRequired,
    indexInCell: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    widthCoef: PropTypes.number.isRequired,
  };

  _birthdayIcon = () => {
    const { appointment } = this.props;

    if (appointment.events.length > 1) {
      return null;
    } else {
      return (
        <div className={styles['birthDay']}>
          <BirthdayIcon
            birthDate={appointment.events[0].birth_date}
            currentDate={appointment.date}
            showWhenNear={false}
            size={'small'}
            hideTooltip={true}
          />
        </div>
      );
    }
  };

  render() {
    const {
      showAppointment,
      appointment,
      labelExtractor,
      indexInCell,
      height,
      widthCoef,
    } = this.props;
    const isIndividualAppointment = appointment.events.length === 1;
    const isOneUserParticipating = appointment.users.length === 1;

    const labelInfo = labelExtractor(appointment);

    let event;
    if (isIndividualAppointment) {
      event = appointment.events[0];
    } else {
      event = {
        patient_name: `${locale().schedule.number_of_participants}: ${
          appointment.events.length
        }`,
        name: locale().appointments.group_treatment,
      };
    }

    const width = 100 / widthCoef;

    return (
      <div
        className={classNames(styles['appointment'], {
          [styles['cancelled']]: appointment.cancelled,
        })}
        style={{
          borderLeftColor: isOneUserParticipating
            ? appointment.users[0].color
            : null,
          height: height,
          width: `${width - 1}%`, // -1 just to add little space between appointments in same cell
          left: `${indexInCell * width}%`,
        }}
        onClick={showAppointment}
      >
        {appointment.cancelled && (
          <div className={styles['isCancelled']}>
            {locale().appointments.cancelled}
          </div>
        )}
        <div className={styles['top']}>
          <div className={styles['date']}>
            {Date.formatAppointment(appointment.date, appointment.duration)}
          </div>
          <div className={styles['right']}>
            {labelInfo && (
              <div className={styles['label']}>
                <div
                  className={styles['tab']}
                  style={{ background: labelInfo.color }}
                >
                  {labelInfo.label}
                </div>
              </div>
            )}
            {this._birthdayIcon()}
          </div>
        </div>

        <div className={styles['info']}>
          <div className={styles['accent']}>{event.patient_name}</div>
          {event.diagnose_name && <div>{event.diagnose_name}</div>}
          <div>{event.name}</div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    showAppointment: () =>
      dispatch(actions.showAppointment(ownProps.appointment.id)),
  };
};

Appointment = connect(null, mapDispatchToProps)(Appointment);

export default Appointment;
