import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './SidebarDialog.module.scss';
import classNames from 'classnames';
import Dialog from './_Dialog';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import { Form } from 'ui/Form';
import EllipsisText from 'ui/Text/EllipsisText';
import { Button } from 'ui/Button';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import { loadingStates } from 'util/redux';

class SidebarDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func,
    saveText: PropTypes.string,
    title: PropTypes.string.isRequired,
    loadingState: PropTypes.string,
    error: PropTypes.string,
    inProgress: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    padding: PropTypes.bool,
    footer: PropTypes.node,
    width: PropTypes.oneOf(['normal', 'extended']),
  };

  static defaultProps = {
    onSave: null,
    error: null,
    loadingState: loadingStates.present,
    inProgress: false,
    padding: true,
    width: 'normal',
  };

  _startClosing = () => {
    if (this._dialog) {
      this._dialog.classList.add(styles['closing']);
    }
  };

  _saveText = () => {
    const { inProgress, saveText } = this.props;
    return Number.isInteger(inProgress)
      ? `${inProgress}%`
      : saveText || locale().actions.save;
  };

  _mobileHeader = () => {
    const { title, onClose, onSave, inProgress } = this.props;

    let saveButton;

    if (onSave) {
      saveButton = (
        <Button
          value={this._saveText()}
          onClick={onSave}
          disabled={!!inProgress}
          actionType={'mobile'}
        />
      );
    }

    return (
      <HeaderBar
        title={title}
        onBack={onClose}
        rightContent={saveButton}
        rightPadding={true}
      />
    );
  };

  _desktopHeader = () => (
    <div className={styles['titleWrapper']}>
      <div className={styles['title']}>
        <EllipsisText>{this.props.title}</EllipsisText>
      </div>
    </div>
  );

  _children = () => {
    const { padding, children } = this.props;

    return (
      <div
        className={classNames({
          [styles['children']]: padding,
        })}
      >
        {children}
      </div>
    );
  };

  _desktopChildren = () => {
    const { onClose, onSave, inProgress } = this.props;

    let WrapperComponent,
      wrapperProps = {};
    if (onSave) {
      WrapperComponent = Form;
      wrapperProps = { onSubmit: onSave };
    } else {
      WrapperComponent = React.Fragment;
    }

    return (
      <WrapperComponent {...wrapperProps}>
        {this._children()}
        <div className={styles['actions']}>
          <Button
            value={locale().actions.close}
            actionType={'normal'}
            onClick={onClose}
          />
          {onSave && (
            <Button
              value={this._saveText()}
              disabled={!!inProgress}
              actionType={'primary'}
              type={'submit'}
            />
          )}
        </div>
      </WrapperComponent>
    );
  };

  render() {
    const { open, onClose, loadingState, error, footer, width } = this.props;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else {
      let errorNode;
      if (error) {
        errorNode = <div className={styles['error']}>{error}</div>;
      }

      bodyNode = (
        <React.Fragment>
          {errorNode}
          <MobileDesktopRender
            mobile={this._children}
            desktop={this._desktopChildren}
          />
          {footer}
        </React.Fragment>
      );
    }

    return (
      <Dialog open={open} onClose={onClose} onClosing={this._startClosing}>
        <div
          className={classNames(styles['wrapper'], styles[width])}
          ref={ref => (this._dialog = ref)}
        >
          <MobileDesktopRender
            mobile={this._mobileHeader}
            desktop={this._desktopHeader}
          />
          <div className={styles['bodyWrapper']}>{bodyNode}</div>
        </div>
      </Dialog>
    );
  }
}

export default SidebarDialog;
