import React, { Component } from 'react';
import DiagnoseSelectUI from './DiagnoseSelectUI';
import { connect, actions, selectors } from 'util/redux';

class DiagnoseSelect extends Component {
  componentDidMount() {
    const { fetchDiagnoses } = this.props;
    fetchDiagnoses();
  }

  render() {
    return <DiagnoseSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    diagnoses: selectors.getDiagnoses(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDiagnoses: () => dispatch(actions.fetchDiagnoses()),
  };
};

DiagnoseSelect = connect(mapStateToProps, mapDispatchToProps)(DiagnoseSelect);

export default DiagnoseSelect;
