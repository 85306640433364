import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import View from './_View';
import viewStyles from './_View.module.scss';
import { connect, actions } from 'util/redux';

class PostCreated extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
  };

  _openPostsPage = () => {
    const { openPostsPage, closeMenu } = this.props;

    closeMenu();
    openPostsPage();
  };

  render() {
    const { notification } = this.props;
    const post = notification.notifiable;

    return (
      <View notification={notification}>
        <div className={viewStyles['title']}>
          {locale().notifications.post_created.title}
        </div>
        <div className={viewStyles['content']}>
          <span className={viewStyles['link']} onClick={this._openPostsPage}>
            {post.title}
          </span>
        </div>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openPostsPage: () => dispatch(actions.openPostsPage()),
  };
};

PostCreated = connect(null, mapDispatchToProps)(PostCreated);

export default PostCreated;
