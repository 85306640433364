import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_ServiceItemSelect.module.scss';
import { Select } from 'ui/Form';

class ServiceItemSelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    serviceItems: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static optionRenderer = option => (
    <div className={classNames({ [styles['attention']]: option.attention })}>
      {option.label}
    </div>
  );

  _options = () => {
    const { value, serviceItems } = this.props;

    return serviceItems
      .filter(item => !item.appointment_date || item.id === value)
      .map(serviceItem => ({
        value: serviceItem.id,
        label: serviceItem.name,
        attention: !!serviceItem.appointments.find(a => a.cancelled),
      }));
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        optionRenderer={ServiceItemSelect.optionRenderer}
      />
    );
  }
}

export default ServiceItemSelect;
