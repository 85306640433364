import Response from '../_response';
import {
  createResourceSerializer,
  MoneyAmountSerializer,
} from './../_serializers';

const Serializer = createResourceSerializer({
  price: MoneyAmountSerializer,
});

class PackagesEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client.fetch('packages', 'GET').then(({ response, json }) => {
      return new Response(response, {
        ...json,
        resources: json.resources.map(package_treatment =>
          Serializer.deserialize(package_treatment)
        ),
      });
    });
  }

  create(data) {
    return this._client
      .fetch('packages', 'POST', Serializer.serialize(data))
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = Serializer.deserialize(json);
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  update(data) {
    return this._client
      .fetch(`packages/${data.id}`, 'PATCH', Serializer.serialize(data))
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = Serializer.deserialize(json);
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`packages/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default PackagesEndpoint;
