import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import ServiceSelect from './_ServiceSelect';
import ServiceItemSelect from './_ServiceItemSelect';
import AddCircle from './../_AddCircle';
import { FormRow, FormColumn, FormLabel } from 'ui/Form';
import { connect, actions, selectors } from 'util/redux';

class TreatmentSelect extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
    serviceId: PropTypes.string,
    serviceItemId: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selectClassName: PropTypes.string,
  };

  static defaultProps = {
    serviceId: null,
    serviceItemId: null,
    selectClassName: null,
  };

  componentDidMount() {
    const { fetchServicesForPatient, serviceId } = this.props;

    fetchServicesForPatient();
    this._fetchItems(serviceId);
  }

  componentDidUpdate(prevProps) {
    const { serviceId } = this.props;

    if (prevProps.serviceId !== serviceId) {
      this._fetchItems(serviceId);
    }
  }

  _fetchItems = serviceId => {
    const { fetchItemsForService } = this.props;

    if (serviceId) {
      fetchItemsForService(serviceId).then(serviceItems => {
        if (serviceItems.length === 1) {
          this._changeServiceItemId(serviceItems[0].id);
        }
      });
    }
  };

  _changeServiceId = serviceId => {
    const { onChange } = this.props;
    const currentServiceId = this.props.serviceId;

    // We don't wanna reset serviceItemId if same service is selected twice
    if (currentServiceId !== serviceId) {
      onChange(serviceId, null);
    }
  };

  _changeServiceItemId = serviceItemId => {
    const { onChange, serviceId } = this.props;

    onChange(serviceId, serviceItemId);
  };

  _serviceSelectNode = () => {
    const { services, serviceId, selectClassName } = this.props;

    return (
      <div className={selectClassName}>
        <FormLabel value={locale().appointments.select_service} />
        <ServiceSelect
          value={serviceId}
          services={services}
          onChange={this._changeServiceId}
        />
      </div>
    );
  };

  _addNewServiceNode = () => {
    const { patientId, startAssigningService } = this.props;

    return <AddCircle onClick={() => startAssigningService(patientId)} />;
  };

  _serviceItemSelectNode = () => {
    const {
      serviceItems,
      serviceId,
      serviceItemId,
      selectClassName,
    } = this.props;

    return (
      <React.Fragment>
        {serviceId && serviceItems.length > 1 && (
          <div className={selectClassName}>
            <FormLabel value={locale().appointments.select_service_item} />
            <ServiceItemSelect
              value={serviceItemId}
              serviceItems={serviceItems}
              onChange={this._changeServiceItemId}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  _mobile = () => (
    <React.Fragment>
      <FormRow>
        <FormColumn>{this._serviceSelectNode()}</FormColumn>
        {this._addNewServiceNode()}
      </FormRow>
      <FormRow>
        <FormColumn>{this._serviceItemSelectNode()}</FormColumn>
      </FormRow>
    </React.Fragment>
  );

  _desktop = () => (
    <React.Fragment>
      <FormColumn>{this._serviceSelectNode()}</FormColumn>
      <FormColumn>{this._addNewServiceNode()}</FormColumn>
      <FormColumn>{this._serviceItemSelectNode()}</FormColumn>
    </React.Fragment>
  );

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    services: selectors.getServicesForPatient(state, ownProps.patientId),
    serviceItems: selectors.getItemsForService(state, ownProps.serviceId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchServicesForPatient: () =>
      dispatch(
        actions.fetchServicesForPatient({
          patientId: ownProps.patientId,
          clearOld: false,
        })
      ),
    fetchItemsForService: serviceId =>
      dispatch(actions.fetchItemsForService(serviceId, false)),
    startAssigningService: patientId =>
      dispatch(actions.startAssigningService({ patientId })),
  };
};

TreatmentSelect = connect(mapStateToProps, mapDispatchToProps)(TreatmentSelect);

export default TreatmentSelect;
