import React, { Component } from 'react';
import locale from 'util/locale';
import qs from 'qs';
import styles from './PatientsOverview.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Navigator from 'navigation/Navigator';
import Paginator from 'navigation/Paginator';
import EmptyState from 'ui/EmptyState';
import HeaderBar from 'ui/HeaderBar';
import { TextInput } from 'ui/Form';
import Icon, { DownloadIcon } from 'ui/Icon';
import PatientsList from './_PatientsList';
import DiagnosesDialog from './_DiagnosesDialog';
import BottomSheet from 'ui/Dialog/BottomSheet';
import Dropdown from 'ui/Dropdown';
import { Button } from 'ui/Button';
import FAB from 'ui/FAB';
import MultiOrgRender from 'ui/MultiOrgRender';
import ArchivedSelect from './_ArchivedSelect';
import DiagnoseDoughnut from './_DiagnoseDoughnut';
import PatientReferralDoughnut from './_PatientReferralDoughnut';
import { EmployeeSelectUI } from 'employees/EmployeeSelect';
import DiagnoseSelect from 'patients/DiagnoseSelect';
import PatientReferralSelect from 'patients/PatientReferralSelect';
import emptyStateImg from 'media/images/patients/emptyState.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';
import ActionCenter from 'util/action_center';

const CHARTS_REFRESH_ACTIONS = [
  'createPatient',
  'updatePatient',
  'destroyPatient',
  'activatePatient',
  'archivePatient',
  'destroyDiagnose',
  'destroyPatientReferral',
];

class PatientsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      diagnosesDialogOpen: false,
      archivedFilterBottomSheetOpen: false,
      employeesFilterBottomSheetOpen: false,
      optionsBottomSheetOpen: false,
    };
    this._debouncingQueryKeys = ['search_str'];
    this._debouncedChartsFetch = this._chartsFetch.debounce(500);
  }

  componentDidMount() {
    const { fetchEmployees } = this.props;

    CHARTS_REFRESH_ACTIONS.forEach(action =>
      ActionCenter.subscribe(action, this._chartsFetch)
    );

    fetchEmployees();
    this._chartsFetch();
  }

  componentDidUpdate(prevProps) {
    if (
      this._debouncingQueryKeys.find(
        key => prevProps.query[key] !== this.props.query[key]
      )
    ) {
      this._debouncedChartsFetch();
    } else {
      if (!Object.same(prevProps.query, this.props.query)) {
        this._chartsFetch();
      }
    }
  }

  componentWillUnmount() {
    CHARTS_REFRESH_ACTIONS.forEach(action =>
      ActionCenter.unsubscribe(action, this._chartsFetch)
    );
  }

  _chartsFetch = () => {
    const { fetchDiagnoseStats, fetchPatientReferralStats } = this.props;
    const query = this._cleanedQuery();

    fetchDiagnoseStats(query);
    fetchPatientReferralStats(query);
  };

  _moreOptions = () => {
    return [
      { label: locale().diagnoses.title, onClick: this._openDiagnosesDialog },
      {
        label: locale().patient_referrals.title,
        onClick: this.props.newPatientReferral,
      },
    ];
  };

  _cleanedQuery = () => {
    const { query } = this.props;
    return {
      ...query,
      user_id: query.user_id === 'all' ? null : query.user_id,
      diagnose_id: query.diagnose_id === 'all' ? null : query.diagnose_id,
      patient_referral_id:
        query.patient_referral_id === 'all' ? null : query.patient_referral_id,
    };
  };

  _openDiagnosesDialog = () => this.setState({ diagnosesDialogOpen: true });

  _closeDiagnosesDialog = () => this.setState({ diagnosesDialogOpen: false });

  _openArchivedFilterBottomSheet = () =>
    this.setState({ archivedFilterBottomSheetOpen: true });

  _closeArchivedFilterBottomSheet = () =>
    this.setState({ archivedFilterBottomSheetOpen: false });

  _openEmployeesBottomSheet = () =>
    this.setState({ employeesFilterBottomSheetOpen: true });

  _closeEmployeesBottomSheet = () =>
    this.setState({ employeesFilterBottomSheetOpen: false });

  _openOptionsBottomSheet = () =>
    this.setState({ optionsBottomSheetOpen: true });

  _closeOptionsBottomSheet = () =>
    this.setState({ optionsBottomSheetOpen: false });

  _changeQuery = changes => {
    const { updateUrlParams } = this.props;
    const query = this._cleanedQuery();

    let queryForUrl = { ...query, ...changes };
    queryForUrl = {
      ...queryForUrl,
      user_id: queryForUrl.user_id === null ? 'all' : queryForUrl.user_id,
    };

    updateUrlParams(queryForUrl);
  };

  _changeArchived = archived => this._changeQuery({ archived });

  _changeEmployee = user_id => this._changeQuery({ user_id });

  _changeDiagnose = diagnose_id => this._changeQuery({ diagnose_id });

  _changePatientReferral = patient_referral_id =>
    this._changeQuery({ patient_referral_id });

  _changeSearchStr = search_str => this._changeQuery({ search_str });

  _downloadCsv = () => {
    const { patientsQuery } = this.props;

    if (patientsQuery.csv_url) {
      window.open(patientsQuery.csv_url, '_blank');
    }
  };

  _patients = () => {
    const { patients } = this.props;
    const query = this._cleanedQuery();

    return (
      patients
        // In case when user clicks activate/archive - to remove it from the list
        .filter(patient => patient.archived === query.archived)
        // Even though backend sort it by name, this is for adding new patient cases
        .sortBy('name')
    );
  };

  _filters = () => {
    const { employees } = this.props;
    const {
      archivedFilterBottomSheetOpen,
      employeesFilterBottomSheetOpen,
    } = this.state;
    const query = this._cleanedQuery();

    return (
      <React.Fragment>
        <MultiOrgRender>
          <EmployeeSelectUI
            value={query.user_id === 'all' ? null : query.user_id}
            employees={employees}
            onChange={this._changeEmployee}
            placeholder={locale().employees.all_employees}
            renderOnMobile={false}
            mobileClearable={true}
            mobileOpen={employeesFilterBottomSheetOpen}
            onMobileClose={this._closeEmployeesBottomSheet}
          />
        </MultiOrgRender>
        <ArchivedSelect
          value={query.archived}
          onChange={this._changeArchived}
          renderOnMobile={false}
          mobileOpen={archivedFilterBottomSheetOpen}
          onMobileClose={this._closeArchivedFilterBottomSheet}
        />
      </React.Fragment>
    );
  };

  _changeLoadingState = loadingState => this.setState({ loadingState });

  _mobileHeader = () => {
    const { employees } = this.props;
    const { optionsBottomSheetOpen } = this.state;

    const query = this._cleanedQuery();

    return (
      <React.Fragment>
        <HeaderBar
          title={locale().patients.title}
          rightContent={
            <React.Fragment>
              {employees.length > 1 && (
                <Icon
                  icon={'people'}
                  color={'#FFFFFF'}
                  expand={true}
                  onClick={this._openEmployeesBottomSheet}
                />
              )}
              <Icon
                icon={'event_note'}
                color={'#FFFFFF'}
                expand={true}
                onClick={this._openArchivedFilterBottomSheet}
              />
              <Icon
                icon={'more_vert'}
                color={'#FFFFFF'}
                expand={true}
                onClick={this._openOptionsBottomSheet}
              />
            </React.Fragment>
          }
          search={{
            value: query.search_str,
            placeholder: locale().patients.search_by,
            onChange: this._changeSearchStr,
          }}
        />
        {this._filters()}
        <BottomSheet
          open={optionsBottomSheetOpen}
          onClose={this._closeOptionsBottomSheet}
          items={this._moreOptions()}
        />
      </React.Fragment>
    );
  };

  _desktopHeader = () => {
    const { diagnoseStats, patientReferralStats } = this.props;
    const query = this._cleanedQuery();

    return (
      <div className={styles['panel']}>
        <div className={styles['group']}>
          <div className={styles['row']}>{this._filters()}</div>
          <div className={styles['row']}>
            <DiagnoseSelect
              value={query.diagnose_id === 'all' ? null : query.diagnose_id}
              onChange={this._changeDiagnose}
              placeholder={locale().diagnoses.all_diagnoses}
            />
            <PatientReferralSelect
              value={
                query.patient_referral_id === 'all'
                  ? null
                  : query.patient_referral_id
              }
              onChange={this._changePatientReferral}
              placeholder={locale().patient_referrals.all_patient_referrals}
            />
          </div>
          <div className={styles['row']}>
            <TextInput
              value={query.search_str}
              onChange={this._changeSearchStr}
              prefix={<Icon icon={'search'} color={'#949BAC'} size={22} />}
              placeholder={locale().patients.search_by}
            />
          </div>
        </div>
        <div className={styles['group']}>
          <div className={styles['row']}>
            <DiagnoseDoughnut stats={diagnoseStats} />
            <PatientReferralDoughnut stats={patientReferralStats} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { patients, fetchPatients, newPatient } = this.props;
    const { loadingState, diagnosesDialogOpen } = this.state;
    const query = this._cleanedQuery();

    // user_id is always present so we can not check it here
    const shouldShowEmptyState =
      loadingState === loadingStates.present &&
      patients.length === 0 &&
      !query.archived &&
      !query.search_str &&
      !query.diagnose_id &&
      !query.patient_referral_id;

    return (
      <Navigator title={locale().patients.title} mountedAs={'patients'}>
        <React.Fragment>
          <MobileDesktopRender
            desktop={this._desktopHeader}
            mobile={this._mobileHeader}
          />
          <Paginator
            fetch={fetchPatients}
            query={this._cleanedQuery()}
            debouncingQueryKeys={this._debouncingQueryKeys}
            onLoadingStateChange={this._changeLoadingState}
            padding={true}
          >
            <div className={styles['header']}>
              <DownloadIcon onClick={this._downloadCsv} />
              <Dropdown
                title={locale().patients.options}
                items={this._moreOptions()}
              />
              <Button value={locale().patients.new} onClick={newPatient} />
            </div>
            <PatientsList patients={this._patients()} />
          </Paginator>
          {shouldShowEmptyState && (
            <EmptyState
              heading={locale().patients.empty_state_heading}
              image={emptyStateImg}
              intro={locale().patients.empty_state_intro}
            />
          )}
          <FAB onClick={this.props.newPatient} />
          <DiagnosesDialog
            open={diagnosesDialogOpen}
            onClose={this._closeDiagnosesDialog}
          />
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const searchQ = (ownProps.location.search || '').substring(1);
  const rawQuery = qs.parse(searchQ);

  // Fallback is only for cases when logging out from this page
  const currentUser = selectors.getCurrentUser(state) || {};

  const query = {
    user_id: rawQuery.user_id || currentUser.id,
    archived: rawQuery.archived === 'true',
    diagnose_id: rawQuery.diagnose_id || null,
    patient_referral_id: rawQuery.patient_referral_id || null,
    search_str: rawQuery.search_str || '',
  };

  return {
    patients: selectors.getPatients(state),
    employees: selectors.getEmployees(state),
    diagnoseStats: selectors.getDiagnoseStats(state),
    patientReferralStats: selectors.getPatientReferralStats(state),
    patientsQuery: selectors.getPatientsQuery(state),
    query,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    newPatient: () => dispatch(actions.newPatient()),
    newPatientReferral: () => dispatch(actions.newPatientReferral()),
    fetchPatients: query => dispatch(actions.fetchPatients(query)),
    fetchDiagnoseStats: query => dispatch(actions.fetchDiagnoseStats(query)),
    fetchPatientReferralStats: query =>
      dispatch(actions.fetchPatientReferralStats(query)),
    fetchEmployees: () => dispatch(actions.fetchEmployees({ archived: false })),
    updateUrlParams: urlParams => dispatch(actions.updateUrlParams(urlParams)),
  };
};

PatientsOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientsOverview);

export default PatientsOverview;
