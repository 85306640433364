import React from 'react';

const sr = {
  onboard: {
    invalid_email_or_password_error: 'Pogrešna email adresa ili lozinka.',
    user_name: 'Tvoje ime',
    organisation_name: 'Naziv tvog kabineta ili ordinacije',
    organisation_currency: 'Izaberi valutu',
    language: 'Izaberi jezik',
    email: 'Email adresa',
    password: 'Tvoja lozinka',
    choose_password: 'Izaberi lozinku',
    forgot_password_question: 'Zaboravljena lozinka?',
    need_account_question: 'Treba ti nalog?',
    login_head: 'Prijavi se na svoj Logovel nalog',
    login_intro: 'Prati tretmane i termine svojih pacijenata',
    invalid_invitation_error: 'Pozivnica je nevažeća.',
    registration_through_invitation_info: 'Registracija preko pozivnice',
    subscription_exceeded_error:
      'Trenutna pretplata ne dozvoljava više naloga za terapeute',
    agree_to_terms: 'Pravljenjem naloga prihvatate',
    terms_of_service: 'uslove korišćenja',
    registration_head: 'Aplikacija za defektologe',
    registration_intro: 'Evidencija o pacijentima i tretmanima',
    registration_small_head: 'Napravi svoj Logovel nalog',
    registration_small_intro: 'Zaboravi na sveske i papire',
    already_have_account_question: 'Imaš nalog?',
    send_reset_instructions: 'Pošalji uputstva',
    reset_password_instructions_sent:
      'Uputstva za resetovanje lozinke su poslata na tvoju email adresu.',
    forgot_password_info:
      'Nema problema. Unesi svoju email adresu i šaljemo ti uputstva za resetovanje.',
    know_password_question: 'Imaš lozinku?',
    reset_password_head: 'Promena lozinke',
    reset_password_intro: 'Izaberi novu lozinku za svoj Logovel nalog',
    reset_password_invalid_link_error: 'Link za resetovanje je nevalidan.',
    reset_password_link_expired_error:
      'Link je istekao. Pošalji ponovo instrukcije za resetovanje.',
    reset_password_successful_info:
      'Lozinka promenjena. Iskoristi je da se prijaviš u svoj nalog.',
    new_password: 'Nova lozinka',
  },
  dashboard: {
    title: 'Početna',
    greeting: 'Kratak pregled tvog poslovanja',
    finance_overview_head: 'Finansije za tekući mesec',
    finance_income: 'Prihodi',
    finance_debt: 'Ukupan dug',
    finance_expense: 'Troškovi',
    finance_chart_head: 'Finansije po mesecima',
    quick_actions: 'Brze akcije',
  },
  practice: {
    title: 'Kabinet',
  },
  schedule: {
    title: 'Raspored',
    empty_state_heading: 'Nema zakazanih tretmana za izabran datum.',
    number_of_participants: 'Broj učesnika',
    no_location_name: 'Bez lokacije',
    no_location_short_name: '*',
    resources_type: {
      user: 'Terapeuti',
      location: 'Lokacije',
    },
  },
  patients: {
    title: 'Pacijenti',
    options: 'Dodaci',
    search_by: 'Traži po imenu ili broju telefona...',
    number_of_patients: 'Broj pacijenata',
    active_patients: 'Aktivni pacijenti',
    archived_patients: 'Arhivirani pacijenti',
    archived: 'Arhiviran',
    new: 'Novi pacijent',
    edit: 'Izmeni pacijenta',
    empty_state_heading: 'Ovde će se prikazivati svi tvoji pacijenti.',
    empty_state_intro: 'Zašto ne evidentiraš jednog odmah?',
    name: 'Ime i prezime',
    gender: 'Pol',
    birth_date: 'Datum rođenja',
    birthday_today: 'Rođendan je danas!',
    birthday_soon: 'Rođendan je uskoro',
    birthday_recently: 'Rođendan je bio nedavno',
    diagnose: 'Dijagnoza',
    patient_referral: 'Kako je pacijent saznao za kabinet?',
    parent_name: 'Ime roditelja',
    phone_number: 'Broj telefona',
    email: 'Email',
    assign_to_who: 'Kome je sve pacijent dodeljen?',
    anamnesis: 'Anamneza i napomene',
    anamnesis_info: 'Dodaj anamnestičke podatke ili napomene...',
    add_birth_date: 'Dodaj datum rođenja...',
    male: 'Muški',
    female: 'Ženski',
    patient_saved: 'Pacijent sačuvan.',
    patient_archived: 'Pacijent arhiviran.',
    patient_activated: 'Pacijent aktiviran.',
    patient_destroyed: 'Pacijent obrisan.',
    no_debt: 'Plaćeno',
    destroy_patient_head: 'Obriši pacijenta',
    destroy_patient_notice:
      'Brisanjem pacijenta brišeš i sve njegove zakazane tretmane i uplate. Akcija se ne može poništiti!',
    not_found: 'Pacijent nije pronađen. Ili je obrisan, ili ne postoji.',
    patient_data_head: 'Podaci o pacijentu',
  },
  appointments: {
    title: 'Tretmani',
    empty_state_description:
      'Ovde se prikazuju svi zakazani i otkazani tretmani.',
    details: 'Detalji',
    not_scheduled: 'Tretman nije zakazan',
    schedule_an_appointment: 'Zakaži tretman',
    appointment_scheduled: 'Termin zakazan.',
    scheduled_for: 'Zakazano za',
    cancelled: 'Otkazano',
    cancel_appointment: 'Otkaži termin',
    appointment_canceled: 'Termin otkazan.',
    cancel_appointment_notice:
      'Da li ste sigurni da želite da otkažete ovaj tretman?',
    cancel_debt: 'Oprosti dug',
    cancellation_reason: 'Opcioni razlog otkazivanja',
    appointment_taken_error: 'Izabrani termin je zauzet.',
    location_taken_error: 'Izabrana lokacija je zauzeta u terminu.',
    users_required: 'Bar jedan terapeut mora učestvovati u tretmanu.',
    events_required: 'Unesi podatke o pacijentima koji učestvuju u tretmanu.',
    already_scheduled: 'Usluga je već zakazana.',
    group_treatment: 'Grupni tretman',
    appointment_user: 'Ko obavlja tretman?',
    select_patient: 'Izaberi pacijenta',
    select_service: 'Izaberi uslugu',
    select_service_item: 'Izaberi tretman',
    add_patient: 'Uključi još jednog pacijenta',
    not_found: 'Tretman nije pronađen. Ili je otkazan ili ne postoji.',
    show_service: 'Vidi uslugu',
  },
  patient_referrals: {
    title: 'Preporuke',
    all_patient_referrals: 'Sve preporuke',
    name: 'Naziv',
    destroy_patient_referral_head: 'Obriši preporuku',
    destroy_patient_referral_notice:
      'Brisanjem ove preporuke je uklanjaš i sa svih pacijenata. Akcija se ne može poništiti!',
    patient_referral_saved: 'Preporuka sačuvana.',
    patient_referral_destroyed: 'Preporuka obrisana.',
  },
  diagnoses: {
    title: 'Dijagnoze',
    all_diagnoses: 'Sve dijagnoze',
    name: 'Naziv',
    destroy_diagnose_head: 'Obriši dijagnozu',
    destroy_diagnose_notice:
      'Brisanjem ove dijagnoze je uklanjaš i sa svih pacijenata. Akcija se ne može poništiti!',
    diagnose_saved: 'Dijagnoza sačuvana.',
    diagnose_destroyed: 'Dijagnosa obrisana.',
  },
  services: {
    title: 'Usluge',
    new: 'Nova usluga',
    edit: 'Izmeni uslugu',
    assign: 'Izaberi uslugu',
    activated_on: 'Aktiviran',
    price: 'Cena usluge',
    empty_state_description:
      'Ovde se prikazuju sve usluge povezane sa pacijentom. Prilikom zakazivanja tretmana, dug pacijenta se uveća za cenu usluge.',
    service_assigned: 'Usluga dodeljena.',
    service_updated: 'Usluga ažurirana.',
    service_destroyed: 'Usluga obrisana.',
    destroy_service_head: 'Obriši uslugu',
    destroy_service_notice:
      'Brisanjem usluge brišeš i sve zakazane tretmane i uplate za ovu uslugu. Akcija se ne može poništiti!',
    appointments_scheduled: (part, sum) => `${part}/${sum} tretmana zakazano`,
    service_not_selected: 'Izaberi prvo uslugu.',
    no_services_notice: () => (
      <React.Fragment>
        Trenutno ne postoji ni jedna usluga. Na stranici svih usluga možeš uneti
        tretmane i pakete tretmana. <br />
        <br />
        Za početak, klikom na akciju Nova usluga, možeš uneti uslugu Prvi
        pregled.
      </React.Fragment>
    ),
    payed_amount: 'Plaćeno',
    not_found: 'Usluga nije pronađena. Ili je obrisana, ili ne postoji.',
  },
  service_items: {
    destroy_service_item_head:
      'Da li ste sigurni da želite da obrišete ovaj tretman?',
    destroy_service_item_notice:
      'Brisanjem uklanjate i sve beleške za ovaj tretman. Akcija se ne može poništiti!',
    service_item_destroyed: 'Tretman uklonjen.',
    select_treatment: 'Izaberi tretman',
    add_treatment: 'Dodaj tretman',
  },
  patient_notes: {
    title: 'Beleške',
    notes_dropdown: 'Pregled beleški sa tretmana',
    notes_head: 'Beleške za pacijenta',
    date: 'Datum',
    add_notes: 'Dodaj beleške',
    progress_rate: 'Oceni napredak',
    empty_state_heading:
      'Prati napredak pacijenta evidentirajući beleške nakon svakog tretmana.',
    notes_saved: 'Beleške sačuvane.',
    notes_destroyed: 'Beleške obrisane.',
    destroy_patient_note_head: 'Obriši beleške',
    destroy_patient_note_notice: 'Akcija se ne može poništiti.',
  },
  service_payments: {
    title: 'Pregled uplata',
    title_short: 'Uplate',
    empty_state_description: 'Ovde se prikazuju sve evidentirane uplate.',
    new: 'Dodaj uplatu',
    payment_hint:
      'Ova uplata će pokriti sve usluge u veličini uplate, počevši od najstarije.',
    overflow_error: 'Iznos uplate mora biti manji od ukupnog duga za uslugu.',
    inflow_overflow_error:
      'Iznos uplate mora biti manji od ukupnog duga pacijenta.',
    patient: 'Pacijent',
    date: 'Datum',
    payment_type: 'Tip uplate',
    amount: 'Iznos',
    service_payment_saved: 'Uplata sačuvana.',
    service_payment_destroyed: 'Uplata obrisana.',
  },
  patient_reports: {
    title: 'Izveštaji',
    new: 'Novi izveštaj',
    empty_state_heading:
      'Sastavi personalizovane izveštaje za svakog pacijenta. Izaberi boju, logo i izvezi u PDF.',
    not_found: 'Izveštaj nije pronađen. Ili je obrisan, ili ne postoji.',
    date: 'Datum',
    patient_name: 'Ime i prezime',
    patient_birth_date: 'Datum rođenja',
    report_type: 'Tip izveštaja',
    section_title: 'Unesi naslov',
    section_content: 'Unesi sadržaj',
    add_section: 'Dodaj sekciju',
    patient_report_destroyed: 'Izveštaj obrisan.',
    destroy_patient_report_head: 'Obriši izveštaj',
    destroy_patient_report_notice: 'Akcija se ne može poništiti.',
  },
  patient_documents: {
    title: 'Datoteke',
    new: 'Nova datoteka',
    edit: 'Izmeni podatke',
    empty_state_heading:
      'Ovde možeš čuvati sva dokumenta, slike ili video zapise za pacijenta.',
    document_title: 'Naziv',
    date: 'Datum',
    file: 'Izaberi datoteku',
    document_created: 'Datoteka sačuvana.',
    document_saved: 'Izmene sačuvane.',
    document_destroyed: 'Datoteka obrisana.',
    destroy_document_head: 'Obriši datoteku',
    destroy_document_notice: 'Akcija se ne može poništiti.',
  },
  patient_invoices: {
    new: 'Nova faktura',
    invoice: 'Faktura',
    new_disabled_reason: 'Prvo izaberi usluge',
    number: 'Broj fakture (ostavi prazno za autogenerisanje)',
    date: 'Datum fakture',
    due_date: 'Rok za plaćanje',
    no_due_date: 'Bez roka',
    invoice_saved: 'Faktura sačuvana.',
    invoice_destroyed: 'Faktura obrisana.',
    not_found: 'Faktura nije pronađena. Ili je obrisana, ili ne postoji.',
    invoice_receiver: 'Unesi detalje o primaocu',
    instructions: 'Ostale informacije i instrukcije (opciono)',
    destroy_patient_invoice_head: 'Obriši fakturu',
    destroy_patient_invoice_notice: 'Akcija se ne može poništiti.',
    group_services: 'Grupiši usluge',
    ungroup_services: 'Razdvoj usluge',
    line_item_name: 'Naziv stavke',
    services_grouped: 'Usluge grupisane.',
    services_ungrouped: 'Usluge razdvojene.',
  },
  treatments: {
    title: 'Tretmani',
    new: 'Novi tretman',
    edit: 'Izmeni tretman',
    treatment_saved: 'Tretman sačuvan.',
    treatment_destroyed: 'Tretman obrisan.',
    empty_state_heading:
      'Ovde će se prikazivati sve usluge koje pruža tvoj kabinet.',
    empty_state_intro:
      'Napravi jedan individualni, a potom možeš i paketski tretman.',
    name: 'Naziv',
    duration: 'Trajanje',
    price: 'Cena tretmana',
    destroy_treatment_head: 'Obriši tretman',
    destroy_treatment_notice:
      'Brisanjem tretmana ga takodje uklanjaš iz paketa, ukoliko postoji. Svi tvoji zakazani tretmani će ostati.',
  },
  packages: {
    title: 'Paketi',
    new: 'Novi paket',
    edit: 'Izmeni paket',
    package_saved: 'Paket sačuvan.',
    package_destroyed: 'Paket obrisan.',
    treatments_included: 'Uključeni tretmani',
    include_treatments_error: 'Uključi tretmane u paket',
    empty_state_heading: 'Želiš da napraviš paket tretmana?',
    empty_state_intro:
      'Odaberi skup tretmana i od njih formiraj paket tretmana po nižoj ceni.',
    name: 'Naziv paketa',
    price: 'Cena paketa',
    destroy_package_head: 'Obriši paket',
    destroy_package_notice:
      'Brisanjem paketa ne uklanjaš do sada zakazane tretmane iz ovog paketa. Oni će ostati.',
    number_of_treatments: numberOfTreatments =>
      'tretman'.plural(numberOfTreatments),
  },
  finances: {
    title: 'Finansije',
    income_report_title: 'Prihodi u periodu',
    expense_report_title: 'Rashodi u periodu',
    chart_label: 'Iznos',
  },
  expenses: {
    title: 'Poslovni troškovi',
    new: 'Novi trošak',
    edit: 'Izmeni trošak',
    date: 'Datum',
    expense_type: 'Tip troška',
    amount: 'Iznos',
    description: 'Opis',
    expense_saved: 'Trošak sačuvan.',
    expense_destroyed: 'Trošak obrisan.',
    destroy_expense_head: 'Obriši poslovni trošak',
    destroy_expense_notice: 'Akcija se ne može poništiti.',
  },
  expense_types: {
    title: 'Tipovi troškova',
    new: 'Novi tip troškova',
    edit: 'Izmeni tip troškova',
    name: 'Naziv',
    expense_type_saved: 'Tip troškova sačuvan.',
    expense_type_destroyed: 'Tip troškova obrisan.',
    destroy_expense_type_head: 'Samo trenutak!',
    destroy_expense_type_notice:
      'Brisanjem ovog tipa, brišeš i sve sačuvane troškove za ovaj tip troška! Akcija se ne može poništiti.',
  },
  locations: {
    title: 'Lokacije',
    personal_location: 'Bez lokacije',
    all_locations: 'Sve lokacije',
    new: 'Nova lokacija',
    edit: 'Izmeni lokaciju',
    empty_state_heading:
      'Ovde se prikazuju sve tvoje prostorije i deljeni resursi',
    empty_state_intro:
      'Ukoliko radiš sa više terapeuta, možeš uneti kapacitete resursa kako ne bi došlo do preklapanja u rasporedu.',
    name: 'Naziv lokacije',
    capacity: 'Kapacitet',
    location_saved: 'Lokacija sačuvana.',
    location_destroyed: 'Lokacija obrisana.',
    location_label: 'Oznaka u rasporedu',
    destroy_location_head: 'Obriši lokaciju',
    destroy_location_notice:
      'Brisanjem lokacije prebacuješ sve zakazane tretmane van lokacije, ukoliko ih ima. To znači da će možda doći do preklapanja termina.',
  },
  employees: {
    title: 'Terapeuti',
    all_employees: 'Svi terapeuti',
    empty_state_heading: 'Odavde možeš da vodiš svoj kabinet.',
    empty_state_intro: 'Pozovi ostale terapeute i dodeli im pacijente.',
    send_invitation: 'Pošalji pozivnicu',
    invitation_sent: 'Pozivnica poslata.',
    send_invitation_info: (
      <React.Fragment>
        Unesi email adresu terapeuta i pošalji pozivnicu sa linkom za
        registraciju.
        <br />
        <br />
        Ostali terapeuti neće imati pregled finansija, ali će moći da
        evidentiraju uplate.
        <br />
        <br />
        Svi podaci su deljeni i svaka promena u vezi sa pacijentom ili uslugom
        je vidljiva i tebi i ostalim terapeutima.
      </React.Fragment>
    ),
    invitation_email: 'Email',
    invitation_exists_error: 'Pozivnica je već poslata.',
    subscription_exceeded_error:
      'Trenutna pretplata ne dozvoljava više naloga za terapeute. Kontaktirajte podršku preko info@logovel.com',
    subscription_exceeded_activate_user_error:
      'Pretplata ne podržava više aktivnih naloga.',
    activation_freeze_period_error:
      'Nalog se može aktivirati najmanje 24h posle arhiviranja.',
    revoke_invitation: 'Otkaži',
    invitation_destroyed: 'Pozivnica obrisana.',
    destroy_invitation_head: 'Otkaži pozivnicu za',
    destroy_invitation_notice:
      'Možeš je opet poslati, a terapeut ne mora koristiti ovaj email kako bi se registrovao.',
    employee_archived: 'Korisnik arhiviran.',
    employee_activated: 'Korisnik aktiviran.',
    worked_with_patients: 'Rad sa pacijentima u izabranom periodu',
    assessment: 'Procena učinka',
    edit_employee: 'Izmeni terapeuta',
    name: 'Ime i prezime',
    employee_title: 'Zvanje',
    email: 'Email',
    employee_saved: 'Podaci sačuvani.',
    admin: 'Admin',
    therapist: 'Terapeut',
  },
  posts: {
    title: 'Novosti',
    new: 'Nova objava',
    edit: 'Izmeni objavu',
    post_title: 'Naslov',
    post_content: 'Sadržaj',
    post_created: 'Vest objavljena.',
    post_updated: 'Objava ažurirana.',
    post_destroyed: 'Objava obrisana.',
    empty_state_heading: 'Dobrodošli na Logovel Novosti!',
    empty_state_intro:
      'Objavi sve što je u interesu tebi i kolegama iz kabineta kao što su korisne informacije, linkovi, podsetnici...',
    destroy_post_head: 'Obriši objavu',
    destroy_post_notice: 'Akcija se ne može poništiti.',
  },
  account: {
    title: 'Moj nalog',
    user_data_head: 'Tvoji podaci',
    organisation_data_head: 'Podaci tvog kabineta',
    security_head: 'Sigurnost naloga',
    name: 'Tvoje ime',
    email: 'Email',
    user_title: 'Tvoje zvanje (npr. mr defektolog - logoped)',
    organisation_name: 'Naziv kabineta',
    organisation_email: 'Email kabineta',
    organisation_website: 'Web stranica',
    organisation_address: 'Adresa',
    organisation_phone: 'Broj telefona',
    company_identifier: 'Matični broj',
    company_identifier_short: 'MB',
    tax_number: 'PIB',
    current_password_wrong_error: 'Trenutna lozinka je pogrešna.',
    current_password: 'Trenutna lozinka',
    new_password: 'Nova lozinka',
    change_password: 'Promeni lozinku',
    info_saved: 'Podaci sačuvani.',
    password_changed: 'Lozinka promenjena.',
    logo: 'Logo',
    storage_limit_reached:
      'Dostignut je limit, kontaktirajte podršku za povećanje.',
  },
  notifications: {
    title: 'Notifikacije',
    empty_state_heading: 'Notifikacije',
    empty_state_intro: 'Sve na šta treba da obratiš pažnju.',
    patient_inactive: {
      title: 'Pacijent neaktivan',
      content:
        'ne dolazi na tretmane nekoliko meseci. Želiš li da arhiviraš pacijenta?',
    },
    post_created: {
      title: 'Nova vest',
    },
  },
  subscription: {
    title: 'Pretplata',
    valid_until: days => `još ${days} ${'dan'.plural(days)}`,
    headline: 'Pretplati se na Logovel',
    intro_1:
      'Pretplata je bez ugovora i možeš je otkazati u bilo kom trenutku.',
    intro_2:
      'Uz fakturu koju dobiješ, Logovel predstavlja poslovni trošak koji se odbija od poreza.',
    active_and_valid_until: date => `Pretplata je aktivna i važi do ${date}`,
    included: [
      { label: 'Evidencija pacijenata' },
      { label: 'Deljeni kalendar' },
      { label: 'Pametno zakazivanje' },
      { label: 'Tretmani i paketi' },
      { label: 'Evidencija uplata' },
      { label: 'Beleške o tretmanima' },
      { label: 'Personalizovani izveštaji' },
      { label: 'Upravljanje kabinetom', admin: true },
      { label: 'Pregled učinka svih terapeuta', admin: true },
      { label: 'Pregled prihoda i rashoda kabineta', admin: true },
    ],
    monthly: 'mesečno',
    yearly: 'godišnje',
    admin_account: 'Admin nalog',
    therapist_account: 'Terapeut nalog',
    save_money: 'Uštedi',
    with_yearly_subscription: 'godišnjom pretplatom',
    contact_for_payment_instructions: email =>
      `Kontaktiraj nas na ${email} za detalje oko plaćanja.`,
  },
  actions: {
    log_in: 'Prijavi se',
    log_out: 'Odjavi se',
    register: 'Registruj se',
    save: 'Sačuvaj',
    edit: 'Izmeni',
    close: 'Zatvori',
    activate: 'Aktiviraj',
    archive: 'Arhiviraj',
    destroy: 'Obriši',
    confirm: 'Potvrdi',
    cancel: 'Odustani',
    back: 'Nazad',
    send: 'Pošalji',
    download: 'Preuzmi',
    load_more: 'Učitaj još',
    get_pdf: 'Preuzmi PDF',
  },
  common_labels: {
    choose_color: 'Izaberi boju',
    organisation_data_notice:
      'Podatke o kabinetu možeš promeniti na stranici Moj nalog.',
  },
  system: {
    help: 'Pomoć',
    global_error: 'Ups, došlo je do problema. Pokušaj ponovo kasnije.',
    too_many_requests: 'Previše pogrešnih pokušaja. Pokušaj opet malo kasnije.',
    wait_notice: 'Samo trenutak!',
    more: 'Više',
    show_more: 'Prikaži više',
    new: 'Novo',
    saving: 'Čuvanje u toku',
    no_options: 'Nema opcija',
    coming_soon: 'Opcija dolazi uskoro u Logovel!',
    new_api_version:
      'Dostupna je nova verzija aplikacije. Potrebno je osvežiti stranicu (refresh akcija) u pretraživaču.',
  },
  validations: {
    field_required: 'Polje je obavezno',
  },
  dates: {
    today: 'Danas',
    week: 'Sedam dana',
    month: 'Mesec dana',
    custom: 'Izaberi period',
    current_month: 'Tekući mesec',
    last_month: 'Prošli mesec',
    current_year: 'Tekuća godina',
    last_year: 'Prošla godina',
    time_caption: 'Vreme',
  },
  days: {
    week: '7 dana',
    two_weeks: '14 dana',
    month: '30 dana',
  },
  currencies: {
    aud: 'Australijski dolar',
    bam: 'Konvertibilna marka',
    cad: 'Kanadski dolar',
    chf: 'Švajcarski franak',
    gbp: 'Britanska funta',
    eur: 'Euro',
    hrk: 'Hrvatska kuna',
    huf: 'Mađarska forinta',
    nok: 'Norveška kruna',
    rsd: 'Srpski dinar',
    rub: 'Ruska rublja',
    sek: 'Švedska kruna',
    usd: 'Američki dolar',
  },
  payment_types: {
    direct: 'Uplata u kešu',
    account: 'Uplata na račun',
  },
};

export default sr;
