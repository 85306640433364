import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_Box.module.scss';

class Box extends Component {
  static propTypes = {
    header: PropTypes.string,
    intro: PropTypes.string,
  };

  render() {
    const { header, intro } = this.props;

    return (
      <div className={styles['box']}>
        {header && <div className={styles['header']}>{header}</div>}
        {intro && <div className={styles['intro']}>{intro}</div>}
        {this.props.children}
      </div>
    );
  }
}

export default Box;
