import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BottomSheet from 'ui/Dialog/BottomSheet';

class MobileDateRange extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    range: PropTypes.shape({
      from_date: PropTypes.instanceOf(Date).isRequired,
      to_date: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
    ranges: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  _valueFromRange = () => {
    const { ranges, range } = this.props;

    const rangeOfInterest = ranges
      .filter(r => r.value)
      .find(
        r =>
          r.value.from_date.effectivelySame(range.from_date) &&
          r.value.to_date.effectivelySame(range.to_date)
      );

    // This should never be null,
    // but just in case if someone copy/paste url from desktop and open it on mobile
    return rangeOfInterest ? rangeOfInterest.value : null;
  };

  render() {
    const { open, onClose, ranges, onChange } = this.props;
    const value = this._valueFromRange();

    return (
      <BottomSheet
        open={open}
        onClose={onClose}
        items={ranges}
        onSelect={onChange}
        selectedItem={value}
      />
    );
  }
}

export default MobileDateRange;
