import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './ForgotPassword.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import OnboardNav from './../_OnboardNav';
import Box from './../_Box';
import ErrorBox from './../_ErrorBox';
import { Form, FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import { Button } from 'ui/Button';
import { connect, actions } from 'util/redux';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
      },
      errors: {},
      isSaving: false,
      showMessage: false,
    };
  }

  _changeData = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
  };

  _sendInstructions = () => {
    const { sendResetPasswordInstructions } = this.props;
    const { email } = this.state.data;

    this.setState({ isSaving: true, showMessage: false, errors: {} });
    sendResetPasswordInstructions(email)
      .then(() => {
        this.setState({ isSaving: false, showMessage: true });
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: { _global: locale().system.global_error },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _globalError = () => <ErrorBox>{this.state.errors._global}</ErrorBox>;

  _successMessage = () => (
    <div className={styles['success']}>
      {locale().onboard.reset_password_instructions_sent}
    </div>
  );

  _form = () => {
    const { data, errors, isSaving } = this.state;

    return (
      <Form onSubmit={this._sendInstructions}>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().onboard.email} error={errors.email} />
            <TextInput
              value={data.email}
              onChange={value => this._changeData('email', value)}
              type={'email'}
            />
          </FormColumn>
        </FormRow>
        <div className={styles['action']}>
          <Button
            value={locale().onboard.send_reset_instructions}
            type={'submit'}
            disabled={isSaving}
            fill={true}
          />
        </div>
      </Form>
    );
  };

  _mobile = () => {
    const { showMessage } = this.state;

    let body;
    if (showMessage) {
      body = this._successMessage();
    } else {
      body = (
        <React.Fragment>
          {this._globalError()}
          {this._form()}
        </React.Fragment>
      );
    }

    return <div className={styles['mobileWrapper']}>{body}</div>;
  };

  _desktop = () => {
    const { showMessage } = this.state;

    if (showMessage) {
      return <Box>{this._successMessage()}</Box>;
    } else {
      return (
        <Box
          header={locale().onboard.forgot_password_question}
          intro={locale().onboard.forgot_password_info}
        >
          {this._globalError()}
          {this._form()}
        </Box>
      );
    }
  };

  render() {
    const { openLoginPage } = this.props;

    return (
      <React.Fragment>
        <OnboardNav
          actionQuestion={locale().onboard.know_password_question}
          actionTitle={locale().actions.log_in}
          action={openLoginPage}
        />
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openLoginPage: () => dispatch(actions.openLoginPage()),
    sendResetPasswordInstructions: email =>
      dispatch(actions.sendResetPasswordInstructions(email)),
  };
};

ForgotPassword = connect(null, mapDispatchToProps)(ForgotPassword);

export default ForgotPassword;
