import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { push } from 'connected-react-router';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_POST_EDITING = 'START_POST_EDITING';
const FINISH_POST_EDITING = 'FINISH_POST_EDITING';

export default (
  state = { editingPost: emptyPostData(), postDialogOpen: false },
  action
) => {
  switch (action.type) {
    case START_POST_EDITING:
      return {
        ...state,
        editingPost: action.post,
        postDialogOpen: true,
      };
    case FINISH_POST_EDITING:
      return {
        ...state,
        editingPost: emptyPostData(),
        postDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const openPostsPage = () => push('/posts');

const emptyPostData = () => ({
  title: '',
  content: '',
});

const newPost = () => {
  return {
    type: START_POST_EDITING,
    post: emptyPostData(),
  };
};

const editPost = post => {
  return {
    type: START_POST_EDITING,
    post,
  };
};

const finishPostEditing = () => {
  return {
    type: FINISH_POST_EDITING,
  };
};

const fetchPosts = () => {
  return dispatch => {
    return Api.posts.query().then(response => {
      if (response.isOk()) {
        dispatch(clearQuery(entityTypes.post));
        const query = response.data();
        dispatch(receiveQuery(entityTypes.post, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPost = data => {
  return dispatch => {
    return Api.posts.create(data).then(response => {
      if (response.isOk()) {
        const post = response.data();
        dispatch(receiveEntities(entityTypes.post, [post]));
        dispatch(systemActions.showToast(locale().posts.post_created));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updatePost = data => {
  return dispatch => {
    return Api.posts.update(data).then(response => {
      if (response.isOk()) {
        const post = response.data();
        dispatch(receiveEntities(entityTypes.post, [post]));
        dispatch(systemActions.showToast(locale().posts.post_updated));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPost = id => {
  return dispatch => {
    return Api.posts.destroy(id).then(response => {
      if (response.isOk()) {
        const post = response.data();
        dispatch(removeEntities(entityTypes.post, [post.id]));
        dispatch(systemActions.showToast(locale().posts.post_destroyed));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openPostsPage,
  newPost,
  editPost,
  finishPostEditing,
  fetchPosts,
  createPost,
  updatePost,
  destroyPost,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getPosts = state => getEntityList(state, entityTypes.post);

const getEditingPost = state => state.editingPost;

const isEditingPost = state => state.postDialogOpen;

export const selectors = {
  getPosts,
  getEditingPost,
  isEditingPost,
};
