import React from 'react';

const hr = {
  onboard: {
    invalid_email_or_password_error: 'Pogrešna email adresa ili lozinka.',
    user_name: 'Tvoje ime',
    organisation_name: 'Naziv tvoje prakse',
    organisation_currency: 'Odaberi valutu',
    language: 'Odaberi jezik',
    email: 'Email adresa',
    password: 'Tvoja lozinka',
    choose_password: 'Odaberi lozinku',
    forgot_password_question: 'Zaboravljena lozinka?',
    need_account_question: 'Treba ti račun?',
    login_head: 'Prijavi se na svoj Logovel račun',
    login_intro: 'Prati tretmane i termine svojih pacijenata',
    invalid_invitation_error: 'Pozivnica je nevaljana.',
    registration_through_invitation_info: 'Registracija putem pozivnice',
    subscription_exceeded_error:
      'Trenutna pretplata ne dozvoljava više računa za terapeute',
    agree_to_terms: 'Kreiranjem računa prihvatate',
    terms_of_service: 'uvjete korištenja',
    registration_head: 'Aplikacija za defektologe',
    registration_intro: 'Pacijenti i tretmani u oblaku',
    registration_small_head: 'Kreiraj svoj Logovel račun',
    registration_small_intro: 'Zaboravi na bilježnice i papire',
    already_have_account_question: 'Imaš račun?',
    send_reset_instructions: 'Pošalji upute',
    reset_password_instructions_sent:
      'Uputi za resetiranje lozinke poslani su na vašu email adresu.',
    forgot_password_info:
      'Nema problema. Unesi svoju email adresu i šaljemo ti upute za resetiranje.',
    know_password_question: 'Imaš lozinku?',
    reset_password_head: 'Promijenite lozinku',
    reset_password_intro: 'Odaberi novu lozinku za svoj Logovel račun',
    reset_password_invalid_link_error: 'Link za resetiranje je nevalidan.',
    reset_password_link_expired_error:
      'Link je istekao. Pošalji ponovo upute za resetiranje.',
    reset_password_successful_info:
      'Lozinka promijenjena. Iskoristi je da se prijaviš na svoj račun.',
    new_password: 'Nova lozinka',
  },
  dashboard: {
    title: 'Početna',
    greeting: 'Kratak pregled tvog poslovanja',
    finance_overview_head: 'Financije za tekući mjesec',
    finance_income: 'Prihod',
    finance_debt: 'Ukupni dug',
    finance_expense: 'Troškovi',
    finance_chart_head: 'Financije po mjesecima',
    quick_actions: 'Brze akcije',
  },
  practice: {
    title: 'Praksa',
  },
  schedule: {
    title: 'Raspored',
    empty_state_heading: 'Nijedan tretman nije zakazan za odabrani datum.',
    number_of_participants: 'Broj sudionika',
    no_location_name: 'Bez lokacije',
    no_location_short_name: '*',
    resources_type: {
      user: 'Terapeuti',
      location: 'Lokacije',
    },
  },
  patients: {
    title: 'Pacijenti',
    options: 'Dodaci',
    search_by: 'Traži po imenu ili broju telefona...',
    number_of_patients: 'Broj pacijenata',
    active_patients: 'Aktivni pacijenti',
    archived_patients: 'Arhivirani pacijenti',
    archived: 'Arhiviran',
    new: 'Novi pacijent',
    edit: 'Uredi pacijenta',
    empty_state_heading: 'Ovdje će biti prikazani svi tvoji pacijenti.',
    empty_state_intro: 'Zašto ne kreiraš jednog odmah?',
    name: 'Ime i prezime',
    gender: 'Spol',
    birth_date: 'Datum rođenja',
    birthday_today: 'Rođendan je danas!',
    birthday_soon: 'Rođendan je uskoro',
    birthday_recently: 'Rođendan je bio nedavno',
    diagnose: 'Dijagnoza',
    patient_referral: 'Kako je pacijent saznao za praksu?',
    parent_name: 'Ime roditelja',
    phone_number: 'Broj telefona',
    email: 'Email',
    assign_to_who: 'Kome je sve pacijent dodijeljen?',
    anamnesis: 'Povijest i bilješke',
    anamnesis_info: 'Dodaj anamnestičke podatke ili bilješke...',
    add_birth_date: 'Dodaj datum rođenja...',
    male: 'Muški',
    female: 'Ženski',
    patient_saved: 'Pacijent snimljen.',
    patient_archived: 'Pacijent arhiviran.',
    patient_activated: 'Pacijent aktiviran.',
    patient_destroyed: 'Pacijent izbrisan.',
    no_debt: 'Plaćeno',
    destroy_patient_head: 'Izbriši pacijenta',
    destroy_patient_notice:
      'Brisanjem pacijenta brišeš i sve njegove zakazane tretmane i plaćanja. Akcija se ne može poništiti!',
    not_found: 'Pacijent nije pronađen. Ili je izbrisan, ili ne postoji.',
    patient_data_head: 'Podaci o pacijentu',
  },
  appointments: {
    title: 'Tretmani',
    empty_state_description:
      'Ovde se prikazuju svi zakazani i otkazani tretmani.',
    details: 'Pojedinosti',
    not_scheduled: 'Tretman nije zakazan',
    schedule_an_appointment: 'Zakaži tretman',
    appointment_scheduled: 'Tretman zakazan.',
    scheduled_for: 'Zakazan za',
    cancelled: 'Otkazan',
    cancel_appointment: 'Otkaži tretman',
    appointment_canceled: 'Termin otkazan.',
    cancel_appointment_notice:
      'Da li ste sigurni da želite otkazati ovaj tretman?',
    cancel_debt: 'Otkaži dug za uslugu',
    cancellation_reason: 'Neobavezni razlog otkazivanja',
    appointment_taken_error: 'Odabrani termin je zauzet.',
    location_taken_error: 'Lokacija je zauzeta u odabrano vrijeme.',
    users_required: 'Bar jedan terapeut mora sudjelovati u tretmanu.',
    events_required: 'Unesi podatke o pacijentima koji sudjeluju u tretmanu.',
    already_scheduled: 'Usluga je već zakazana.',
    group_treatment: 'Grupni tretman',
    appointment_user: 'Tko obavlja tretman?',
    select_patient: 'Odaberi pacijenta',
    select_service: 'Odaberi uslugu',
    select_service_item: 'Odaberi tretman',
    add_patient: 'Uključi još jednog pacijenta',
    not_found: 'Tretman nije pronađen. Ili je otkazan ili ne postoji.',
    show_service: 'Vidi uslugu',
  },
  patient_referrals: {
    title: 'Preporuke',
    all_patient_referrals: 'Sve preporuke',
    name: 'Naziv',
    destroy_patient_referral_head: 'Obriši preporuku',
    destroy_patient_referral_notice:
      'Brisanjem ove preporuke je uklanjaš i sa svih pacijenata. Akcija se ne može poništiti!',
    patient_referral_saved: 'Preporuka snimljena.',
    patient_referral_destroyed: 'Preporuka izbrisana.',
  },
  diagnoses: {
    title: 'Dijagnoze',
    all_diagnoses: 'Sve dijagnoze',
    name: 'Naziv',
    destroy_diagnose_head: 'Izbriši dijagnozu',
    destroy_diagnose_notice:
      'Brisanjem ove dijagnoze je uklanjaš i sa svih pacijenata. Akcija se ne može poništiti!',
    diagnose_saved: 'Dijagnoza snimljena.',
    diagnose_destroyed: 'Dijagnosa izbrisana.',
  },
  services: {
    title: 'Usluge',
    new: 'Nova usluga',
    edit: 'Uredi uslugu',
    assign: 'Odaberi uslugu',
    activated_on: 'Aktiviran',
    price: 'Cijena usluge',
    empty_state_description:
      'Ovde se prikazuju sve usluge povezane sa pacijentom. Prilikom zakazivanja tretmana, dug pacijenta raste za cijenu usluge.',
    service_assigned: 'Usluga dodijeljena.',
    service_updated: 'Usluga ažurirana.',
    service_destroyed: 'Usluga izbrisana.',
    destroy_service_head: 'Izbriši uslugu',
    destroy_service_notice:
      'Brisanjem usluge brišeš i sve zakazane tretmane i plaćanja za ovu uslugu. Akcija se ne može poništiti!',
    appointments_scheduled: (part, sum) => `${part}/${sum} tretmana zakazano`,
    service_not_selected: 'Prvo odaberite uslugu.',
    no_services_notice: () => (
      <React.Fragment>
        Trenutno ne postoji ni jedna usluga. Na stranici svih usluga možeš
        unijeti tretmane i pakete tretmana. <br />
        <br />
        Za početak, klikom na akciju Nova usluga, možeš unijeti uslugu Prvi
        pregled.
      </React.Fragment>
    ),
    payed_amount: 'Plaćeno',
    not_found: 'Usluga nije pronađena. Ili je izbrisana, ili ne postoji.',
  },
  service_items: {
    destroy_service_item_head:
      'Da li ste sigurni da želite da izbrišete ovaj tretman?',
    destroy_service_item_notice:
      'Brisanjem uklanjate i sve bilješke za ovaj tretman. Akcija se ne može poništiti!',
    service_item_destroyed: 'Tretman izbrisan.',
    select_treatment: 'Odaberi tretman',
    add_treatment: 'Dodaj tretman',
  },
  patient_notes: {
    title: 'Bilješke',
    notes_dropdown: 'Pregled bilješki sa tretmana',
    notes_head: 'Bilješke o pacijentu',
    date: 'Datum',
    add_notes: 'Dodaj bilješke',
    progress_rate: 'Procijeni napredak',
    empty_state_heading:
      'Prati napredak pacijenta bilježeći opažanja nakon svakog tretmana.',
    notes_saved: 'Bilješke snimljene.',
    notes_destroyed: 'Bilješke izbrisane.',
    destroy_patient_note_head: 'Izbriši bilješke',
    destroy_patient_note_notice: 'Akcija se ne može poništiti.',
  },
  service_payments: {
    title: 'Pregled plaćanja',
    title_short: 'Plaćanja',
    empty_state_description: 'Ovdje se prikazana sva evidentirana plaćanja.',
    new: 'Dodaj plaćanje',
    payment_hint:
      'Ovo plaćanje pokriva sve usluge u iznosu plaćanja, počevši od najstarijeg.',
    overflow_error: 'Iznos plaćanja mora biti manji od ukupnog duga za uslugu.',
    inflow_overflow_error:
      'Iznos plaćanja mora biti manji od ukupnog duga pacijenta.',
    patient: 'Pacijent',
    date: 'Datum',
    payment_type: 'Tip plaćanja',
    amount: 'Iznos',
    service_payment_saved: 'Plaćanje snimljeno.',
    service_payment_destroyed: 'Plaćanje izbrisano.',
  },
  patient_reports: {
    title: 'Izvještaji',
    new: 'Novi izvještaj',
    empty_state_heading:
      'Sastavi prilagođene izvještaje za svakog pacijenta. Odaberi boju, logotip i izvezi u PDF.',
    not_found: 'Izvještaj nije pronađen. Ili je izbrisan, ili ne postoji.',
    date: 'Datum',
    patient_name: 'Ime i prezime',
    patient_birth_date: 'Datum rođenja',
    report_type: 'Tip izvještaja',
    section_title: 'Unesi naslov',
    section_content: 'Unesi sadržaj',
    add_section: 'Dodaj odjeljak',
    patient_report_destroyed: 'Izvještaj izbrisan.',
    destroy_patient_report_head: 'Izbriši izvještaj',
    destroy_patient_report_notice: 'Akcija se ne može poništiti.',
  },
  patient_documents: {
    title: 'Datoteke',
    new: 'Nova datoteka',
    edit: 'Uredi podatke',
    empty_state_heading:
      'Ovde možeš prenijeti sve dokumente, slike ili videozapise pacijenta.',
    document_title: 'Naziv',
    date: 'Datum',
    file: 'Odaberi datoteku',
    document_created: 'Datoteka snimljena.',
    document_saved: 'Promjene snimljene.',
    document_destroyed: 'Datoteka izbrisana.',
    destroy_document_head: 'Izbriši datoteku',
    destroy_document_notice: 'Akcija se ne može poništiti.',
  },
  patient_invoices: {
    new: 'Nova dostavnica',
    invoice: 'Dostavnica',
    new_disabled_reason: 'Prvo odaberi usluge',
    number: 'Broj dostavnice (ostavi prazno za autogeneraciju)',
    date: 'Datum dostavnice',
    due_date: 'Datum dospijeća',
    no_due_date: 'Bez roka',
    invoice_saved: 'Dostavnica snimljena.',
    invoice_destroyed: 'Dostavnica izbrisana.',
    not_found: 'Dostavnica nije pronađena. Ili je izbrisana, ili ne postoji.',
    invoice_receiver: 'Unesi detalje o primatelju',
    instructions: 'Ostale informacije i instrukcije (opciono)',
    destroy_patient_invoice_head: 'Izbriši fakturu',
    destroy_patient_invoice_notice: 'Akcija se ne može poništiti.',
    group_services: 'Grupiraj usluge',
    ungroup_services: 'Odvoj usluge',
    line_item_name: 'Naziv stavke',
    services_grouped: 'Usluge grupirane.',
    services_ungrouped: 'Usluge odvojene.',
  },
  treatments: {
    title: 'Tretmani',
    new: 'Novi tretman',
    edit: 'Uredi tretman',
    treatment_saved: 'Tretman snimljen.',
    treatment_destroyed: 'Tretman izbrisan.',
    empty_state_heading:
      'Ovde će se prikazivati sve usluge koje pruža tvoja praksa.',
    empty_state_intro:
      'Kreiraj jedan treatman, a potom možeš i pakete tretmana.',
    name: 'Naziv',
    duration: 'Trajanje',
    price: 'Cijena tretmana',
    destroy_treatment_head: 'Izbriši tretman',
    destroy_treatment_notice:
      'Brisanjem tretmana ga uklanjaš iz paketa, ukoliko postoji. Svi zakazani tretmani će ostati.',
  },
  packages: {
    title: 'Paketi',
    new: 'Novi paket',
    edit: 'Uredi paket',
    package_saved: 'Paket snimljen.',
    package_destroyed: 'Paket izbrisan.',
    treatments_included: 'Uključeni tretmani',
    include_treatments_error: 'Uključi tretmane u paket',
    empty_state_heading: 'Želiš napraviti paket tretmana?',
    empty_state_intro:
      'Odaberi skup tretmana i od njih formiraj paket tretmana po nižoj cijeni.',
    name: 'Naziv paketa',
    price: 'Cijena paketa',
    destroy_package_head: 'Izbriši paket',
    destroy_package_notice:
      'Brisanjem paketa ne uklanjaš zakazane tretmane iz ovog paketa. Oni će ostati.',
    number_of_treatments: numberOfTreatments =>
      'tretman'.plural(numberOfTreatments),
  },
  finances: {
    title: 'Financije',
    income_report_title: 'Prihodi u periodu',
    expense_report_title: 'Troškovi u periodu',
    chart_label: 'Iznos',
  },
  expenses: {
    title: 'Poslovni troškovi',
    new: 'Novi trošak',
    edit: 'Uredi trošak',
    date: 'Datum',
    expense_type: 'Tip troška',
    amount: 'Iznos',
    description: 'Opis',
    expense_saved: 'Trošak snimljen.',
    expense_destroyed: 'Trošak izbrisan.',
    destroy_expense_head: 'Izbriši poslovni trošak',
    destroy_expense_notice: 'Akcija se ne može poništiti.',
  },
  expense_types: {
    title: 'Tipovi troškova',
    new: 'Novi tip troškova',
    edit: 'Uredi tip troškova',
    name: 'Naziv',
    expense_type_saved: 'Tip troškova snimljen.',
    expense_type_destroyed: 'Tip troškova izbrisan.',
    destroy_expense_type_head: 'Samo trenutak!',
    destroy_expense_type_notice:
      'Brisanjem ovog tipa, brišeš i sve troškove za ovaj tip troška! Akcija se ne može poništiti.',
  },
  locations: {
    title: 'Lokacije',
    personal_location: 'Bez lokacije',
    all_locations: 'Sve lokacije',
    new: 'Nova lokacija',
    edit: 'Uredi lokaciju',
    empty_state_heading:
      'Ovde se prikazuju sve tvoje sobe i zajednički resursi',
    empty_state_intro:
      'Ako radiš sa više terapeuta, možeš unijeti kapacitete resursa kako biste izbegli preklapanje u rasporeda.',
    name: 'Naziv lokacije',
    capacity: 'Kapacitet',
    location_saved: 'Lokacija snimljena.',
    location_destroyed: 'Lokacija izbrisana.',
    location_label: 'Oznaka u rasporedu',
    destroy_location_head: 'Izbriši lokaciju',
    destroy_location_notice:
      'Brisanjem lokacije prenosiš sve zakazane tretmane izvan lokacije, ako ih ima. To znači da može doći do preklapanja u rasporedu.',
  },
  employees: {
    title: 'Terapeuti',
    all_employees: 'Svi terapeuti',
    empty_state_heading: 'Odavde možeš voditi svoju praksu.',
    empty_state_intro: 'Pozovi druge terapeute i dodijeli im pacijente.',
    send_invitation: 'Pošalji pozivnicu',
    invitation_sent: 'Pozivnica poslata.',
    send_invitation_info: (
      <React.Fragment>
        Unesi email adresu terapeuta i pošalji pozivnicu sa linkom za
        registraciju.
        <br />
        <br />
        Ostali terapeuti neće imati pregled financija, ali će moći da
        evidentiraju plaćanja.
        <br />
        <br />
        Svi podaci se dijele i svaka promena u vezi sa pacijentom ili uslugom je
        vidljiva i tebi i ostalim terapeutima.
      </React.Fragment>
    ),
    invitation_email: 'Email',
    invitation_exists_error: 'Pozivnica je već poslata.',
    subscription_exceeded_error:
      'Trenutna pretplata ne dopušta više računa za terapeute. Kontaktirajte podršku putem info@logovel.com',
    subscription_exceeded_activate_user_error:
      'Pretplata ne dopušta više aktivnih računa.',
    activation_freeze_period_error:
      'Račun se može aktivirati najmanje 24h nakon arhiviranja.',
    revoke_invitation: 'Otkaži',
    invitation_destroyed: 'Pozivnica izbrisana.',
    destroy_invitation_head: 'Otkaži pozivnicu za',
    destroy_invitation_notice:
      'Možeš je opet poslati, a terapeut ne mora koristiti ovaj email za registraciju.',
    employee_archived: 'Korisnik arhiviran.',
    employee_activated: 'Korisnik aktiviran.',
    worked_with_patients: 'Rad sa pacijentima u odabranom razdoblju',
    assessment: 'Procjena utjecaja',
    edit_employee: 'Uredi terapeuta',
    name: 'Ime i prezime',
    employee_title: 'Titula',
    email: 'Email',
    employee_saved: 'Podaci snimljeni.',
    admin: 'Admin',
    therapist: 'Terapeut',
  },
  posts: {
    title: 'Vijesti',
    new: 'Nova objava',
    edit: 'Uredi objavu',
    post_title: 'Naslov',
    post_content: 'Sadržaj',
    post_created: 'Vijest objavljena.',
    post_updated: 'Objava uređena.',
    post_destroyed: 'Objava izbrisana.',
    empty_state_heading: 'Dobrodošli na Logovel Vijesti!',
    empty_state_intro:
      'Objavi sve što tebe i kolege iz prakse zanima poput korisnih informacija, linkova, podsjetnika...',
    destroy_post_head: 'Izbriši objavu',
    destroy_post_notice: 'Akcija se ne može poništiti.',
  },
  account: {
    title: 'Moj račun',
    user_data_head: 'Tvoji podaci',
    organisation_data_head: 'Podaci tvoje prakse',
    security_head: 'Sigurnost računa',
    name: 'Tvoje ime',
    email: 'Email',
    user_title: 'Tvoja titula (npr. mr defektolog - logoped)',
    organisation_name: 'Naziv prakse',
    organisation_email: 'Email prakse',
    organisation_website: 'Web stranica',
    organisation_address: 'Adresa',
    organisation_phone: 'Broj telefona',
    company_identifier: 'Osobni identifikacijski broj',
    company_identifier_short: 'OIB',
    tax_number: 'VAT ID',
    current_password_wrong_error: 'Trenutna lozinka nije točna.',
    current_password: 'Trenutna lozinka',
    new_password: 'Nova lozinka',
    change_password: 'Promijeni lozinku',
    info_saved: 'Podaci snimljeni.',
    password_changed: 'Lozinka promijenjena.',
    logo: 'Logo',
    storage_limit_reached:
      'Dosegnuto ograničenje, kontaktirajte podršku za povećanje.',
  },
  notifications: {
    title: 'Obavijesti',
    empty_state_heading: 'Obavijesti',
    empty_state_intro: 'Sve na što trebaš obratiti pozornost.',
    patient_inactive: {
      title: 'Pacijent neaktivan',
      content:
        'ne dolazi na tretmane nekoliko mjeseci. Želiš li da arhiviraš pacijenta?',
    },
    post_created: {
      title: 'Nova vijest',
    },
  },
  subscription: {
    title: 'Pretplata',
    valid_until: days => `još ${days} ${'dan'.plural(days)}`,
    headline: 'Pretplati se na Logovel',
    intro_1:
      'Pretplata je bez ugovora i možeš je otkazati u bilo kojem trenutku.',
    intro_2:
      'Uz fakturu koju primiš, Logovel predstavlja poslovni trošak koji se odbija od poreza.',
    active_and_valid_until: date => `Pretplata je aktivna do ${date}`,
    included: [
      { label: 'Zapisi pacijenata' },
      { label: 'Zajednički kalendar' },
      { label: 'Pametno zakazivanje' },
      { label: 'Tretmani i paketi' },
      { label: 'Evidencija plaćanja' },
      { label: 'Bilješke o tretmanima' },
      { label: 'Personalizirana izvješća' },
      { label: 'Upravljanje praksom', admin: true },
      { label: 'Pregled uspješnosti svih terapeuta', admin: true },
      { label: 'Pregled prihoda i rashoda prakse', admin: true },
    ],
    monthly: 'mjesečno',
    yearly: 'godišnje',
    admin_account: 'Admin račun',
    therapist_account: 'Terapeut račun',
    save_money: 'Uštedi',
    with_yearly_subscription: 'godišnjom pretplatom',
    contact_for_payment_instructions: email =>
      `Kontaktiraj nas na ${email} za detalje plaćanja.`,
  },
  actions: {
    log_in: 'Prijavi se',
    log_out: 'Odjavi se',
    register: 'Registriraj se',
    save: 'Snimi',
    edit: 'Uredi',
    close: 'Zatvori',
    activate: 'Aktiviraj',
    archive: 'Arhiviraj',
    destroy: 'Izbriši',
    confirm: 'Potvrdi',
    cancel: 'Odustani',
    back: 'Nazad',
    send: 'Pošalji',
    download: 'Preuzmi',
    load_more: 'Učitaj više',
    get_pdf: 'Preuzmi PDF',
  },
  common_labels: {
    choose_color: 'Odaberi boju',
    organisation_data_notice:
      'Podatke o praksi možeš promijeniti na stranici Moj račun.',
  },
  system: {
    help: 'Pomoć',
    global_error: 'Ups, došlo je do problema. Pokušaj ponovo kasnije.',
    too_many_requests: 'Previše pogrešnih pokušaja. Pokušaj opet malo kasnije.',
    wait_notice: 'Samo trenutak!',
    more: 'Više',
    show_more: 'Prikaži više',
    new: 'Novo',
    saving: 'Snimanje u tijeku',
    no_options: 'Nema opcija',
    coming_soon: 'Opcija dolazi uskoro u Logovel!',
    new_api_version:
      'Dostupna je nova verzija aplikacije. Potrebno je osvježiti stranicu (refresh akcija) u pretraživaču.',
  },
  validations: {
    field_required: 'Polje je obavezno',
  },
  dates: {
    today: 'Danas',
    week: 'Sedam dana',
    month: 'Mjesec dana',
    custom: 'Odaberi razdoblje',
    current_month: 'Trenutni mjesec',
    last_month: 'Prošli mjesec',
    current_year: 'Trenutna godina',
    last_year: 'Prošla godina',
    time_caption: 'Vrijeme',
  },
  days: {
    week: '7 dana',
    two_weeks: '14 dana',
    month: '30 dana',
  },
  currencies: {
    aud: 'Australski dolar',
    bam: 'Konvertibilna marka',
    cad: 'Kanadski dolar',
    chf: 'Švicarski franak',
    gbp: 'Britanska funta',
    eur: 'Euro',
    hrk: 'Hrvatske kune',
    huf: 'Mađarska forinta',
    nok: 'Norveška kruna',
    rsd: 'Srpski dinar',
    rub: 'Ruska rublja',
    sek: 'Švedska kruna',
    usd: 'Američki dolar',
  },
  payment_types: {
    direct: 'Gotovinsko plaćanje',
    account: 'Plaćanje na račun',
  },
};

export default hr;
