import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PatientsForm.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { FormColumn, FormLabel, FormRow } from 'ui/Form';
import Icon from 'ui/Icon';
import TreatmentSelect from './_TreatmentSelect';
import AddCircle from './_AddCircle';
import { PatientSelectUI } from 'patients/PatientSelect';
import { connect, actions, selectors } from 'util/redux';

class PatientsForm extends Component {
  static propTypes = {
    patientsData: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchPatients } = this.props;
    fetchPatients();
  }

  _emptyPatientData = key => ({
    patientId: null,
    key: key,
    serviceId: null,
    serviceItemId: null,
  });

  _addAnotherPatient = () => {
    const { patientsData, onChange } = this.props;
    const newKey = Math.max(...patientsData.map(d => d.key)) + 1;

    const newPatientsData = [
      ...patientsData,
      { ...this._emptyPatientData(newKey) },
    ];

    onChange(newPatientsData);
  };

  _removePatient = key => {
    const { patientsData, onChange } = this.props;
    let newPatientsData = patientsData.filter(d => d.key !== key);

    onChange(newPatientsData);
  };

  _changePatientsData = (key, changes) => {
    const { patientsData, onChange } = this.props;
    const data = patientsData.find(d => d.key === key);

    let newData = { ...data, ...changes };
    const newPatientsData = [
      ...patientsData.filter(d => d.key !== key),
      newData,
    ];

    onChange(newPatientsData);
  };

  _changePatientId = (key, patientId) =>
    this._changePatientsData(key, {
      patientId,
      serviceId: null,
      serviceItemId: null,
    });

  _changeTreatmentData = (key, serviceId, serviceItemId) =>
    this._changePatientsData(key, { serviceId, serviceItemId });

  _patients = patientId => {
    const { patients, patientsData } = this.props;

    return patients.filter(
      patient =>
        patient.id === patientId ||
        !patientsData.find(d => d.patientId === patient.id)
    );
  };

  _removePatientNode = data => {
    const { patientsData } = this.props;

    return (
      <React.Fragment>
        {patientsData.length > 1 && (
          <FormColumn>
            <div className={styles['close']}>
              <Icon
                icon={'close'}
                color={'#949bac'}
                onClick={() => this._removePatient(data.key)}
              />
            </div>
          </FormColumn>
        )}
      </React.Fragment>
    );
  };

  _selectPatientNode = data => {
    return (
      <div className={styles['item']}>
        <FormLabel value={locale().appointments.select_patient} />
        <PatientSelectUI
          value={data.patientId}
          patients={this._patients(data.patientId)}
          onChange={value => this._changePatientId(data.key, value)}
          autoSelectOnCreation={true}
        />
      </div>
    );
  };

  _addNewPatientNode = () => {
    const { newPatient } = this.props;

    return <AddCircle onClick={newPatient} />;
  };

  _selectTreatmentNode = data => {
    return (
      <TreatmentSelect
        patientId={data.patientId}
        serviceId={data.serviceId}
        serviceItemId={data.serviceItemId}
        onChange={(serviceId, serviceItemId) =>
          this._changeTreatmentData(data.key, serviceId, serviceItemId)
        }
        selectClassName={styles['item']}
      />
    );
  };

  _mobile = () => {
    const { patientsData } = this.props;

    return (
      <React.Fragment>
        {patientsData.sortBy('key').map(data => (
          <div key={data.key} className={styles['form']}>
            <FormRow>{this._removePatientNode(data)}</FormRow>
            <FormRow>
              <FormColumn>{this._selectPatientNode(data)}</FormColumn>
              {this._addNewPatientNode()}
            </FormRow>
            {data.patientId && this._selectTreatmentNode(data)}
          </div>
        ))}
      </React.Fragment>
    );
  };

  _desktop = () => {
    const { patientsData } = this.props;

    return (
      <React.Fragment>
        {patientsData.sortBy('key').map(data => (
          <div key={data.key} className={styles['form']}>
            <FormRow>
              {this._removePatientNode(data)}
              <FormColumn>{this._selectPatientNode(data)}</FormColumn>
              <FormColumn>{this._addNewPatientNode()}</FormColumn>
              {data.patientId && this._selectTreatmentNode(data)}
            </FormRow>
          </div>
        ))}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className={styles['wrapper']}>
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        </div>
        <div className={styles['addAnother']} onClick={this._addAnotherPatient}>
          {locale().appointments.add_patient}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    patients: selectors.getPatients(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPatients: () => dispatch(actions.fetchPatients({ archived: false })),
    newPatient: () => dispatch(actions.newPatient()),
  };
};

PatientsForm = connect(mapStateToProps, mapDispatchToProps)(PatientsForm);

export default PatientsForm;
