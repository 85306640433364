import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQueryWatch from './MediaQueryWatch';

class MobileDesktopRender extends Component {
  static propTypes = {
    mobile: PropTypes.func,
    desktop: PropTypes.func,
  };

  static defaultProps = {
    mobile: () => {},
    desktop: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isDesktop: null,
    };
  }

  _changeMode = isDesktop => {
    this.setState({ isDesktop });
  };

  _render() {
    const { isDesktop } = this.state;
    const { desktop, mobile } = this.props;

    if (isDesktop === null) {
      return null;
    } else if (isDesktop) {
      return desktop();
    } else {
      return mobile();
    }
  }

  render() {
    return (
      <MediaQueryWatch
        mediaQuery={'(min-width: 800px)'}
        onMatchesChange={this._changeMode}
      >
        {this._render()}
      </MediaQueryWatch>
    );
  }
}

export default MobileDesktopRender;
