import './array';
import './date';
import './object';
import './string';
import './function';

window.Promise = require('bluebird');

Promise.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false,
  },
});
