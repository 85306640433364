import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FAB.module.scss';
import Icon from 'ui/Icon';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class FAB extends Component {
  static propTypes = {
    onClick: PropTypes.func, // User either onClick or items props
    icon: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        icon: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    icon: 'add',
  };

  constructor(props) {
    super(props);
    this.state = {
      itemsVisible: false,
    };
  }

  _multipleOptions = () => !!this.props.items;

  _showItems = () => this.setState({ itemsVisible: true });

  _hideItems = () => this.setState({ itemsVisible: false });

  _items = () => {
    const items = [
      ...this.props.items,
      {
        label: '',
        onClick: this._hideItems,
        icon: 'close',
        className: 'close',
        color: '#01325f',
      },
    ];

    return (
      <React.Fragment>
        <div className={styles['overlay']} onClick={this._hideItems} />
        <div className={styles['items']}>
          {items.map((item, i) => (
            <div key={i} className={styles['item']}>
              <span>{item.label}</span>
              <div
                className={classNames(
                  styles['extraFab'],
                  styles[item.className]
                )}
                onClick={() => {
                  item.onClick();
                  this._hideItems();
                }}
              >
                <Icon
                  icon={item.icon}
                  color={item.color || '#FFFFFF'}
                  size={26}
                />
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  _mobile = () => {
    const { onClick, icon } = this.props;
    const { itemsVisible } = this.state;

    return (
      <React.Fragment>
        {!itemsVisible && (
          <div
            onClick={this._multipleOptions() ? this._showItems : onClick}
            className={styles['mainFab']}
          >
            <Icon icon={icon} color={'#FFFFFF'} size={26} />
          </div>
        )}
        {itemsVisible && this._items()}
      </React.Fragment>
    );
  };

  render() {
    return <MobileDesktopRender mobile={this._mobile} />;
  }
}

export default FAB;
