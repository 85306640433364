import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './LoadingIndicator.module.scss';

class LoadingIndicator extends Component {
  static propTypes = {
    size: PropTypes.oneOf(['normal', 'small']),
  };

  static defaultProps = {
    size: 'normal',
  };

  render() {
    const { size } = this.props;

    return (
      <div
        className={classNames(styles['loadingSpinnerWrapper'], styles[size])}
      >
        <div className={styles['loadingSpinner']} />
      </div>
    );
  }
}

export default LoadingIndicator;
