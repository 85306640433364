import React, { Component } from 'react';
import PropTypes from 'prop-types';
import locale from 'util/locale';
import Icon from './Icon';
import WithTooltip from 'ui/WithTooltip';

class BirthdayIcon extends Component {
  static propTypes = {
    birthDate: PropTypes.instanceOf(Date),
    currentDate: PropTypes.instanceOf(Date),
    showWhenNear: PropTypes.bool,
    size: PropTypes.oneOf(['normal', 'small']),
    hideTooltip: PropTypes.bool,
  };

  static defaultProps = {
    showWhenNear: true,
    size: 'normal',
    hideTooltip: false,
  };

  _sizes = {
    normal: 21,
    small: 16,
  };

  render() {
    const { birthDate, showWhenNear, size, hideTooltip } = this.props;
    const currentDate = this.props.currentDate || new Date();

    if (!birthDate) {
      return null;
    }

    const birthday = new Date();
    birthday.setMonth(birthDate.getMonth());
    birthday.setDate(birthDate.getDate());

    const delta = currentDate.daysTo(birthday);
    let birthdayMessage, color;

    if (delta === 0) {
      birthdayMessage = locale().patients.birthday_today;
      color = '#F47983';
    } else if (Math.abs(delta) > 3) {
      return null;
    } else if (!showWhenNear) {
      return null;
    } else if (delta > 0) {
      birthdayMessage = locale().patients.birthday_soon;
      color = '#949bac';
    } else {
      birthdayMessage = locale().patients.birthday_recently;
      color = '#dce0e7';
    }

    const icon = <Icon icon={'cake'} color={color} size={this._sizes[size]} />;
    if (hideTooltip) {
      return icon;
    } else {
      return <WithTooltip text={birthdayMessage}>{icon}</WithTooltip>;
    }
  }
}

export default BirthdayIcon;
