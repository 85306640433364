import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, actions, selectors } from 'util/redux';

class PermissionsRender extends Component {
  static propTypes = {
    permission: PropTypes.oneOf([
      'finance_overview',
      'manage_employees',
      'manage_locations',
      'manage_subscription',
    ]).isRequired,
    children: PropTypes.any.isRequired,
    redirect: PropTypes.bool,
  };

  static defaultProps = {
    redirect: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      safeToRender: false,
    };
  }

  componentDidMount() {
    const { user, permission, redirect, openSchedulePage } = this.props;

    if (!user.permissions[permission]) {
      if (redirect) {
        openSchedulePage();
      }
    } else {
      this.setState({ safeToRender: true });
    }
  }

  render() {
    const { safeToRender } = this.state;

    if (safeToRender) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openSchedulePage: () => dispatch(actions.openSchedulePage()),
  };
};

PermissionsRender = connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsRender);

export default PermissionsRender;
