import Response from '../_response';
import { PatientInvoiceSerializer } from '../_serializers';

class PatientInvoicesEndpoint {
  constructor(client) {
    this._client = client;
  }

  get(id) {
    return this._client
      .fetch(`patient_invoices/${id}`, 'GET')
      .then(({ response, json }) => {
        return PatientInvoicesEndpoint._buildResponse(response, json);
      });
  }

  create(data) {
    return this._client
      .fetch(
        'patient_invoices',
        'POST',
        PatientInvoiceSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        return PatientInvoicesEndpoint._buildResponse(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(
        `patient_invoices/${data.id}`,
        'PATCH',
        PatientInvoiceSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientInvoiceSerializer.deserialize(json)
        );
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patient_invoices/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return PatientInvoicesEndpoint._buildResponse(response, json);
      });
  }

  group(data) {
    return this._client
      .fetch(`patient_invoices/${data.id}/group`, 'POST', data)
      .then(({ response, json }) => {
        return PatientInvoicesEndpoint._buildResponse(response, json);
      });
  }

  ungroup(id) {
    return this._client
      .fetch(`patient_invoices/${id}/ungroup`, 'POST')
      .then(({ response, json }) => {
        return PatientInvoicesEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = PatientInvoiceSerializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default PatientInvoicesEndpoint;
