import { replace } from 'connected-react-router';
import qs from 'qs';

const CHANGE_ACTIVE_PAGE = 'CHANGE_ACTIVE_PAGE';

export default (
  state = {
    activePage: null,
  },
  action
) => {
  switch (action.type) {
    case CHANGE_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.page,
      };
    default:
      return state;
  }
};

// ACTIONS

const changeActivePage = page => {
  return {
    type: CHANGE_ACTIVE_PAGE,
    page,
  };
};

const updateUrlParams = urlParams => {
  let realParams = {};

  Object.keys(urlParams).forEach(key => {
    if (
      urlParams[key] !== null &&
      urlParams[key] !== undefined &&
      urlParams[key] !== ''
    ) {
      realParams[key] = urlParams[key];
    }
  });

  return replace(`?${qs.stringify(realParams)}`);
};

const scrollToTop = () => () => {
  document.getElementById('root').scrollTo(0, 0);
};

export const actions = {
  changeActivePage,
  updateUrlParams,
  scrollToTop,
};

// SELECTORS

const getActivePage = state => state.activePage;

const getSearchParams = routerState =>
  routerState.location.search.substring(1) || '';

export const selectors = {
  getActivePage,
  getSearchParams,
};
