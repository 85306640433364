import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardRow.module.scss';
import Dropdown from 'ui/Dropdown';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class CardRow extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    rowSize: PropTypes.oneOf(['normal', 'small']),
    contextMenuItems: PropTypes.array,
    resizable: PropTypes.bool,
    borderBottom: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    rowSize: 'normal',
    contextMenuItems: null,
    resizable: false,
    borderBottom: true,
  };

  _mobileDropdown = () => (
    <Dropdown
      items={this.props.contextMenuItems}
      icon={'expand_more'}
      className={styles['mobileContextMenu']}
    />
  );

  _desktopDropdown = () => (
    <Dropdown
      items={this.props.contextMenuItems}
      classToHide={styles['more']}
      className={styles['contextMenu']}
    />
  );

  render() {
    const {
      children,
      onClick,
      rowSize,
      contextMenuItems,
      resizable,
      borderBottom,
    } = this.props;

    let dropdownNode;
    if (contextMenuItems) {
      dropdownNode = (
        <MobileDesktopRender
          mobile={this._mobileDropdown}
          desktop={this._desktopDropdown}
        />
      );
    }

    return (
      <div
        onClick={onClick}
        className={classNames(styles['wrapper'], styles[rowSize], {
          [styles['clickable']]: onClick !== null,
          [styles['resizable']]: resizable,
          [styles['withBottomBorder']]: borderBottom,
        })}
      >
        <div className={styles['content']}>{children}</div>
        {dropdownNode}
      </div>
    );
  }
}

export default CardRow;
