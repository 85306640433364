import React, { Component } from 'react';
import PropTypes from 'prop-types';
import supportedPaymentTypes from 'util/supported_payment_types';
import Icon from './Icon';

class PaymentIcon extends Component {
  static propTypes = {
    paymentType: PropTypes.oneOf(Object.keys(supportedPaymentTypes())),
    size: PropTypes.number,
  };

  render() {
    const { paymentType, size } = this.props;
    const paymentTypes = supportedPaymentTypes();

    return <Icon icon={paymentTypes[paymentType].icon} size={size} />;
  }
}

export default PaymentIcon;
