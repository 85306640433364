import Response from '../_response';

class InvitationsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client
      .fetch('invitations', 'GET')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  get(token) {
    return this._client
      .fetch(`invitations/${token}`, 'GET')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  create(data) {
    return this._client
      .fetch('invitations', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`invitations/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default InvitationsEndpoint;
