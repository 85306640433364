import locale from 'util/locale';

export default () => ({
  direct: {
    label: locale().payment_types.direct,
    value: 'direct',
    icon: 'monetization_on',
  },
  account: {
    label: locale().payment_types.account,
    value: 'account',
    icon: 'account_balance',
  },
});
