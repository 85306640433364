import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PatientInactive, PostCreated } from './views';

class Notification extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
  };

  static VIEWS = {
    patient_inactive: PatientInactive,
    post_created: PostCreated,
  };

  render() {
    const { notification, closeMenu } = this.props;
    const NotificationView = Notification.VIEWS[notification.kind];

    return (
      <NotificationView notification={notification} closeMenu={closeMenu} />
    );
  }
}

export default Notification;
