import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './HeaderBar.module.scss';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import { TextInput } from 'ui/Form';

class HeaderBar extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    rightContent: PropTypes.node,
    onBack: PropTypes.func,
    historyBack: PropTypes.bool,
    rightPadding: PropTypes.bool,
    search: PropTypes.shape({
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      placeholder: PropTypes.string,
    }),
  };

  static defaultProps = {
    onBack: null,
    historyBack: false,
    rightPadding: false,
    search: null,
  };

  _goBack = () => window.history.back();

  render() {
    const {
      title,
      rightContent,
      onBack,
      historyBack,
      rightPadding,
      search,
    } = this.props;

    let backArrowNode;
    if (onBack !== null || historyBack) {
      backArrowNode = (
        <Icon
          icon={'arrow_back'}
          color={'#FFFFFF'}
          size={28}
          expand={true}
          onClick={onBack !== null ? onBack : this._goBack}
        />
      );
    }

    let rightContentNode;
    if (rightContent) {
      rightContentNode = <div className={styles['right']}>{rightContent}</div>;
    }

    return (
      <React.Fragment>
        <div
          className={classNames(styles['placeholder'], {
            [styles['withSearch']]: search,
          })}
        />
        <div className={styles['wrapper']}>
          <div
            className={classNames(styles['header'], {
              [styles['withoutBack']]: onBack === null && historyBack === false,
              [styles['rightPadding']]: rightPadding,
            })}
          >
            <div className={styles['left']}>
              {backArrowNode}
              <div className={styles['title']}>{title}</div>
            </div>
            {rightContentNode}
          </div>
          {search && (
            <div className={styles['search']}>
              <TextInput
                value={search.value}
                onChange={search.onChange}
                placeholder={search.placeholder}
                prefix={
                  <div className={styles['searchIcon']}>
                    <Icon icon={'search'} color={'#949BAC'} size={20} />
                  </div>
                }
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
