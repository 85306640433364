import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import ActionCenter from 'util/action_center';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_EXPENSE_EDITING = 'START_EXPENSE_EDITING';
const FINISH_EXPENSE_EDITING = 'FINISH_EXPENSE_EDITING';

export default (
  state = {
    editingExpense: emptyExpenseData(),
    expenseDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_EXPENSE_EDITING:
      return {
        ...state,
        editingExpense: action.expense,
        expenseDialogOpen: true,
      };
    case FINISH_EXPENSE_EDITING:
      return {
        ...state,
        editingExpense: emptyExpenseData(),
        expenseDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyExpenseData = () => ({
  date: new Date(),
  expense_type_id: null,
  amount: 0,
  description: '',
});

const newExpense = () => {
  return {
    type: START_EXPENSE_EDITING,
    expense: emptyExpenseData(),
  };
};

const editExpense = expense => {
  const data = { ...expense, expense_type_id: expense.expense_type.id };
  delete data.expense_type;

  return {
    type: START_EXPENSE_EDITING,
    expense: data,
  };
};

const finishExpenseEditing = () => {
  return {
    type: FINISH_EXPENSE_EDITING,
  };
};

const fetchExpenses = data => {
  return dispatch => {
    return Api.expenses.query(data).then(response => {
      if (response.isOk()) {
        dispatch(clearQuery(entityTypes.expense));
        const query = response.data();
        dispatch(receiveQuery(entityTypes.expense, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createExpense = data => {
  return dispatch => {
    return Api.expenses.create(data).then(response => {
      if (response.isOk()) {
        const expense = response.data();
        dispatch(receiveEntities(entityTypes.expense, [expense]));
        dispatch(systemActions.showToast(locale().expenses.expense_saved));
        ActionCenter.publish('createExpense');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateExpense = data => {
  return dispatch => {
    return Api.expenses.update(data).then(response => {
      if (response.isOk()) {
        const expense = response.data();
        dispatch(receiveEntities(entityTypes.expense, [expense]));
        dispatch(systemActions.showToast(locale().expenses.expense_saved));
        ActionCenter.publish('updateExpense');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyExpense = id => {
  return dispatch => {
    return Api.expenses.destroy(id).then(response => {
      if (response.isOk()) {
        const expense = response.data();
        dispatch(removeEntities(entityTypes.expense, [expense.id]));
        dispatch(systemActions.showToast(locale().expenses.expense_destroyed));
        ActionCenter.publish('destroyExpense');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newExpense,
  editExpense,
  finishExpenseEditing,
  fetchExpenses,
  createExpense,
  updateExpense,
  destroyExpense,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getExpenses = state => getEntityList(state, entityTypes.expense);

const getEditingExpense = state => state.editingExpense;

const isEditingExpense = state => state.expenseDialogOpen;

export const selectors = {
  getExpenses,
  getEditingExpense,
  isEditingExpense,
};
