import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './UserAccountOverview.module.scss';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import PermissionsRender from 'ui/PermissionsRender';
import HeaderBar from 'ui/HeaderBar';
import { Card, CardHeader } from 'ui/Card';
import { SectionList, SectionListItem } from 'ui/SectionList';
import Icon from 'ui/Icon';
import { FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import { Button } from 'ui/Button';
import { connect, actions, selectors } from 'util/redux';

class UserAccountOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.user },
      organisationData: { ...props.user.organisation },
      passwordData: {
        password: '',
        current_password: '',
      },
      userErrors: {},
      organisationErrors: {},
      passwordErrors: {},
      isUserSaving: false,
      isOrganisationSaving: false,
      isPasswordSaving: false,
    };
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _changeOrganisationData = (field, value) => {
    const { organisationData } = this.state;
    this.setState({
      organisationData: {
        ...organisationData,
        [field]: value,
      },
    });
  };

  _changePasswordData = (field, value) => {
    const { passwordData } = this.state;
    this.setState({
      passwordData: {
        ...passwordData,
        [field]: value,
      },
    });
  };

  _saveDetails = () => {
    const { updateCurrentUserDetails } = this.props;
    const { data } = this.state;

    this.setState({ isUserSaving: true, userErrors: {} });
    updateCurrentUserDetails(data)
      .then(() => {
        this.setState({ isUserSaving: false });
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            userErrors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            userErrors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isUserSaving: false });
      });
  };

  _saveOrganisationDetails = () => {
    const { updateCurrentOrganisation } = this.props;
    const { organisationData } = this.state;

    this.setState({ isOrganisationSaving: true, organisationErrors: {} });
    updateCurrentOrganisation(organisationData)
      .then(() => {
        this.setState({ isOrganisationSaving: false });
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError) {
          this.setState({
            organisationErrors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            organisationErrors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isOrganisationSaving: false });
      });
  };

  _updatePassword = () => {
    const { changePassword } = this.props;
    const { passwordData } = this.state;

    this.setState({ isPasswordSaving: true, passwordErrors: {} });
    changePassword(passwordData)
      .then(() => {
        this.setState({ isPasswordSaving: false });
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            passwordErrors: response.validationErrors().errorPerField(),
          });
        } else if (response.isBadRequest && response.isBadRequest()) {
          this.setState({
            passwordErrors: {
              current_password: locale().account.current_password_wrong_error,
            },
          });
        } else {
          this.setState({
            passwordErrors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isPasswordSaving: false });
      });
  };

  _mobileHeader = () => <HeaderBar title={locale().account.title} />;

  _userForm = () => {
    const { data, userErrors, isUserSaving } = this.state;

    let userGlobalErrorNode;
    if (userErrors._global) {
      userGlobalErrorNode = (
        <FormRow>
          <FormColumn>
            <div className={styles['error']}>{userErrors._global}</div>
          </FormColumn>
        </FormRow>
      );
    }

    return (
      <div className={styles['form']}>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().account.name} error={userErrors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.email}
              error={userErrors.email}
            />
            <TextInput
              value={data.email}
              onChange={value => this._changeData('email', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.user_title}
              error={userErrors.title}
            />
            <TextInput
              value={data.title}
              onChange={value => this._changeData('title', value)}
            />
          </FormColumn>
        </FormRow>
        {userGlobalErrorNode}
        <FormRow>
          <FormColumn>
            <Button
              value={locale().actions.save}
              onClick={this._saveDetails}
              disabled={isUserSaving}
            />
          </FormColumn>
        </FormRow>
      </div>
    );
  };

  _organisationForm = () => {
    const {
      organisationData,
      organisationErrors,
      isOrganisationSaving,
    } = this.state;

    let organisationGlobalErrorNode;
    if (organisationErrors._global) {
      organisationGlobalErrorNode = (
        <FormRow>
          <FormColumn>
            <div className={styles['error']}>{organisationErrors._global}</div>
          </FormColumn>
        </FormRow>
      );
    }

    return (
      <div className={styles['form']}>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.organisation_name}
              error={organisationErrors.name}
            />
            <TextInput
              value={organisationData.name}
              onChange={value => this._changeOrganisationData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.organisation_email}
              error={organisationErrors.email}
            />
            <TextInput
              value={organisationData.email}
              onChange={value => this._changeOrganisationData('email', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.organisation_website}
              error={organisationErrors.website}
            />
            <TextInput
              value={organisationData.website}
              onChange={value => this._changeOrganisationData('website', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.organisation_address}
              error={organisationErrors.address}
            />
            <TextInput
              value={organisationData.address}
              onChange={value => this._changeOrganisationData('address', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.organisation_phone}
              error={organisationErrors.phone}
            />
            <TextInput
              value={organisationData.phone}
              onChange={value => this._changeOrganisationData('phone', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.company_identifier}
              error={organisationErrors.company_identifier}
            />
            <TextInput
              value={organisationData.company_identifier}
              onChange={value =>
                this._changeOrganisationData('company_identifier', value)
              }
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.tax_number}
              error={organisationErrors.tax_number}
            />
            <TextInput
              value={organisationData.tax_number}
              onChange={value =>
                this._changeOrganisationData('tax_number', value)
              }
            />
          </FormColumn>
        </FormRow>
        {organisationGlobalErrorNode}
        <FormRow>
          <FormColumn>
            <Button
              value={locale().actions.save}
              onClick={this._saveOrganisationDetails}
              disabled={isOrganisationSaving}
            />
          </FormColumn>
        </FormRow>
      </div>
    );
  };

  _passwordForm = () => {
    const { passwordData, passwordErrors, isPasswordSaving } = this.state;

    let passwordGlobalErorNode;
    if (passwordErrors._global) {
      passwordGlobalErorNode = (
        <FormRow>
          <FormColumn>
            <div className={styles['error']}>{passwordErrors._global}</div>
          </FormColumn>
        </FormRow>
      );
    }

    return (
      <div className={styles['form']}>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.current_password}
              error={passwordErrors.current_password}
            />
            <TextInput
              value={passwordData.current_password}
              onChange={value =>
                this._changePasswordData('current_password', value)
              }
              type={'password'}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().account.new_password}
              error={passwordErrors.password}
            />
            <TextInput
              value={passwordData.password}
              onChange={value => this._changePasswordData('password', value)}
              type={'password'}
            />
          </FormColumn>
        </FormRow>
        {passwordGlobalErorNode}
        <FormRow>
          <FormColumn>
            <Button
              value={locale().account.change_password}
              onClick={this._updatePassword}
              disabled={isPasswordSaving}
            />
          </FormColumn>
        </FormRow>
      </div>
    );
  };

  _mobile = () => {
    const { logout } = this.props;

    return (
      <React.Fragment>
        <SectionListItem
          title={locale().actions.log_out}
          onClick={logout}
          rightContent={<Icon icon={'chevron_right'} expand={true} size={32} />}
        />
        <SectionList leftHeadline={locale().account.user_data_head}>
          {this._userForm()}
        </SectionList>
        <PermissionsRender permission={'manage_employees'}>
          <SectionList leftHeadline={locale().account.organisation_data_head}>
            {this._organisationForm()}
          </SectionList>
        </PermissionsRender>
        <SectionList leftHeadline={locale().account.security_head}>
          {this._passwordForm()}
        </SectionList>
      </React.Fragment>
    );
  };

  _desktop = () => {
    return (
      <div className={styles['wrapper']}>
        <Card padding={true}>
          <CardHeader title={locale().account.user_data_head} />
          {this._userForm()}
        </Card>
        <PermissionsRender permission={'manage_employees'}>
          <Card padding={true}>
            <CardHeader title={locale().account.organisation_data_head} />
            {this._organisationForm()}
          </Card>
        </PermissionsRender>
        <Card padding={true}>
          <CardHeader title={locale().account.security_head} />
          {this._passwordForm()}
        </Card>
      </div>
    );
  };

  render() {
    return (
      <Navigator title={locale().account.title} mountedAs={'account'}>
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobileHeader} />
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentUserDetails: data =>
      dispatch(actions.updateCurrentUserDetails(data)),
    updateCurrentOrganisation: data =>
      dispatch(actions.updateCurrentOrganisation(data)),
    changePassword: data => dispatch(actions.changePassword(data)),
    logout: () => dispatch(actions.logout()),
  };
};

UserAccountOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAccountOverview);

export default UserAccountOverview;
