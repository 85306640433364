import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectNBottomSheet } from 'ui/Form';

class PatientReferralSelect extends Component {
  static propTypes = {
    patientReferrals: PropTypes.array.isRequired,
  };

  _options = () => {
    const { patientReferrals } = this.props;

    return patientReferrals.sortBy('name').map(referral => ({
      value: referral.id,
      label: referral.name,
    }));
  };

  render() {
    return <SelectNBottomSheet options={this._options()} {...this.props} />;
  }
}

export default PatientReferralSelect;
