import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { actions as patientActions } from './_patients';
import { push } from 'connected-react-router';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  makeGetEntityList,
  getEntityById,
  clearQuery,
  removeEntities,
} from './_entities';

const START_PATIENT_REPORT_EDITING = 'START_PATIENT_REPORT_EDITING';
const FINISH_PATIENT_REPORT_EDITING = 'FINISH_PATIENT_REPORT_EDITING';

export default (
  state = { editingPatientReport: null, patientReportDialogOpen: false },
  action
) => {
  switch (action.type) {
    case START_PATIENT_REPORT_EDITING:
      return {
        ...state,
        editingPatientReport: action.report,
        patientReportDialogOpen: true,
      };
    case FINISH_PATIENT_REPORT_EDITING:
      return {
        ...state,
        editingPatientReport: emptyPatientReportData(),
        patientReportDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyPatientReportData = () => ({
  date: new Date(),
  report_type: '',
  patient_id: null,
});

const newPatientReport = data => {
  return {
    type: START_PATIENT_REPORT_EDITING,
    report: { ...emptyPatientReportData(), ...data },
  };
};

const finishPatientReportEditing = () => {
  return {
    type: FINISH_PATIENT_REPORT_EDITING,
  };
};

const showPatientReport = id => push(`/reports/${id}`);

const fetchReportsForPatient = id => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.patient_report));
    return Api.patients.reports(id).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.patient_report, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOnePatientReport = id => {
  return dispatch => {
    return Api.patientReports.get(id).then(response => {
      if (response.isOk()) {
        const patientReport = response.data();
        dispatch(receiveEntities(entityTypes.patient_report, [patientReport]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatientReport = data => {
  return dispatch => {
    return Api.patientReports.create(data).then(response => {
      if (response.isOk()) {
        const patientReport = response.data();
        dispatch(showPatientReport(patientReport.id));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

let updatePatientReportPromise = Promise.resolve(() => {});
const updatePatientReport = (id, data) => {
  return dispatch => {
    updatePatientReportPromise.cancel();
    updatePatientReportPromise = Api.patientReports
      .update(id, data)
      .then(response => {
        if (response.isOk()) {
          const patientReport = response.data();
          dispatch(
            receiveEntities(entityTypes.patient_report, [patientReport])
          );
        } else {
          return Promise.reject(response);
        }
      });

    return updatePatientReportPromise;
  };
};

const destroyPatientReport = (id, backToPatient = false) => {
  return dispatch => {
    return Api.patientReports.destroy(id).then(response => {
      if (response.isOk()) {
        const patientReport = response.data();
        if (backToPatient) {
          dispatch(
            patientActions.showPatient(patientReport.patient_id, 'reports')
          );
        }
        dispatch(
          removeEntities(entityTypes.patient_report, [patientReport.id])
        );
        dispatch(
          systemActions.showToast(
            locale().patient_reports.patient_report_destroyed
          )
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPatientReport,
  finishPatientReportEditing,
  showPatientReport,
  fetchReportsForPatient,
  fetchOnePatientReport,
  createPatientReport,
  updatePatientReport,
  destroyPatientReport,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getReportsForPatient = (state, patientId) =>
  getEntityList(state, entityTypes.patient_report).filter(
    report => report.patient_id === patientId
  );

const getPatientReport = (state, id) =>
  getEntityById(state, entityTypes.patient_report, id);

const getEditingPatientReport = state => state.editingPatientReport;

const isEditingPatientReport = state => state.patientReportDialogOpen;

export const selectors = {
  getReportsForPatient,
  getPatientReport,
  getEditingPatientReport,
  isEditingPatientReport,
};
