import React, { Component } from 'react';
import locale from 'util/locale';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from 'ui/EmptyState';
import NotificationsList from './_NotificationsList';
import emptyStateImg from 'media/images/notifications/emptyState.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

// Currently only used on mobile

class NotificationsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchNotifications } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchNotifications()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(() => this.setState({ loadingState: loadingStates.failed }));
  }

  _mobileHeader = () => <HeaderBar title={locale().notifications.title} />;

  render() {
    const { notifications } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (notifications.length === 0) {
      bodyNode = (
        <EmptyState
          image={emptyStateImg}
          heading={locale().notifications.empty_state_heading}
          intro={locale().notifications.empty_state_intro}
          size={'normal'}
        />
      );
    } else {
      bodyNode = <NotificationsList notifications={notifications} />;
    }

    return (
      <Navigator
        title={locale().notifications.title}
        mountedAs={'notifications'}
      >
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobileHeader} />
          {bodyNode}
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: selectors.getNotifications(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNotifications: () =>
      dispatch(actions.fetchNotifications({ unacknowledged_only: true })),
  };
};

NotificationsOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsOverview);

export default NotificationsOverview;
