import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui/Form';
import supportedPaymentTypes from 'util/supported_payment_types';

class PaymentTypeSelect extends Component {
  static propTypes = {
    value: PropTypes.oneOf(Object.keys(supportedPaymentTypes())),
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { value, onChange } = this.props;
    const paymentTypes = supportedPaymentTypes();

    return (
      <Select
        value={value}
        onChange={onChange}
        clearable={false}
        searchable={false}
        options={Object.keys(paymentTypes).map(key => {
          const type = paymentTypes[key];

          return {
            label: type.label,
            value: type.value,
          };
        })}
      />
    );
  }
}

export default PaymentTypeSelect;
