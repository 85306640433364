import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import CalendarView from './../_Schedule/_CalendarView';
import { SelectNBottomSheet } from 'ui/Form';

class ResourcesTypeSelect extends Component {
  static propTypes = {
    value: PropTypes.oneOf(CalendarView.RESOURCE_TYPES),
    onChange: PropTypes.func.isRequired,
  };

  _options = () => {
    return [
      {
        value: 'user',
        label: locale().schedule.resources_type.user,
      },
      {
        value: 'location',
        label: locale().schedule.resources_type.location,
      },
    ];
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <SelectNBottomSheet
        options={this._options()}
        value={value}
        onChange={onChange}
        clearable={false}
        searchable={false}
        {...this.props}
      />
    );
  }
}

export default ResourcesTypeSelect;
