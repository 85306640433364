import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';
import ReSelect, { components } from 'react-select';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import BottomSheet from 'ui/Dialog/BottomSheet';
import Icon from 'ui/Icon';

class Select extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    optionRenderer: PropTypes.func,
    placeholder: PropTypes.string,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    optionRenderer: null,
    placeholder: '',
    clearable: true,
    searchable: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      bottomSheetOpen: false,
    };
  }

  _openBottomSheet = () => this.setState({ bottomSheetOpen: true });

  _closeBottomSheet = () => this.setState({ bottomSheetOpen: false });

  _arrow = () => <Icon icon={'expand_more'} size={20} color={'#949BAC'} />;

  _customRenderer = props => {
    const { optionRenderer } = this.props;

    return (
      <components.Option {...props} children={optionRenderer(props.data)} />
    );
  };

  _onChange = option => {
    const { onChange } = this.props;
    onChange(option ? option.value : null);
  };

  _selectedValue = () =>
    this.props.options.find(o => o.value === this.props.value) || null;

  _mobile = () => {
    const {
      onChange,
      options,
      value,
      placeholder,
      optionRenderer,
    } = this.props;
    const { bottomSheetOpen } = this.state;
    let item = this._selectedValue();

    return (
      <React.Fragment>
        <div className={styles['wrapper']} onClick={this._openBottomSheet}>
          <div className={styles['item']}>
            {item ? item.label : placeholder}
          </div>
          {this._arrow()}
        </div>
        <BottomSheet
          open={bottomSheetOpen}
          onClose={this._closeBottomSheet}
          items={options}
          selectedItem={value}
          onSelect={onChange}
          itemRenderer={optionRenderer}
        />
      </React.Fragment>
    );
  };

  _desktop = () => {
    const {
      options,
      optionRenderer,
      placeholder,
      clearable,
      searchable,
    } = this.props;

    const components = {};
    if (optionRenderer) {
      components['Option'] = this._customRenderer;
    }

    return (
      <ReSelect
        value={this._selectedValue()}
        onChange={this._onChange}
        className={styles['select']}
        classNamePrefix={'select'}
        options={options}
        components={components}
        placeholder={placeholder}
        noOptionsMessage={() => null}
        isClearable={clearable}
        isSearchable={searchable}
      />
    );
  };

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default Select;
