import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_Document.module.scss';
import Icon from 'ui/Icon';
import Sidebar from './_Sidebar';

class Document extends Component {
  static propTypes = {
    stripe: PropTypes.number,
    ...Sidebar.propTypes,
  };

  static defaultProps = {
    stripe: 0,
  };

  _goBack = () => window.history.back();

  render() {
    const { stripe, document } = this.props;

    return (
      <React.Fragment>
        <div className={styles['top']}>
          <div className={styles['backLink']} onClick={this._goBack}>
            <Icon icon={'arrow_back'} color={'#23B0FF'} size={18} />
            <span>{locale().actions.back}</span>
          </div>
        </div>
        <div className={styles['wrapper']}>
          <div
            className={styles['document']}
            style={{
              borderLeft: stripe,
              borderLeftStyle: 'solid',
              borderLeftColor: document.color,
            }}
          >
            {this.props.children}
          </div>
          <Sidebar {...this.props} />
        </div>
        <div className={styles['info']}>
          {locale().common_labels.organisation_data_notice}
        </div>
      </React.Fragment>
    );
  }
}

export default Document;
