import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import ActionCenter from 'util/action_center';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_SERVICE_PAYMENT_EDITING = 'START_SERVICE_PAYMENT_EDITING';
const FINISH_SERVICE_PAYMENT_EDITING = 'FINISH_SERVICE_PAYMENT_EDITING';
const START_INFLOW = 'START_INFLOW';
const FINISH_INFLOW = 'FINISH_INFLOW';

export default (
  state = {
    editingServicePayment: emptyServicePaymentData(),
    servicePaymentDialogOpen: false,
    editingInflow: emptyInflowData(),
    inflowDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_SERVICE_PAYMENT_EDITING:
      return {
        ...state,
        editingServicePayment: action.service_payment,
        servicePaymentDialogOpen: true,
      };
    case FINISH_SERVICE_PAYMENT_EDITING:
      return {
        ...state,
        editingServicePayment: emptyServicePaymentData(),
        servicePaymentDialogOpen: false,
      };
    case START_INFLOW:
      return {
        ...state,
        editingInflow: action.data,
        inflowDialogOpen: true,
      };
    case FINISH_INFLOW:
      return {
        ...state,
        editingInflow: emptyInflowData(),
        inflowDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyServicePaymentData = () => ({
  date: new Date(),
  amount: 0,
  payment_type: 'direct',
});

const emptyInflowData = () => ({
  patient_id: null,
  ...emptyServicePaymentData(),
});

const newServicePayment = serviceId => {
  return {
    type: START_SERVICE_PAYMENT_EDITING,
    service_payment: { ...emptyServicePaymentData(), service_id: serviceId },
  };
};

const finishServicePaymentEditing = () => {
  return {
    type: FINISH_SERVICE_PAYMENT_EDITING,
  };
};

const newInflow = (data = {}) => {
  return {
    type: START_INFLOW,
    data: { ...emptyInflowData(), ...data },
  };
};

const finishInflowEditing = () => {
  return {
    type: FINISH_INFLOW,
  };
};

const fetchPaymentsForPatient = ({ patientId, clearOld = true, page = 1 }) => {
  return dispatch => {
    if (clearOld) {
      dispatch(clearQuery(entityTypes.service_payment));
    }

    return Api.patients.servicePayments({ patientId, page }).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.service_payment, query));
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

let fetchPaymentsForServicePromise = Promise.resolve(() => {});
const fetchPaymentsForService = serviceId => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.service_payment));
    fetchPaymentsForServicePromise.cancel();
    fetchPaymentsForServicePromise = Api.services
      .payments(serviceId)
      .then(response => {
        if (response.isOk()) {
          const query = response.data();
          dispatch(receiveQuery(entityTypes.service_payment, query));
        } else {
          return Promise.reject(response);
        }
      });
    return fetchPaymentsForServicePromise;
  };
};

let createServicePayment = data => {
  return dispatch => {
    return Api.servicePayments.create(data).then(response => {
      if (response.isOk()) {
        const servicePayment = response.data();
        dispatch(
          receiveEntities(entityTypes.service_payment, [servicePayment])
        );
        dispatch(
          systemActions.showToast(
            locale().service_payments.service_payment_saved
          )
        );
        ActionCenter.publish('createServicePayment', servicePayment);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

let destroyServicePayment = id => {
  return dispatch => {
    return Api.servicePayments.destroy(id).then(response => {
      if (response.isOk()) {
        const servicePayment = response.data();
        dispatch(
          removeEntities(entityTypes.service_payment, [servicePayment.id])
        );
        dispatch(
          systemActions.showToast(
            locale().service_payments.service_payment_destroyed
          )
        );
        ActionCenter.publish('destroyServicePayment', servicePayment);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

let inflowServicePayments = data => {
  return dispatch => {
    return Api.servicePayments.inflow(data).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.service_payment, query));
        dispatch(
          systemActions.showToast(
            locale().service_payments.service_payment_saved
          )
        );
        ActionCenter.publish('inflowServicePayments', query.resources);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newServicePayment,
  newInflow,
  finishInflowEditing,
  finishServicePaymentEditing,
  fetchPaymentsForPatient,
  fetchPaymentsForService,
  createServicePayment,
  destroyServicePayment,
  inflowServicePayments,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getPaymentsForPatient = (state, patientId) =>
  getEntityList(state, entityTypes.service_payment).filter(
    payment => payment.service.patient_id === patientId
  );

const getPaymentsForService = (state, serviceId) =>
  getEntityList(state, entityTypes.service_payment).filter(
    servicePayment => servicePayment.service_id === serviceId
  );

const getEditingServicePayment = state => state.editingServicePayment;

const isEditingServicePayment = state => state.servicePaymentDialogOpen;

const getEditingInflow = state => state.editingInflow;

const isEditingInflow = state => state.inflowDialogOpen;

export const selectors = {
  getPaymentsForPatient,
  getPaymentsForService,
  getEditingServicePayment,
  isEditingServicePayment,
  getEditingInflow,
  isEditingInflow,
};
