import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './DateRange.module.scss';
import DatePicker from 'ui/DatePicker';
import { Select } from 'ui/Form';
import Icon from 'ui/Icon';

class DateRange extends Component {
  static propTypes = {
    range: PropTypes.shape({
      from_date: PropTypes.instanceOf(Date).isRequired,
      to_date: PropTypes.instanceOf(Date).isRequired,
    }).isRequired,
    ranges: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      customVisible: false,
    };
  }

  _valueFromRange = () => {
    const { ranges, range } = this.props;

    const rangeOfInterest = ranges
      .filter(r => r.value)
      .find(
        r =>
          r.value.from_date.effectivelySame(range.from_date) &&
          r.value.to_date.effectivelySame(range.to_date)
      );

    return rangeOfInterest ? rangeOfInterest.value : null;
  };

  _onChange = range => {
    const { onChange } = this.props;

    if (range === null) {
      this.setState({ customVisible: true });
    } else {
      onChange(range);
    }
  };

  _triggerCustomChange = (fromDate, toDate) =>
    this.props.onChange({
      from_date: fromDate,
      to_date: toDate,
    });

  _changeCustomFrom = fromDate => {
    let toDate = this.props.range.to_date;
    if (fromDate > toDate) {
      toDate = fromDate.endOfWork();
    }

    this._triggerCustomChange(fromDate, toDate);
  };

  _changeCustomTo = toDate => {
    let fromDate = this.props.range.from_date;
    if (fromDate > toDate) {
      fromDate = toDate.startOfWork();
    }

    this._triggerCustomChange(fromDate, toDate);
  };

  _closeCustom = () => {
    const { ranges } = this.props;
    this.setState({ customVisible: false }, () =>
      this._onChange(ranges[0].value)
    );
  };

  render() {
    const { range, ranges } = this.props;
    const { customVisible } = this.state;
    const value = this._valueFromRange();

    if (customVisible || !value) {
      return (
        <div className={styles['custom']}>
          <div className={styles['picker']}>
            <DatePicker
              value={range.from_date}
              dateOnly={true}
              onChange={this._changeCustomFrom}
            />
          </div>
          <div className={styles['picker']}>
            <DatePicker
              value={range.to_date}
              dateOnly={true}
              onChange={this._changeCustomTo}
            />
          </div>
          <div className={styles['close']}>
            <Icon
              icon={'close'}
              expand={true}
              size={28}
              onClick={this._closeCustom}
            />
          </div>
        </div>
      );
    } else {
      return (
        <Select
          value={value}
          onChange={this._onChange}
          options={ranges}
          clearable={false}
          searchable={false}
        />
      );
    }
  }
}

export default DateRange;
