import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './ConfirmationDialog.module.scss';
import CenterDialog from './CenterDialog';
import { Button } from 'ui/Button';

class ConfirmationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
  };

  _onConfirm = () => {
    const { onClose, onConfirm } = this.props;
    onClose();
    onConfirm();
  };

  render() {
    const { open, onClose, title, description } = this.props;

    return (
      <CenterDialog open={open} onClose={onClose}>
        <div className={styles['wrapper']}>
          <div>
            <div className={styles['title']}>{title}</div>
            <div className={styles['description']}>{description}</div>
          </div>
          <div className={styles['actions']}>
            <Button
              onClick={onClose}
              value={locale().actions.cancel}
              actionType={'normal'}
            />
            <Button
              onClick={this._onConfirm}
              value={locale().actions.confirm}
              actionType={'danger'}
            />
          </div>
        </div>
      </CenterDialog>
    );
  }
}

export default ConfirmationDialog;
