import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './_GreetingNode.module.scss';
import { connect, selectors } from 'util/redux';

class GreetingNode extends Component {
  render() {
    const { user } = this.props;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['circle']}>{user.initials}</div>
        <div className={styles['greet']}>
          <div className={styles['name']}>{user.name.capitalize()}</div>
          <div className={styles['message']}>{locale().dashboard.greeting}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
  };
};

GreetingNode = connect(mapStateToProps)(GreetingNode);

export default GreetingNode;
