import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CenterDialog.module.scss';
import classNames from 'classnames';
import Dialog from './_Dialog';

class CenterDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    padding: PropTypes.bool,
  };

  static defaultProps = {
    padding: true,
  };

  constructor(props) {
    super(props);
    this._dialog = React.createRef();
  }

  _startClosing = () => {
    if (this._dialog) {
      this._dialog.current.classList.add(styles['closing']);
    }
  };

  render() {
    const { open, onClose, padding } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        onClosing={this._startClosing}
        hideOverlayOnMobile={true}
      >
        <div
          className={classNames(styles['wrapper'], {
            [styles['padding']]: padding,
          })}
          ref={this._dialog}
        >
          {this.props.children}
        </div>
      </Dialog>
    );
  }
}

export default CenterDialog;
