import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './PatientNotesList.module.scss';
import MultiOrgRender from 'ui/MultiOrgRender';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { CardRow } from 'ui/Card';
import { RatePicker } from 'ui/Form';
import { connect, actions } from 'util/redux';

class PatientNotesList extends Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
      noteId: null,
    };
  }

  _openConfirmationDialog = noteId =>
    this.setState({ confirmationDialogOpen: true, noteId });

  _closeConfirmationDialog = () =>
    this.setState({
      confirmationDialogOpen: false,
      noteId: null,
    });

  _destroyNote = () => {
    const { destroyNote } = this.props;
    const { noteId } = this.state;

    destroyNote(noteId);
  };

  _renderNote = note => {
    const { editNote } = this.props;

    return (
      <CardRow
        key={note.id}
        resizable={true}
        borderBottom={false}
        contextMenuItems={[
          {
            label: locale().actions.edit,
            onClick: () => editNote(note),
          },
          {
            label: locale().actions.destroy,
            onClick: () => this._openConfirmationDialog(note.id),
            type: 'attention',
          },
        ]}
      >
        <div className={styles['text']}>{note.content}</div>
        <div className={styles['footer']}>
          <MultiOrgRender>
            <span>{note.user_name}</span>
          </MultiOrgRender>
          <span>{note.date.format('dd.MM.yyyy.')}</span>
          {note.progress_rate && (
            <RatePicker
              value={note.progress_rate}
              size={'small'}
              readOnly={true}
            />
          )}
        </div>
      </CardRow>
    );
  };

  render() {
    const { notes } = this.props;
    const { confirmationDialogOpen } = this.state;

    if (notes.length === 0) {
      return null;
    } else {
      return (
        <div>
          {notes.sortBy('date').reverse().map(this._renderNote)}
          <ConfirmationDialog
            open={confirmationDialogOpen}
            onClose={this._closeConfirmationDialog}
            onConfirm={this._destroyNote}
            title={locale().patient_notes.destroy_patient_note_head}
            description={locale().patient_notes.destroy_patient_note_notice}
          />
        </div>
      );
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editNote: note => dispatch(actions.editPatientNote(note)),
    destroyNote: id => dispatch(actions.destroyPatientNote(id)),
  };
};

PatientNotesList = connect(null, mapDispatchToProps)(PatientNotesList);

export default PatientNotesList;
