import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_Invoice.module.scss';
import ImagePicker from 'ui/ImagePicker';
import { MoneyText } from 'ui/Money';
import { LinkButton } from 'ui/Button';
import DocumentTextArea from '../../_Document/_DocumentTextArea';

class Invoice extends Component {
  static propTypes = {
    invoice: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onUploadLogo: PropTypes.func.isRequired,
    onRemoveLogo: PropTypes.func.isRequired,
    onGroupServicesDialog: PropTypes.func.isRequired,
    onUngroupServicesDialog: PropTypes.func.isRequired,
  };

  render() {
    const {
      invoice,
      onUpdate,
      onUploadLogo,
      onRemoveLogo,
      onGroupServicesDialog,
      onUngroupServicesDialog,
    } = this.props;

    const lineItem = invoice.line_items[0];
    const total = invoice.services.reduce(
      (acc, service) => acc + service.price,
      0
    );

    return (
      <React.Fragment>
        <div className={styles['details']}>
          <div className={styles['organisation']}>
            <ImagePicker
              onChange={onUploadLogo}
              onRemove={onRemoveLogo}
              placeholder={locale().account.logo}
              url={invoice.logo_url}
            />
            <div className={styles['business']}>
              <div className={styles['title']}>
                {invoice.organisation_name ||
                  locale().account.organisation_name}
              </div>
              <div className={styles['info']}>
                {locale().account.company_identifier_short}:{' '}
                {invoice.company_identifier || '-'}
              </div>
              <div className={styles['info']}>
                {locale().account.tax_number}: {invoice.tax_number || '-'}
              </div>
              <div className={styles['info']}>
                {invoice.organisation_address ||
                  locale().account.organisation_address}
              </div>
              <div className={styles['info']}>
                {invoice.organisation_email ||
                  locale().account.organisation_email}
              </div>
              <div className={styles['info']}>
                {invoice.organisation_website ||
                  locale().account.organisation_website}
              </div>
              <div className={styles['info']}>
                {invoice.organisation_phone ||
                  locale().account.organisation_phone}
              </div>
            </div>
          </div>
          <div className={styles['right']}>
            <div className={styles['head']}>
              <div
                className={styles['badge']}
                style={{ background: invoice.color }}
              >
                {locale().patient_invoices.invoice} #{invoice.number}
              </div>
              <div className={styles['date']}>
                {locale().patient_invoices.date}:{' '}
                {invoice.date.format('dd.MM.yyyy.')}
              </div>
              {invoice.due_date && (
                <div className={styles['date']}>
                  {locale().patient_invoices.due_date}:{' '}
                  {invoice.due_date.format('dd.MM.yyyy.')}
                </div>
              )}
            </div>
            <div className={styles['receiver']}>
              <DocumentTextArea
                value={invoice.receiver_details}
                placeholder={locale().patient_invoices.invoice_receiver}
                onChange={value => onUpdate('receiver_details', value)}
                alignRight={true}
              />
            </div>
          </div>
        </div>
        <div className={styles['groupingAction']}>
          {!lineItem && (
            <LinkButton
              onClick={onGroupServicesDialog}
              value={locale().patient_invoices.group_services}
            />
          )}
          {lineItem && (
            <LinkButton
              onClick={onUngroupServicesDialog}
              value={locale().patient_invoices.ungroup_services}
            />
          )}
        </div>
        <div className={styles['services']}>
          {!lineItem &&
            invoice.services.sortBy('effectively_active_from').map(service => (
              <div key={service.id} className={styles['service']}>
                <span>{service.name}</span>
                <div className={styles['right']}>
                  <div>
                    {service.effectively_active_from.format('dd.MM.yyyy.')}
                  </div>
                  <div className={styles['money']}>
                    <MoneyText value={service.price} />
                  </div>
                </div>
              </div>
            ))}
          {lineItem && (
            <div className={styles['service']}>
              <span>{lineItem.name}</span>
              <div className={styles['right']}>
                <div className={styles['money']}>
                  <MoneyText value={total} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles['total']}>
          <MoneyText value={total} />
        </div>
        <div className={styles['instructions']}>
          <DocumentTextArea
            value={invoice.instructions}
            placeholder={locale().patient_invoices.instructions}
            onChange={value => onUpdate('instructions', value)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Invoice;
