import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import DatePicker from 'ui/DatePicker';
import { actions, connect, selectors } from 'util/redux';

class PatientInvoiceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      errors: {},
      invoice: { ...props.invoice },
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        errors: {},
        invoice: { ...this.props.invoice },
      });
    }
  }

  _changeInvoice = (key, field) => {
    const { invoice } = this.state;
    this.setState({
      invoice: {
        ...invoice,
        [key]: field,
      },
    });
  };

  _saveInvoice = () => {
    const { createPatientInvoice, onClose } = this.props;
    const { invoice } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createPatientInvoice(invoice)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { isSaving, errors, invoice } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        onSave={this._saveInvoice}
        title={locale().patient_invoices.new}
        error={errors._global}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_invoices.number}
              error={errors.number}
            />
            <TextInput
              value={invoice.number}
              onChange={value => this._changeInvoice('number', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_invoices.date}
              error={errors.date}
            />
            <DatePicker
              value={invoice.date}
              onChange={value => this._changeInvoice('date', value)}
              dateOnly={true}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    invoice: selectors.getEditingPatientInvoice(state),
    open: selectors.isEditingPatientInvoice(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPatientInvoice: data => dispatch(actions.createPatientInvoice(data)),
    onClose: () => dispatch(actions.finishPatientInvoiceEditing()),
  };
};

PatientInvoiceDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientInvoiceDialog);

export default PatientInvoiceDialog;
