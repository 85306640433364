import Response from './../_response';
import {
  createResourceSerializer,
  createCollectionSerializer,
  MoneyAmountSerializer,
  DateSerializer,
} from './../_serializers';

// Income/Expense stats

const StatsPerMonthSerializer = createResourceSerializer({
  date: DateSerializer,
  amount: MoneyAmountSerializer,
});

const IncomeSerializer = createResourceSerializer({
  debt: MoneyAmountSerializer,
  payments: createCollectionSerializer(StatsPerMonthSerializer),
});

const ExpenseSerializer = createResourceSerializer({
  expenses: createCollectionSerializer(StatsPerMonthSerializer),
});

// Report stats

const ReportSerializer = createResourceSerializer({
  total: MoneyAmountSerializer,
  amount_per_type: createCollectionSerializer(
    createResourceSerializer({
      amount: MoneyAmountSerializer,
    })
  ),
});

const PLReportSerializer = createResourceSerializer({
  from_date: DateSerializer,
  to_date: DateSerializer,
  income_report: ReportSerializer,
  expense_report: ReportSerializer,
});

class OrganisationStatsEndpoint {
  constructor(client) {
    this._client = client;
  }

  getIncomeStats(data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch('income_stats', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, IncomeSerializer.deserialize(json));
      });
  }

  getExpenseStats(data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch('expense_stats', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, ExpenseSerializer.deserialize(json));
      });
  }

  getDiagnoseStats(data) {
    return this._client
      .fetch('diagnose_stats', 'GET', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  getPatientReferralStats(data) {
    return this._client
      .fetch('patient_referral_stats', 'GET', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  getProfitAndLossReport(data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch('profit_and_loss_report', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, PLReportSerializer.deserialize(json));
      });
  }

  getAppointmentStats(data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch('appointment_stats', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  _serializeDateRange = data => {
    let query = { ...data };

    if (data.from_date) {
      query.from_date = DateSerializer.serialize(data.from_date);
    }
    if (data.to_date) {
      query.to_date = DateSerializer.serialize(data.to_date);
    }

    return query;
  };
}

export default OrganisationStatsEndpoint;
