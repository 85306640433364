import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BottomSheet from 'ui/Dialog/BottomSheet';
import { Select } from 'ui/Form';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class SelectNBottomSheet extends Component {
  static propTypes = {
    value: PropTypes.any,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchable: PropTypes.bool,
    clearable: PropTypes.bool,
    // renderOnMobile indicates if Select will render on mobile (props below makes sense only if it is false)
    renderOnMobile: PropTypes.bool,
    mobileOpen: PropTypes.bool,
    onMobileClose: PropTypes.func,
  };

  static defaultProps = {
    searchable: true,
    clearable: true,
    renderOnMobile: true,
  };

  _mobile = () => {
    const {
      value,
      options,
      placeholder,
      mobileOpen,
      onMobileClose,
      onChange,
      clearable,
    } = this.props;

    let theOptions = [...options];

    if (clearable) {
      theOptions = [
        { value: null, label: placeholder, color: '#949bac' },
        ...theOptions,
      ];
    }

    return (
      <BottomSheet
        open={mobileOpen}
        onClose={onMobileClose}
        items={theOptions}
        onSelect={onChange}
        selectedItem={value}
      />
    );
  };

  _desktop = () => {
    const {
      value,
      options,
      onChange,
      placeholder,
      searchable,
      clearable,
    } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        searchable={searchable}
        clearable={clearable}
        options={options}
      />
    );
  };

  render() {
    const { renderOnMobile } = this.props;

    if (renderOnMobile) {
      return this._desktop();
    } else {
      return (
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      );
    }
  }
}

export default SelectNBottomSheet;
