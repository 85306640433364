import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_TreatmentsListItem.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionListItem } from 'ui/SectionList';
import { CardRow } from 'ui/Card';
import { MoneyText } from 'ui/Money';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class TreatmentsListItem extends Component {
  static propTypes = {
    treatment: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._moreActions = [
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editTreatment = () => {
    const { treatment, editTreatment } = this.props;
    editTreatment(treatment);
  };

  _onDestroy = () => {
    const { treatment, destroyTreatment } = this.props;
    destroyTreatment(treatment.id);
  };

  _mobile = () => {
    const { treatment } = this.props;

    return (
      <SectionListItem
        title={treatment.name}
        extra={<MoneyText value={treatment.price} />}
        extraColor={'#46AC5A'}
        rightContent={`${treatment.duration} min`}
        onClick={this._editTreatment}
        contextMenuItems={this._moreActions}
      />
    );
  };

  _desktop = () => {
    const { treatment } = this.props;

    return (
      <CardRow
        onClick={this._editTreatment}
        rowSize={'small'}
        contextMenuItems={this._moreActions}
      >
        <div className={styles['wrapper']}>
          <div className={styles['name']}>{treatment.name}</div>
          <div className={styles['meta']}>
            <div className={styles['duration']}>{treatment.duration} min</div>
            <div className={styles['price']}>
              <MoneyText value={treatment.price} />
            </div>
          </div>
        </div>
      </CardRow>
    );
  };

  render() {
    const { treatment } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._onDestroy}
          title={`${locale().treatments.destroy_treatment_head}: ${
            treatment.name
          }`}
          description={locale().treatments.destroy_treatment_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    destroyTreatment: id => dispatch(actions.destroyTreatment(id)),
    editTreatment: treatment => dispatch(actions.editTreatment(treatment)),
  };
};

TreatmentsListItem = connect(null, mapDispatchToProps)(TreatmentsListItem);

export default TreatmentsListItem;
