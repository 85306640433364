import Response from '../_response';
import { PatientNoteSerializer } from '../_serializers';

class PatientNotesEndpoint {
  constructor(client) {
    this._client = client;
  }

  create(data) {
    return this._client
      .fetch('patient_notes', 'POST', PatientNoteSerializer.serialize(data))
      .then(({ response, json }) => {
        return PatientNotesEndpoint._buildResponse(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(
        `patient_notes/${data.id}`,
        'PATCH',
        PatientNoteSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        return PatientNotesEndpoint._buildResponse(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patient_notes/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return PatientNotesEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = PatientNoteSerializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default PatientNotesEndpoint;
