import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './PatientSelectUI.module.scss';
import { Select } from 'ui/Form';
import { MoneyText } from 'ui/Money';
import ActionCenter from 'util/action_center';

class PatientSelectUI extends Component {
  static propTypes = {
    value: PropTypes.string,
    patients: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    renderer: PropTypes.oneOf(['default', 'debt']),
    autoSelectOnCreation: PropTypes.bool,
  };

  static defaultProps = {
    renderer: 'default',
    autoSelectOnCreation: false,
  };

  componentDidMount() {
    const { autoSelectOnCreation } = this.props;

    if (autoSelectOnCreation) {
      ActionCenter.subscribe('createPatient', this._onPatientCreated);
    }
  }

  componentWillUnmount() {
    const { autoSelectOnCreation } = this.props;

    if (autoSelectOnCreation) {
      ActionCenter.unsubscribe('createPatient', this._onPatientCreated);
    }
  }

  _onPatientCreated = patient => {
    const { value } = this.props;

    if (!value) {
      this.props.onChange(patient.id);
    }
  };

  _renderers = () => ({
    default: null,
    debt: patient => (
      <div className={styles['debtWrapper']}>
        <div>{patient.label}</div>
        {patient.debt > 0 && (
          <div className={styles['debt']}>
            <MoneyText value={patient.debt} />
          </div>
        )}
      </div>
    ),
  });

  _options = () => {
    const { patients } = this.props;

    return patients.sortBy('name').map(patient => ({
      value: patient.id,
      label: patient.name,
      debt: patient.debt,
    }));
  };

  render() {
    const { value, onChange, renderer } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        optionRenderer={this._renderers()[renderer]}
      />
    );
  }
}

export default PatientSelectUI;
