import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_AppointmentsList.module.scss';
import Paginator from 'navigation/Paginator';
import Accordion from 'ui/Accordion';
import EmptyState from './../_EmptyState';
import AppointmentsListItem from './_AppointmentsListItem';
import ActionCenter from 'util/action_center';
import appointmentsImg from 'media/images/schedule/emptyState.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class AppointmentsList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    ActionCenter.subscribe('createService', this._onServiceCreated);
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createService', this._onServiceCreated);
  }

  _onFirstLoad = () => this.setState({ loadingState: loadingStates.present });

  _onServiceCreated = service => {
    const { patientId, showService, openSchedulePage } = this.props;

    if (service.number_of_treatments > 1) {
      showService(service.id);
    } else {
      openSchedulePage({
        patientId: patientId,
        serviceId: service.id,
      });
    }
  };

  _appointmentMap = () => {
    const { appointments } = this.props;
    const appointmentMap = {};

    appointments.forEach(appointment => {
      const key = appointment.date.format('yyyy-MM');
      appointmentMap[key] = [...(appointmentMap[key] || []), appointment];
    });

    return appointmentMap;
  };

  render() {
    const { patientId } = this.props;

    const { loadingState } = this.state;
    const firstFetchCompleted = loadingState === loadingStates.present;

    const appointmentMap = this._appointmentMap();
    const dateKeys = Object.keys(appointmentMap).sortBy('').reverse();
    const hasAppointmentsInFirstMonth =
      dateKeys[0] &&
      (Date.fromString(dateKeys[0]).isCurrentMonth() ||
        Date.fromString(dateKeys[0]).isInFuture());

    return (
      <div className={styles['wrapper']}>
        <Paginator
          fetch={this.props.fetchAppointmentsForPatient}
          onFirstLoad={this._onFirstLoad}
        >
          <Accordion
            items={dateKeys.map(dateKey => {
              const date = new Date.fromString(dateKey);

              return {
                id: dateKey,
                title: date.format('MMMM yyyy').capitalize(),
                content: (
                  <div className={styles['list']}>
                    {appointmentMap[dateKey].sortBy('date').map(appointment => (
                      <AppointmentsListItem
                        key={appointment.id}
                        patientId={patientId}
                        appointment={appointment}
                      />
                    ))}
                  </div>
                ),
              };
            })}
            firstOpened={hasAppointmentsInFirstMonth}
          />
        </Paginator>
        {firstFetchCompleted && this.props.appointments.length === 0 && (
          <EmptyState
            intro={locale().appointments.empty_state_description}
            image={appointmentsImg}
            extraSpace={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    appointments: selectors.getAppointmentsForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    fetchAppointmentsForPatient: ({ clearOld, page }) =>
      dispatch(
        actions.fetchAppointmentsForPatient({ patientId, clearOld, page })
      ),
    showService: id => dispatch(actions.showService(id)),
    openSchedulePage: data => dispatch(actions.openSchedulePage(data)),
  };
};

AppointmentsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentsList);

export default AppointmentsList;
