import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './AppointmentShow.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Icon, { BirthdayIcon } from 'ui/Icon';
import HeaderBar from 'ui/HeaderBar';
import Dropdown from 'ui/Dropdown';
import CancelConfirmationDialog from './_CancelConfirmationDialog';
import ActionCenter from 'util/action_center';
import PatientNotesList from 'patients/PatientNotesList';
import AppointmentForm from 'schedule/AppointmentForm';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class AppointmentShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      cancelConfirmationDialogOpen: false,
      editingMode: false,
    };
  }

  componentDidMount() {
    this._fetch();
    ActionCenter.subscribe('createPatientNote', this._fetch);
    ActionCenter.subscribe('updatePatientNote', this._fetch);
    ActionCenter.subscribe('destroyPatientNote', this._fetch);
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createPatientNote', this._fetch);
    ActionCenter.unsubscribe('updatePatientNote', this._fetch);
    ActionCenter.unsubscribe('destroyPatientNote', this._fetch);
  }

  _isCancelled = () => this.props.appointment.cancelled;

  _contextMenuItems = () => {
    if (this._isCancelled()) {
      return null;
    } else {
      return [
        {
          label: locale().actions.edit,
          onClick: this._enterEditingMode,
        },
        {
          label: locale().appointments.cancel_appointment,
          onClick: this._openCancelConfirmationDialog,
          type: 'attention',
        },
      ];
    }
  };

  _enterEditingMode = () => this.setState({ editingMode: true });

  _quitEditingMode = () => this.setState({ editingMode: false });

  _openCancelConfirmationDialog = () =>
    this.setState({ cancelConfirmationDialogOpen: true });

  _closeCancelConfirmationDialog = () =>
    this.setState({ cancelConfirmationDialogOpen: false });

  _fetch = () => {
    const { fetchAppointment } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchAppointment()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        if (response.isNotFound && response.isNotFound()) {
          this.setState({ loadingState: loadingStates.missing });
        } else {
          this.setState({ loadingState: loadingStates.failed });
        }
      });
  };

  _goBack = () => window.history.back();

  _cancelAppointment = data => {
    const { cancelAppointment } = this.props;
    cancelAppointment(data);
  };

  _newPatientNote = serviceItem => {
    const { newPatientNote } = this.props;

    newPatientNote({
      service_item_id: serviceItem.id,
      patient_id: serviceItem.patient_id,
    });
  };

  _mobileHeader = () => {
    const { appointment } = this.props;
    const title = appointment
      ? appointment.date.format('dd.MM.yyyy. HH:mm')
      : '';

    return <HeaderBar title={title} historyBack={true} />;
  };

  _desktopHeader = () => (
    <React.Fragment>
      {!this.state.editingMode && (
        <div className={styles['goBack']}>
          <div className={styles['clickable']} onClick={this._goBack}>
            <Icon icon={'arrow_back'} size={18} color={'#23b0ff'} />
            <span>{locale().actions.back}</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );

  _form = () => {
    const { appointment, updateAppointment } = this.props;

    return (
      <div className={styles['form']}>
        <AppointmentForm
          saveAction={data =>
            updateAppointment({ id: appointment.id, ...data })
          }
          onFinish={this._quitEditingMode}
          date={appointment.date}
          locationId={appointment.location ? appointment.location.id : null}
          userIds={appointment.users.map(u => u.id)}
          patientsData={appointment.events.map(si => ({
            patientId: si.patient_id,
            serviceId: si.service_id,
            serviceItemId: si.id,
          }))}
          editingMode={true}
        />
      </div>
    );
  };

  _presenter = () => {
    const {
      appointment,
      newServicePayment,
      showPatient,
      showService,
    } = this.props;

    // Can be null for a moment when destroyed
    if (!appointment) {
      return null;
    }

    const contextMenuItems = this._contextMenuItems();

    return (
      <React.Fragment>
        <MobileDesktopRender desktop={this._desktopHeader} />
        <div className={styles['wrapper']}>
          {this._isCancelled() && (
            <div className={styles['cancelled']}>
              <div className={styles['cancelledBy']}>
                {locale().appointments.cancelled}&nbsp;
                {appointment.cancelled_by &&
                  `- ${appointment.cancelled_by.name}`}
              </div>
              {appointment.cancel_reason && (
                <div className={styles['reason']}>
                  {appointment.cancel_reason}
                </div>
              )}
            </div>
          )}
          <div className={styles['title']}>
            <span>
              {appointment.date.format('EEEE, dd. MMMM yyyy.').capitalize()}
            </span>
            <div className={styles['dropdown']}>
              {contextMenuItems && <Dropdown items={contextMenuItems} />}
            </div>
          </div>
          <div className={styles['time']}>
            {Date.formatAppointment(appointment.date, appointment.duration)}
            {appointment.location && ` | ${appointment.location.name}`}
          </div>
          <div className={styles['users']}>
            {appointment.users.map(user => (
              <div key={user.id} className={styles['participant']}>
                <Icon icon={'person_outline'} color={'#46ac5a'} />
                <span>{user.name}</span>
              </div>
            ))}
          </div>
          <div className={styles['events']}>
            {appointment.events.map(event => (
              <div key={event.id} className={styles['event']}>
                <div className={styles['name']}>
                  <div
                    className={styles['label']}
                    onClick={() => showPatient(event.patient_id)}
                  >
                    {event.display_name}
                  </div>
                  <div className={styles['birthday']}>
                    <BirthdayIcon
                      birthDate={event.birth_date}
                      currentDate={appointment.date}
                      showWhenNear={false}
                    />
                  </div>
                </div>
                <div className={styles['extra']}>{event.name}</div>
                {event.notes.length > 0 && (
                  <div className={styles['notes']}>
                    <PatientNotesList notes={event.notes} />
                  </div>
                )}
                <div className={styles['actions']}>
                  <div
                    className={styles['action']}
                    onClick={() => showService(event.service_id)}
                  >
                    {locale().appointments.show_service}
                  </div>
                  <div className={styles['group']}>
                    <div
                      className={styles['action']}
                      onClick={() => newServicePayment(event.service_id)}
                    >
                      {locale().service_payments.title}
                    </div>
                    <div
                      className={styles['action']}
                      onClick={() => this._newPatientNote(event)}
                    >
                      {locale().patient_notes.add_notes}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      loadingState,
      cancelConfirmationDialogOpen,
      editingMode,
    } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (loadingState === loadingStates.missing) {
      bodyNode = <FailureInfo info={locale().appointments.not_found} />;
    } else if (editingMode) {
      bodyNode = this._form();
    } else {
      bodyNode = this._presenter();
    }

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobileHeader} />
        {bodyNode}
        <CancelConfirmationDialog
          open={cancelConfirmationDialogOpen}
          onClose={this._closeCancelConfirmationDialog}
          onConfirm={this._cancelAppointment}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    appointment: selectors.getAppointment(state, id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    showPatient: patientId => dispatch(actions.showPatient(patientId)),
    showService: serviceId => dispatch(actions.showService(serviceId)),
    fetchAppointment: () => dispatch(actions.fetchOneAppointment(id)),
    cancelAppointment: data =>
      dispatch(actions.cancelAppointment({ id: id, ...data })),
    updateAppointment: data => dispatch(actions.updateAppointment(data)),
    newServicePayment: serviceId =>
      dispatch(actions.newServicePayment(serviceId)),
    newPatientNote: data => dispatch(actions.newPatientNote(data)),
  };
};

AppointmentShow = connect(mapStateToProps, mapDispatchToProps)(AppointmentShow);

export default AppointmentShow;
