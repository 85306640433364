import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { CardRow } from 'ui/Card';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class InvitationsListItem extends Component {
  static propTypes = {
    invitation: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _onDestroy = () => {
    const { invitation, destroyInvitation } = this.props;
    destroyInvitation(invitation.id);
  };

  render() {
    const { invitation } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <CardRow
          rowSize={'small'}
          contextMenuItems={[
            {
              label: locale().employees.revoke_invitation,
              onClick: this._openConfirmationDialog,
              type: 'attention',
            },
          ]}
        >
          {invitation.email}
        </CardRow>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._onDestroy}
          title={`${locale().employees.destroy_invitation_head} ${
            invitation.email
          }`}
          description={locale().employees.destroy_invitation_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    destroyInvitation: id => dispatch(actions.destroyInvitation(id)),
  };
};

InvitationsListItem = connect(null, mapDispatchToProps)(InvitationsListItem);

export default InvitationsListItem;
