import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { connect, actions } from 'util/redux';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormColumn, FormLabel, FormRow, TextInput } from 'ui/Form';

class GroupServicesDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    patientInvoiceId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this._emptyData(),
      errors: {},
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: this._emptyData(),
        errors: {},
      });
    }
  }

  _emptyData = () => ({ name: '' });

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const {
      patientInvoiceId,
      groupPatientInvoiceServices,
      onClose,
    } = this.props;
    const { data } = this.state;

    this.setState({ errors: {}, isSaving: true });
    groupPatientInvoiceServices({ id: patientInvoiceId, ...data })
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, errors } = this.state;

    return (
      <SidebarDialog
        onClose={onClose}
        open={open}
        title={locale().patient_invoices.group_services}
        onSave={this._save}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_invoices.line_item_name}
              error={errors.name}
            />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    groupPatientInvoiceServices: data =>
      dispatch(actions.groupPatientInvoiceServices(data)),
  };
};

GroupServicesDialog = connect(null, mapDispatchToProps)(GroupServicesDialog);

export default GroupServicesDialog;
