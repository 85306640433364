import Client from './_http-client';
import locale from 'util/locale';

import UserEndpoint from './endpoints/_user';
import NotificationsEndpoint from './endpoints/_notifications';
import DiagnosesEndpoint from './endpoints/_diagnoses';
import PatientReferralsEndpoint from './endpoints/_patient_referrals';
import PatientsEndpoint from './endpoints/_patients';
import ServicesEndpoint from './endpoints/_services';
import PatientReportsEndpoint from './endpoints/_patient_reports';
import PatientDocumentsEndpoint from './endpoints/_patient_documents';
import PatientInvoicesEndpoint from './endpoints/_patient_invoices';
import ServiceItemsEndpoint from './endpoints/_service_items';
import PatientNotesEndpoint from './endpoints/_patient_notes';
import AppointmentsEndpoint from './endpoints/_appointments';
import ServicePaymentsEndpoint from './endpoints/_service_payments';
import PackagesEndpoint from './endpoints/_packages';
import TreatmentsEndpoint from './endpoints/_treatments';
import OrganisationStatsEndpoint from './endpoints/_organisation_stats';
import UserStatsEndpoint from './endpoints/_user_stats';
import EmployeesEndpoint from './endpoints/_employees';
import InvitationsEndpoint from './endpoints/_invitations';
import LocationsEndpoint from './endpoints/_locations';
import SubscriptionPlansEndpoint from './endpoints/_subscription_plans';
import PostsEndpoint from './endpoints/_posts';
import ExpensesEndpoint from './endpoints/_expenses';
import ExpenseTypesEndpoint from './endpoints/_expense_types';

const defaultConfig = {
  onRequest: () => {},
  onResponse: () => {},
};

class Api {
  constructor(config = {}) {
    const actualConfig = {
      ...defaultConfig,
      ...config,
    };

    let apiDomain;
    if (typeof window !== 'undefined') {
      let port = `:${window.location.port.replace(':', '')}`;
      apiDomain = `${window.location.protocol}//${window.location.hostname}${port}`;
    } else {
      apiDomain = 'https://app.logovel.com';
    }
    const apiRoot = `${apiDomain}/api`;

    const client = new Client({
      apiRoot: apiRoot,
      onRequest: actualConfig.onRequest,
      onResponse: actualConfig.onResponse,
    });

    this.user = new UserEndpoint(client);
    this.notifications = new NotificationsEndpoint(client);
    this.diagnoses = new DiagnosesEndpoint(client);
    this.patient_referrals = new PatientReferralsEndpoint(client);
    this.patients = new PatientsEndpoint(client);
    this.services = new ServicesEndpoint(client);
    this.serviceItems = new ServiceItemsEndpoint(client);
    this.patientNotes = new PatientNotesEndpoint(client);
    this.appointments = new AppointmentsEndpoint(client);
    this.servicePayments = new ServicePaymentsEndpoint(client);
    this.patientReports = new PatientReportsEndpoint(client);
    this.patientDocuments = new PatientDocumentsEndpoint(client);
    this.patientInvoices = new PatientInvoicesEndpoint(client);
    this.packages = new PackagesEndpoint(client);
    this.treatments = new TreatmentsEndpoint(client);
    this.organisationStats = new OrganisationStatsEndpoint(client);
    this.userStats = new UserStatsEndpoint(client);
    this.employees = new EmployeesEndpoint(client);
    this.invitations = new InvitationsEndpoint(client);
    this.locations = new LocationsEndpoint(client);
    this.subscriptionPlans = new SubscriptionPlansEndpoint(client);
    this.posts = new PostsEndpoint(client);
    this.expenses = new ExpensesEndpoint(client);
    this.expenseTypes = new ExpenseTypesEndpoint(client);
  }
}

const authPages = [
  '/onboard/login',
  '/onboard/register',
  '/onboard/forgot-password',
  '/onboard/reset-password',
];

let API = new Api({
  onResponse: ({ response, json }) => {
    if (response.headers.api_version) {
      if (!window.api_version) {
        window.api_version = response.headers.api_version;
      } else if (response.headers.api_version !== window.api_version) {
        if (
          !window.delay_reload_until ||
          Date.fromString(window.delay_reload_until) < new Date()
        ) {
          window.delay_reload_until = new Date().addMinutes(1);

          if (window.confirm(locale().system.new_api_version)) {
            window.location.reload();
          }
        }
      }
    }

    if (
      response.status === 401 &&
      !authPages.includes(window.location.pathname)
    ) {
      window.location = '/onboard/login';
    } else if (response.status === 402) {
      window.location = '/subscription';
    }
  },
});

export default API;
