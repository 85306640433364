import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, TextInput, FormLabel } from 'ui/Form';
import { connect, actions, selectors } from 'util/redux';

class LocationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.location },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.location },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createLocation, updateLocation, onClose } = this.props;
    const { data } = this.state;

    const save = data.id ? updateLocation : createLocation;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { onClose, open } = this.props;
    const { data, errors, isSaving } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={data.id ? locale().locations.edit : locale().locations.new}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().locations.name} error={errors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().locations.capacity}
              error={errors.capacity}
            />
            <TextInput
              value={data.capacity}
              onChange={value => this._changeData('capacity', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    location: selectors.getEditingLocation(state),
    open: selectors.isEditingLocation(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createLocation: data => dispatch(actions.createLocation(data)),
    updateLocation: data => dispatch(actions.updateLocation(data)),
    onClose: () => dispatch(actions.finishLocationEditing()),
  };
};

LocationDialog = connect(mapStateToProps, mapDispatchToProps)(LocationDialog);

export default LocationDialog;
