import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_View.module.scss';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import { connect, actions } from 'util/redux';

class View extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    onConfirm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      seen: true,
      errors: {},
    };
  }

  componentDidMount() {
    const { notification, seenNotification } = this.props;

    this.setState({ seen: !!notification.seen_at }, () => {
      if (!notification.seen_at) {
        seenNotification(notification.id);
      }
    });
  }

  _confirm = () => {
    const { notification, onConfirm, reloadNotification } = this.props;

    this.setState({ isSaving: true });
    onConfirm()
      .then(() => reloadNotification(notification.id))
      .then(() => this.setState({ isSaving: false }))
      .catch(this._handleError);
  };

  _cancel = () => {
    const { acknowledgeNotification, notification } = this.props;

    this.setState({ isSaving: true });
    acknowledgeNotification(notification.id)
      .then(() => this.setState({ isSaving: false }))
      .catch(this._handleError);
  };

  _handleError = response => {
    console.error(response);
    this.setState({
      isSaving: false,
      errors: { _global: locale().system.global_error },
    });
  };

  render() {
    const { notification, onConfirm } = this.props;
    const { isSaving, errors, seen } = this.state;

    return (
      <div
        className={classNames(styles['wrapper'], {
          [styles['notSeen']]: !seen,
        })}
      >
        <div className={styles['body']}>
          <div className={styles['text']}>
            {!errors._global && this.props.children}
            {errors._global && (
              <div className={styles['error']}>{errors._global}</div>
            )}
          </div>
          <div className={styles['actions']}>
            {!isSaving && (
              <React.Fragment>
                {onConfirm && (
                  <Icon
                    icon={'done'}
                    size={22}
                    color={'#46ac5a'}
                    onClick={this._confirm}
                  />
                )}
                <Icon
                  icon={'close'}
                  size={22}
                  color={'#949bac'}
                  onClick={this._cancel}
                />
              </React.Fragment>
            )}
            {isSaving && <LoadingIndicator size={'small'} />}
          </div>
        </div>
        <div className={styles['footer']}>
          {notification.created_at.format('dd.MM.yyyy. HH:mm ')}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reloadNotification: id => dispatch(actions.fetchOneNotification(id)),
    acknowledgeNotification: id =>
      dispatch(actions.acknowledgeNotification(id)),
    seenNotification: id => dispatch(actions.seenNotification(id)),
  };
};

View = connect(null, mapDispatchToProps)(View);

export default View;
