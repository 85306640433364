import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Dropdown.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import BottomSheet from 'ui/Dialog/BottomSheet';
import Icon from 'ui/Icon';

class Dropdown extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        type: PropTypes.oneOf(['normal', 'attention']),
      })
    ).isRequired,
    title: PropTypes.string,
    icon: PropTypes.oneOf(['expand_more', 'more_horiz', 'more_vert']),
    size: PropTypes.number,
    // Desktop properties below
    boxPosition: PropTypes.oneOf(['left', 'right']),
    classToHide: PropTypes.string, // Should be class with display: none; it will be visible when open
    className: PropTypes.string, // Use it for positioning
  };

  static defaultProps = {
    title: null,
    icon: 'more_horiz',
    boxPosition: 'left',
  };

  static _boxPositionStyle = {
    left: { right: '-2px' },
    right: { left: '2px' },
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  _open = () => {
    this.setState({ open: true });
    document.addEventListener('click', this._close);
  };

  _close = () => {
    this.setState({ open: false });
    document.removeEventListener('click', this._close);
  };

  _icons = () => ({
    expand_more: (
      <Icon
        icon={'expand_more'}
        color={'#6E7482'}
        expand={true}
        size={this.props.size || 20}
        onClick={this._open}
      />
    ),
    more_horiz: (
      <Icon
        icon={'more_horiz'}
        size={this.props.size || 32}
        onClick={this._open}
      />
    ),
    more_vert: (
      <Icon
        icon={'more_vert'}
        size={this.props.size || 24}
        color={'#ffffff'}
        onClick={this._open}
        expand={true}
      />
    ),
  });

  _mobile = () => {
    const { items } = this.props;
    const { open } = this.state;

    return <BottomSheet onClose={this._close} open={open} items={items} />;
  };

  _desktop = () => {
    const { items, title, boxPosition } = this.props;
    const { open } = this.state;
    const style = Dropdown._boxPositionStyle[boxPosition];

    if (!open) {
      return null;
    }

    return (
      <div
        className={classNames(styles['box'], {
          [styles['withTitle']]: title !== null,
        })}
        style={style}
      >
        {items.map(item => (
          <div
            key={item.label}
            onClick={item.onClick}
            className={classNames(
              styles['item'],
              styles[item.type || 'normal']
            )}
          >
            {item.label}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { classToHide, className, title, icon } = this.props;
    const { open } = this.state;

    let boxOpenerNode;
    if (title) {
      boxOpenerNode = (
        <div className={styles['dropdown']} onClick={this._open}>
          <div className={styles['title']}>{title}</div>
          <Icon icon={'expand_more'} size={18} />
        </div>
      );
    } else {
      boxOpenerNode = this._icons()[icon];
    }

    return (
      <div
        className={classNames(styles['wrapper'], className, {
          [classToHide]: !open,
        })}
        onClick={e => e.stopPropagation()}
      >
        {boxOpenerNode}
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      </div>
    );
  }
}

export default Dropdown;
