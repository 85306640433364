import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';
import classNames from 'classnames';
import Icon from 'ui/Icon';

class Checkbox extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.node,
  };

  static defaultProps = {
    label: '',
  };

  _onChange = e => {
    const { value, onChange } = this.props;

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    onChange(!value);
  };

  render() {
    const { value, label } = this.props;

    return (
      <div className={styles['withLabel']} onClick={this._onChange}>
        <div
          className={classNames(styles['wrapper'], {
            [styles['checked']]: value,
          })}
        >
          <div className={styles['inner']}>
            <Icon icon={'done'} size={14} color={'#ffffff'} />
          </div>
        </div>
        <div className={styles['label']}>{label}</div>
      </div>
    );
  }
}

export default Checkbox;
