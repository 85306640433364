import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { CardRow } from 'ui/Card';
import { FormColumn, FormLabel, FormRow, TextInput } from 'ui/Form';
import { loadingStates, actions, connect, selectors } from 'util/redux';

class PatientReferralDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.patientReferral },
      isSaving: false,
      errors: {},
      loadingState: loadingStates.fetching,
      confirmationDialogOpen: false,
      referralId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchPatientReferrals } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.patientReferral },
        errors: {},
        loadingState: loadingStates.fetching,
      });
      fetchPatientReferrals()
        .then(() => this.setState({ loadingState: loadingStates.present }))
        .catch(response => {
          console.error(response);
          this.setState({ loadingState: loadingStates.failed });
        });
    }
  }

  _openConfirmationDialog = referralId =>
    this.setState({ confirmationDialogOpen: true, referralId });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false, referralId: null });

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createPatientReferral, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createPatientReferral(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _destroyPatientReferral = () => {
    const { destroyPatientReferral } = this.props;
    const { referralId } = this.state;

    this.setState({ errors: {} });
    destroyPatientReferral(referralId).catch(response => {
      console.error(response);
      this.setState({
        errors: {
          _global: locale().system.global_error,
        },
      });
    });
  };

  render() {
    const { patientReferrals, open, onClose } = this.props;
    const {
      data,
      isSaving,
      loadingState,
      confirmationDialogOpen,
      referralId,
      errors,
    } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={locale().patient_referrals.title}
        onSave={this._save}
        error={errors._global}
        loadingState={loadingState}
        inProgress={isSaving}
        footer={
          <React.Fragment>
            {patientReferrals.map(referral => (
              <CardRow
                key={referral.id}
                contextMenuItems={[
                  {
                    label: locale().actions.destroy,
                    onClick: () => this._openConfirmationDialog(referral.id),
                    type: 'attention',
                  },
                ]}
              >
                {referral.name}
              </CardRow>
            ))}
          </React.Fragment>
        }
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_referrals.name}
              error={errors.name}
            />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._destroyPatientReferral}
          title={`${
            locale().patient_referrals.destroy_patient_referral_head
          }: ${
            (patientReferrals.find(d => d.id === referralId) || { name: '' })
              .name
          }`}
          description={
            locale().patient_referrals.destroy_patient_referral_notice
          }
        />
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    patientReferrals: selectors.getPatientReferrals(state),
    open: selectors.isEditingPatientReferral(state),
    expense: selectors.getEditingPatientReferral(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPatientReferrals: () => dispatch(actions.fetchPatientReferrals()),
    createPatientReferral: data =>
      dispatch(actions.createPatientReferral(data)),
    destroyPatientReferral: id => dispatch(actions.destroyPatientReferral(id)),
    onClose: () => dispatch(actions.finishPatientReferralEditing()),
  };
};

PatientReferralDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientReferralDialog);

export default PatientReferralDialog;
