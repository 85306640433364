import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from 'util/helpers';
import { connect, selectors } from 'util/redux';

class MoneyText extends Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    currency: PropTypes.string,
  };

  render() {
    const { value, currency, currentUser } = this.props;

    let formattedMoney = value.toFixed(2);
    let groups = 0,
      finalValue = '';
    for (let i = formattedMoney.length - 1; i >= 0; i--) {
      let c = formattedMoney.charAt(i);
      groups++;
      if (c === '.') {
        groups = -1;
      } else if (groups === 3) {
        finalValue += ',';
        groups = 0;
      }
      finalValue += c;
    }
    finalValue = finalValue.split('').reverse().join('');

    return (
      <div>
        {formatMoney(finalValue, currency || currentUser.organisation.currency)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
  };
};

MoneyText = connect(mapStateToProps)(MoneyText);

export default MoneyText;
