import React, { Component } from 'react';
import './Toast.module.scss';
import { ToastContainer, Slide } from 'react-toastify';
import Portal from 'ui/Portal';
import { MediaQueryWatch } from 'ui/MobileDesktopRender';

class Toast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: null,
    };
  }

  _changeMode = isDesktop => {
    this.setState({ isDesktop });
  };

  render() {
    const { isDesktop } = this.state;

    return (
      <Portal>
        <MediaQueryWatch
          mediaQuery={'(min-width: 800px)'}
          onMatchesChange={this._changeMode}
        />
        <ToastContainer
          autoClose={1700}
          position={isDesktop ? 'bottom-left' : 'bottom-center'}
          transition={Slide}
          closeButton={false}
          hideProgressBar={true}
        />
      </Portal>
    );
  }
}

export default Toast;
