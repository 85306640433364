import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './AssignServiceDialog.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionListItem } from 'ui/SectionList';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { RadioButton, TextInput } from 'ui/Form';
import { MoneyText } from 'ui/Money';
import Icon from 'ui/Icon';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class AssignServiceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      searchQuery: '',
      selectedServiceId: null,
      selectedServiceType: '',
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchTreatments, fetchPackages } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        loadingState: loadingStates.fetching,
        errors: {},
        searchQuery: '',
        selectedServiceId: null,
        selectedServiceType: '',
      });

      Promise.all([fetchTreatments(), fetchPackages()])
        .then(() => this.setState({ loadingState: loadingStates.present }))
        .catch(error => {
          console.error(error);
          this.setState({ loadingState: loadingStates.failed });
        });
    }
  }

  _changeSearchQuery = searchQuery => this.setState({ searchQuery });

  _assignService = () => {
    const { createService, onClose, assigningServiceData } = this.props;
    const { selectedServiceId, selectedServiceType } = this.state;

    if (selectedServiceId) {
      this.setState({ isSaving: true, errors: {} });
      createService({
        patient_id: assigningServiceData.patientId,
        [`${selectedServiceType}_id`]: selectedServiceId,
      })
        .then(() => {
          this.setState({ isSaving: false });
          onClose();
        })
        .catch(response => {
          console.error(response);
          if (response.isValidationError && response.isValidationError()) {
            this.setState({
              errors: response.validationErrors().errorPerField(),
            });
          } else {
            this.setState({
              errors: {
                _global: locale().system.global_error,
              },
            });
          }
          this.setState({ isSaving: false });
        });
    } else {
      this.setState({
        errors: { _global: locale().services.service_not_selected },
      });
    }
  };

  _selectService = (service, type) =>
    this.setState({
      selectedServiceId: service.id,
      selectedServiceType: type,
    });

  _mobile = (item, type) => (
    <SectionListItem
      title={
        <RadioButton
          value={this.state.selectedServiceId === item.id}
          label={item.name}
          onClick={() => {}}
        />
      }
      rightContent={<MoneyText value={item.price} />}
      rightPadding={true}
      onClick={() => this._selectService(item, type)}
    />
  );

  _desktop = (item, type) => (
    <div className={styles['row']}>
      <RadioButton
        value={this.state.selectedServiceId === item.id}
        label={item.name}
        onClick={() => this._selectService(item, type)}
      />
      <div className={styles['money']}>
        <MoneyText value={item.price} />
      </div>
    </div>
  );

  _services = () => {
    const { treatments, packages } = this.props;
    const { searchQuery } = this.state;

    return [
      ...treatments
        .sortBy('name')
        .map(t => ({ type: 'treatment', service: t })),
      ...packages.sortBy('name').map(p => ({ type: 'package', service: p })),
    ].filter(data =>
      data.service.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  _renderRow = (item, type) => (
    <MobileDesktopRender
      key={item.id}
      mobile={() => this._mobile(item, type)}
      desktop={() => this._desktop(item, type)}
    />
  );

  render() {
    const { open, onClose, treatments, packages, newTreatment } = this.props;
    const { loadingState, searchQuery, isSaving, errors } = this.state;

    const noServices = treatments.length === 0 && packages.length === 0;

    let bodyNode;
    if (noServices) {
      bodyNode = (
        <div className={styles['emptyState']}>
          {locale().services.no_services_notice()}
        </div>
      );
    } else {
      bodyNode = (
        <React.Fragment>
          <div className={styles['searchForm']}>
            <TextInput
              value={searchQuery}
              onChange={this._changeSearchQuery}
              prefix={<Icon icon={'search'} color={'#949BAC'} size={22} />}
            />
          </div>
          {this._services().map(data =>
            this._renderRow(data.service, data.type)
          )}
        </React.Fragment>
      );
    }

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        onSave={noServices ? newTreatment : this._assignService}
        saveText={noServices ? locale().services.new : locale().services.assign}
        padding={false}
        title={locale().services.assign}
        loadingState={loadingState}
        inProgress={isSaving}
        error={errors._global}
      >
        {bodyNode}
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isAssigningService(state),
    assigningServiceData: selectors.getAssigningServiceData(state),
    treatments: selectors.getTreatments(state),
    packages: selectors.getPackages(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    newTreatment: () => dispatch(actions.newTreatment()),
    createService: data => dispatch(actions.createService(data)),
    fetchTreatments: () => dispatch(actions.fetchTreatments()),
    fetchPackages: () => dispatch(actions.fetchPackages()),
    onClose: () => dispatch(actions.finishAssigningService()),
  };
};

AssignServiceDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignServiceDialog);

export default AssignServiceDialog;
