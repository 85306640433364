import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './SectionListItem.module.scss';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import Dropdown from 'ui/Dropdown';

class SectionListItem extends Component {
  static propTypes = {
    leftContent: PropTypes.node,
    title: PropTypes.node.isRequired,
    titleColor: PropTypes.string,
    extra: PropTypes.node,
    extraColor: PropTypes.string,
    rightContent: PropTypes.node,
    extraRightContent: PropTypes.node,
    rightPadding: PropTypes.bool, // Add more padding to the right if moreArrow is not used
    onClick: PropTypes.func,
    contextMenuItems: PropTypes.array, // Eiter use this or onMoreClick
    onMoreClick: PropTypes.func,
  };

  static defaultProps = {
    titleColor: '#1A1C1F',
    extraColor: '#949BAC',
    rightPadding: false,
  };

  constructor(props) {
    super(props);
    this._touchable = React.createRef();
  }

  _onClick = () => {
    const { onClick } = this.props;

    if (onClick && this._touchable) {
      this._touchable.current.classList.add(styles['animate']);
    }

    setTimeout(() => {
      if (onClick && this._touchable) {
        this._touchable.current.classList.remove(styles['animate']);
      }

      if (onClick) {
        onClick();
      }
    }, 150);
  };

  render() {
    const {
      leftContent,
      title,
      titleColor,
      extra,
      extraColor,
      rightContent,
      extraRightContent,
      rightPadding,
      contextMenuItems,
      onMoreClick,
    } = this.props;

    let extraNode;
    if (extra) {
      extraNode = (
        <div className={styles['extra']} style={{ color: extraColor }}>
          {extra}
        </div>
      );
    }

    return (
      <div
        className={styles['wrapper']}
        onClick={this._onClick}
        ref={this._touchable}
      >
        <div className={styles['leftWrapper']}>
          {leftContent && (
            <div className={styles['leftContent']}>{leftContent}</div>
          )}
          <div className={styles['central']}>
            {extraNode}
            <div className={styles['title']} style={{ color: titleColor }}>
              {title}
            </div>
          </div>
        </div>
        <div
          className={classNames(styles['rightWrapper'], {
            [styles['morePadding']]: rightPadding,
          })}
        >
          <div>
            {extraRightContent && (
              <div className={styles['extra']}>{extraRightContent}</div>
            )}
            {rightContent}
          </div>
          {onMoreClick && (
            <Icon
              icon={'expand_more'}
              color={'#6E7482'}
              expand={true}
              size={20}
              onClick={onMoreClick}
            />
          )}
          {contextMenuItems && (
            <Dropdown items={contextMenuItems} size={20} icon={'expand_more'} />
          )}
        </div>
      </div>
    );
  }
}

export default SectionListItem;
