import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';
import classNames from 'classnames';

class Card extends Component {
  static propTypes = {
    leftHeadline: PropTypes.node,
    rightHeadline: PropTypes.node,
    padding: PropTypes.bool,
  };

  static defaultProps = {
    padding: false,
  };

  render() {
    const { leftHeadline, rightHeadline, padding } = this.props;

    let topNode;
    if (leftHeadline || rightHeadline) {
      topNode = (
        <div className={styles['top']}>
          <div>{leftHeadline}</div>
          <div>{rightHeadline}</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {topNode}
        <div
          className={classNames(styles['wrapper'], {
            [styles['addPadding']]: padding,
          })}
        >
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export default Card;
