import React, { Component } from 'react';
import locale from 'util/locale';
import qs from 'qs';
import styles from './ScheduleOverview.module.scss';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import Schedule from './_Schedule';
import AppointmentForm from 'schedule/AppointmentForm';
import DateRange, { MobileDateRange } from 'ui/DateRange';
import { defaultRanges } from 'util/date_ranges';
import ResourcesTypeSelect from './_ResourcesTypeSelect';
import { EmployeeSelectUI } from 'employees/EmployeeSelect';
import { LocationSelectUI } from 'locations/LocationSelect';
import FAB from 'ui/FAB';
import Icon, { DownloadIcon } from 'ui/Icon';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from 'ui/EmptyState';
import { Button } from 'ui/Button';
import emptyStateImg from 'media/images/schedule/emptyState.png';
import localStorage from 'util/local_storage';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class ScheduleOverview extends Component {
  constructor(props) {
    super(props);
    this._defaultRanges = defaultRanges();
    this.state = {
      loadingState: loadingStates.fetching,
      formVisible: props.isScheduling,
      resourcesTypeBottomSheetOpen: false,
      employeesFilterBottomSheetOpen: false,
      locationFilterBottomSheetOpen: false,
      dateRangeBottomSheetOpen: false,
    };

    this._debouncedFetch = this._fetchAppointments.debounce(100);
  }

  componentDidMount() {
    const {
      fetchEmployees,
      fetchLocations,
      isScheduling,
      scrollToTop,
    } = this.props;

    scrollToTop();

    if (!isScheduling) {
      fetchEmployees();
      fetchLocations();
      this._fetchAppointments();
    }
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    const prevQuery = prevProps.query;

    if (
      prevQuery.user_id !== query.user_id ||
      prevQuery.location_id !== query.location_id ||
      !prevQuery.range.from_date.effectivelySame(query.range.from_date) ||
      !prevQuery.range.to_date.effectivelySame(query.range.to_date)
    ) {
      this._debouncedFetch();
    }
  }

  _flattenQuery = realQuery => {
    let query = { ...realQuery };

    if (query.range) {
      query.to_date = query.range.to_date;
      query.from_date = query.range.from_date;
      delete query.range;
    }

    return query;
  };

  _updateUrlParams = (changes = {}) => {
    const { updateUrlParams, schedulingQuery } = this.props;
    const query = this._flattenQuery({
      ...this.props.query,
      ...changes,
    });

    updateUrlParams({ ...query, ...schedulingQuery });
  };

  _resetFilters = () =>
    this._updateUrlParams({
      range: this._defaultRanges[1].value,
      user_id: null,
      location_id: null,
    });

  _startScheduling = () =>
    this.setState({ formVisible: true }, () => {
      this._resetFilters();
      this.props.scrollToTop();
    });

  _finishScheduling = () =>
    this.setState({ formVisible: false }, this._resetFilters);

  _openResourcesTypeBottomSheet = () =>
    this.setState({ resourcesTypeBottomSheetOpen: true });

  _closeResourcesTypeBottomSheet = () =>
    this.setState({ resourcesTypeBottomSheetOpen: false });

  _openEmployeesFilterBottomSheet = () => {
    this.setState({ employeesFilterBottomSheetOpen: true });
  };

  _closeEmployeesFilterBottomSheet = () => {
    this.setState({ employeesFilterBottomSheetOpen: false });
  };

  _openLocationFilterBottomSheet = () =>
    this.setState({ locationFilterBottomSheetOpen: true });

  _closeLocationFilterBottomSheet = () =>
    this.setState({ locationFilterBottomSheetOpen: false });

  _openDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: true });

  _closeDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: false });

  _fetchAppointments = () => {
    const { fetchAppointments } = this.props;
    const query = this._flattenQuery(this.props.query);

    this.setState({ loadingState: loadingStates.fetching });
    fetchAppointments(query)
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  };

  _downloadCsv = () => {
    const { appointmentsQuery } = this.props;

    if (appointmentsQuery.csv_url) {
      window.open(appointmentsQuery.csv_url, '_blank');
    }
  };

  _changeQuery = (key, value) => this._updateUrlParams({ [key]: value });

  _changeResourcesType = resourcesType => {
    localStorage.set(localStorage.keys.schedule.resources_type, resourcesType);
    this._changeQuery('resources_type', resourcesType);
  };

  _changeEmployee = userId => this._changeQuery('user_id', userId);

  _changeLocation = locationId => this._changeQuery('location_id', locationId);

  _changeRange = range => this._changeQuery('range', range);

  _changeAppointmentDate = date =>
    this._changeRange({
      from_date: date.startOfWork(),
      to_date: date.endOfWork(),
    });

  _dateFilter = () => {
    const { query } = this.props;

    return (
      <div className={styles['dateFilter']}>
        <DateRange
          range={query.range}
          ranges={this._defaultRanges}
          onChange={this._changeRange}
        />
      </div>
    );
  };

  _filters = () => {
    const { employees, locations, query } = this.props;
    const {
      resourcesTypeBottomSheetOpen,
      employeesFilterBottomSheetOpen,
      locationFilterBottomSheetOpen,
    } = this.state;

    return (
      <React.Fragment>
        {locations.length > 0 && (
          <ResourcesTypeSelect
            value={query.resources_type}
            onChange={this._changeResourcesType}
            renderOnMobile={false}
            mobileOpen={resourcesTypeBottomSheetOpen}
            onMobileClose={this._closeResourcesTypeBottomSheet}
          />
        )}
        {employees.length > 1 && (
          <EmployeeSelectUI
            value={query.user_id}
            employees={employees}
            renderOnMobile={false}
            mobileClearable={true}
            onChange={this._changeEmployee}
            placeholder={locale().employees.all_employees}
            mobileOpen={employeesFilterBottomSheetOpen}
            onMobileClose={this._closeEmployeesFilterBottomSheet}
          />
        )}
        <LocationSelectUI
          value={query.location_id}
          locations={locations}
          onChange={this._changeLocation}
          clearable={true}
          renderOnMobile={false}
          mobileClearable={true}
          personalLocation={false}
          mobileOpen={locationFilterBottomSheetOpen}
          onMobileClose={this._closeLocationFilterBottomSheet}
        />
      </React.Fragment>
    );
  };

  _appointmentForm = () => {
    const { createAppointment, schedulingQuery, isScheduling } = this.props;

    return (
      <div className={styles['appointmentForm']}>
        <AppointmentForm
          patientsData={[
            {
              patientId: schedulingQuery.patient_id,
              serviceId: schedulingQuery.service_id,
              serviceItemId: schedulingQuery.service_item_id,
            },
          ]}
          saveAction={createAppointment}
          onFinish={
            !isScheduling ? this._finishScheduling : () => window.history.back() // Scheduling from PatientService page
          }
          onDateChange={this._changeAppointmentDate}
          onLocationChange={this._changeLocation}
        />
      </div>
    );
  };

  _mobileHeader = () => {
    const { employees, locations, query } = this.props;
    const { dateRangeBottomSheetOpen, formVisible } = this.state;

    return (
      <React.Fragment>
        <HeaderBar
          title={locale().schedule.title}
          rightContent={
            !formVisible && (
              <React.Fragment>
                {locations.length > 0 && (
                  <Icon
                    icon={'style'}
                    color={'#FFFFFF'}
                    expand={true}
                    onClick={this._openResourcesTypeBottomSheet}
                  />
                )}
                {employees.length > 1 && (
                  <Icon
                    icon={'people'}
                    color={'#FFFFFF'}
                    expand={true}
                    onClick={this._openEmployeesFilterBottomSheet}
                  />
                )}
                {locations.length > 0 && (
                  <Icon
                    icon={'edit_location'}
                    color={'#FFFFFF'}
                    expand={true}
                    onClick={this._openLocationFilterBottomSheet}
                  />
                )}
                <Icon
                  icon={'event_note'}
                  color={'#FFFFFF'}
                  expand={true}
                  onClick={this._openDateRangeBottomSheet}
                />
              </React.Fragment>
            )
          }
        />
        {!formVisible && this._filters()}
        {formVisible && this._appointmentForm()}
        <MobileDateRange
          open={dateRangeBottomSheetOpen}
          onClose={this._closeDateRangeBottomSheet}
          ranges={this._defaultRanges.slice(0, -1)}
          onChange={this._changeRange}
          range={query.range}
        />
      </React.Fragment>
    );
  };

  _desktopHeader = () => {
    const { locations } = this.props;
    const { formVisible } = this.state;

    return (
      <React.Fragment>
        {formVisible && this._appointmentForm()}
        {!formVisible && (
          <Toolbar>
            <div className={styles['toolbarInner']}>
              <div className={styles['top']}>
                <ToolbarGroup>
                  {this._filters()}
                  {locations.length === 0 && this._dateFilter()}
                </ToolbarGroup>
                <ToolbarGroup>
                  <DownloadIcon onClick={this._downloadCsv} />
                  <Button
                    value={locale().appointments.schedule_an_appointment}
                    onClick={this._startScheduling}
                  />
                </ToolbarGroup>
              </div>
              {locations.length > 0 && this._dateFilter()}
            </div>
          </Toolbar>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { appointments, employees, locations, query } = this.props;
    const { loadingState, formVisible } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (appointments.length === 0) {
      bodyNode = (
        <EmptyState
          heading={locale().schedule.empty_state_heading}
          image={emptyStateImg}
        />
      );
    } else {
      bodyNode = (
        <Schedule
          appointments={appointments}
          users={employees}
          locations={locations}
          resourcesType={query.resources_type}
          cellType={'appointment'}
          selectedUserId={query.user_id}
          selectedLocationId={query.location_id}
        />
      );
    }

    return (
      <Navigator title={locale().schedule.title} mountedAs={'schedule'}>
        <React.Fragment>
          <MobileDesktopRender
            mobile={this._mobileHeader}
            desktop={this._desktopHeader}
          />
          {bodyNode}
          {!formVisible && <FAB onClick={this._startScheduling} />}
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const searchQ = (ownProps.location.search || '').substring(1);
  const rawQuery = qs.parse(searchQ);

  const locations = selectors.getLocations(state);
  let resourcesType = 'user';
  if (locations.length > 0) {
    resourcesType =
      rawQuery.resources_type ||
      localStorage.get(localStorage.keys.schedule.resources_type) ||
      'user';
  }

  const defaultRange = defaultRanges()[1].value;

  const query = {
    resources_type: resourcesType,
    user_id: rawQuery.user_id || null,
    location_id: rawQuery.location_id || null,
    range: {
      from_date: rawQuery.from_date
        ? Date.fromString(rawQuery.from_date)
        : defaultRange.from_date,
      to_date: rawQuery.to_date
        ? Date.fromString(rawQuery.to_date)
        : defaultRange.to_date,
    },
  };

  const schedulingQuery = {
    patient_id: rawQuery.patient_id || null,
    service_id: rawQuery.service_id || null,
    service_item_id: rawQuery.service_item_id || null,
  };

  return {
    user: selectors.getCurrentUser(state),
    employees: selectors.getEmployees(state),
    locations: locations,
    appointments: selectors.getAppointments(state),
    appointmentsQuery: selectors.getAppointmentsQuery(state),
    isScheduling: !!schedulingQuery.patient_id,
    schedulingQuery,
    query,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAppointments: data => dispatch(actions.fetchAppointments(data)),
    createAppointment: data => dispatch(actions.createAppointment(data)),
    fetchEmployees: () => dispatch(actions.fetchEmployees({ archived: false })),
    fetchLocations: () => dispatch(actions.fetchLocations()),
    updateUrlParams: urlParams => dispatch(actions.updateUrlParams(urlParams)),
    scrollToTop: () => dispatch(actions.scrollToTop()),
  };
};

ScheduleOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleOverview);

export default ScheduleOverview;
