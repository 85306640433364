import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Form extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  _submit = e => {
    const { onSubmit } = this.props;

    e.preventDefault();
    onSubmit();
  };

  render() {
    return <form onSubmit={this._submit}>{this.props.children}</form>;
  }
}

export default Form;
