import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui/Form';
import ServiceItemSelect from './../_ServiceItemSelect';
import ActionCenter from 'util/action_center';

class ServiceSelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    services: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  componentDidMount() {
    ActionCenter.subscribe('createService', this._onServiceCreated);
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createService', this._onServiceCreated);
  }

  _onServiceCreated = service => {
    const { value } = this.props;

    if (!value) {
      this.props.onChange(service.id);
    }
  };

  _options = () => {
    const { value, services } = this.props;

    return services
      .filter(
        service =>
          (service.number_of_active_appointments <
            service.number_of_treatments &&
            service.created_at > new Date().subMonths(1)) ||
          service.id === value
      )
      .sortBy('created_at')
      .reverse()
      .map(service => ({
        value: service.id,
        label: service.name,
        attention:
          service.number_of_treatments === 1 &&
          service.last_appointment_cancelled,
      }));
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        optionRenderer={ServiceItemSelect.optionRenderer}
      />
    );
  }
}

export default ServiceSelect;
