import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './DownloadIcon.module.scss';
import Icon from './index';
import WithTooltip from 'ui/WithTooltip';

class DownloadIcon extends Component {
  static propTypes = { onClick: PropTypes.func.isRequired };

  render() {
    const { onClick } = this.props;

    return (
      <WithTooltip text={locale().actions.download}>
        <div className={styles['wrapper']} onClick={onClick}>
          <Icon icon={'get_app'} size={22} />
        </div>
      </WithTooltip>
    );
  }
}

export default DownloadIcon;
