import Response from './../_response';

class DiagnosesEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client.fetch('diagnoses', 'GET').then(({ response, json }) => {
      return new Response(response, json);
    });
  }

  create(data) {
    return this._client
      .fetch('diagnoses', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`diagnoses/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default DiagnosesEndpoint;
