import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as patientActions } from './_patients';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  makeGetEntityList,
  clearQuery,
  removeEntities,
  getEntityById,
} from './_entities';
import { push } from 'connected-react-router';
import ActionCenter from 'util/action_center';

const START_SERVICE_EDITING = 'START_SERVICE_EDITING';
const FINISH_SERVICE_EDITING = 'FINISH_SERVICE_EDITING';
const START_ASSIGNING_SERVICE = 'START_ASSIGNING_SERVICE';
const FINISH_ASSIGNING_SERVICE = 'FINISH_ASSIGNING_SERVICE';

export default (
  state = {
    editingService: emptyServiceData(),
    serviceDialogOpen: false,
    assigningServiceData: null,
    assigningServiceDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_SERVICE_EDITING:
      return {
        ...state,
        editingService: action.service,
        serviceDialogOpen: true,
      };
    case FINISH_SERVICE_EDITING:
      return {
        ...state,
        editingService: emptyServiceData(),
        serviceDialogOpen: false,
      };
    case START_ASSIGNING_SERVICE:
      return {
        ...state,
        assigningServiceData: action.data,
        assigningServiceDialogOpen: true,
      };
    case FINISH_ASSIGNING_SERVICE:
      return {
        ...state,
        assigningServiceData: null,
        assigningServiceDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const showService = id => push(`/services/${id}`);

const emptyServiceData = () => ({
  price: 0,
});

const editService = service => {
  return {
    type: START_SERVICE_EDITING,
    service,
  };
};

const finishServiceEditing = () => {
  return {
    type: FINISH_SERVICE_EDITING,
  };
};

const startAssigningService = data => {
  return {
    type: START_ASSIGNING_SERVICE,
    data,
  };
};

const finishAssigningService = () => {
  return {
    type: FINISH_ASSIGNING_SERVICE,
  };
};

const fetchServicesForPatient = ({ patientId, clearOld = true, page = 1 }) => {
  return dispatch => {
    if (clearOld) {
      dispatch(clearQuery(entityTypes.service));
    }

    return Api.patients.services({ patientId, page }).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.service, query));
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOneService = id => {
  return dispatch => {
    return Api.services.get(id).then(response => {
      if (response.isOk()) {
        const service = response.data();
        dispatch(receiveEntities(entityTypes.service, [service]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createService = data => {
  return dispatch => {
    return Api.services.create(data).then(response => {
      if (response.isOk()) {
        const service = response.data();
        dispatch(receiveEntities(entityTypes.service, [service]));
        dispatch(systemActions.showToast(locale().services.service_assigned));
        ActionCenter.publish('createService', service);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateService = data => {
  return dispatch => {
    return Api.services.update(data).then(response => {
      if (response.isOk()) {
        const service = response.data();
        dispatch(receiveEntities(entityTypes.service, [service]));
        dispatch(systemActions.showToast(locale().services.service_updated));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const destroyService = id => {
  return dispatch => {
    return Api.services.destroy(id).then(response => {
      if (response.isOk()) {
        const service = response.data();
        dispatch(patientActions.showPatient(service.patient_id));
        dispatch(removeEntities(entityTypes.service, [service.id]));
        dispatch(systemActions.showToast(locale().services.service_destroyed));
        ActionCenter.publish('destroyService', service);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  showService,
  editService,
  finishServiceEditing,
  startAssigningService,
  finishAssigningService,
  fetchServicesForPatient,
  fetchOneService,
  createService,
  updateService,
  destroyService,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getServicesForPatient = (state, patientId) =>
  getEntityList(state, entityTypes.service).filter(
    service => service.patient_id === patientId
  );

const getService = (state, id) => getEntityById(state, entityTypes.service, id);

const getEditingService = state => state.editingService;

const isEditingService = state => state.serviceDialogOpen;

const getAssigningServiceData = state => state.assigningServiceData;

const isAssigningService = state => state.assigningServiceDialogOpen;

export const selectors = {
  getServicesForPatient,
  getService,
  getEditingService,
  isEditingService,
  getAssigningServiceData,
  isAssigningService,
};
