import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_AddCircle.module.scss';
import Icon from 'ui/Icon';

class AddCircle extends Component {
  static propTypes = { onClick: PropTypes.func.isRequired };

  render() {
    const { onClick } = this.props;

    return (
      <div className={styles['addNew']}>
        <div className={styles['circle']} onClick={onClick}>
          <Icon icon={'add'} color={'#23b0ff'} />
        </div>
      </div>
    );
  }
}

export default AddCircle;
