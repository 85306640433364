import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { SelectNBottomSheet } from 'ui/Form';

class ArchivedSelect extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  _options = () => {
    return [
      {
        value: false,
        label: locale().patients.active_patients,
      },
      {
        value: true,
        label: locale().patients.archived_patients,
      },
    ];
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <SelectNBottomSheet
        options={this._options()}
        value={value}
        onChange={onChange}
        clearable={false}
        searchable={false}
        {...this.props}
      />
    );
  }
}

export default ArchivedSelect;
