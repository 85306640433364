import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

// ACTIONS

const fetchInvitations = () => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.invitation));
    return Api.invitations.query().then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.invitation, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOneInvitation = token => {
  return dispatch => {
    return Api.invitations.get(token).then(response => {
      if (response.isOk()) {
        const invitation = response.data();
        dispatch(receiveEntities(entityTypes.invitation, [invitation]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createInvitation = data => {
  return dispatch => {
    return Api.invitations.create(data).then(response => {
      if (response.isOk()) {
        const invitation = response.data();
        dispatch(receiveEntities(entityTypes.invitation, [invitation]));
        dispatch(systemActions.showToast(locale().employees.invitation_sent));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyInvitation = id => {
  return dispatch => {
    return Api.invitations.destroy(id).then(response => {
      if (response.isOk()) {
        const invitation = response.data();
        dispatch(removeEntities(entityTypes.invitation, [invitation.id]));
        dispatch(
          systemActions.showToast(locale().employees.invitation_destroyed)
        );
      }
    });
  };
};

export const actions = {
  fetchInvitations,
  fetchOneInvitation,
  createInvitation,
  destroyInvitation,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getInvitations = state => getEntityList(state, entityTypes.invitation);

const getInvitationByToken = (state, token) =>
  getInvitations(state).find(invitation => invitation.token === token);

export const selectors = {
  getInvitations,
  getInvitationByToken,
};
