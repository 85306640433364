import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './NotificationsMenu.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from 'ui/EmptyState';
import Notification from 'notifications/Notification';
import emptyStateImg from 'media/images/notifications/emptyState.png';
import NotificationsIcon from 'notifications/NotificationsIcon';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class NotificationsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      open: false,
    };
  }

  _fetch = () => {
    const { fetchNotifications } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchNotifications()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(() => this.setState({ loadingState: loadingStates.failed }));
  };

  _open = () => {
    this.setState({ open: true }, () => this._fetch());
    document.addEventListener('click', this._close);
  };

  _close = () => {
    this.setState({ open: false });
    document.removeEventListener('click', this._close);
  };

  render() {
    const { notifications } = this.props;
    const { loadingState, open } = this.state;

    let bodyNode;
    if (open) {
      if (loadingState === loadingStates.fetching) {
        bodyNode = (
          <div className={styles['loadingState']}>
            <LoadingIndicator size={'small'} />
          </div>
        );
      } else if (loadingState === loadingStates.failed) {
        bodyNode = (
          <div className={styles['failedState']}>
            <FailureInfo padding={false} />
          </div>
        );
      } else if (notifications.length === 0) {
        bodyNode = (
          <EmptyState
            heading={locale().notifications.empty_state_heading}
            image={emptyStateImg}
            intro={locale().notifications.empty_state_intro}
            size={'small'}
          />
        );
      } else {
        bodyNode = (
          <React.Fragment>
            {notifications.map(notification => (
              <Notification
                key={notification.id}
                notification={notification}
                closeMenu={this._close}
              />
            ))}
          </React.Fragment>
        );
      }
      bodyNode = (
        <div
          className={styles['menu']}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {bodyNode}
        </div>
      );
    }

    return (
      <div className={styles['wrapper']}>
        <NotificationsIcon
          size={28}
          icon={'notifications'}
          color={'#01325f'}
          onClick={this._open}
        />
        {bodyNode}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: selectors.getNotifications(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNotifications: () =>
      dispatch(actions.fetchNotifications({ unacknowledged_only: true })),
  };
};

NotificationsMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsMenu);

export default NotificationsMenu;
