import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line as ChartJsLine, Bar } from 'react-chartjs-2';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class Line extends Component {
  static propTypes = {
    listOfDataForEntities: PropTypes.arrayOf(
      PropTypes.shape({
        entities: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.any.isRequired,
          }).isRequired
        ).isRequired,
        label: PropTypes.string.isRequired,
        colors: PropTypes.shape({
          border: PropTypes.string.isRequired,
          background: PropTypes.string.isRequired,
          point: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    labels: PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.any),
      mobileFormat: PropTypes.func,
      desktopFormat: PropTypes.func,
    }).isRequired,
    ticks: PropTypes.shape({
      format: PropTypes.func,
      minFallback: PropTypes.number.isRequired,
      maxFallback: PropTypes.number.isRequired,
      stepFallback: PropTypes.number.isRequired,
    }).isRequired,
    tooltip: PropTypes.shape({
      format: PropTypes.func,
    }),
  };

  static defaultProps = {
    tooltip: {},
  };

  _formatTicks = value => {
    const { format } = this.props.ticks;

    if (format) {
      return format(value);
    } else {
      return value;
    }
  };

  _formatTooltip = value => {
    const { format } = this.props.tooltip;

    if (format) {
      return format(value);
    } else {
      return value;
    }
  };

  _formatLabel = (label, mobileFormat, desktopFormat, isMobile) => {
    if (isMobile && mobileFormat) {
      return mobileFormat(label);
    } else if (!isMobile && desktopFormat) {
      return desktopFormat(label);
    } else {
      return label;
    }
  };

  _options() {
    const { listOfDataForEntities, ticks } = this.props;

    let theTicks = {
      max: ticks.maxFallback,
      min: ticks.minFallback,
      stepSize: ticks.stepFallback,
      display: true,
      fontColor: '#6E7482',
      callback: this._formatTicks,
    };

    if (
      listOfDataForEntities.some(data =>
        data.entities.some(entity => entity.value !== 0)
      )
    ) {
      theTicks = {
        ...theTicks,
        max: undefined,
        min: 0,
        stepSize: undefined,
        maxTicksLimit: 5,
      };
    }

    return {
      title: {
        display: false,
      },
      tooltips: {
        enabled: true,
        backgroundColor: '#1A1C1F',
        titleFontSize: 10,
        bodyFontSize: 20,
        titleFontColor: '#FFFFFF',
        callbacks: {
          label: tooltipItems => this._formatTooltip(tooltipItems.yLabel),
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#6E7482',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(220, 224, 231, 1)',
              zeroLineColor: 'rgba(220, 224, 231, 1)',
            },
            ticks: theTicks,
          },
        ],
      },
    };
  }

  _chartProps = isMobile => {
    const { listOfDataForEntities, labels } = this.props;

    return {
      data: {
        labels: labels.values.map(labelValue =>
          this._formatLabel(
            labelValue,
            labels.mobileFormat,
            labels.desktopFormat,
            isMobile
          )
        ),
        datasets: listOfDataForEntities.map(data => ({
          label: data.label,
          data: data.entities.map(p => p.value),
          borderColor: data.colors.border,
          backgroundColor: data.colors.background,
          pointBackgroundColor: data.colors.point,
          borderWidth: 3,
        })),
      },
      options: this._options(),
    };
  };

  _mobile = () => <Bar {...this._chartProps(true)} />;

  _desktop = () => <ChartJsLine {...this._chartProps(false)} />;

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default Line;
