import Response from '../_response';
import { createResourceSerializer, DateSerializer } from '../_serializers';

const Serializer = createResourceSerializer({
  updated_at: DateSerializer,
});

class PostsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client.fetch('posts', 'GET').then(({ response, json }) => {
      return new Response(response, {
        ...json,
        resources: json.resources.map(post => Serializer.deserialize(post)),
      });
    });
  }

  create(data) {
    return this._client
      .fetch('posts', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, Serializer.deserialize(json));
      });
  }

  update(data) {
    return this._client
      .fetch(`posts/${data.id}`, 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, Serializer.deserialize(json));
      });
  }

  destroy(id) {
    return this._client
      .fetch(`posts/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, Serializer.deserialize(json));
      });
  }
}

export default PostsEndpoint;
