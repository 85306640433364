import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PostsListItem.module.scss';
import { CardRow } from 'ui/Card';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class PostsListItem extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._contextMenuItems = [
      {
        label: locale().actions.edit,
        onClick: this._editPost,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editPost = () => {
    const { editPost, post } = this.props;
    editPost(post);
  };

  render() {
    const { post, destroyPost } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <div className={styles['wrapper']}>
        <CardRow
          resizable={true}
          borderBottom={false}
          contextMenuItems={this._contextMenuItems}
        >
          <div className={styles['user']}>{post.created_by.name}</div>
          <div className={styles['date']}>
            {post.updated_at.format('dd.MM.yyyy. HH:mm')}
          </div>
          <div className={styles['title']}>{post.title}</div>
          <div className={styles['content']}>{post.content}</div>
        </CardRow>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={destroyPost}
          title={locale().posts.destroy_post_head}
          description={locale().posts.destroy_post_notice}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const postId = ownProps.post.id;

  return {
    editPost: post => dispatch(actions.editPost(post)),
    destroyPost: () => dispatch(actions.destroyPost(postId)),
  };
};

PostsListItem = connect(null, mapDispatchToProps)(PostsListItem);

export default PostsListItem;
