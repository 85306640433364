import React, { PureComponent } from 'react';
import locale from 'util/locale';
import styles from './LoginOverview.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import OnboardNav from './../_OnboardNav';
import Box from './../_Box';
import ErrorBox from './../_ErrorBox';
import { Form, FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import { Button } from 'ui/Button';
import { connect, actions } from 'util/redux';

class LoginOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        password: '',
      },
      errors: {},
      isSaving: false,
    };
  }

  _changeData = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value,
      },
    });
  };

  _login = () => {
    const { login } = this.props;
    const { email, password } = this.state.data;

    this.setState({ isSaving: true, errors: {} });
    login(email, password).catch(response => {
      console.error(response);
      if (response.isValidationError && response.isValidationError()) {
        this.setState({ errors: response.validationErrors().errorPerField() });
      } else if (response.isToManyRequests && response.isToManyRequests()) {
        this.setState({
          errors: {
            _global: locale().system.too_many_requests,
          },
        });
      } else {
        this.setState({
          errors: { _global: locale().onboard.invalid_email_or_password_error },
        });
      }
      this.setState({ isSaving: false });
    });
  };

  _globalError = () => <ErrorBox>{this.state.errors._global}</ErrorBox>;

  _form = () => {
    const { openForgotPasswordPage } = this.props;
    const { data, errors, isSaving } = this.state;

    return (
      <Form onSubmit={this._login}>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().onboard.email} error={errors.email} />
            <TextInput
              value={data.email}
              onChange={value => this._changeData('email', value)}
              type={'email'}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().onboard.password}
              error={errors.password}
            />
            <div className={styles['password']}>
              <TextInput
                value={data.password}
                onChange={value => this._changeData('password', value)}
                type={'password'}
              />
              <div
                className={styles['forgotPassword']}
                onClick={openForgotPasswordPage}
              >
                {locale().onboard.forgot_password_question}
              </div>
            </div>
          </FormColumn>
        </FormRow>
        <div className={styles['action']}>
          <Button
            value={locale().actions.log_in}
            type={'submit'}
            disabled={isSaving}
            fill={true}
          />
        </div>
      </Form>
    );
  };

  _mobile = () => (
    <div className={styles['mobileWrapper']}>
      {this._globalError()}
      {this._form()}
    </div>
  );

  _desktop = () => (
    <Box
      header={locale().onboard.login_head}
      intro={locale().onboard.login_intro}
    >
      {this._globalError()}
      {this._form()}
    </Box>
  );

  render() {
    const { openRegisterPage } = this.props;

    return (
      <React.Fragment>
        <OnboardNav
          actionQuestion={locale().onboard.need_account_question}
          actionTitle={locale().actions.register}
          action={openRegisterPage}
        />
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openRegisterPage: () => dispatch(actions.openRegisterPage()),
    openForgotPasswordPage: () => dispatch(actions.openForgotPasswordPage()),
    login: (email, password) => dispatch(actions.login(email, password)),
  };
};

LoginOverview = connect(null, mapDispatchToProps)(LoginOverview);

export default LoginOverview;
