import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ColorPicker.module.scss';

class ColorPicker extends Component {
  static propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    color: '#1A1C1F',
  };

  static colors = [
    '#1A1C1F',
    '#F37A51',
    '#57D5C0',
    '#FFCD00',
    '#7599FE',
    '#F3699A',
    '#A6CF3E',
    '#AC70E9',
    '#EE6C7C',
    '#4DAEEE',
    '#F0A151',
    '#F1A900',
    '#57CDE1',
    '#47CA84',
    '#8484F3',
    '#6DC162',
    '#FB8F59',
    '#54D89D',
    '#8269E7',
    '#64A9E8',
    '#3FAFAC',
    '#56C6E1',
    '#FEAC6D',
    '#F05794',
    '#FF6565',
  ];

  render() {
    const { color, onChange } = this.props;

    return (
      <div className={styles['wrapper']}>
        {ColorPicker.colors.map(c => (
          <div
            key={c}
            className={styles['box']}
            style={{ background: c }}
            onClick={() => onChange(c)}
          >
            {color === c && <div className={styles['selected']} />}
          </div>
        ))}
      </div>
    );
  }
}

export default ColorPicker;
