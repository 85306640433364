import Response from '../_response';

class LocationsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client.fetch('locations', 'GET').then(({ response, json }) => {
      return new Response(response, json);
    });
  }

  create(data) {
    return this._client
      .fetch('locations', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(`locations/${data.id}`, 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`locations/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default LocationsEndpoint;
