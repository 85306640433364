import { enGB, srLatn, hr as hrLatn } from 'date-fns/locale';
import sr from './_sr';
import en from './_en';
import hr from './_hr';
import localStorage from 'util/local_storage';

const DEFAULT_LANGUAGE = 'en';

const supportedLanguages = {
  en: {
    label: 'English',
    value: 'en',
    icon: '🇬🇧',
    date: enGB,
    locale: en,
  },
  sr: {
    label: 'Srpski',
    value: 'sr',
    icon: '🇷🇸',
    date: srLatn,
    locale: sr,
  },
  hr: {
    label: 'Hrvatski',
    value: 'hr',
    icon: '🇭🇷',
    date: hrLatn,
    locale: hr,
  },
};

const language = () =>
  localStorage.get(localStorage.keys.language) || DEFAULT_LANGUAGE;

const setLanguage = ln => localStorage.set(localStorage.keys.language, ln);

const locale = () => supportedLanguages[language()].locale;
const dateLocale = () => supportedLanguages[language()].date;

export default locale;
export { language, setLanguage, dateLocale, supportedLanguages };
