import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './EmptyState.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { Button } from 'ui/Button';
import FAB from 'ui/FAB';

class EmptyState extends Component {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    intro: PropTypes.string,
    actionText: PropTypes.string,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['small', 'normal', 'large']),
  };

  static defaultProps = {
    onClick: null,
    size: 'large',
  };

  _mobileAction = () => {
    const { onClick } = this.props;

    if (onClick) {
      return <FAB onClick={onClick} />;
    } else {
      return null;
    }
  };

  _desktopAction = () => {
    const { onClick } = this.props;

    if (onClick) {
      return (
        <Button value={this.props.actionText} onClick={this.props.onClick} />
      );
    } else {
      return null;
    }
  };

  render() {
    const { heading, image, intro, size } = this.props;

    return (
      <div className={classNames(styles['wrapper'], styles[size])}>
        <img
          src={image}
          className={classNames(styles['image'], styles[size])}
          alt={''}
        />
        <div className={styles['heading']}>{heading}</div>
        <div className={styles['content']}>
          <div className={styles['intro']}>{intro}</div>
          <MobileDesktopRender
            desktop={this._desktopAction}
            mobile={this._mobileAction}
          />
        </div>
      </div>
    );
  }
}

export default EmptyState;
