import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_DocumentsList.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from './../_EmptyState';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionList } from 'ui/SectionList';
import DocumentsListItem from './_DocumentsListItem';
import storageImg from 'media/images/patients/storage.png';
import { connect, actions, selectors, loadingStates } from 'util/redux';

class DocumentsList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchDocumentsForPatient } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchDocumentsForPatient()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  _documents = () => this.props.documents.sortBy('date').reverse();

  _documentsList = () =>
    this._documents().map(document => (
      <DocumentsListItem key={document.id} document={document} />
    ));

  _mobile = () => {
    return <SectionList>{this._documentsList()}</SectionList>;
  };

  _desktop = () => {
    return <div className={styles['wrapper']}>{this._documentsList()}</div>;
  };

  render() {
    const { documents } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (documents.length === 0) {
      bodyNode = (
        <EmptyState
          image={storageImg}
          intro={locale().patient_documents.empty_state_heading}
        />
      );
    } else {
      bodyNode = (
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    documents: selectors.getDocumentsForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    fetchDocumentsForPatient: () =>
      dispatch(actions.fetchDocumentsForPatient(patientId)),
  };
};

DocumentsList = connect(mapStateToProps, mapDispatchToProps)(DocumentsList);

export default DocumentsList;
