import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './MobileNav.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import MoreMenuDialog from './_MoreMenuDialog';
import Icon from 'ui/Icon';
import NotificationsIcon from 'notifications/NotificationsIcon';
import { connect, actions, selectors } from 'util/redux';

class MobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreMenuDialogOpen: false,
    };
    this._navItems = [
      {
        title: locale().dashboard.title,
        onClick: props.openDashboardPage,
        page: 'dashboard',
        icon: 'home',
        show: props.user.permissions.finance_overview,
      },
      {
        title: locale().schedule.title,
        onClick: props.openSchedulePage,
        page: 'schedule',
        icon: 'schedule',
        show: true,
      },
      {
        title: locale().patients.title,
        onClick: props.openPatientsPage,
        page: 'patients',
        icon: 'people',
        show: true,
      },
      {
        title: locale().notifications.title,
        onClick: props.openNotificationsPage,
        page: 'notifications',
        component: NotificationsIcon,
        icon: 'notifications_none',
        show: true,
      },
      {
        title: locale().services.title,
        onClick: props.openTreatmentsPage,
        page: 'treatments',
        icon: 'pages',
        show: true,
      },
      {
        title: locale().finances.title,
        onClick: props.openFinancesPage,
        page: 'finance',
        icon: 'trending_up',
        show: props.user.permissions.finance_overview,
      },
      {
        title: locale().employees.title,
        onClick: props.openEmployeesPage,
        icon: 'assessment',
        show: props.user.permissions.manage_employees,
      },
      {
        title: locale().posts.title,
        onClick: props.openPostsPage,
        page: 'posts',
        icon: 'forum',
        show: true,
      },
      {
        title: locale().subscription.title,
        onClick: props.openUserSubscriptionPage,
        page: 'subscription',
        icon: 'payment',
        show: props.user.permissions.manage_subscription,
      },
      {
        title: locale().account.title,
        onClick: props.openUserAccountPage,
        page: 'account',
        icon: 'settings',
        show: true,
      },
    ];
    this._moreOptions = {
      title: locale().system.more,
      onClick: this._openMoreMenuDialog,
      page: 'more',
      icon: 'more_horiz',
      show: props.user.permissions.finance_overview,
    };
  }

  _openMoreMenuDialog = () => this.setState({ moreMenuDialogOpen: true });

  _closeMoreMenuDialog = callback =>
    this.setState({ moreMenuDialogOpen: false }, callback);

  _render = () => {
    const { activePage } = this.props;
    const { moreMenuDialogOpen } = this.state;

    const allItems = this._navItems.filter(item => item.show);
    const moreOptionsNeeded = allItems.length > 5;

    let tabItems;
    if (moreOptionsNeeded) {
      tabItems = allItems.slice(0, 4);
      tabItems.push(this._moreOptions);
    } else {
      tabItems = allItems;
    }

    return (
      <React.Fragment>
        <div className={styles['tabbar']}>
          {tabItems.map(item => {
            const isActive = item.page === activePage;
            const Component = item.component ? item.component : Icon;

            return (
              <div
                key={item.title}
                onClick={item.onClick}
                className={styles['item']}
              >
                <Component
                  icon={item.icon}
                  color={isActive ? '#46AC5A' : '#1A1C1F'}
                />
                <div
                  className={classNames(styles['title'], {
                    [styles['active']]: isActive,
                  })}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
        {moreOptionsNeeded && (
          <MoreMenuDialog
            open={moreMenuDialogOpen}
            onClose={this._closeMoreMenuDialog}
            items={allItems}
          />
        )}
        <div className={styles['placeholder']} />
      </React.Fragment>
    );
  };

  render() {
    return <MobileDesktopRender mobile={this._render} />;
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
    activePage: selectors.getActivePage(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openDashboardPage: () => dispatch(actions.openDashboardPage()),
    openSchedulePage: () => dispatch(actions.openSchedulePage()),
    openPatientsPage: () => dispatch(actions.openPatientsPage()),
    openTreatmentsPage: () => dispatch(actions.openTreatmentsPage()),
    openNotificationsPage: () => dispatch(actions.openNotificationsPage()),
    openPostsPage: () => dispatch(actions.openPostsPage()),
    openFinancesPage: () => dispatch(actions.openFinancesPage()),
    openEmployeesPage: () => dispatch(actions.openEmployeesPage()),
    openUserAccountPage: () => dispatch(actions.openUserAccountPage()),
    openUserSubscriptionPage: () =>
      dispatch(actions.openUserSubscriptionPage()),
  };
};

MobileNav = connect(mapStateToProps, mapDispatchToProps)(MobileNav);

export default MobileNav;
