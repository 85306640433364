import { actions as pageActions } from './ducks/_page';
import { actions as systemActions } from './ducks/_system';
import { actions as authActions } from './ducks/_auth';
import { actions as userActions } from './ducks/_user';
import { actions as notificationsActions } from './ducks/_notifications';
import { actions as diagnosesActions } from './ducks/_diagnoses';
import { actions as patientReferralActions } from './ducks/_patient_referrals';
import { actions as organisationStatsActions } from './ducks/_organisation_stats';
import { actions as userStatsActions } from './ducks/_user_stats';
import { actions as patientsActions } from './ducks/_patients';
import { actions as servicesActions } from './ducks/_services';
import { actions as serviceItemsActions } from './ducks/_service_items';
import { actions as patientNotesActions } from './ducks/_patient_notes';
import { actions as servicePaymentsActions } from './ducks/_service_payments';
import { actions as appointmentsActions } from './ducks/_appointments';
import { actions as patientReportsActions } from './ducks/_patient_reports';
import { actions as patientDocumentsActions } from './ducks/_patient_documents';
import { actions as patientInvoicesActions } from './ducks/_patient_invoices';
import { actions as treatmentsActions } from './ducks/_treatments';
import { actions as packagesActions } from './ducks/_packages';
import { actions as employeesActions } from './ducks/_employees';
import { actions as invitationsActions } from './ducks/_invitations';
import { actions as locationsActions } from './ducks/_locations';
import { actions as subscriptionPlanActions } from './ducks/_subscription_plans';
import { actions as postsActions } from './ducks/_posts';
import { actions as expensesActions } from './ducks/_expenses';
import { actions as expenseTypesActions } from './ducks/_expense_types';

export const changeActivePage = pageActions.changeActivePage;

export const updateUrlParams = pageActions.updateUrlParams;

export const scrollToTop = pageActions.scrollToTop;

export const showToast = systemActions.showToast;

export const openLoginPage = authActions.openLoginPage;

export const openRegisterPage = authActions.openRegisterPage;

export const openForgotPasswordPage = authActions.openForgotPasswordPage;

export const login = authActions.login;

export const logout = authActions.logout;

export const register = authActions.register;

export const changePassword = authActions.changePassword;

export const sendResetPasswordInstructions =
  authActions.sendResetPasswordInstructions;

export const resetPassword = authActions.resetPassword;

export const openUserAccountPage = userActions.openUserAccountPage;

export const openUserSubscriptionPage = userActions.openUserSubscriptionPage;

export const fetchCurrentUser = userActions.fetchCurrentUser;

export const updateCurrentUserDetails = userActions.updateCurrentUserDetails;

export const updateCurrentOrganisation = userActions.updateCurrentOrganisation;

export const createOrganisationLogo = userActions.createOrganisationLogo;

export const destroyOrganisationLogo = userActions.destroyOrganisationLogo;

export const removeCurrentUser = userActions.removeCurrentUser;

export const openNotificationsPage = notificationsActions.openNotificationsPage;

export const fetchNotifications = notificationsActions.fetchNotifications;

export const fetchOneNotification = notificationsActions.fetchOneNotification;

export const acknowledgeNotification =
  notificationsActions.acknowledgeNotification;

export const seenNotification = notificationsActions.seenNotification;

export const fetchWorkingStats = userStatsActions.fetchWorkingStats;

export const fetchOneWorkingStats = userStatsActions.fetchOneWorkingStats;

export const fetchIncomeStats = organisationStatsActions.fetchIncomeStats;

export const fetchExpenseStats = organisationStatsActions.fetchExpenseStats;

export const fetchDiagnoseStats = organisationStatsActions.fetchDiagnoseStats;

export const fetchPatientReferralStats =
  organisationStatsActions.fetchPatientReferralStats;

export const fetchProfitAndLossReport =
  organisationStatsActions.fetchProfitAndLossReport;

export const fetchAppointmentStats =
  organisationStatsActions.fetchAppointmentStats;

export const openDashboardPage = organisationStatsActions.openDashboardPage;

export const openFinancesPage = organisationStatsActions.openFinancesPage;

export const fetchDiagnoses = diagnosesActions.fetchDiagnoses;

export const createDiagnose = diagnosesActions.createDiagnose;

export const destroyDiagnose = diagnosesActions.destroyDiagnose;

export const newPatientReferral = patientReferralActions.newPatientReferral;

export const finishPatientReferralEditing =
  patientReferralActions.finishPatientReferralEditing;

export const fetchPatientReferrals =
  patientReferralActions.fetchPatientReferrals;

export const createPatientReferral =
  patientReferralActions.createPatientReferral;

export const destroyPatientReferral =
  patientReferralActions.destroyPatientReferral;

export const openPatientsPage = patientsActions.openPatientsPage;

export const newPatient = patientsActions.newPatient;

export const editPatient = patientsActions.editPatient;

export const finishPatientEditing = patientsActions.finishPatientEditing;

export const showPatient = patientsActions.showPatient;

export const fetchPatients = patientsActions.fetchPatients;

export const fetchOnePatient = patientsActions.fetchOnePatient;

export const createPatient = patientsActions.createPatient;

export const updatePatient = patientsActions.updatePatient;

export const destroyPatient = patientsActions.destroyPatient;

export const archivePatient = patientsActions.archivePatient;

export const activatePatient = patientsActions.activatePatient;

export const showService = servicesActions.showService;

export const editService = servicesActions.editService;

export const finishServiceEditing = servicesActions.finishServiceEditing;

export const fetchServicesForPatient = servicesActions.fetchServicesForPatient;

export const fetchOneService = servicesActions.fetchOneService;

export const createService = servicesActions.createService;

export const updateService = servicesActions.updateService;

export const destroyService = servicesActions.destroyService;

export const startAssigningService = servicesActions.startAssigningService;

export const finishAssigningService = servicesActions.finishAssigningService;

export const fetchItemsForService = serviceItemsActions.fetchItemsForService;

export const createServiceItem = serviceItemsActions.createServiceItem;

export const destroyServiceItem = serviceItemsActions.destroyServiceItem;

export const newPatientNote = patientNotesActions.newPatientNote;

export const editPatientNote = patientNotesActions.editPatientNote;

export const finishPatientNoteEditing =
  patientNotesActions.finishPatientNoteEditing;

export const fetchNotesForPatient = patientNotesActions.fetchNotesForPatient;

export const createPatientNote = patientNotesActions.createPatientNote;

export const updatePatientNote = patientNotesActions.updatePatientNote;

export const destroyPatientNote = patientNotesActions.destroyPatientNote;

export const fetchPaymentsForPatient =
  servicePaymentsActions.fetchPaymentsForPatient;

export const fetchPaymentsForService =
  servicePaymentsActions.fetchPaymentsForService;

export const createServicePayment = servicePaymentsActions.createServicePayment;

export const destroyServicePayment =
  servicePaymentsActions.destroyServicePayment;

export const inflowServicePayments =
  servicePaymentsActions.inflowServicePayments;

export const newServicePayment = servicePaymentsActions.newServicePayment;

export const finishServicePaymentEditing =
  servicePaymentsActions.finishServicePaymentEditing;

export const newInflow = servicePaymentsActions.newInflow;

export const finishInflowEditing = servicePaymentsActions.finishInflowEditing;

export const openSchedulePage = appointmentsActions.openSchedulePage;

export const showAppointment = appointmentsActions.showAppointment;

export const fetchAppointments = appointmentsActions.fetchAppointments;

export const fetchAppointmentsForPatient =
  appointmentsActions.fetchAppointmentsForPatient;

export const createAppointment = appointmentsActions.createAppointment;

export const updateAppointment = appointmentsActions.updateAppointment;

export const fetchOneAppointment = appointmentsActions.fetchOneAppointment;

export const cancelAppointment = appointmentsActions.cancelAppointment;

export const predictAppointment = appointmentsActions.predictAppointment;

export const newPatientReport = patientReportsActions.newPatientReport;

export const finishPatientReportEditing =
  patientReportsActions.finishPatientReportEditing;

export const showPatientReport = patientReportsActions.showPatientReport;

export const fetchReportsForPatient =
  patientReportsActions.fetchReportsForPatient;

export const fetchOnePatientReport =
  patientReportsActions.fetchOnePatientReport;

export const createPatientReport = patientReportsActions.createPatientReport;

export const updatePatientReport = patientReportsActions.updatePatientReport;

export const destroyPatientReport = patientReportsActions.destroyPatientReport;

export const newPatientDocument = patientDocumentsActions.newPatientDocument;

export const editPatientDocument = patientDocumentsActions.editPatientDocument;

export const finishPatientDocumentEditing =
  patientDocumentsActions.finishPatientDocumentEditing;

export const fetchDocumentsForPatient =
  patientDocumentsActions.fetchDocumentsForPatient;

export const createPatientDocument =
  patientDocumentsActions.createPatientDocument;

export const updatePatientDocument =
  patientDocumentsActions.updatePatientDocument;

export const destroyPatientDocument =
  patientDocumentsActions.destroyPatientDocument;

export const newPatientInvoice = patientInvoicesActions.newPatientInvoice;

export const finishPatientInvoiceEditing =
  patientInvoicesActions.finishPatientInvoiceEditing;

export const toggleServiceForInvoicing =
  patientInvoicesActions.toggleServiceForInvoicing;

export const clearServicesForInvoicing =
  patientInvoicesActions.clearServicesForInvoicing;

export const showPatientInvoice = patientInvoicesActions.showPatientInvoice;

export const fetchOnePatientInvoice =
  patientInvoicesActions.fetchOnePatientInvoice;

export const createPatientInvoice = patientInvoicesActions.createPatientInvoice;

export const updatePatientInvoice = patientInvoicesActions.updatePatientInvoice;

export const destroyPatientInvoice =
  patientInvoicesActions.destroyPatientInvoice;

export const groupPatientInvoiceServices =
  patientInvoicesActions.groupPatientInvoiceServices;

export const ungroupPatientInvoiceServices =
  patientInvoicesActions.ungroupPatientInvoiceServices;

export const openTreatmentsPage = treatmentsActions.openTreatmentsPage;

export const newTreatment = treatmentsActions.newTreatment;

export const editTreatment = treatmentsActions.editTreatment;

export const finishTreatmentEditing = treatmentsActions.finishTreatmentEditing;

export const fetchTreatments = treatmentsActions.fetchTreatments;

export const createTreatment = treatmentsActions.createTreatment;

export const updateTreatment = treatmentsActions.updateTreatment;

export const destroyTreatment = treatmentsActions.destroyTreatment;

export const newPackage = packagesActions.newPackage;

export const editPackage = packagesActions.editPackage;

export const finishPackageEditing = packagesActions.finishPackageEditing;

export const fetchPackages = packagesActions.fetchPackages;

export const createPackage = packagesActions.createPackage;

export const updatePackage = packagesActions.updatePackage;

export const destroyPackage = packagesActions.destroyPackage;

export const openEmployeesPage = employeesActions.openEmployeesPage;

export const editEmployee = employeesActions.editEmployee;

export const finishEmployeeEditing = employeesActions.finishEmployeeEditing;

export const fetchEmployees = employeesActions.fetchEmployees;

export const updateEmployee = employeesActions.updateEmployee;

export const archiveEmployee = employeesActions.archiveEmployee;

export const activateEmployee = employeesActions.activateEmployee;

export const fetchInvitations = invitationsActions.fetchInvitations;

export const fetchOneInvitation = invitationsActions.fetchOneInvitation;

export const createInvitation = invitationsActions.createInvitation;

export const destroyInvitation = invitationsActions.destroyInvitation;

export const openLocationsPage = locationsActions.openLocationsPage;

export const newLocation = locationsActions.newLocation;

export const editLocation = locationsActions.editLocation;

export const finishLocationEditing = locationsActions.finishLocationEditing;

export const fetchLocations = locationsActions.fetchLocations;

export const createLocation = locationsActions.createLocation;

export const updateLocation = locationsActions.updateLocation;

export const destroyLocation = locationsActions.destroyLocation;

export const fetchSubscriptionPlans =
  subscriptionPlanActions.fetchSubscriptionPlans;

export const openPostsPage = postsActions.openPostsPage;

export const newPost = postsActions.newPost;

export const editPost = postsActions.editPost;

export const finishPostEditing = postsActions.finishPostEditing;

export const fetchPosts = postsActions.fetchPosts;

export const createPost = postsActions.createPost;

export const updatePost = postsActions.updatePost;

export const destroyPost = postsActions.destroyPost;

export const newExpense = expensesActions.newExpense;

export const editExpense = expensesActions.editExpense;

export const finishExpenseEditing = expensesActions.finishExpenseEditing;

export const fetchExpenses = expensesActions.fetchExpenses;

export const createExpense = expensesActions.createExpense;

export const updateExpense = expensesActions.updateExpense;

export const destroyExpense = expensesActions.destroyExpense;

export const newExpenseType = expenseTypesActions.newExpenseType;

export const editExpenseType = expenseTypesActions.editExpenseType;

export const finishExpenseTypeEditing =
  expenseTypesActions.finishExpenseTypeEditing;

export const fetchExpenseTypes = expenseTypesActions.fetchExpenseTypes;

export const createExpenseType = expenseTypesActions.createExpenseType;

export const updateExpenseType = expenseTypesActions.updateExpenseType;

export const destroyExpenseType = expenseTypesActions.destroyExpenseType;
