export const DateSerializer = {
  serialize: date => {
    if (date) {
      return date.toISOString();
    } else {
      return date;
    }
  },

  deserialize: str => {
    if (str) {
      return Date.fromISOString(str);
    } else {
      return str;
    }
  },
};

export const DateOnlySerializer = {
  serialize: date => {
    if (date) {
      return date.format('yyyy-MM-dd');
    } else {
      return date;
    }
  },

  deserialize: str => {
    if (str) {
      return Date.fromString(str);
    } else {
      return str;
    }
  },
};

export const MoneyAmountSerializer = {
  serialize: amount => {
    if (amount) {
      return Math.round(amount * 100);
    } else {
      return amount;
    }
  },

  deserialize: amount => {
    if (amount) {
      return amount / 100;
    } else {
      return amount;
    }
  },
};

export const createResourceSerializer = serializerMap => ({
  serialize: resource => {
    if (!resource) {
      return null;
    }

    return Object.map(resource, (value, field) => {
      if (serializerMap[field]) {
        return serializerMap[field].serialize(value);
      } else {
        return value;
      }
    });
  },

  deserialize: resourceJson => {
    if (!resourceJson) {
      return null;
    }

    return Object.map(resourceJson, (value, field) => {
      if (serializerMap[field]) {
        return serializerMap[field].deserialize(value);
      } else {
        return value;
      }
    });
  },
});

export const createCollectionSerializer = resourceSerializer => {
  return {
    serialize: collection =>
      collection.map(item => resourceSerializer.serialize(item)),
    deserialize: collection =>
      collection.map(item => resourceSerializer.deserialize(item)),
  };
};

const _InvoiceSerializerMap = {
  date: DateSerializer,
  due_date: DateSerializer,
};

export const MeSerializer = createResourceSerializer({
  organisation: createResourceSerializer({
    subscription: createResourceSerializer({
      valid_until: DateSerializer,
    }),
  }),
});

export const PatientSerializer = createResourceSerializer({
  debt: MoneyAmountSerializer,
  birth_date: DateOnlySerializer,
});

export const ServiceSerializer = createResourceSerializer({
  debt: MoneyAmountSerializer,
  price: MoneyAmountSerializer,
  created_at: DateSerializer,
  effectively_active_from: DateSerializer,
  patient_invoice: createResourceSerializer(_InvoiceSerializerMap),
});

export const PatientNoteSerializer = createResourceSerializer({
  date: DateOnlySerializer,
});

export const PatientReportSerializer = createResourceSerializer({
  date: DateOnlySerializer,
  patient_birth_date: DateOnlySerializer,
});

export const AppointmentSerializer = createResourceSerializer({
  date: DateSerializer,
  events: createCollectionSerializer(
    createResourceSerializer({
      birth_date: DateOnlySerializer,
      notes: createCollectionSerializer(PatientNoteSerializer),
    })
  ),
});

export const ServiceItemSerializer = createResourceSerializer({
  appointments: createCollectionSerializer(AppointmentSerializer),
  notes: createCollectionSerializer(PatientNoteSerializer),
  appointment_date: DateSerializer,
});

export const ServicePaymentSerializer = createResourceSerializer({
  date: DateSerializer,
  amount: MoneyAmountSerializer,
  service: ServiceSerializer,
});

export const PatientDocumentSerializer = createResourceSerializer({
  date: DateSerializer,
});

export const PatientInvoiceSerializer = createResourceSerializer({
  ..._InvoiceSerializerMap,
  services: createCollectionSerializer(ServiceSerializer),
});
