import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './BottomSheet.module.scss';
import classNames from 'classnames';
import Dialog from './_Dialog';

class BottomSheet extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any,
        onClick: PropTypes.func,
        type: PropTypes.oneOf(['normal', 'attention']),
      })
    ).isRequired,
    itemRenderer: PropTypes.func,
    onSelect: PropTypes.func, // Fallback to this if onClick not provided
    selectedItem: PropTypes.any, // item.value
  };

  static defaultProps = {
    selectedItem: null, // item.value is undefined by default so nothing will be selected
    onSelect: () => {},
  };

  constructor(props) {
    super(props);
    this._dialog = React.createRef();
  }

  _startClosing = () => {
    if (this._dialog) {
      this._dialog.current.classList.add(styles['closing']);
    }
  };

  _onClick = (callback, value) => {
    const { onClose } = this.props;
    onClose();
    callback(value);
  };

  render() {
    const {
      open,
      onClose,
      items,
      itemRenderer,
      selectedItem,
      onSelect,
    } = this.props;

    return (
      <Dialog open={open} onClose={onClose} onClosing={this._startClosing}>
        <div className={styles['wrapper']} ref={this._dialog}>
          {items.map((item, ind) => (
            <div
              key={`${item.label}-${ind}`}
              onClick={() =>
                this._onClick(item.onClick || onSelect, item.value)
              }
              className={classNames(styles['item'], {
                [styles['selected']]: item.value === selectedItem,
                [styles['attention']]: item.type === 'attention',
              })}
            >
              {itemRenderer ? itemRenderer(item) : item.label}
            </div>
          ))}
          {items.length === 0 && (
            <div className={classNames(styles['item'], styles['empty'])}>
              {locale().system.no_options}
            </div>
          )}
        </div>
      </Dialog>
    );
  }
}

export default BottomSheet;
