import Api from 'util/api/api';
import { push } from 'connected-react-router';
import {
  clearQuery,
  entityTypes,
  makeGetEntityList,
  receiveEntities,
  receiveQuery,
  removeEntities,
} from './_entities';

// ACTIONS

const openNotificationsPage = () => push('/notifications');

const fetchNotifications = data => {
  return dispatch => {
    return Api.notifications.query(data).then(response => {
      dispatch(clearQuery(entityTypes.notification));

      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.notification, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOneNotification = id => {
  return dispatch => {
    return Api.notifications.get(id).then(response => {
      if (response.isOk()) {
        const notification = response.data();
        dispatch(receiveEntities(entityTypes.notification, [notification]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const acknowledgeNotification = id => {
  return dispatch => {
    return Api.notifications.acknowledge(id).then(response => {
      if (response.isOk()) {
        const notification = response.data();
        dispatch(removeEntities(entityTypes.notification, [notification.id]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const seenNotification = id => {
  return dispatch => {
    return Api.notifications.seen(id).then(response => {
      if (response.isOk()) {
        const notification = response.data();
        dispatch(receiveEntities(entityTypes.notification, [notification]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openNotificationsPage,
  fetchNotifications,
  fetchOneNotification,
  acknowledgeNotification,
  seenNotification,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getNotifications = state =>
  getEntityList(state, entityTypes.notification);

export const selectors = {
  getNotifications,
};
