import React, { Component } from 'react';
import locale from 'util/locale';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import Report from './_Report';
import Document from './../_Document';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class PatientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      data: this._emptyData(),
      isSaving: false,
      errorOccurred: false,
    };

    this._debouncedSave = this._savePatientReport.debounce(250);
  }

  componentDidMount() {
    this._fetchReport();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.patientReport !== this.props.patientReport) {
      this.setState({ data: { ...this.props.patientReport } });
    }
  }

  _fetchReport = () => {
    const { fetchOnePatientReport } = this.props;

    this.setState({
      loadingState: loadingStates.fetching,
      errorOccurred: false,
    });
    fetchOnePatientReport()
      .then(() => {
        this.setState({ loadingState: loadingStates.present });
      })
      .catch(response => {
        console.error(response);
        if (response.isNotFound && response.isNotFound()) {
          this.setState({ loadingState: loadingStates.missing });
        } else {
          this.setState({ loadingState: loadingStates.failed });
        }
      });
  };

  _emptyData = () => ({
    sections: [],
  });

  _addSection = () => {
    const { data } = this.state;
    const sections = [
      ...data.sections,
      { title: '', content: '', ordinal: data.sections.length },
    ];
    this.setState({ data: { ...data, sections } }, this._invokeSaving);
  };

  _removeSection = ordinal => {
    const { data } = this.state;
    const sections = data.sections
      .filter(s => s.ordinal !== ordinal)
      .map(s => ({
        ...s,
        ordinal: s.ordinal > ordinal ? s.ordinal - 1 : s.ordinal,
      }));

    this.setState({ data: { ...data, sections } }, this._invokeSaving);
  };

  _updatePatientReport = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } }, this._invokeSaving);
  };

  _updateSection = (ordinal, key, value) => {
    const { data } = this.state;
    const sections = data.sections.filter(s => s.ordinal !== ordinal);
    const sectionToUpdate = {
      ...data.sections.find(s => s.ordinal === ordinal),
      [key]: value,
    };

    this.setState(
      {
        data: { ...data, sections: [...sections, sectionToUpdate] },
      },
      this._invokeSaving
    );
  };

  _invokeSaving = (debounced = true) => {
    this.setState({ isSaving: true, errorOccurred: false });
    if (debounced) {
      this._debouncedSave();
    } else {
      this._savePatientReport();
    }
  };

  _savePatientReport = () => {
    const { updatePatientReport } = this.props;
    const { data } = this.state;

    updatePatientReport(data)
      .then(() => this.setState({ isSaving: false }))
      .catch(response => {
        console.error(response);
        this.setState({ isSaving: false, errorOccurred: true });
      });
  };

  _destroyPatientReport = () => {
    const { destroyPatientReport } = this.props;
    destroyPatientReport();
  };

  _uploadLogo = (logo, onProgress) => {
    const { createOrganisationLogo } = this.props;
    return createOrganisationLogo({ logo }, onProgress).then(this._fetchReport);
  };

  _removeLogo = () => {
    const { destroyOrganisationLogo } = this.props;
    destroyOrganisationLogo().then(this._fetchReport);
  };

  render() {
    const { patientReport } = this.props;
    const { loadingState, data, isSaving, errorOccurred } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (loadingState === loadingStates.missing) {
      bodyNode = <FailureInfo info={locale().patient_reports.not_found} />;
    } else {
      bodyNode = (
        <Document
          document={data}
          onUpdate={this._updatePatientReport}
          isSaving={isSaving}
          errorOccurred={errorOccurred}
          onDestroy={this._destroyPatientReport}
          destroyConfirmation={{
            headText: locale().patient_reports.destroy_patient_report_head,
            noticeText: locale().patient_reports.destroy_patient_report_notice,
          }}
          pdfUrl={patientReport.pdf_url}
        >
          <Report
            patientReport={data}
            onUpdate={this._updatePatientReport}
            onUploadLogo={this._uploadLogo}
            onRemoveLogo={this._removeLogo}
            onSectionUpdate={this._updateSection}
            onSectionAdd={this._addSection}
            onSectionRemove={this._removeSection}
          />
        </Document>
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientReportId = ownProps.match.params.id;

  return {
    patientReport: selectors.getPatientReport(state, patientReportId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientReportId = ownProps.match.params.id;

  return {
    fetchOnePatientReport: () =>
      dispatch(actions.fetchOnePatientReport(patientReportId)),
    updatePatientReport: data =>
      dispatch(actions.updatePatientReport(patientReportId, data)),
    destroyPatientReport: () =>
      dispatch(actions.destroyPatientReport(patientReportId, true)),
    createOrganisationLogo: (data, onProgress) =>
      dispatch(actions.createOrganisationLogo(data, onProgress)),
    destroyOrganisationLogo: () => dispatch(actions.destroyOrganisationLogo()),
  };
};

PatientReport = connect(mapStateToProps, mapDispatchToProps)(PatientReport);

export default PatientReport;
