import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import Icon from 'ui/Icon';
import SectionListItem from 'ui/SectionList/SectionListItem';

class MoreMenuDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
  };

  render() {
    const { open, onClose, items } = this.props;

    return (
      <SidebarDialog
        title={'Logovel'}
        open={open}
        onClose={onClose}
        padding={false}
      >
        {items
          .filter(item => item.show)
          .map(item => {
            const Component = item.component ? item.component : Icon;

            return (
              <SectionListItem
                key={item.title}
                leftContent={
                  <Component icon={item.icon} size={24} color={'#46ac5a'} />
                }
                title={item.title}
                onClick={() => onClose(item.onClick)}
                rightContent={
                  <Icon expand={true} size={28} icon={'chevron_right'} />
                }
              />
            );
          })}
      </SidebarDialog>
    );
  }
}

export default MoreMenuDialog;
