import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_CancelConfirmationDialog.module.scss';
import CenterDialog from 'ui/Dialog/CenterDialog';
import { Button } from 'ui/Button';
import { Checkbox, Form, FormLabel, TextArea } from 'ui/Form';

class CancelConfirmationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this._defaultData(),
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ data: this._defaultData() });
    }
  }

  _defaultData = () => ({ reason: '', clear_debt: false });

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _onConfirm = () => {
    const { onClose, onConfirm } = this.props;
    const { data } = this.state;

    onClose();
    onConfirm(data);
  };

  render() {
    const { open, onClose } = this.props;
    const { data } = this.state;

    return (
      <CenterDialog open={open} onClose={onClose}>
        <div className={styles['form']}>
          <Form onSubmit={this._onConfirm}>
            <div className={styles['wrapper']}>
              <div>
                <div className={styles['title']}>
                  {locale().appointments.cancel_appointment_notice}
                </div>
                <FormLabel value={locale().appointments.cancellation_reason} />
                <TextArea
                  onChange={value => this._changeData('reason', value)}
                  value={data.reason}
                  className={styles['reason']}
                />
                <div className={styles['clearDebt']}>
                  <Checkbox
                    onChange={value => this._changeData('clear_debt', value)}
                    value={data.clear_debt}
                    label={locale().appointments.cancel_debt}
                  />
                </div>
              </div>
              <div className={styles['actions']}>
                <Button
                  onClick={onClose}
                  value={locale().actions.cancel}
                  actionType={'normal'}
                />
                <Button
                  value={locale().actions.confirm}
                  actionType={'danger'}
                  type={'submit'}
                />
              </div>
            </div>
          </Form>
        </div>
      </CenterDialog>
    );
  }
}

export default CancelConfirmationDialog;
