import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

// ACTIONS

const fetchItemsForService = (serviceId, clearOld = true) => {
  return dispatch => {
    return Api.services.items(serviceId).then(response => {
      if (response.isOk()) {
        if (clearOld) {
          dispatch(clearQuery(entityTypes.service_item));
        }
        const query = response.data();
        dispatch(receiveQuery(entityTypes.service_item, query));

        return query.resources;
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createServiceItem = data => {
  return dispatch => {
    return Api.serviceItems.create(data).then(response => {
      if (response.isOk()) {
        const serviceItem = response.data();
        dispatch(receiveEntities(entityTypes.service_item, [serviceItem]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyServiceItem = id => {
  return dispatch => {
    return Api.serviceItems.destroy(id).then(response => {
      if (response.isOk()) {
        const serviceItem = response.data();
        dispatch(removeEntities(entityTypes.service_item, [serviceItem.id]));
        dispatch(
          systemActions.showToast(locale().service_items.service_item_destroyed)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  fetchItemsForService,
  createServiceItem,
  destroyServiceItem,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getItemsForService = (state, serviceId) =>
  getEntityList(state, entityTypes.service_item).filter(
    serviceItem => serviceItem.service_id === serviceId
  );

export const selectors = {
  getItemsForService,
};
