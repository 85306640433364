import Response from '../_response';
import {
  createResourceSerializer,
  MoneyAmountSerializer,
} from './../_serializers';

const Serializer = createResourceSerializer({
  price: MoneyAmountSerializer,
});

class TreatmentsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client
      .fetch('treatments', 'GET')
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(treatment =>
            Serializer.deserialize(treatment)
          ),
        });
      });
  }

  create(data) {
    return this._client
      .fetch('treatments', 'POST', Serializer.serialize(data))
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = Serializer.deserialize(json);
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  update(data) {
    return this._client
      .fetch(`treatments/${data.id}`, 'PATCH', Serializer.serialize(data))
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = Serializer.deserialize(json);
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`treatments/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default TreatmentsEndpoint;
