import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui/Card';
import LocationsListItem from './_LocationsListItem';

class LocationsList extends Component {
  static propTypes = {
    locations: PropTypes.array.isRequired,
  };

  render() {
    const { locations } = this.props;

    return (
      <Card>
        {locations.map(location => (
          <LocationsListItem key={location.id} location={location} />
        ))}
      </Card>
    );
  }
}

export default LocationsList;
