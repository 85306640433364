import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_LocationsListItem.module.scss';
import { CardRow } from 'ui/Card';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class LocationsListItem extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editLocation = () => {
    const { location, editLocation } = this.props;
    editLocation(location);
  };

  _onDestroy = () => {
    const { location, destroyLocation } = this.props;
    destroyLocation(location.id);
  };

  render() {
    const { location } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <CardRow
          onClick={this._editLocation}
          rowSize={'small'}
          contextMenuItems={[
            {
              label: locale().actions.destroy,
              onClick: this._openConfirmationDialog,
              type: 'attention',
            },
          ]}
        >
          <div className={styles['wrapper']}>
            <div className={styles['name']}> {location.name}</div>
            <div className={styles['right']}>
              <div className={styles['block']}>
                {locale().locations.capacity}
                :&nbsp;&nbsp;
                {location.capacity}
              </div>
              <div className={styles['block']}>
                <div>
                  {locale().locations.location_label}
                  :&nbsp;&nbsp;
                </div>
                <div
                  className={styles['label']}
                  style={{ background: location.color }}
                >
                  {location.short_name}
                </div>
              </div>
            </div>
          </div>
        </CardRow>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._onDestroy}
          title={`${locale().locations.destroy_location_head}: ${
            location.name
          }`}
          description={locale().locations.destroy_location_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editLocation: location => dispatch(actions.editLocation(location)),
    destroyLocation: id => dispatch(actions.destroyLocation(id)),
  };
};

LocationsListItem = connect(null, mapDispatchToProps)(LocationsListItem);

export default LocationsListItem;
