import Response from '../_response';
import { PatientDocumentSerializer } from './../_serializers';

class PatientDocumentsEndpoint {
  constructor(client) {
    this._client = client;
  }

  create(data, onProgress) {
    return this._client
      .fetch(
        'patient_documents',
        'POST',
        PatientDocumentSerializer.serialize(data),
        onProgress
      )
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientDocumentSerializer.deserialize(json)
        );
      });
  }

  update(data) {
    return this._client
      .fetch(
        `patient_documents/${data.id}`,
        'PATCH',
        PatientDocumentSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientDocumentSerializer.deserialize(json)
        );
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patient_documents/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientDocumentSerializer.deserialize(json)
        );
      });
  }
}

export default PatientDocumentsEndpoint;
