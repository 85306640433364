import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import View from './_View';
import viewStyles from './_View.module.scss';
import { connect, actions } from 'util/redux';

class PatientInactive extends Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
    closeMenu: PropTypes.func.isRequired,
  };

  _showPatient = () => {
    const { notification, showPatient, closeMenu } = this.props;

    closeMenu();
    showPatient(notification.notifiable.id);
  };

  _archivePatient = () => {
    const { notification, archivePatient } = this.props;
    return archivePatient(notification.notifiable.id);
  };

  render() {
    const { notification } = this.props;
    const patient = notification.notifiable;

    return (
      <View
        notification={notification}
        onConfirm={patient.archived ? null : this._archivePatient}
      >
        <div className={viewStyles['title']}>
          {locale().notifications.patient_inactive.title}
        </div>
        <div className={viewStyles['content']}>
          <span className={viewStyles['link']} onClick={this._showPatient}>
            {patient.name}
          </span>
          &nbsp;
          <span>
            {patient.archived
              ? '- ' + locale().patients.patient_archived
              : locale().notifications.patient_inactive.content}
          </span>
        </div>
      </View>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showPatient: id => dispatch(actions.showPatient(id)),
    archivePatient: id => dispatch(actions.archivePatient(id)),
  };
};

PatientInactive = connect(null, mapDispatchToProps)(PatientInactive);

export default PatientInactive;
