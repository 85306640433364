import React, { Component } from 'react';
import styles from './_ErrorBox.module.scss';

class ErrorBox extends Component {
  render() {
    const { children } = this.props;

    if (children) {
      return <div className={styles['wrapper']}>{children}</div>;
    } else {
      return null;
    }
  }
}

export default ErrorBox;
