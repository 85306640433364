import locale from 'util/locale';

export default () => ({
  aud: {
    icon: '🇦🇺',
    label: locale().currencies.aud,
    value: 'aud',
    sign: '$',
    format: 0,
  },
  bam: {
    icon: '🇧🇦',
    label: locale().currencies.bam,
    value: 'bam',
    sign: 'KM',
    format: 1,
  },
  cad: {
    icon: '🇨🇦',
    label: locale().currencies.cad,
    value: 'cad',
    sign: '$',
    format: 0,
  },
  chf: {
    icon: '🇨🇭',
    label: locale().currencies.chf,
    value: 'chf',
    sign: 'Fr.',
    format: 1,
  },
  gbp: {
    icon: '🇬🇧',
    label: locale().currencies.gbp,
    value: 'gbp',
    sign: '£',
    format: 0,
  },
  eur: {
    icon: '🇪🇺',
    label: locale().currencies.eur,
    value: 'eur',
    sign: '€',
    format: 1,
  },
  hrk: {
    icon: '🇭🇷',
    label: locale().currencies.hrk,
    value: 'hrk',
    sign: 'KN',
    format: 1,
  },
  huf: {
    icon: '🇭🇺',
    label: locale().currencies.huf,
    value: 'huf',
    sign: 'Ft',
    format: 1,
  },
  nok: {
    icon: '🇳🇴',
    label: locale().currencies.nok,
    value: 'nok',
    sign: 'kr',
    format: 1,
  },
  rsd: {
    icon: '🇷🇸',
    label: locale().currencies.rsd,
    value: 'rsd',
    sign: 'RSD',
    format: 1,
  },
  rub: {
    icon: '🇷🇺',
    label: locale().currencies.rub,
    value: 'rub',
    sign: '₽',
    format: 1,
  },
  sek: {
    icon: '🇸🇪',
    label: locale().currencies.sek,
    value: 'sek',
    sign: 'kr',
    format: 1,
  },
  usd: {
    icon: '🇺🇸',
    label: locale().currencies.usd,
    value: 'usd',
    sign: '$',
    format: 0,
  },
});
