import locale from 'util/locale';
import { push } from 'connected-react-router';
import Api from 'util/api/api';
import {
  clearQuery,
  entityTypes,
  getEntityById,
  makeGetEntityList,
  receiveEntities,
  receiveQuery,
  getQuery,
  removeEntities,
} from './_entities';
import { actions as systemActions } from './_system';

// ACTIONS

const openSchedulePage = (data = null) => {
  let query = '';

  if (data && data.patientId && data.serviceId) {
    query = `?patient_id=${data.patientId}&service_id=${data.serviceId}`;

    if (data.serviceItemId) {
      query += `&service_item_id=${data.serviceItemId}`;
    }
  }

  return push(`/schedule${query}`);
};

const showAppointment = id => push(`/schedule/${id}`);

let fetchAppointmentsPromise = Promise.resolve(() => {});
let fetchAppointments = data => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.appointment));

    fetchAppointmentsPromise.cancel();
    fetchAppointmentsPromise = Api.appointments.query(data).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.appointment, query));
      } else {
        return Promise.reject(response);
      }
    });

    return fetchAppointmentsPromise;
  };
};

const fetchAppointmentsForPatient = ({
  patientId,
  clearOld = true,
  page = 1,
}) => {
  return dispatch => {
    if (clearOld) {
      dispatch(clearQuery(entityTypes.appointment));
    }

    return Api.patients.appointments({ patientId, page }).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.appointment, query));
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const predictAppointment = data => {
  return () => {
    return Api.appointments.predict(data).then(response => {
      if (response.isOk()) {
        return response.data();
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createAppointment = data => {
  return dispatch => {
    return Api.appointments.create(data).then(response => {
      if (response.isOk()) {
        const appointment = response.data();
        dispatch(receiveEntities(entityTypes.appointment, [appointment]));
        dispatch(
          systemActions.showToast(locale().appointments.appointment_scheduled)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateAppointment = data => {
  return dispatch => {
    return Api.appointments.update(data).then(response => {
      if (response.isOk()) {
        const appointment = response.data();
        dispatch(receiveEntities(entityTypes.appointment, [appointment]));
        dispatch(
          systemActions.showToast(locale().appointments.appointment_scheduled)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOneAppointment = id => {
  return dispatch => {
    return Api.appointments.get(id).then(response => {
      if (response.isOk()) {
        const appointment = response.data();
        dispatch(receiveEntities(entityTypes.appointment, [appointment]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const cancelAppointment = data => {
  return dispatch => {
    return Api.appointments.cancel(data).then(response => {
      if (response.isOk()) {
        const appointment = response.data();
        dispatch(receiveEntities(entityTypes.appointment, [appointment]));
        dispatch(
          systemActions.showToast(locale().appointments.appointment_canceled)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openSchedulePage,
  showAppointment,
  fetchAppointments,
  fetchAppointmentsForPatient,
  predictAppointment,
  createAppointment,
  updateAppointment,
  fetchOneAppointment,
  cancelAppointment,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getAppointments = state => getEntityList(state, entityTypes.appointment);

const getAppointmentsForPatient = (state, patientId) =>
  getAppointments(state).filter(appointment =>
    appointment.events.some(e => e.patient_id === patientId)
  );

const getAppointment = (state, id) =>
  getEntityById(state, entityTypes.appointment, id);

const getAppointmentsQuery = state => getQuery(state, entityTypes.appointment);

export const selectors = {
  getAppointments,
  getAppointmentsForPatient,
  getAppointment,
  getAppointmentsQuery,
};
