import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PatientHeader.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import MultiOrgRender from 'ui/MultiOrgRender';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import HeaderBar from 'ui/HeaderBar';
import Icon from 'ui/Icon';
import { MoneyText } from 'ui/Money';
import BottomSheet from 'ui/Dialog/BottomSheet';
import Dropdown from 'ui/Dropdown';
import Summary from 'ui/Summary';
import { connect, actions } from 'util/redux';

class PatientHeader extends Component {
  static propTypes = {
    patient: PropTypes.object.isRequired,
    navigation: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      bottomSheetOpen: false,
      confirmationDialogOpen: false,
    };
  }

  _genders = () => ({
    male: locale().patients.male,
    female: locale().patients.female,
  });

  _openBottomSheet = () => this.setState({ bottomSheetOpen: true });

  _closeBottomSheet = () => this.setState({ bottomSheetOpen: false });

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editPatient = () => this.props.editPatient(this.props.patient);

  _changeActiveState = () => {
    const { patient, activatePatient, archivePatient } = this.props;

    if (patient.archived) {
      activatePatient(patient.id);
    } else {
      archivePatient(patient.id);
    }
  };

  _destroyPatient = () => {
    const { destroyPatient, patient } = this.props;
    destroyPatient(patient.id);
  };

  _moreOptions = () => {
    const { patient } = this.props;

    return [
      {
        label: locale().actions.edit,
        onClick: this._editPatient,
      },
      {
        label: patient.archived
          ? locale().actions.activate
          : locale().actions.archive,
        onClick: this._changeActiveState,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  };

  _renderUsers = users =>
    users.sortBy('name').map(user => (
      <div
        key={user.id}
        className={classNames(styles['user'], {
          [styles['archived']]: user.archived,
        })}
        style={{ background: user.color }}
      >
        {user.name}
      </div>
    ));

  _content = () => {
    const { patient } = this.props;
    const debtColor = patient.debt > 0 ? '#d82b2b' : undefined;

    return (
      <div className={styles['content']}>
        <div className={styles['left']}>
          <div className={styles['name']}>
            <span>{patient.name}</span>
            <Dropdown items={this._moreOptions()} boxPosition={'right'} />
          </div>
          {patient.diagnose && (
            <div className={styles['diagnose']}>{patient.diagnose.name}</div>
          )}
          <MultiOrgRender>
            <div className={styles['assignedTo']}>
              {this._renderUsers(patient.users.filter(u => !u.archived))}
              {this._renderUsers(patient.users.filter(u => u.archived))}
            </div>
          </MultiOrgRender>
          <div className={styles['businessGroup']}>
            {patient.archived && (
              <div className={styles['meta']}>
                <Icon icon={'archive'} size={14} />
                <span>{locale().patients.archived}</span>
              </div>
            )}
            <div className={styles['meta']}>
              <Icon icon={'account_balance'} size={14} color={debtColor} />
              <span style={{ color: debtColor }}>
                <MoneyText value={patient.debt} />
              </span>
            </div>
          </div>
          <div className={styles['meta']}>
            <Icon icon={'loyalty'} size={14} />
            <span>{this._genders()[patient.gender]}</span>
          </div>
          <div
            onClick={patient.birth_date ? null : this._editPatient}
            className={classNames(styles['meta'], {
              [styles['addMeta']]: !patient.birth_date,
            })}
          >
            <Icon icon={'cake'} size={14} />
            <span>
              {patient.birth_date
                ? patient.birth_date.format('dd. MMMM yyyy.')
                : locale().patients.add_birth_date}
            </span>
          </div>
          <div
            onClick={patient.notes ? null : this._editPatient}
            className={classNames(styles['meta'], styles['wrapMeta'], {
              [styles['addMeta']]: !patient.notes,
            })}
          >
            <Icon icon={'description'} size={14} />
            <span>{patient.notes || locale().patients.anamnesis_info}</span>
          </div>
        </div>
        <div className={styles['right']}>
          {patient.parent_name && (
            <div className={styles['meta']}>
              <Icon icon={'wc'} size={14} />
              <span>{patient.parent_name}</span>
            </div>
          )}
          {patient.email && (
            <div className={styles['meta']}>
              <Icon icon={'contact_mail'} size={14} />
              <span>{patient.email}</span>
            </div>
          )}
          {patient.phone_number && (
            <div className={styles['meta']}>
              <Icon icon={'contact_phone'} size={14} />
              <span>{patient.phone_number}</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  _mobile = () => {
    const { patient, navigation } = this.props;
    const { bottomSheetOpen } = this.state;

    return (
      <React.Fragment>
        <HeaderBar
          title={patient.name}
          rightContent={
            <Icon
              icon={'more_vert'}
              color={'#FFFFFF'}
              expand={true}
              onClick={this._openBottomSheet}
            />
          }
        />
        <Summary
          mainDetails={
            patient.diagnose
              ? patient.diagnose.name
              : locale().patients.patient_data_head
          }
        >
          {this._content()}
        </Summary>
        {navigation}
        <BottomSheet
          open={bottomSheetOpen}
          onClose={this._closeBottomSheet}
          items={this._moreOptions()}
        />
      </React.Fragment>
    );
  };

  _desktop = () => {
    const { navigation } = this.props;
    return (
      <div className={styles['wrapper']}>
        {this._content()}
        {navigation}
      </div>
    );
  };

  render() {
    const { patient } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._destroyPatient}
          title={`${locale().patients.destroy_patient_head}: ${patient.name}`}
          description={locale().patients.destroy_patient_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editPatient: patient => dispatch(actions.editPatient(patient)),
    activatePatient: id => dispatch(actions.activatePatient(id)),
    archivePatient: id => dispatch(actions.archivePatient(id)),
    destroyPatient: id => dispatch(actions.destroyPatient(id, true)),
  };
};

PatientHeader = connect(null, mapDispatchToProps)(PatientHeader);

export default PatientHeader;
