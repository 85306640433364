import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Doughnut.module.scss';
import classNames from 'classnames';
import WithTooltip from 'ui/WithTooltip';
import { Doughnut as ChartJSDoughnut } from 'react-chartjs-2';

const DEFAULT_COLORS = ['#46aa5a', '#8269e7', '#f37a51', '#7599fe', '#f1a900'];

class Doughnut extends Component {
  static propTypes = {
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        color: PropTypes.string,
      })
    ).isRequired,
    labelsOverride: PropTypes.string,
  };

  _stats = () => {
    let stats = this.props.stats;

    if (stats.length === 0) {
      stats = Array.from(Array(5).keys()).map(i => ({
        label: `Option ${i}`,
        amount: 1,
      }));
    }

    return stats
      .sortBy('amount')
      .reverse()
      .slice(0, 5)
      .map((data, i) => ({
        ...data,
        color: data.color || DEFAULT_COLORS[i],
      }));
  };

  render() {
    const { labelsOverride } = this.props;
    const isEmpty = this.props.stats.length === 0;

    let statsData = this._stats();
    let amounts = statsData.map(data => data.amount);
    let colors = statsData.map(data => data.color);
    let labels = statsData.map(data =>
      labelsOverride ? labelsOverride : data.label
    );

    return (
      <div className={styles['wrapper']}>
        <div className={styles['donut']}>
          <ChartJSDoughnut
            width={150}
            height={150}
            data={{
              datasets: [
                {
                  data: amounts,
                  backgroundColor: colors,
                  hoverBackgroundColor: colors,
                  hoverBorderColor: new Array(colors.length).fill(
                    'rgba(0, 0, 0, 0)'
                  ),
                },
              ],
              labels: labels,
            }}
            options={{
              cutoutPercentage: 75,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: !isEmpty,
              },
            }}
          />
        </div>
        <div
          className={classNames(styles['list'], { [styles['empty']]: isEmpty })}
        >
          <div>
            {statsData.map((data, i) => (
              <WithTooltip key={i} text={data.label}>
                <div className={styles['listItem']}>
                  <span
                    className={classNames(styles['box'])}
                    style={{ backgroundColor: data.color }}
                  />
                  <span className={classNames(styles['text'])}>
                    {data.label}
                  </span>
                </div>
              </WithTooltip>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Doughnut;
