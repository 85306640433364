import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_OnboardNav.module.scss';
import logo from 'media/images/logo.png';
import HeaderBar from 'ui/HeaderBar';
import Icon from 'ui/Icon';
import { Button } from 'ui/Button';
import MobileDesktopRender from '../../ui/MobileDesktopRender/MobileDesktopRender';

class OnboardNav extends Component {
  static propTypes = {
    actionQuestion: PropTypes.string.isRequired,
    actionTitle: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  };

  _mobile = () => {
    const { actionTitle, action } = this.props;

    return (
      <HeaderBar
        title={'Logovel'}
        rightContent={
          <div className={styles['mobileRight']} onClick={action}>
            <span>{actionTitle}</span>
            <Icon icon={'arrow_forward'} color={'#FFFFFF'} expand={true} />
          </div>
        }
      />
    );
  };

  _desktop = () => {
    const { actionQuestion, actionTitle, action } = this.props;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['title']}>
          <img src={logo} className={styles['logo']} alt={''} />
          <span>Logovel</span>
        </div>
        <div className={styles['right']}>
          <div className={styles['question']}>{actionQuestion}</div>
          <Button value={actionTitle} onClick={action} actionType={'normal'} />
        </div>
      </div>
    );
  };

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default OnboardNav;
