import React, { Component } from 'react';
import locale from 'util/locale';
import qs from 'qs';
import styles from './PatientShow.module.scss';
import classNames from 'classnames';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import { Card } from 'ui/Card';
import { Button } from 'ui/Button';
import FAB from 'ui/FAB';
import PatientHeader from './_PatientHeader';
import AppointmentsList from './_AppointmentsList';
import ServicesList from './_ServicesList';
import ServicePaymentsList from './_ServicePaymentsList';
import NotesList from './_NotesList';
import ReportsList from './_ReportsList';
import DocumentsList from './_DocumentsList';
import ActionCenter from 'util/action_center';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class PatientShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    this._fetch();
    ActionCenter.subscribe('createService', this._reloadPatient);
    ActionCenter.subscribe('destroyService', this._reloadPatient);
    ActionCenter.subscribe('inflowServicePayments', this._reloadPatient);
    ActionCenter.subscribe('createServicePayment', this._reloadPatient);
    ActionCenter.subscribe('destroyServicePayment', this._reloadPatient);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this._fetch();
    }
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createService', this._reloadPatient);
    ActionCenter.unsubscribe('destroyService', this._reloadPatient);
    ActionCenter.unsubscribe('inflowServicePayments', this._reloadPatient);
    ActionCenter.unsubscribe('createServicePayment', this._reloadPatient);
    ActionCenter.unsubscribe('destroyServicePayment', this._reloadPatient);
  }

  _nav = () => {
    const { invoicingDisabled } = this.props;

    return {
      appointments: {
        view: AppointmentsList,
        label: locale().appointments.title,
        action: {
          label: locale().appointments.schedule_an_appointment,
          onClick: this.props.startAssigningService,
          icon: 'assignment',
        },
      },
      services: {
        view: ServicesList,
        label: locale().services.title,
        action: {
          label: locale().patient_invoices.new,
          onClick: this.props.newPatientInvoice,
          icon: 'not-used',
          disabled: invoicingDisabled,
          disabledReason: locale().patient_invoices.new_disabled_reason,
          desktopOnly: true,
        },
      },
      payments: {
        view: ServicePaymentsList,
        label: locale().service_payments.title_short,
        showOnMobile: true,
        action: {
          label: locale().service_payments.new,
          onClick: this.props.newInflow,
          icon: 'attach_money',
        },
      },
      notes: {
        view: NotesList,
        label: locale().patient_notes.title,
        action: {
          label: locale().patient_notes.add_notes,
          onClick: this.props.newPatientNote,
          icon: 'note_add',
        },
      },
      reports: {
        view: ReportsList,
        label: locale().patient_reports.title,
        desktopOnly: true,
        action: {
          label: locale().patient_reports.new,
          onClick: this.props.newPatientReport,
        },
      },
      documents: {
        view: DocumentsList,
        label: locale().patient_documents.title,
        action: {
          label: locale().patient_documents.new,
          onClick: this.props.newPatientDocument,
          icon: 'cloud_upload',
        },
      },
    };
  };

  _fetch = () => {
    const { fetchOnePatient } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchOnePatient()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        if (response.isNotFound && response.isNotFound()) {
          this.setState({ loadingState: loadingStates.missing });
        } else {
          this.setState({ loadingState: loadingStates.failed });
        }
      });
  };

  _reloadPatient = () => this.props.fetchOnePatient();

  _mobileAction = () => {
    const { visibleView } = this.props;
    const actionItem = this._nav()[visibleView].action;

    if (actionItem.desktopOnly) {
      return null;
    } else {
      return <FAB onClick={actionItem.onClick} icon={actionItem.icon} />;
    }
  };

  _desktopAction = () => {
    const { visibleView } = this.props;
    const actionItem = this._nav()[visibleView].action;

    return (
      <div className={styles['group']}>
        <Button
          key={actionItem.label}
          value={actionItem.label}
          onClick={actionItem.onClick}
          disabled={actionItem.disabled}
          disabledReason={actionItem.disabledReason}
          size={'small'}
        />
      </div>
    );
  };

  _page = nav => {
    const { showPatient, patient, visibleView } = this.props;

    const View = this._nav()[visibleView].view;

    const navItems = Object.keys(nav).map(view => {
      const item = nav[view];

      return {
        label: item.label,
        onClick: () => showPatient(view),
        selected: view === visibleView,
      };
    });

    return (
      <React.Fragment>
        <PatientHeader
          patient={patient}
          navItems={navItems}
          navigation={
            <div className={styles['navigation']}>
              <div className={styles['group']}>
                {navItems.map((item, i) => (
                  <div
                    key={i}
                    className={classNames(styles['item'], {
                      [styles['selected']]: item.selected,
                    })}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </div>
                ))}
              </div>
              <MobileDesktopRender
                mobile={this._mobileAction}
                desktop={this._desktopAction}
              />
            </div>
          }
        />
        <div className={styles['view']}>
          <View patientId={patient.id} />
        </div>
      </React.Fragment>
    );
  };

  _mobile = () => {
    return this._page(
      Object.filter(this._nav(), navItem => !navItem.desktopOnly)
    );
  };

  _desktop = () => {
    return <Card>{this._page(this._nav())}</Card>;
  };

  render() {
    const { patient } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (loadingState === loadingStates.missing) {
      bodyNode = <FailureInfo info={locale().patients.not_found} />;
      // Patient can be null for a moment when switching to other patient (like from notifications)
      // But componentDidUpdate will handle it
    } else if (loadingState === loadingStates.fetching || !patient) {
      bodyNode = <LoadingIndicator />;
    } else {
      bodyNode = (
        <Navigator title={patient.name}>
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        </Navigator>
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const searchQ = (ownProps.location.search || '').substring(1);
  const rawQuery = qs.parse(searchQ);

  const id = ownProps.match.params.id;

  return {
    id,
    visibleView: rawQuery.view || 'appointments',
    patient: selectors.getPatient(state, id),
    documents: selectors.getDocumentsForPatient(state, id),
    invoicingDisabled:
      selectors.getSelectedServiceIdsForInvoicing(state).length === 0,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    showPatient: page => dispatch(actions.showPatient(id, page)),
    fetchOnePatient: () => dispatch(actions.fetchOnePatient(id)),
    startAssigningService: () =>
      dispatch(actions.startAssigningService({ patientId: id })),
    newPatientInvoice: () =>
      dispatch(actions.newPatientInvoice({ patient_id: id })),
    newInflow: () => dispatch(actions.newInflow({ patient_id: id })),
    newPatientNote: () => dispatch(actions.newPatientNote({ patient_id: id })),
    newPatientReport: () =>
      dispatch(actions.newPatientReport({ patient_id: id })),
    newPatientDocument: () =>
      dispatch(actions.newPatientDocument({ patient_id: id })),
  };
};

PatientShow = connect(mapStateToProps, mapDispatchToProps)(PatientShow);

export default PatientShow;
