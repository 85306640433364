import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PlansOverview.module.scss';
import { MoneyText } from 'ui/Money';
import { Checkbox } from 'ui/Form';
import Icon from 'ui/Icon';

const SUBSCRIPTION_CURRENCY = 'usd';
const INSTRUCTIONS_EMAIL_CONTACT = 'info@logovel.com';

class PlanOverview extends Component {
  static propTypes = {
    plans: PropTypes.array.isRequired,
    subscription: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      yearlyPlanSelected: false,
    };
  }

  _intervalLabels = () => ({
    monthly: locale().subscription.monthly,
    yearly: locale().subscription.yearly,
  });

  _toggleSavings = yearlyPlanSelected =>
    this.setState({
      yearlyPlanSelected,
    });

  _roles = () => {
    const { subscription, plans } = this.props;
    const { yearlyPlanSelected } = this.state;
    const plan = plans[yearlyPlanSelected ? 1 : 0];

    const headMonthlyPrice = yearlyPlanSelected
      ? Math.round(plan.head_price / 12)
      : plan.head_price;
    const employeeMonthlyPrice = yearlyPlanSelected
      ? Math.round(plan.employee_price / 12)
      : plan.employee_price;

    return [
      {
        name: locale().subscription.admin_account,
        price: headMonthlyPrice,
        payed_count: subscription.head_count,
        admin: true,
      },
      {
        name: locale().subscription.therapist_account,
        price: employeeMonthlyPrice,
        payed_count: subscription.employee_count,
        admin: false,
      },
    ];
  };

  _savings = () => {
    const { plans, subscription } = this.props;
    return (
      subscription.head_count * plans[0].head_price * 12 +
      subscription.employee_count * plans[0].employee_price * 12 -
      (subscription.head_count * plans[1].head_price +
        subscription.employee_count * plans[1].employee_price)
    );
  };

  _amount = plan => {
    const { subscription } = this.props;

    return (
      subscription.head_count * plan.head_price +
      subscription.employee_count * plan.employee_price
    );
  };

  render() {
    const { plans } = this.props;
    const { yearlyPlanSelected } = this.state;
    const plan = plans[yearlyPlanSelected ? 1 : 0];

    return (
      <div>
        <div className={styles['roles']}>
          {this._roles().map(role => (
            <div key={role.name} className={styles['role']}>
              <div className={styles['head']}>
                <div>{role.name}</div>
                <div className={styles['payed']}>{role.payed_count}</div>
              </div>
              <div className={styles['price']}>
                <MoneyText
                  value={role.price}
                  currency={SUBSCRIPTION_CURRENCY}
                />
                <span>{locale().subscription.monthly}</span>
              </div>
              <div>
                {locale().subscription.included.map((included, i) => {
                  const notIncluded = included.admin && !role.admin;

                  return (
                    <div key={i} className={styles['included']}>
                      <Icon
                        icon={notIncluded ? 'close' : 'done'}
                        color={notIncluded ? '#d82b2b' : '#46ac5a'}
                        size={16}
                      />
                      <span>{included.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className={styles['fullPriceWrapper']}>
          <div className={styles['fullPrice']}>
            <MoneyText
              value={this._amount(plan)}
              currency={SUBSCRIPTION_CURRENCY}
            />
            &nbsp;{this._intervalLabels()[plan.interval]}
          </div>
          <Checkbox
            value={yearlyPlanSelected}
            onChange={this._toggleSavings}
            label={
              <div className={styles['savings']}>
                <div className={styles['save']}>
                  {locale().subscription.save_money}&nbsp;
                  <MoneyText
                    value={this._savings()}
                    currency={SUBSCRIPTION_CURRENCY}
                  />
                  &nbsp;
                </div>
                <div>{locale().subscription.with_yearly_subscription}</div>
              </div>
            }
          />
        </div>
        <div className={styles['attention']}>
          {locale().subscription.contact_for_payment_instructions(
            INSTRUCTIONS_EMAIL_CONTACT
          )}
        </div>
      </div>
    );
  }
}

export default PlanOverview;
