import Response from './../_response';
import { ServiceItemSerializer } from './../_serializers';

class ServiceItemsEndpoint {
  constructor(client) {
    this._client = client;
  }

  create(data) {
    return this._client
      .fetch('service_items', 'POST', data)
      .then(({ response, json }) => {
        return ServiceItemsEndpoint._buildResponse(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`service_items/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return ServiceItemsEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = ServiceItemSerializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default ServiceItemsEndpoint;
