import React, { PureComponent } from 'react';
import styles from './PageContainer.module.scss';

class PageContainer extends PureComponent {
  static MAX_WIDTH = 1240;
  static SCREEN_SIZE_WITH_NO_PADDING = 1310;
  static MAX_PADDING = 35;

  render() {
    return <div className={styles['container']}>{this.props.children}</div>;
  }
}

export default PageContainer;
