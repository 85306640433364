const responseTypes = {
  200: 'success',
  400: 'bad_request',
  401: 'unauthorized',
  404: 'not_found',
  422: 'validation_error',
  429: 'to_many_requests',
  500: 'server_error',
};

class ValidationErrors {
  constructor(response) {
    this._response = response;
  }

  errorPerField() {
    const allErrors = this._response.data();
    let saveErrors = {};
    Object.keys(allErrors).forEach(
      field => (saveErrors[field] = allErrors[field][0].full_message)
    );
    return saveErrors;
  }

  fullErrorsAsArray() {
    const errorPerField = this.errorPerField();
    let errors = [];
    Object.keys(errorPerField).forEach(key => errors.push(errorPerField[key]));
    return errors;
  }
}

class Response {
  constructor(responseOrStatus, json) {
    if (Number.isInteger(responseOrStatus)) {
      this._status = responseOrStatus;
    } else {
      const headers = responseOrStatus.headers;

      this._status = responseOrStatus.status;
      this._pagination = {
        total: parseInt(headers.pagination_total),
        page: parseInt(headers.pagination_page),
        per_page: parseInt(headers.pagination_per_page),
      };
    }
    this._json = json;
  }

  isOk() {
    return this._status === 200 || this._status === 201;
  }

  isBadRequest() {
    return this._status === 400;
  }

  isNotFound() {
    return this._status === 404;
  }

  isValidationError() {
    return this._status === 422;
  }

  isToManyRequests() {
    return this._status === 429;
  }

  validationErrors() {
    if (this.isValidationError()) {
      return new ValidationErrors(this);
    } else {
      return null;
    }
  }

  type() {
    return responseTypes[this._status.status] || 'unknown_error';
  }

  data() {
    return this._json;
  }

  pagination() {
    return this._pagination;
  }
}

export default Response;
