import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';
import ActionCenter from 'util/action_center';

// ACTIONS

const fetchDiagnoses = () => {
  return dispatch => {
    return Api.diagnoses.query().then(response => {
      if (response.isOk()) {
        dispatch(clearQuery(entityTypes.diagnose));
        const query = response.data();
        dispatch(receiveQuery(entityTypes.diagnose, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createDiagnose = data => {
  return dispatch => {
    return Api.diagnoses.create(data).then(response => {
      if (response.isOk()) {
        const diagnose = response.data();
        dispatch(receiveEntities(entityTypes.diagnose, [diagnose]));
        dispatch(systemActions.showToast(locale().diagnoses.diagnose_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyDiagnose = id => {
  return dispatch => {
    return Api.diagnoses.destroy(id).then(response => {
      if (response.isOk()) {
        const diagnose = response.data();
        dispatch(removeEntities(entityTypes.diagnose, [diagnose.id]));
        dispatch(
          systemActions.showToast(locale().diagnoses.diagnose_destroyed)
        );
        ActionCenter.publish('destroyDiagnose');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  fetchDiagnoses,
  createDiagnose,
  destroyDiagnose,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getDiagnoses = state => getEntityList(state, entityTypes.diagnose);

export const selectors = {
  getDiagnoses,
};
