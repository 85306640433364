import { connect } from 'react-redux';
import * as actions from './_actions';
import * as selectors from './reducer';

const loadingStates = {
  fetching: 'fetching',
  missing: 'missing',
  failed: 'failed',
  present: 'present',
};

export { loadingStates, connect, actions, selectors };
