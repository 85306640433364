import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './ServicesOverview.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Navigator from 'navigation/Navigator';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from 'ui/EmptyState';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import HeaderBar from 'ui/HeaderBar';
import { Button } from 'ui/Button';
import FAB from 'ui/FAB';
import Icon from 'ui/Icon';
import TreatmentsList from './_TreatmentsList';
import PackagesList from './_PackagesList';
import emptyStateImg from 'media/images/services/treatments.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';
import ActionCenter from 'util/action_center';

class ServicesOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    ActionCenter.subscribe('destroyTreatment', this._fetch);
    this._fetch(false);
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('destroyTreatment', this._fetch);
  }

  _fetch = (onlyPackages = true) => {
    const { fetchTreatments, fetchPackages } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([
      onlyPackages ? Promise.resolve(() => {}) : fetchTreatments(),
      fetchPackages(),
    ])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(error => {
        console.error(error);
        this.setState({ loadingState: loadingStates.failed });
      });
  };

  _mobileHeader = () => {
    const { treatments, newPackage } = this.props;

    return (
      <HeaderBar
        title={locale().services.title}
        rightContent={
          treatments.length > 0 && (
            <div className={styles['mobileHeaderRight']} onClick={newPackage}>
              <span>{locale().packages.new}</span>
              <Icon icon={'arrow_forward'} color={'#FFFFFF'} expand={true} />
            </div>
          )
        }
      />
    );
  };

  _mobile = () => <FAB onClick={this.props.newTreatment} />;

  _desktop = () => {
    const { newTreatment } = this.props;

    return (
      <Toolbar>
        <ToolbarGroup />
        <ToolbarGroup>
          <Button value={locale().treatments.new} onClick={newTreatment} />
        </ToolbarGroup>
      </Toolbar>
    );
  };

  render() {
    const { treatments, packages, newTreatment } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (treatments.length === 0) {
      bodyNode = (
        <EmptyState
          heading={locale().treatments.empty_state_heading}
          image={emptyStateImg}
          intro={locale().treatments.empty_state_intro}
          actionText={locale().treatments.new}
          onClick={newTreatment}
        />
      );
    } else {
      bodyNode = (
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
          <TreatmentsList treatments={treatments} />
          <PackagesList packages={packages} />
        </React.Fragment>
      );
    }

    return (
      <Navigator title={locale().services.title} mountedAs={'treatments'}>
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobileHeader} />
          {bodyNode}
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
    treatments: selectors.getTreatments(state),
    packages: selectors.getPackages(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTreatments: () => dispatch(actions.fetchTreatments()),
    fetchPackages: () => dispatch(actions.fetchPackages()),
    newTreatment: () => dispatch(actions.newTreatment()),
    newPackage: () => dispatch(actions.newPackage()),
  };
};

ServicesOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesOverview);

export default ServicesOverview;
