import Response from '../_response';

class EmployeesEndpoint {
  constructor(client) {
    this._client = client;
  }

  query(data = {}) {
    return this._client
      .fetch('employees', 'GET', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  update(data = {}) {
    return this._client
      .fetch(`employees/${data.id}`, 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  archive(id) {
    return this._client
      .fetch(`employees/${id}/archive`, 'POST')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  activate(id) {
    return this._client
      .fetch(`employees/${id}/activate`, 'POST')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default EmployeesEndpoint;
