import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { push } from 'connected-react-router';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  getQuery,
  getEntityById,
  removeEntities,
} from './_entities';
import ActionCenter from 'util/action_center';

const START_PATIENT_EDITING = 'START_PATIENT_EDITING';
const FINISH_PATIENT_EDITING = 'FINISH_PATIENT_EDITING';

export default (
  state = {
    editingPatient: emptyPatientData(),
    patientDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_PATIENT_EDITING:
      return {
        ...state,
        editingPatient: action.patient,
        patientDialogOpen: true,
      };
    case FINISH_PATIENT_EDITING:
      return {
        ...state,
        editingPatient: emptyPatientData(),
        patientDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const openPatientsPage = () => push('/patients');

const showPatient = (id, page) => {
  const query = page ? `?view=${page}` : '';

  return push(`/patients/${id}${query}`);
};

const emptyPatientData = () => ({
  name: '',
  gender: '',
  parent_name: '',
  email: '',
  birth_date: null,
  phone_number: '',
  notes: '',
  diagnose_id: null,
  patient_referral_id: null,
  user_ids: [],
});

const newPatient = () => {
  return {
    type: START_PATIENT_EDITING,
    patient: emptyPatientData(),
  };
};

const editPatient = patient => {
  return {
    type: START_PATIENT_EDITING,
    patient,
  };
};

const finishPatientEditing = () => {
  return {
    type: FINISH_PATIENT_EDITING,
  };
};

let fetchPatientsPromise = Promise.resolve(() => {});
const fetchPatients = query => {
  return dispatch => {
    if (query.clearOld || query.clearOld === undefined) {
      dispatch(clearQuery(entityTypes.patient));
    }

    fetchPatientsPromise.cancel();
    fetchPatientsPromise = Api.patients.query(query).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.patient, query));
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    });
    return fetchPatientsPromise;
  };
};

const fetchOnePatient = id => {
  return dispatch => {
    return Api.patients.get(id).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        dispatch(receiveEntities(entityTypes.patient, [patient]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatient = data => {
  return dispatch => {
    return Api.patients.create(data).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        dispatch(receiveEntities(entityTypes.patient, [patient]));
        dispatch(systemActions.showToast(locale().patients.patient_saved));
        ActionCenter.publish('createPatient', patient);
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updatePatient = data => {
  return dispatch => {
    return Api.patients.update(data).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        dispatch(receiveEntities(entityTypes.patient, [patient]));
        dispatch(systemActions.showToast(locale().patients.patient_saved));
        ActionCenter.publish('updatePatient');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPatient = (id, openOverview = false) => {
  return dispatch => {
    return Api.patients.destroy(id).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        if (openOverview) {
          dispatch(openPatientsPage());
        }
        dispatch(removeEntities(entityTypes.patient, [patient.id]));
        dispatch(systemActions.showToast(locale().patients.patient_destroyed));
        ActionCenter.publish('destroyPatient');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const activatePatient = id => {
  return dispatch => {
    return Api.patients.activate(id).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        dispatch(receiveEntities(entityTypes.patient, [patient]));
        dispatch(systemActions.showToast(locale().patients.patient_activated));
        ActionCenter.publish('activatePatient');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const archivePatient = id => {
  return dispatch => {
    return Api.patients.archive(id).then(response => {
      if (response.isOk()) {
        const patient = response.data();
        dispatch(receiveEntities(entityTypes.patient, [patient]));
        dispatch(systemActions.showToast(locale().patients.patient_archived));
        ActionCenter.publish('archivePatient');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openPatientsPage,
  newPatient,
  editPatient,
  finishPatientEditing,
  showPatient,
  fetchPatients,
  fetchOnePatient,
  createPatient,
  updatePatient,
  destroyPatient,
  archivePatient,
  activatePatient,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getPatients = state => getEntityList(state, entityTypes.patient);

const getPatient = (state, id) => getEntityById(state, entityTypes.patient, id);

const getPatientsQuery = state => getQuery(state, entityTypes.patient);

const getEditingPatient = state => state.editingPatient;

const isEditingPatient = state => state.patientDialogOpen;

export const selectors = {
  getPatients,
  getPatient,
  getPatientsQuery,
  getEditingPatient,
  isEditingPatient,
};
