import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './DesktopNav.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import logo from 'media/images/logo.png';
import NotificationsMenu from 'notifications/NotificationsMenu';
import AccountMenu from './_AccountMenu';
import { connect, actions, selectors } from 'util/redux';

class DesktopNav extends Component {
  _navItems = () => [
    {
      title: locale().schedule.title,
      onClick: this.props.openSchedulePage,
      pages: ['schedule'],
      show: true,
      items: [],
    },
    {
      title: locale().patients.title,
      onClick: this.props.openPatientsPage,
      pages: ['patients'],
      show: true,
      items: [],
    },
    {
      title: locale().practice.title,
      onClick: this.props.openTreatmentsPage,
      pages: ['treatments', 'finances', 'employees', 'locations'],
      show: true,
      items: [
        {
          title: locale().services.title,
          onClick: this.props.openTreatmentsPage,
          page: 'treatments',
          show: true,
        },
        {
          title: locale().finances.title,
          onClick: this.props.openFinancesPage,
          page: 'finances',
          show: this.props.user.permissions.finance_overview,
        },
        {
          title: locale().employees.title,
          onClick: this.props.openEmployeesPage,
          page: 'employees',
          show: this.props.user.permissions.manage_employees,
        },
        {
          title: locale().locations.title,
          onClick: this.props.openLocationsPage,
          page: 'locations',
          show: this.props.user.permissions.manage_locations,
        },
      ],
    },
    {
      title: locale().posts.title,
      onClick: this.props.openPostsPage,
      pages: ['posts'],
      show: true,
      items: [],
    },
  ];

  _render = () => {
    const { activePage, openDashboardPage, user } = this.props;

    let extraTitle;
    if (document.title !== 'Logovel') {
      extraTitle = <div className={styles['extraTitle']}>{document.title}</div>;
    }

    return (
      <div className={styles['wrapper']}>
        <div className={styles['innerWrapper']}>
          <div className={styles['title']}>
            <div
              className={classNames(styles['homepage'], {
                [styles['clickable']]: user.permissions.finance_overview,
              })}
              onClick={
                user.permissions.finance_overview ? openDashboardPage : () => {}
              }
            >
              <img src={logo} className={styles['logo']} alt={''} />
              <span>Logovel</span>
            </div>
            {extraTitle}
          </div>
          <div className={styles['rightContent']}>
            {this._navItems()
              .filter(item => item.show)
              .map(navItem => {
                const isActive = navItem.pages.includes(activePage);
                const items = navItem.items.filter(item => item.show);
                return (
                  <div
                    key={navItem.title}
                    onClick={navItem.onClick}
                    className={classNames(styles['navItem'], {
                      [styles['selected']]: isActive,
                    })}
                  >
                    {navItem.title}
                    {items.length > 1 && (
                      <div className={styles['moreItems']}>
                        <div className={styles['arrow']} />
                        {navItem.items
                          .filter(item => item.show)
                          .map(item => (
                            <div
                              key={item.title}
                              className={styles['item']}
                              onClick={e => {
                                e.stopPropagation();
                                item.onClick();
                              }}
                            >
                              {item.title}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                );
              })}
            <div className={styles['more']}>
              <NotificationsMenu />
              <AccountMenu />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <MobileDesktopRender desktop={this._render} />;
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
    activePage: selectors.getActivePage(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openDashboardPage: () => dispatch(actions.openDashboardPage()),
    openSchedulePage: () => dispatch(actions.openSchedulePage()),
    openPatientsPage: () => dispatch(actions.openPatientsPage()),
    openTreatmentsPage: () => dispatch(actions.openTreatmentsPage()),
    openFinancesPage: () => dispatch(actions.openFinancesPage()),
    openEmployeesPage: () => dispatch(actions.openEmployeesPage()),
    openLocationsPage: () => dispatch(actions.openLocationsPage()),
    openPostsPage: () => dispatch(actions.openPostsPage()),
  };
};

DesktopNav = connect(mapStateToProps, mapDispatchToProps)(DesktopNav);

export default DesktopNav;
