import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.scss';
import classNames from 'classnames';

class Icon extends Component {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func,
    expand: PropTypes.bool,
  };

  static defaultProps = {
    size: 24,
    color: '#1A1C1F',
    onClick: null,
    expand: false,
  };

  _onClick = e => {
    const { onClick } = this.props;
    if (onClick) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onClick();
    }
  };

  render() {
    const { icon, size, color, onClick, expand } = this.props;

    let iconStyle = {
      fontSize: size,
      color: color,
    };

    return (
      <div
        className={classNames([
          styles['wrapper'],
          {
            [styles['expand']]: expand,
            [styles['pointer']]: onClick !== null,
          },
        ])}
        onClick={this._onClick}
      >
        <div className={styles['icon']} style={{ maxWidth: size }}>
          <i className={'material-icons'} style={iconStyle}>
            {icon}
          </i>
        </div>
      </div>
    );
  }
}

export default Icon;
