import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { push } from 'connected-react-router';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  removeEntities,
  makeGetEntityList,
} from './_entities';
import ActionCenter from 'util/action_center';

const START_TREATMENT_EDITING = 'START_TREATMENT_EDITING';
const FINISH_TREATMENT_EDITING = 'FINISH_TREATMENT_EDITING';

export default (
  state = {
    editingTreatment: emptyTreatmentData(),
    treatmentDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_TREATMENT_EDITING:
      return {
        ...state,
        editingTreatment: action.treatment,
        treatmentDialogOpen: true,
      };
    case FINISH_TREATMENT_EDITING:
      return {
        ...state,
        editingTreatment: emptyTreatmentData(),
        treatmentDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyTreatmentData = () => ({
  name: '',
  duration: 0,
  price: 0,
});

const newTreatment = () => {
  return {
    type: START_TREATMENT_EDITING,
    treatment: emptyTreatmentData(),
  };
};

const editTreatment = treatment => {
  return {
    type: START_TREATMENT_EDITING,
    treatment,
  };
};

const finishTreatmentEditing = () => {
  return {
    type: FINISH_TREATMENT_EDITING,
  };
};

const openTreatmentsPage = () => push('/services');

const fetchTreatments = () => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.treatment));
    return Api.treatments.query().then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.treatment, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createTreatment = data => {
  return dispatch => {
    return Api.treatments.create(data).then(response => {
      if (response.isOk()) {
        const treatment = response.data();
        dispatch(receiveEntities(entityTypes.treatment, [treatment]));
        dispatch(systemActions.showToast(locale().treatments.treatment_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateTreatment = data => {
  return dispatch => {
    return Api.treatments.update(data).then(response => {
      if (response.isOk()) {
        const treatment = response.data();
        dispatch(receiveEntities(entityTypes.treatment, [treatment]));
        dispatch(systemActions.showToast(locale().treatments.treatment_saved));
      }
    });
  };
};

const destroyTreatment = id => {
  return dispatch => {
    return Api.treatments.destroy(id).then(response => {
      if (response.isOk()) {
        // Package items contains treatment_id -> it needs to be published first, then remove from entities
        ActionCenter.publish('destroyTreatment');
        const treatment = response.data();
        dispatch(removeEntities(entityTypes.treatment, [treatment.id]));
        dispatch(
          systemActions.showToast(locale().treatments.treatment_destroyed)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openTreatmentsPage,
  newTreatment,
  editTreatment,
  finishTreatmentEditing,
  fetchTreatments,
  createTreatment,
  updateTreatment,
  destroyTreatment,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getTreatments = state => getEntityList(state, entityTypes.treatment);

const getEditingTreatment = state => state.editingTreatment;

const isEditingTreatment = state => state.treatmentDialogOpen;

export const selectors = {
  getTreatments,
  getEditingTreatment,
  isEditingTreatment,
};
