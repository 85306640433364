import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui/Form';
import BottomSheet from 'ui/Dialog/BottomSheet';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class TreatmentSelectUI extends Component {
  static propTypes = {
    value: PropTypes.string,
    treatments: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    renderOnMobile: PropTypes.bool,
    mobileOpen: PropTypes.bool,
    onMobileClose: PropTypes.func,
  };

  static defaultProps = {
    renderOnMobile: true,
    mobileOpen: false,
    onMobileClose: () => {},
  };

  _options = () => {
    const { treatments } = this.props;

    return treatments.sortBy('name').map(treatment => ({
      value: treatment.id,
      label: treatment.name,
    }));
  };

  _mobile = () => {
    const { value, mobileOpen, onMobileClose, onChange } = this.props;

    return (
      <BottomSheet
        open={mobileOpen}
        onClose={onMobileClose}
        items={this._options()}
        onSelect={onChange}
        selectedItem={value}
      />
    );
  };

  _desktop = () => {
    const { value, onChange } = this.props;

    return (
      <Select value={value} onChange={onChange} options={this._options()} />
    );
  };

  render() {
    const { renderOnMobile } = this.props;

    if (renderOnMobile) {
      return this._desktop();
    } else {
      return (
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      );
    }
  }
}

export default TreatmentSelectUI;
