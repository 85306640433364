import React, { Component } from 'react';
import TreatmentSelectUI from './TreatmentSelectUI';
import { connect, actions, selectors } from 'util/redux';

class TreatmentSelect extends Component {
  componentDidMount() {
    const { fetchTreatments } = this.props;
    fetchTreatments();
  }

  render() {
    return <TreatmentSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    treatments: selectors.getTreatments(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTreatments: () => dispatch(actions.fetchTreatments()),
  };
};

TreatmentSelect = connect(mapStateToProps, mapDispatchToProps)(TreatmentSelect);

export default TreatmentSelect;
