import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_OccypuingBlock.module.scss';

class OccupyingBlock extends Component {
  static propTypes = {
    appointments: PropTypes.array.isRequired,
  };

  render() {
    // NOT YET IMPLEMENTED
    return <div className={styles['wrapper']} />;
  }
}

export default OccupyingBlock;
