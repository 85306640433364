import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_DocumentInput.module.scss';
import classNames from 'classnames';

class DocumentInput extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    kind: PropTypes.oneOf(['normal', 'title']),
    color: PropTypes.string,
    alignRight: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    kind: 'normal',
    alignRight: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  _onChange = e => this.setState({ value: e.target.value });

  _onBlur = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (this.props.value !== value) {
      onChange(value);
    }
  };

  render() {
    const { placeholder, kind, color, alignRight } = this.props;
    const { value } = this.state;

    return (
      <input
        value={value}
        onChange={this._onChange}
        onBlur={this._onBlur}
        className={classNames(styles['input'], styles[kind])}
        style={{ textAlign: alignRight ? 'right' : 'left', color: color }}
        placeholder={placeholder}
        type={'text'}
      />
    );
  }
}

export default DocumentInput;
