import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CalendarView from './_CalendarView';

class Schedule extends Component {
  static propTypes = {
    appointments: PropTypes.array.isRequired,
  };

  render() {
    const { appointments } = this.props;

    let appointmentsPerDay = {};
    appointments.forEach(appointment => {
      let key = appointment.date.format('yyyy-MM-dd');
      if (appointmentsPerDay[key]) {
        appointmentsPerDay[key].push(appointment);
      } else {
        appointmentsPerDay[key] = [appointment];
      }
    });

    return (
      <React.Fragment>
        {Object.keys(appointmentsPerDay)
          .sortBy('')
          .map(key => (
            <CalendarView
              {...this.props}
              key={key}
              appointments={appointmentsPerDay[key]}
              date={new Date(key)}
            />
          ))}
      </React.Fragment>
    );
  }
}

export default Schedule;
