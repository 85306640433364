import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import Select from './Select';

class GenderSelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  _options = () => [
    {
      value: 'male',
      label: locale().patients.male,
    },
    {
      value: 'female',
      label: locale().patients.female,
    },
  ];

  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        clearable={false}
        searchable={false}
      />
    );
  }
}

export default GenderSelect;
