import { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { connect, actions } from 'util/redux';

class Navigator extends Component {
  static propTypes = {
    title: PropTypes.string,
    mountedAs: PropTypes.string,
  };

  static defaultProps = {
    title: 'Logovel',
  };

  componentDidMount() {
    const { title, mountedAs, changeActivePage } = this.props;
    document.title = title;
    changeActivePage(mountedAs);
  }

  componentWillUnmount() {
    const { changeActivePage } = this.props;
    document.title = Navigator.defaultProps.title;
    changeActivePage(null);
  }

  render() {
    if (this.props.children) {
      return Children.only(this.props.children);
    } else {
      return null;
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeActivePage: page => dispatch(actions.changeActivePage(page)),
  };
};

Navigator = connect(null, mapDispatchToProps)(Navigator);

export default Navigator;
