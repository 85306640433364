import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_AppointmentsListItem.module.scss';
import classNames from 'classnames';
import MultiOrgRender from 'ui/MultiOrgRender';
import Icon from 'ui/Icon';
import { connect, actions } from 'util/redux';

class AppointmentsListItem extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
    appointment: PropTypes.object.isRequired,
  };

  _serviceItemForPatient = () => {
    const { patientId, appointment } = this.props;

    return appointment.events.find(e => e.patient_id === patientId);
  };

  render() {
    const { appointment, showAppointment } = this.props;
    const serviceItem = this._serviceItemForPatient();

    const isCancelled = appointment.cancelled;

    return (
      <React.Fragment>
        <div
          className={classNames(styles['appointment'], {
            [styles['cancelled']]: isCancelled,
          })}
          onClick={() => showAppointment(appointment.id)}
        >
          <div className={styles['date']}>
            <Icon
              icon={isCancelled ? 'event_busy' : 'event_available'}
              color={isCancelled ? '#d82b2b' : undefined}
              size={14}
            />
            <span>{appointment.date.format('dd.MM. HH:mm')}</span>
          </div>
          <div className={styles['name']}>{serviceItem.name}</div>
          <MultiOrgRender>
            <div className={styles['users']}>
              {appointment.users.map(user => (
                <div
                  key={user.id}
                  className={styles['label']}
                  style={{ background: user.color }}
                >
                  {user.name}
                </div>
              ))}
            </div>
          </MultiOrgRender>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showAppointment: id => dispatch(actions.showAppointment(id)),
  };
};

AppointmentsListItem = connect(null, mapDispatchToProps)(AppointmentsListItem);

export default AppointmentsListItem;
