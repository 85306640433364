import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_DocumentsListItem.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionListItem } from 'ui/SectionList';
import { CardRow } from 'ui/Card';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class DocumentsListItem extends Component {
  static propTypes = {
    document: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._contextMenuItems = [
      {
        label: locale().actions.edit,
        onClick: this._editDocument,
      },
      {
        label: locale().actions.download,
        onClick: this._openFile,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _openFile = () => window.open(this.props.document.file_url, '_blank');

  _editDocument = () => this.props.editDocument(this.props.document);

  _mobile = () => {
    const { document } = this.props;

    return (
      <SectionListItem
        extra={document.date.format('dd.MM.yyyy.')}
        title={document.title}
        contextMenuItems={this._contextMenuItems}
      />
    );
  };

  _desktop = () => {
    const { document } = this.props;

    return (
      <CardRow contextMenuItems={this._contextMenuItems}>
        <div className={styles['title']}>{document.title}</div>
        <div className={styles['date']}>
          {document.date.format('dd. MMM yyyy.')}
        </div>
      </CardRow>
    );
  };

  render() {
    const { destroyDocument } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={destroyDocument}
          title={locale().patient_documents.destroy_document_head}
          description={locale().patient_documents.destroy_document_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.document.id;

  return {
    editDocument: document => dispatch(actions.editPatientDocument(document)),
    destroyDocument: () => dispatch(actions.destroyPatientDocument(id)),
  };
};

DocumentsListItem = connect(null, mapDispatchToProps)(DocumentsListItem);

export default DocumentsListItem;
