import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Paginator.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import { loadingStates } from 'util/redux';

class Paginator extends Component {
  static propTypes = {
    fetch: PropTypes.func.isRequired, // Function must return promise
    query: PropTypes.object,
    debouncingQueryKeys: PropTypes.array, // When query changes for these keys, fetch will debounce (it is needed for things like search)
    onFirstLoad: PropTypes.func,
    onLoadingStateChange: PropTypes.func,
    padding: PropTypes.bool,
  };

  static defaultProps = {
    query: {},
    debouncingQueryKeys: [],
    onFirstLoad: () => {},
    onLoadingStateChange: () => {},
    padding: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      total: 1,
      loadingState: loadingStates.fetching,
    };

    this._debouncedLoadMore = this._loadMore.debounce(500);
  }

  componentDidMount() {
    const { onFirstLoad } = this.props;
    this._loadMore().then(() => onFirstLoad());
  }

  componentDidUpdate(prevProps) {
    const { debouncingQueryKeys } = this.props;

    // Total is set to -1 just to hide "Load more" link which will pop up for a blink of an eye if we don't
    // After fetch is complete, total will be set to its right value

    if (
      debouncingQueryKeys.find(
        key => prevProps.query[key] !== this.props.query[key]
      )
    ) {
      this.setState({ page: 1, total: -1 }, this._debouncedLoadMore);
    } else {
      if (!Object.same(prevProps.query, this.props.query)) {
        this.setState({ page: 1, total: -1 }, this._loadMore);
      }
    }
  }

  reflectLoadingStateChange = () =>
    this.props.onLoadingStateChange(this.state.loadingState);

  _loadMore = () => {
    const { fetch, query } = this.props;
    const { page } = this.state;

    const clearOld = page === 1;

    this.setState(
      { loadingState: loadingStates.fetching },
      this.reflectLoadingStateChange
    );
    return fetch({ ...query, clearOld, page })
      .then(response => {
        this.setState(
          { loadingState: loadingStates.present },
          this.reflectLoadingStateChange
        );
        this.setState({
          page: response.pagination().page + 1,
          total: response.pagination().total,
        });
      })
      .catch(error => {
        console.error(error);
        this.setState(
          { loadingState: loadingStates.failed },
          this.reflectLoadingStateChange
        );

        return Promise.reject(error);
      });
  };

  _firstPage = () => this.state.page === 1;

  _canLoadMore = () =>
    this.state.page <= this.state.total &&
    this.state.loadingState === loadingStates.present;

  render() {
    const { padding } = this.props;
    const { loadingState } = this.state;

    let activityNode;
    if (loadingState === loadingStates.fetching) {
      activityNode = (
        <LoadingIndicator size={this._firstPage() ? 'normal' : 'small'} />
      );
    } else if (loadingState === loadingStates.failed) {
      activityNode = <FailureInfo padding={false} />;
    }

    return (
      <React.Fragment>
        {this.props.children}
        {activityNode && (
          <div className={classNames({ [styles['padding']]: padding })}>
            {activityNode}
          </div>
        )}
        {this._canLoadMore() && (
          <div
            className={classNames(styles['loadMore'], {
              [styles['padding']]: padding,
            })}
            onClick={this._loadMore}
          >
            <span className={styles['link']}>{locale().actions.load_more}</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Paginator;
