import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_Dialog.module.scss';
import Portal from 'ui/Portal';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class DialogStack {
  static pushDialogHash() {
    if (window.location.hash !== '#/dialog') {
      window.history.pushState({}, window.document.title, '#/dialog');
    }
  }

  constructor() {
    this._dialoges = [];
    this._dialogId = 101;

    window.addEventListener('popstate', () => {
      if (this._dialoges.length > 1) {
        DialogStack.pushDialogHash();
      }
      this._closeLastDialog();
    });

    window.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        this._closeLastDialog();
      }
    });

    // Remove hash if page is reloaded with it (otherwise history back will need 2 clicks)
    const uri = window.location.toString();
    if (uri.indexOf('#') > 0) {
      const uriWithoutHash = uri.substring(0, uri.indexOf('#'));
      window.history.replaceState({}, document.title, uriWithoutHash);
    }
  }

  generateId() {
    const id = this._dialogId;
    this._dialogId += 2;
    return id;
  }

  push(dialog) {
    DialogStack.pushDialogHash();
    this._dialoges = [...this._dialoges, dialog];
  }

  remove(id) {
    const dialog = this._dialoges.find(d => d.getId() === id);

    if (dialog) {
      this._dialogId -= 2;
      this._dialoges = this._dialoges.remove(dialog);

      if (this._dialoges.length === 0) {
        // Timeout is for cases when closing dialog (like bottom sheets) opens up new dialog
        // So we add extra 10ms timeout to give time to new dialog to show and push to stack
        // Without timeout, in those cases, new dialog will get closed because of history.back()
        setTimeout(() => {
          if (this._dialoges.length === 0) {
            if (window.location.hash === '#/dialog') {
              window.history.back();
            }
          }
        }, 10);
      }
    }
  }

  _closeLastDialog = () => {
    const lastDialog = this._dialoges[this._dialoges.length - 1];
    if (lastDialog) {
      lastDialog.close();
    }
  };
}

const dialogStack = new DialogStack();

class Dialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onClosing: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    hideOverlayOnMobile: PropTypes.bool,
  };

  static defaultProps = {
    hideOverlayOnMobile: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      actuallyOpen: false,
    };
  }

  close = () => this.props.onClose();

  getId = () => this._id;

  // Be careful that if dialog is doing redirect to other route - it must be in RenderAlways
  // (prevProps.open && !this.props.open) won't happen in those cases if dialog is not in RenderAlways
  // and dialogStack.remove() won't be invoked

  // MoreMenu dialog is exception because it is in mobile nav and basically it is "RenderAlways"
  componentDidUpdate(prevProps) {
    const { onClosing } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({ actuallyOpen: true });
      this._id = dialogStack.generateId();
      dialogStack.push(this);
    }

    if (prevProps.open && !this.props.open) {
      setTimeout(() => this.setState({ actuallyOpen: false }), 250);
      dialogStack.remove(this._id);
      if (this._overlay) {
        this._overlay.classList.add(styles['closing']);
      }
      onClosing();
    }
  }

  _displayOverlay = onClick => (
    <div
      ref={ref => (this._overlay = ref)}
      style={{ zIndex: this._id - 1 }}
      className={styles['overlay']}
      onClick={onClick}
    />
  );

  _mobileOverlay = () =>
    this.props.hideOverlayOnMobile
      ? null
      : this._displayOverlay(this.props.onClose);

  _desktopOverlay = this._displayOverlay;

  render() {
    const { children } = this.props;
    const { actuallyOpen } = this.state;

    if (actuallyOpen) {
      return (
        <Portal>
          <div onClick={e => e.stopPropagation()}>
            <div style={{ position: 'absolute', zIndex: this._id }}>
              {children}
            </div>
            <MobileDesktopRender
              mobile={this._mobileOverlay}
              desktop={this._desktopOverlay}
            />
          </div>
        </Portal>
      );
    } else {
      return null;
    }
  }
}

export default Dialog;
