import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './AppointmentForm.module.scss';
import { MultipleEmployeeSelectUI } from 'employees/EmployeeSelect';
import { LocationSelectUI } from 'locations/LocationSelect';
import PatientsForm from './_PatientsForm';
import { FormRow, FormColumn } from 'ui/Form';
import { Button } from 'ui/Button';
import Icon from 'ui/Icon';
import DatePicker from 'ui/DatePicker';
import { connect, actions, selectors } from 'util/redux';

class AppointmentForm extends Component {
  static propTypes = {
    locationId: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    userIds: PropTypes.array,
    patientsData: PropTypes.arrayOf(
      PropTypes.shape({
        patientId: PropTypes.string,
        serviceId: PropTypes.string,
        serviceItemId: PropTypes.string,
      })
    ).isRequired,
    saveAction: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    onDateChange: PropTypes.func,
    onLocationChange: PropTypes.func,
    editingMode: PropTypes.bool,
  };

  static defaultProps = {
    date: null,
    locationId: null,
    userIds: [],
    onDateChange: () => {},
    onLocationChange: () => {},
    editingMode: false,
  };

  constructor(props) {
    super(props);

    let date = new Date();
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
    date.setSeconds(0);

    this.state = {
      patientsData: [],
      userIds: [],
      date: date,
      locationId: null,
      isSaving: false,
      errors: {},
    };
  }

  componentDidMount() {
    const {
      fetchEmployees,
      fetchLocations,
      date,
      locationId,
      userIds,
      currentUser,
      patientsData,
    } = this.props;

    fetchEmployees();
    fetchLocations();

    this.setState(
      {
        patientsData: patientsData.map((d, ind) => ({ ...d, key: ind })),
        date: date || this.state.date,
        locationId: locationId,
        userIds: userIds.length === 0 ? [currentUser.id] : [...userIds],
      },
      () => {
        if (!!patientsData[0].patientId) {
          this._predictAppointment();
        }
      }
    );
  }

  _errorMessages = () => ({
    appointment_taken: locale().appointments.appointment_taken_error,
    location_taken: locale().appointments.location_taken_error,
    service_item_ids: locale().appointments.events_required,
    user_ids: locale().appointments.users_required,
    already_scheduled: locale().appointments.already_scheduled,
  });

  _hideErrors = () => this.setState({ errors: {} });

  _changeAppointmentDate = date => {
    const { onDateChange } = this.props;
    this.setState({ date });

    onDateChange(date);
  };

  _changePatientsData = patientsData => {
    const currentPatientsData = this.state.patientsData;

    let doPrediction;
    if (
      currentPatientsData[0].patientId !== patientsData[0].patientId &&
      patientsData[0].patientId
    ) {
      doPrediction = this._predictAppointment;
    }
    this.setState({ patientsData }, doPrediction);
  };

  _changeLocation = locationId => {
    const { onLocationChange } = this.props;
    this.setState({ locationId });

    onLocationChange(locationId);
  };

  _changeUsers = userIds => this.setState({ userIds });

  _predictAppointment = () => {
    const { predictAppointment, editingMode } = this.props;
    const { patientsData } = this.state;

    if (editingMode) {
      return;
    }

    predictAppointment({ patient_id: patientsData[0].patientId }).then(
      prediction => {
        this._changeAppointmentDate(prediction.date);
        this._changeLocation(prediction.location_id);
        this._changeUsers(prediction.user_ids);
      }
    );
  };

  _createAppointment = () => {
    const { saveAction, onFinish } = this.props;
    const { date, patientsData, userIds, locationId } = this.state;

    const serviceItemIds = patientsData
      .filter(d => !!d.serviceItemId)
      .map(d => d.serviceItemId);

    this.setState({ isSaving: true, errors: {} });
    saveAction({
      date: date,
      user_ids: userIds,
      location_id: locationId,
      service_item_ids: serviceItemIds,
    })
      .then(() => {
        this.setState({ isSaving: false }, onFinish);
      })
      .catch(response => {
        console.error(response);
        let error = locale().system.global_error;

        if (response.isValidationError && response.isValidationError()) {
          const errors = response.validationErrors().errorPerField();
          const errorMessage = this._errorMessages()[Object.keys(errors)[0]];

          if (errorMessage) {
            error = errorMessage;
          }
        } else if (response.isBadRequest && response.isBadRequest()) {
          const errorData = response.data();
          error = this._errorMessages()[errorData.type];
        }

        this.setState({ errors: { _global: error }, isSaving: false });
      });
  };

  render() {
    const { employees, locations, onFinish } = this.props;
    const {
      date,
      locationId,
      userIds,
      patientsData,
      isSaving,
      errors,
    } = this.state;

    let errorNode;
    if (errors._global) {
      errorNode = (
        <div className={styles['error']}>
          <span>{errors._global}</span>
          <Icon icon={'close'} size={20} onClick={this._hideErrors} />
        </div>
      );
    }

    return (
      <div>
        <PatientsForm
          patientsData={patientsData}
          onChange={this._changePatientsData}
        />
        <div className={styles['item']}>
          <FormRow>
            <FormColumn>
              <DatePicker value={date} onChange={this._changeAppointmentDate} />
            </FormColumn>
          </FormRow>
        </div>
        {locations.length > 0 && (
          <div className={styles['item']}>
            <FormRow>
              <FormColumn>
                <LocationSelectUI
                  value={locationId}
                  locations={locations}
                  onChange={this._changeLocation}
                />
              </FormColumn>
            </FormRow>
          </div>
        )}
        {employees.length > 1 && (
          <div className={styles['item']}>
            <FormRow>
              <FormColumn>
                <MultipleEmployeeSelectUI
                  values={userIds}
                  employees={employees}
                  placeholder={locale().appointments.appointment_user}
                  onChange={this._changeUsers}
                />
              </FormColumn>
            </FormRow>
          </div>
        )}
        {errorNode}
        <div className={styles['actions']}>
          <Button
            value={locale().appointments.schedule_an_appointment}
            onClick={this._createAppointment}
            size={'small'}
            disabled={isSaving}
          />
          <Button
            value={locale().actions.cancel}
            onClick={onFinish}
            actionType={'normal'}
            size={'small'}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
    employees: selectors.getEmployees(state),
    locations: selectors.getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEmployees: () => dispatch(actions.fetchEmployees({ archived: false })),
    fetchLocations: () => dispatch(actions.fetchLocations()),
    predictAppointment: data => dispatch(actions.predictAppointment(data)),
  };
};

AppointmentForm = connect(mapStateToProps, mapDispatchToProps)(AppointmentForm);

export default AppointmentForm;
