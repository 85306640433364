import {
  format,
  formatISO,
  parseISO,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  differenceInDays,
  subMonths,
  subYears,
} from 'date-fns';
import { dateLocale } from 'util/locale';

Date.prototype.format = function (theFormat) {
  return format(this, theFormat, { locale: dateLocale() });
};

Date.prototype.toISOString = function () {
  return formatISO(this);
};

Date.fromISOString = function (str) {
  return parseISO(str);
};

Date.fromString = function (str) {
  return new Date(Date.parse(str));
};

Date.prototype.startOfWork = function () {
  let date = new Date(this.valueOf());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

Date.prototype.endOfWork = function () {
  let date = new Date(this.valueOf());
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  return date;
};

Date.prototype.beginningOfMonth = function () {
  return startOfMonth(this);
};

Date.prototype.endOfMonth = function () {
  return endOfMonth(this);
};

Date.prototype.beginningOfYear = function () {
  return startOfYear(this);
};

Date.prototype.endOfYear = function () {
  return endOfYear(this);
};

Date.prototype.addDays = function (amount) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + amount);
  return date;
};

Date.prototype.addMinutes = function (amount) {
  let date = new Date(this.valueOf());
  date.setMinutes(date.getMinutes() + amount);
  return date;
};

Date.prototype.daysTo = function (date) {
  return differenceInDays(date, this);
};

Date.prototype.isAtSameYearAndMonth = function (date) {
  return (
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth()
  );
};

Date.prototype.isCurrentMonth = function () {
  return this.getMonth() === new Date().getMonth();
};

Date.prototype.isInFuture = function () {
  return this >= new Date();
};

Date.prototype.effectivelySame = function (other) {
  return Math.abs(this.valueOf() - other.valueOf()) < 1000;
};

Date.prototype.subMonths = function (amount) {
  return subMonths(this, amount);
};

Date.prototype.subYears = function (amount) {
  return subYears(this, amount);
};

Date.prototype.getDecimalHours = function () {
  const hours = this.getHours();
  const minutes = this.getMinutes();

  return hours + minutes / 60;
};

Date.formatAppointment = function (startDate, duration) {
  return `${startDate.format('HH:mm')} - ${startDate
    .addMinutes(duration)
    .format('HH:mm')}`;
};

Date.formatMinutes = function (minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes - hours * 60;

  return `${hours > 0 ? `${hours}h ` : ''}${`${minutesLeft}m`}`;
};
