import Response from './../_response';

class PatientReferralsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client
      .fetch('patient_referrals', 'GET')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  create(data) {
    return this._client
      .fetch('patient_referrals', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patient_referrals/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default PatientReferralsEndpoint;
