import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'ui/Form';

class ExpenseTypeSelectUI extends Component {
  static propTypes = {
    value: PropTypes.string,
    expenseTypes: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  _options = () => {
    const { expenseTypes } = this.props;

    return expenseTypes.sortBy('name').map(expenseType => ({
      value: expenseType.id,
      label: expenseType.name,
    }));
  };

  render() {
    const { value, onChange } = this.props;

    return (
      <Select value={value} onChange={onChange} options={this._options()} />
    );
  }
}

export default ExpenseTypeSelectUI;
