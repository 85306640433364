import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import {
  FormRow,
  FormColumn,
  TextInput,
  TextArea,
  GenderSelect,
  FormLabel,
} from 'ui/Form';
import MultiOrgRender from 'ui/MultiOrgRender';
import DatePicker from 'ui/DatePicker';
import DiagnosisSelect from 'patients/DiagnoseSelect';
import PatientReferralSelect from 'patients/PatientReferralSelect';
import EmployeeSelect from 'employees/EmployeeSelect';
import { connect, actions, selectors } from 'util/redux';

class PatientDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.patient },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { currentUser, patient } = this.props;

    if (!prevProps.open && this.props.open) {
      const userIds =
        patient.user_ids.length === 0 ? [currentUser.id] : patient.user_ids;

      this.setState({
        data: { ...this.props.patient, user_ids: userIds },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createPatient, updatePatient, onClose } = this.props;
    const { data } = this.state;

    let save = data.id ? updatePatient : createPatient;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, isSaving, errors } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={data.id ? locale().patients.edit : locale().patients.new}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().patients.name} error={errors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patients.birth_date}
              error={errors.birth_date}
            />
            <DatePicker
              value={data.birth_date}
              onChange={value => this._changeData('birth_date', value)}
              dateOnly={true}
              showMonthYearDropdown={true}
            />
          </FormColumn>
          <FormColumn>
            <FormLabel value={locale().patients.gender} error={errors.gender} />
            <GenderSelect
              value={data.gender}
              onChange={value => this._changeData('gender', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patients.diagnose}
              error={errors.diagnose_id}
            />
            <DiagnosisSelect
              value={data.diagnose_id}
              onChange={value => this._changeData('diagnose_id', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patients.parent_name}
              error={errors.parent_name}
            />
            <TextInput
              value={data.parent_name}
              onChange={value => this._changeData('parent_name', value)}
            />
          </FormColumn>
          <FormColumn>
            <FormLabel
              value={locale().patients.phone_number}
              error={errors.phone_number}
            />
            <TextInput
              value={data.phone_number}
              onChange={value => this._changeData('phone_number', value)}
              type={'number'}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().patients.email} error={errors.email} />
            <TextInput
              value={data.email}
              type={'email'}
              onChange={value => this._changeData('email', value)}
            />
          </FormColumn>
        </FormRow>
        <MultiOrgRender>
          <FormRow>
            <FormColumn>
              <FormLabel
                value={locale().patients.assign_to_who}
                error={errors.user_ids}
              />
              <EmployeeSelect
                multiple={true}
                values={data.user_ids}
                onChange={value => this._changeData('user_ids', value)}
              />
            </FormColumn>
          </FormRow>
        </MultiOrgRender>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patients.patient_referral}
              error={errors.patient_referral_id}
              newFeature={true}
            />
            <PatientReferralSelect
              value={data.patient_referral_id}
              onChange={value => this._changeData('patient_referral_id', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patients.anamnesis}
              error={errors.notes}
            />
            <TextArea
              value={data.notes}
              onChange={value => this._changeData('notes', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingPatient(state),
    patient: selectors.getEditingPatient(state),
    currentUser: selectors.getCurrentUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPatient: data => dispatch(actions.createPatient(data)),
    updatePatient: data => dispatch(actions.updatePatient(data)),
    onClose: () => dispatch(actions.finishPatientEditing()),
  };
};

PatientDialog = connect(mapStateToProps, mapDispatchToProps)(PatientDialog);

export default PatientDialog;
