import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './PatientService.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import { SectionList } from 'ui/SectionList';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import { Card } from 'ui/Card';
import { LinkButton } from 'ui/Button';
import BottomSheet from 'ui/Dialog/BottomSheet';
import Icon from 'ui/Icon';
import Dropdown from 'ui/Dropdown';
import { MoneyText } from 'ui/Money';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import PatientServiceItem from './_PatientServiceItem';
import NewServiceItemDialog from './_NewServiceItemDialog';
import { loadingStates, connect, actions, selectors } from 'util/redux';
import ActionCenter from 'util/action_center';

class PatientService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      confirmationDialogOpen: false,
      bottomSheetOpen: false,
      serviceItemDialogOpen: false,
    };
  }

  componentDidMount() {
    const { fetchOneService, fetchItemsForService } = this.props;
    ActionCenter.subscribe('createServicePayment', this._reloadService);
    ActionCenter.subscribe('destroyServicePayment', this._reloadService);
    ActionCenter.subscribe('createPatientNote', this._reloadServiceItems);
    ActionCenter.subscribe('updatePatientNote', this._reloadServiceItems);
    ActionCenter.subscribe('destroyPatientNote', this._reloadServiceItems);

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([fetchOneService(), fetchItemsForService()])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        if (response.isNotFound && response.isNotFound()) {
          this.setState({ loadingState: loadingStates.missing });
        } else {
          this.setState({ loadingState: loadingStates.failed });
        }
      });
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createServicePayment', this._reloadService);
    ActionCenter.unsubscribe('destroyServicePayment', this._reloadService);
    ActionCenter.unsubscribe('createPatientNote', this._reloadServiceItems);
    ActionCenter.unsubscribe('updatePatientNote', this._reloadServiceItems);
    ActionCenter.unsubscribe('destroyPatientNote', this._reloadServiceItems);
  }

  _moreOptions = () => {
    const { service, editService } = this.props;

    return [
      {
        label: locale().services.edit,
        onClick: () => editService(service),
      },
      {
        label: locale().service_items.add_treatment,
        onClick: this._openServiceItemDialog,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  };

  _reloadService = () => this.props.fetchOneService();

  _reloadServiceItems = () => this.props.fetchItemsForService();

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _openBottomSheet = () => {
    const { loadingState } = this.state;

    // BottomSheet dialog is rendering only when loading finished so it can't be open before
    if (loadingState === loadingStates.present) {
      this.setState({ bottomSheetOpen: true });
    }
  };

  _closeBottomSheet = () => this.setState({ bottomSheetOpen: false });

  _openServiceItemDialog = () => this.setState({ serviceItemDialogOpen: true });

  _closeServiceItemDialog = () =>
    this.setState({ serviceItemDialogOpen: false });

  _destroyService = () => {
    const { destroyService, showPatient, service } = this.props;
    destroyService().then(() => {
      showPatient(service.patient_id);
    });
  };

  _renderItem = item => (
    <PatientServiceItem
      key={item.id}
      patientId={this.props.service.patient_id}
      item={item}
      deletable={this.props.items.length > 1}
    />
  );

  _renderItems = () => {
    const { items } = this.props;

    return (
      <React.Fragment>
        {items
          .filter(i => i.appointment_date)
          .sortBy(i => i.appointment_date)
          .map(this._renderItem)}
        {items
          .filter(i => !i.appointment_date && i.appointments.length > 0)
          .sortBy(i => i.appointments[0].date)
          .map(this._renderItem)}
        {items
          .filter(i => i.appointments.length === 0)
          .sortBy('name')
          .map(this._renderItem)}
      </React.Fragment>
    );
  };

  _mobileHeader = () => {
    const { service } = this.props;
    const title = service ? service.name : '';

    return (
      <HeaderBar
        title={title}
        historyBack={true}
        rightContent={
          <Icon
            icon={'more_vert'}
            color={'#FFFFFF'}
            expand={true}
            onClick={this._openBottomSheet}
          />
        }
      />
    );
  };

  _mobile = () => {
    const { service, newServicePayment } = this.props;
    const { bottomSheetOpen } = this.state;

    return (
      <React.Fragment>
        {service.number_of_treatments > 1 && (
          <SectionList
            leftHeadline={locale().services.activated_on}
            rightHeadline={service.created_at.format('dd.MM.yyyy.')}
          />
        )}
        <SectionList
          leftHeadline={locale().services.payed_amount}
          rightHeadline={
            <div style={{ display: 'flex' }}>
              <MoneyText value={service.price - service.debt} />
              &nbsp;/&nbsp;
              <MoneyText value={service.price} />
            </div>
          }
        >
          {this._renderItems()}
        </SectionList>
        <BottomSheet
          open={bottomSheetOpen}
          onClose={this._closeBottomSheet}
          items={[
            {
              label: locale().service_payments.title,
              onClick: () => newServicePayment(service.id),
            },
            ...this._moreOptions(),
          ]}
        />
      </React.Fragment>
    );
  };

  _desktop = () => {
    const { service, newServicePayment } = this.props;

    const isPackage = service.number_of_treatments > 1;

    return (
      <Card padding={false}>
        <div className={styles['wrapper']}>
          <div className={styles['toolbar']}>
            <div className={styles['group']}>
              <div
                className={styles['backLink']}
                onClick={() => window.history.back()}
              >
                <Icon icon={'arrow_back'} color={'#23B0FF'} size={18} />
                <span>{locale().actions.back}</span>
              </div>
            </div>
            <div className={styles['group']}>
              <Dropdown items={this._moreOptions()} />
            </div>
          </div>
          <div className={styles['serviceHead']}>
            {isPackage && (
              <React.Fragment>
                <div className={styles['name']}>{service.name}</div>
                <div className={styles['meta']}>
                  {locale().services.activated_on}:{' '}
                  {service.created_at.format('dd.MM.yyyy.')}
                </div>
              </React.Fragment>
            )}
            <div className={styles['meta']}>
              <div className={styles['payed']}>
                {locale().services.payed_amount}
                :&nbsp;
                <MoneyText value={service.price - service.debt} />
                &nbsp;/&nbsp;
                <MoneyText value={service.price} />
              </div>
              <LinkButton
                value={locale().service_payments.title}
                onClick={() => newServicePayment(service.id)}
              />
            </div>
          </div>
          {this._renderItems()}
        </div>
      </Card>
    );
  };

  render() {
    const { service } = this.props;
    const {
      loadingState,
      confirmationDialogOpen,
      serviceItemDialogOpen,
    } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (loadingState === loadingStates.missing) {
      bodyNode = <FailureInfo info={locale().services.not_found} />;
    } else {
      bodyNode = (
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
          <ConfirmationDialog
            open={confirmationDialogOpen}
            onClose={this._closeConfirmationDialog}
            onConfirm={this._destroyService}
            title={`${locale().services.destroy_service_head}: ${service.name}`}
            description={locale().services.destroy_service_notice}
          />
          <NewServiceItemDialog
            open={serviceItemDialogOpen}
            onClose={this._closeServiceItemDialog}
            serviceId={service.id}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobileHeader} />
        {bodyNode}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const serviceId = ownProps.match.params.id;

  return {
    service: selectors.getService(state, serviceId),
    items: selectors.getItemsForService(state, serviceId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const serviceId = ownProps.match.params.id;

  return {
    fetchOneService: () => dispatch(actions.fetchOneService(serviceId)),
    fetchItemsForService: () =>
      dispatch(actions.fetchItemsForService(serviceId)),
    editService: service => dispatch(actions.editService(service)),
    destroyService: () => dispatch(actions.destroyService(serviceId)),
    showPatient: id => dispatch(actions.showPatient(id)),
    newServicePayment: serviceId =>
      dispatch(actions.newServicePayment(serviceId)),
  };
};

PatientService = connect(mapStateToProps, mapDispatchToProps)(PatientService);

export default PatientService;
