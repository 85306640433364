import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_Report.module.scss';
import ImagePicker from 'ui/ImagePicker';
import Icon from 'ui/Icon';
import { Button } from 'ui/Button';
import DocumentInput from './../../_Document/_DocumentInput';
import DocumentTextArea from './../../_Document/_DocumentTextArea';

class Report extends Component {
  static propTypes = {
    patientReport: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onUploadLogo: PropTypes.func.isRequired,
    onRemoveLogo: PropTypes.func.isRequired,
    onSectionUpdate: PropTypes.func.isRequired,
    onSectionAdd: PropTypes.func.isRequired,
    onSectionRemove: PropTypes.func.isRequired,
  };

  render() {
    const {
      patientReport,
      onUpdate,
      onUploadLogo,
      onRemoveLogo,
      onSectionUpdate,
      onSectionAdd,
      onSectionRemove,
    } = this.props;

    return (
      <React.Fragment>
        <div className={styles['topSection']}>
          <div className={styles['logo']}>
            <ImagePicker
              onChange={onUploadLogo}
              onRemove={onRemoveLogo}
              placeholder={locale().account.logo}
              url={patientReport.logo_url}
            />
          </div>
          <div className={styles['organisationInfo']}>
            <div className={styles['meta']}>
              <div className={styles['label']}>
                {patientReport.organisation_email ||
                  locale().account.organisation_email}
              </div>
              <Icon icon={'email'} size={18} color={patientReport.color} />
            </div>
            <div className={styles['meta']}>
              <div className={styles['label']}>
                {patientReport.organisation_website ||
                  locale().account.organisation_website}
              </div>
              <Icon icon={'public'} size={18} color={patientReport.color} />
            </div>
            <div className={styles['meta']}>
              <div className={styles['label']}>
                {patientReport.organisation_address ||
                  locale().account.organisation_address}
              </div>
              <Icon
                icon={'location_on'}
                size={18}
                color={patientReport.color}
              />
            </div>
            <div className={styles['meta']}>
              <div className={styles['label']}>
                {patientReport.organisation_phone ||
                  locale().account.organisation_phone}
              </div>
              <Icon icon={'phone'} size={18} color={patientReport.color} />
            </div>
          </div>
        </div>
        <div className={styles['middleSection']}>
          <div className={styles['block']}>
            <div
              className={styles['title']}
              style={{ color: patientReport.color }}
            >
              {patientReport.organisation_name ||
                locale().account.organisation_name}
            </div>
            <div className={styles['info']}>
              {locale().account.company_identifier_short}:{' '}
              {patientReport.company_identifier || '-'}
            </div>
            <div className={styles['info']}>
              {locale().account.tax_number}: {patientReport.tax_number || '-'}
            </div>
            <div className={styles['info']}>{patientReport.user_name}</div>
            <div className={styles['info']}>
              {patientReport.user_title || locale().account.user_title}
            </div>
          </div>
          <div className={classNames(styles['block'], styles['right'])}>
            <div
              className={styles['title']}
              style={{ color: patientReport.color }}
            >
              {patientReport.date.format('dd.MM.yyyy.')}
            </div>
            <div className={styles['info']}>
              {locale().patient_reports.patient_name}:{' '}
              {patientReport.patient_name}
            </div>
            {patientReport.patient_birth_date && (
              <div className={styles['info']}>
                {locale().patient_reports.patient_birth_date}:{' '}
                {patientReport.patient_birth_date.format('dd.MM.yyyy.')}
              </div>
            )}
            <div className={styles['info']}>
              <DocumentInput
                value={patientReport.report_type}
                placeholder={locale().patient_reports.report_type}
                onChange={value => onUpdate('report_type', value)}
                alignRight={true}
              />
            </div>
          </div>
        </div>
        {patientReport.sections.sortBy('ordinal').map(section => (
          <div key={section.ordinal} className={styles['reportSection']}>
            <div className={styles['top']}>
              <div className={styles['title']}>
                <DocumentInput
                  value={section.title}
                  onChange={title =>
                    onSectionUpdate(section.ordinal, 'title', title)
                  }
                  placeholder={`${locale().patient_reports.section_title}...`}
                  kind={'title'}
                  color={patientReport.color}
                />
              </div>
              <div className={styles['remove']}>
                <Icon
                  icon={'close'}
                  size={20}
                  onClick={() => onSectionRemove(section.ordinal)}
                />
              </div>
            </div>
            <div className={styles['content']}>
              <DocumentTextArea
                value={section.content}
                placeholder={`${locale().patient_reports.section_content}...`}
                onChange={content =>
                  onSectionUpdate(section.ordinal, 'content', content)
                }
              />
            </div>
          </div>
        ))}
        <div className={styles['actions']}>
          <Button
            value={locale().patient_reports.add_section}
            onClick={onSectionAdd}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Report;
