import React, { Component } from 'react';
import AutosizeTextArea from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import styles from './TextArea.module.scss';
import classNames from 'classnames';

class TextArea extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    autofocus: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    autofocus: false,
  };

  _onChange = e => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  render() {
    const { value, placeholder, className, onBlur, autofocus } = this.props;

    return (
      <AutosizeTextArea
        className={classNames(styles['textArea'], className)}
        onChange={this._onChange}
        onBlur={onBlur}
        value={value}
        placeholder={placeholder}
        autoFocus={autofocus}
      />
    );
  }
}

export default TextArea;
