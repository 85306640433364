import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import DatePicker from 'ui/DatePicker';
import { connect, actions, selectors } from 'util/redux';

class PatientReportDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.report },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ data: { ...this.props.report }, errors: {} });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createPatientReport, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createPatientReport(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, errors, isSaving } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={locale().patient_reports.new}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_reports.date}
              error={errors.date}
            />
            <DatePicker
              value={data.date}
              onChange={value => this._changeData('date', value)}
              dateOnly={true}
              showMonthYearDropdown={true}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_reports.report_type}
              error={errors.report_type}
            />
            <TextInput
              value={data.report_type}
              onChange={value => this._changeData('report_type', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingPatientReport(state),
    report: selectors.getEditingPatientReport(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPatientReport: data => dispatch(actions.createPatientReport(data)),
    onClose: () => dispatch(actions.finishPatientReportEditing()),
  };
};

PatientReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientReportDialog);

export default PatientReportDialog;
