import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import classNames from 'classnames';
import WithTooltip from 'ui/WithTooltip';

class Button extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['button', 'submit']), // If submit, do not pass onClick
    actionType: PropTypes.oneOf([
      'primary',
      'normal',
      'attention',
      'danger',
      'mobile',
    ]),
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['default', 'small']),
    disabled: PropTypes.bool,
    disabledReason: PropTypes.string,
    fill: PropTypes.bool,
  };

  static defaultProps = {
    type: 'button',
    actionType: 'primary',
    size: 'default',
    disabled: false,
    fill: false,
  };

  render() {
    const {
      value,
      onClick,
      type,
      actionType,
      size,
      disabled,
      disabledReason,
      fill,
    } = this.props;

    const button = (
      <button
        className={classNames(
          styles['button'],
          styles[actionType],
          styles[size],
          {
            [styles['fill']]: fill,
            [styles['disabled']]: disabled,
          }
        )}
        type={type}
        onClick={disabled ? () => {} : onClick}
      >
        {value}
      </button>
    );

    if (disabled && disabledReason) {
      // We wrap it with div because of not-last-child margin
      return (
        <WithTooltip text={disabledReason}>
          <div>{button}</div>
        </WithTooltip>
      );
    } else {
      return button;
    }
  }
}

export default Button;
