import { Component, Children } from 'react';
import PropTypes from 'prop-types';

class MediaQueryWatch extends Component {
  static propTypes = {
    mediaQuery: PropTypes.string.isRequired,
    onMatchesChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._mql = window.matchMedia(props.mediaQuery);
    this.state = {
      isMatched: this._mql.matches,
    };
  }

  _onMqlChange = mql => {
    const { onMatchesChange } = this.props;
    onMatchesChange(mql.matches);
  };

  componentDidMount() {
    this._onMqlChange(this._mql);
    this._mql.addListener(this._onMqlChange);
  }

  componentWillUnmount() {
    this._mql.removeListener(this._onMqlChange);
  }

  render() {
    const { children } = this.props;

    return Children.count(children) > 0 ? Children.only(children) : null;
  }
}

export default MediaQueryWatch;
