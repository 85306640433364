import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextInput, FilePicker } from 'ui/Form';
import DatePicker from 'ui/DatePicker';
import { connect, actions, selectors } from 'util/redux';

class PatientDocumentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.document },
      errors: {},
      progress: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.document },
        errors: {},
        progress: false,
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _onProgress = progress => this.setState({ progress });

  _save = () => {
    const { createDocument, updateDocument, onClose } = this.props;
    const { data } = this.state;

    const action = data.id ? updateDocument : createDocument;

    this.setState({ errors: {}, progress: true });
    action(data, this._onProgress)
      .then(() => {
        this.setState({ progress: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else if (response.isBadRequest && response.isBadRequest()) {
          this.setState({
            errors: {
              _global: locale().account.storage_limit_reached,
            },
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ progress: false });
      });
  };

  _isInEditingMode = () => !!this.state.data.id;

  render() {
    const { onClose, open } = this.props;
    const { data, errors, progress } = this.state;

    return (
      <SidebarDialog
        title={
          this._isInEditingMode()
            ? locale().patient_documents.edit
            : locale().patient_documents.new
        }
        open={open}
        onClose={onClose}
        error={errors._global}
        onSave={this._save}
        inProgress={progress}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_documents.document_title}
              error={errors.title}
            />
            <TextInput
              value={data.title}
              onChange={value => this._changeData('title', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_documents.date}
              error={errors.date}
            />
            <DatePicker
              value={data.date}
              onChange={value => this._changeData('date', value)}
              dateOnly={true}
            />
          </FormColumn>
        </FormRow>
        {!this._isInEditingMode() && (
          <FormRow>
            <FormColumn>
              <FormLabel
                value={locale().patient_documents.file}
                error={errors.file}
              />
              <FilePicker
                placeholder={data.file ? data.file.name : ''}
                onChange={value => this._changeData('file', value)}
              />
            </FormColumn>
          </FormRow>
        )}
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingPatientDocument(state),
    document: selectors.getEditingPatientDocument(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createDocument: (data, onProgress) =>
      dispatch(actions.createPatientDocument(data, onProgress)),
    updateDocument: data => dispatch(actions.updatePatientDocument(data)),
    onClose: () => dispatch(actions.finishPatientDocumentEditing()),
  };
};

PatientDocumentDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientDocumentDialog);

export default PatientDocumentDialog;
