import React, { Component } from 'react';
import { connect, selectors } from 'util/redux';

class MultiOrgRender extends Component {
  render() {
    const { currentUser } = this.props;

    if (currentUser.organisation.multiple_employees_registered) {
      return this.props.children;
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
  };
};

MultiOrgRender = connect(mapStateToProps)(MultiOrgRender);

export default MultiOrgRender;
