import React, { Component } from 'react';
import styles from './FormRow.module.scss';

class FormRow extends Component {
  render() {
    const { children } = this.props;

    return <div className={styles['wrapper']}>{children}</div>;
  }
}

export default FormRow;
