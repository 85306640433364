import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './LinkButton.module.scss';
import classNames from 'classnames';

class LinkButton extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    actionType: PropTypes.oneOf(['default', 'attention']),
  };

  static defaultProps = {
    actionType: 'default',
  };

  render() {
    const { value, onClick, actionType } = this.props;

    return (
      <div
        onClick={onClick}
        className={classNames(styles['wrapper'], styles[actionType])}
      >
        {value}
      </div>
    );
  }
}

export default LinkButton;
