import React, { Component } from 'react';
import locale from 'util/locale';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import FAB from 'ui/FAB';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import { TextInput } from 'ui/Form';
import Icon from 'ui/Icon';
import { Button } from 'ui/Button';
import EmptyState from 'ui/EmptyState';
import PostsList from './_PostsList';
import emptyStateImg from 'media/images/posts/emptyState.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class PostsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      searchQuery: '',
    };
  }

  componentDidMount() {
    const { fetchPosts } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchPosts()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(() => this.setState({ loadingState: loadingStates.failed }));
  }

  _changeSearchQuery = searchQuery => this.setState({ searchQuery });

  _newPost = () => this.props.newPost();

  _posts = () => {
    const { posts } = this.props;
    const searchQuery = this.state.searchQuery.toLowerCase();

    return posts
      .filter(
        post =>
          post.title.toLowerCase().includes(searchQuery) ||
          post.content.toLowerCase().includes(searchQuery) ||
          post.created_by.name.toLowerCase().includes(searchQuery)
      )
      .sortBy('updated_at')
      .reverse();
  };

  _mobileHeader = () => <HeaderBar title={locale().posts.title} />;

  _mobile = () => <FAB onClick={this._newPost} />;

  _desktop = () => (
    <Toolbar>
      <ToolbarGroup>
        <TextInput
          value={this.state.searchQuery}
          onChange={this._changeSearchQuery}
          prefix={<Icon icon={'search'} color={'#949BAC'} size={22} />}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <Button value={locale().posts.new} onClick={this._newPost} />
      </ToolbarGroup>
    </Toolbar>
  );

  render() {
    const { posts } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (posts.length === 0) {
      bodyNode = (
        <EmptyState
          image={emptyStateImg}
          heading={locale().posts.empty_state_heading}
          intro={locale().posts.empty_state_intro}
          actionText={locale().posts.new}
          onClick={this._newPost}
        />
      );
    } else {
      bodyNode = (
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
          <PostsList posts={this._posts()} />
        </React.Fragment>
      );
    }

    return (
      <Navigator title={locale().posts.title} mountedAs={'posts'}>
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobileHeader} />
          {bodyNode}
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = state => {
  return {
    posts: selectors.getPosts(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPosts: () => dispatch(actions.fetchPosts()),
    newPost: () => dispatch(actions.newPost()),
  };
};

PostsOverview = connect(mapStateToProps, mapDispatchToProps)(PostsOverview);

export default PostsOverview;
