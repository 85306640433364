import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CardHeader.module.scss';

class CardHeader extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    const { title } = this.props;

    return <div className={styles['wrapper']}>{title}</div>;
  }
}

export default CardHeader;
