import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PostsListItem from './_PostsListItem';

class PostsList extends Component {
  static propTypes = {
    posts: PropTypes.array.isRequired,
  };

  render() {
    const { posts } = this.props;

    return (
      <React.Fragment>
        {posts.map(post => (
          <PostsListItem key={post.id} post={post} />
        ))}
      </React.Fragment>
    );
  }
}

export default PostsList;
