import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_CalendarResources.module.scss';
import ResourcesGroup from './_ResourcesGroup';

class CalendarResources extends Component {
  static propTypes = {
    resourcesData: PropTypes.arrayOf(
      PropTypes.shape({
        resource: PropTypes.object.isRequired,
        appointments: PropTypes.array.isRequired,
      })
    ).isRequired,
    showResourcesHeader: PropTypes.bool.isRequired,
    startTime: PropTypes.number.isRequired,
    endTime: PropTypes.number.isRequired,
    cellInfo: PropTypes.shape({
      component: PropTypes.object.isRequired,
      itemsGrouped: PropTypes.bool.isRequired,
      nameExtractor: PropTypes.func.isRequired,
      labelExtractor: PropTypes.func.isRequired,
      cellsPerRow: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      margin: PropTypes.number.isRequired,
      time: PropTypes.number.isRequired,
    }).isRequired,
    hoursChunk: PropTypes.number.isRequired,
  };

  _calculateSlots = (startDecimalTime, endDecimalTime) =>
    (endDecimalTime - startDecimalTime) * this.props.hoursChunk;

  _isNotChunkRound = decimalTime => {
    const { hoursChunk } = this.props;

    const res = decimalTime / (1 / hoursChunk);
    return Math.floor(res) !== res;
  };

  _isInChunkRange = (startDecimalTime, decimalTime) =>
    Math.abs(startDecimalTime - decimalTime) < 1 / this.props.hoursChunk / 2;

  _headerProps = () => {
    const { cellInfo } = this.props;

    return {
      width: `${100 / cellInfo.cellsPerRow}%`,
      marginLeft: cellInfo.margin,
      marginRight: cellInfo.margin,
    };
  };

  _cellProps = () => {
    const { cellInfo } = this.props;

    return {
      ...this._headerProps(),
      height: cellInfo.height,
    };
  };

  _renderCell = (resourceData, time) => {
    const { cellInfo } = this.props;

    const appointments = resourceData.appointments.filter(appointment => {
      const startTime = appointment.date.getDecimalHours();

      return (
        startTime === time ||
        (this._isNotChunkRound(startTime) &&
          this._isInChunkRange(startTime, time))
      );
    });

    let groupNode;
    const appointmentsData = appointments.map(appointment => {
      const startTime = appointment.date.getDecimalHours();
      const endTime = appointment.date
        .addMinutes(appointment.duration)
        .getDecimalHours();

      const slots = this._calculateSlots(startTime, endTime);

      return {
        appointment: appointment,
        height: slots * cellInfo.height,
      };
    });

    if (appointmentsData.length > 0) {
      groupNode = (
        <ResourcesGroup
          appointmentsData={appointmentsData}
          itemComponent={cellInfo.component}
          itemLabelExtractor={cellInfo.labelExtractor}
          itemsGrouped={cellInfo.itemsGrouped}
        />
      );
    }

    return (
      <div
        key={resourceData.resource.id}
        className={styles['cell']}
        style={this._cellProps()}
      >
        {groupNode}
      </div>
    );
  };

  render() {
    const {
      resourcesData,
      showResourcesHeader,
      startTime,
      endTime,
      cellInfo,
      hoursChunk,
    } = this.props;

    const times = [];
    for (let i = startTime; i <= endTime; i += 1 / hoursChunk) {
      times.push(i);
    }

    return (
      <React.Fragment>
        <div className={styles['headerContainer']}>
          {showResourcesHeader && (
            <div
              className={styles['header']}
              style={{ height: cellInfo.height + 6 }}
            >
              <div
                className={styles['time']}
                style={{ width: cellInfo.time, minWidth: cellInfo.time }}
              />
              {resourcesData.map(resourceData => (
                <div
                  key={resourceData.resource.id}
                  className={styles['resource']}
                  style={this._headerProps()}
                >
                  {cellInfo.nameExtractor(resourceData.resource)}
                </div>
              ))}
            </div>
          )}
        </div>
        {times.map(time => {
          return (
            <div key={time.toString()} className={styles['row']}>
              <div
                className={styles['time']}
                style={{
                  width: cellInfo.time,
                  minWidth: cellInfo.time,
                  height: cellInfo.height,
                }}
              >
                {String.timeAt(time)}
              </div>
              {resourcesData.map(resourceData =>
                this._renderCell(resourceData, time)
              )}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default CalendarResources;
