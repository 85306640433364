import Api from 'util/api/api';
import {
  entityTypes,
  receiveQuery,
  clearQuery,
  makeGetEntityList,
} from './_entities';

// ACTIONS

const fetchSubscriptionPlans = () => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.subscription_plan));
    return Api.subscriptionPlans.query().then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.subscription_plan, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  fetchSubscriptionPlans,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getSubscriptionPlans = state =>
  getEntityList(state, entityTypes.subscription_plan);

export const selectors = { getSubscriptionPlans };
