import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MultipleSelect } from 'ui/Form';
import EmployeeSelectUI from './EmployeeSelectUI';

class MultipleEmployeeSelectUI extends Component {
  static propTypes = {
    values: PropTypes.array,
    employees: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
  };

  _options = () => {
    const { employees } = this.props;

    return employees.sortBy('name').map(employee => ({
      value: employee.id,
      label: employee.name,
      color: employee.color,
    }));
  };

  render() {
    const { values, onChange, placeholder } = this.props;

    return (
      <MultipleSelect
        values={values}
        onChange={onChange}
        placeholder={placeholder}
        options={this._options()}
        optionRenderer={EmployeeSelectUI.optionRenderer}
      />
    );
  }
}

export default MultipleEmployeeSelectUI;
