import React, { Component } from 'react';
import styles from './SectionList.module.scss';
import PropTypes from 'prop-types';

class SectionList extends Component {
  static propTypes = {
    leftHeadline: PropTypes.node,
    rightHeadline: PropTypes.node,
  };

  static defaultProps = {
    leftHeadline: null,
    rightHeadline: null,
  };

  render() {
    const { leftHeadline, rightHeadline } = this.props;

    let headNode;
    if (leftHeadline || rightHeadline) {
      headNode = (
        <div className={styles['head']}>
          <div>{leftHeadline}</div>
          <div>{rightHeadline}</div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {headNode}
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default SectionList;
