import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PatientServiceItem.module.scss';
import classNames from 'classnames';
import { Button } from 'ui/Button';
import Icon from 'ui/Icon';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import PatientNotesList from 'patients/PatientNotesList';
import { connect, actions } from 'util/redux';

class PatientServiceItem extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    deletable: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      bottomSheetOpen: false,
      confirmationDialogOpen: false,
    };
  }

  _openBottomSheet = () => this.setState({ bottomSheetOpen: true });

  _closeBottomSheet = () => this.setState({ bottomSheetOpen: false });

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _destroyServiceItem = () => {
    const { destroyServiceItem, item } = this.props;
    destroyServiceItem(item.id);
  };

  _scheduleAnAppointment = () => {
    const { openSchedulePage, patientId, item } = this.props;

    openSchedulePage({
      patientId: patientId,
      serviceId: item.service_id,
      serviceItemId: item.id,
    });
  };

  _appointmentNode = (label, appointment, cancelled = false) => {
    const { showAppointment } = this.props;

    return (
      <div key={appointment.id} className={styles['appointment']}>
        {label}: {appointment.date.format('dd.MM.yyyy. HH:mm')}
        <div
          className={classNames(styles['details'], {
            [styles['cancelled']]: cancelled,
          })}
        >
          <span onClick={() => showAppointment(appointment.id)}>
            {locale().appointments.details}
          </span>
        </div>
      </div>
    );
  };

  _appointments = () => {
    const { appointments } = this.props.item;

    // There can be only one active and multiple cancelled
    // But for sake of simplicity, we're using map for both

    return (
      <React.Fragment>
        {appointments
          .filter(a => a.cancelled)
          .sortBy(a => a.date)
          .map(appointment =>
            this._appointmentNode(
              locale().appointments.cancelled,
              appointment,
              true
            )
          )}
        {appointments
          .filter(a => !a.cancelled)
          .map(appointment =>
            this._appointmentNode(
              locale().appointments.scheduled_for,
              appointment
            )
          )}
      </React.Fragment>
    );
  };

  render() {
    const { item, patientId, newPatientNote, deletable } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['title']}>
          <span>{item.name}</span>
          {deletable && (
            <Icon icon={'close'} onClick={this._openConfirmationDialog} />
          )}
        </div>
        {this._appointments()}
        <PatientNotesList notes={item.notes} />
        <div className={styles['actions']}>
          {!item.appointment_date && (
            <Button
              value={locale().appointments.schedule_an_appointment}
              size={'small'}
              onClick={this._scheduleAnAppointment}
            />
          )}
          <Button
            value={locale().patient_notes.add_notes}
            actionType={'normal'}
            size={'small'}
            onClick={() =>
              newPatientNote({
                service_item_id: item.id,
                patient_id: patientId,
              })
            }
          />
        </div>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._destroyServiceItem}
          title={locale().service_items.destroy_service_item_head}
          description={locale().service_items.destroy_service_item_notice}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openSchedulePage: data => dispatch(actions.openSchedulePage(data)),
    showAppointment: id => dispatch(actions.showAppointment(id)),
    destroyServiceItem: id => dispatch(actions.destroyServiceItem(id)),
    newPatientNote: data => dispatch(actions.newPatientNote(data)),
  };
};

PatientServiceItem = connect(null, mapDispatchToProps)(PatientServiceItem);

export default PatientServiceItem;
