import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Notification from 'notifications/Notification';

class NotificationsList extends Component {
  static propTypes = {
    notifications: PropTypes.array.isRequired,
  };

  render() {
    const { notifications } = this.props;

    return (
      <React.Fragment>
        {notifications.map(notification => (
          <Notification
            key={notification.id}
            notification={notification}
            closeMenu={() => {}}
          />
        ))}
      </React.Fragment>
    );
  }
}

export default NotificationsList;
