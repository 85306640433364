import Response from '../_response';
import { MeSerializer } from '../_serializers';

class UserEndpoint {
  constructor(client) {
    this._client = client;
  }

  register(data) {
    return this._client
      .fetch('registrations', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  login(email, password) {
    return this._client
      .fetch('logins', 'POST', { email, password })
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  logout() {
    return this._client.fetch('logins', 'DELETE').then(({ response, json }) => {
      return new Response(response, json);
    });
  }

  get() {
    return this._client.fetch('me', 'GET').then(({ response, json }) => {
      return new Response(response, MeSerializer.deserialize(json));
    });
  }

  update(data) {
    return this._client
      .fetch('me', 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, MeSerializer.deserialize(json));
      });
  }

  updateCurrentOrganisation(data) {
    return this._client
      .fetch('organisations/current', 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  createOrganisationLogo(data, onProgress) {
    return this._client
      .fetch('organisations/logo', 'POST', data, onProgress)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  destroyOrganisationLogo() {
    return this._client
      .fetch('organisations/logo', 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  changePassword(data) {
    return this._client
      .fetch('passwords', 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  sendResetPasswordInstructions(email) {
    return this._client
      .fetch('passwords/instructions', 'POST', { email })
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }

  resetPassword(data) {
    return this._client
      .fetch('passwords/reset', 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, json);
      });
  }
}

export default UserEndpoint;
