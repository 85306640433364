import Response from './../_response';
import {
  MoneyAmountSerializer,
  DateSerializer,
  createResourceSerializer,
} from './../_serializers';

const Serializer = createResourceSerializer({
  date: DateSerializer,
  amount: MoneyAmountSerializer,
});

class ExpensesEndpoint {
  constructor(client) {
    this._client = client;
  }

  query(data = {}) {
    let query = { ...data };
    if (data.from_date && data.to_date) {
      query['from_date'] = DateSerializer.serialize(data.from_date);
      query['to_date'] = DateSerializer.serialize(data.to_date);
    }

    return this._client
      .fetch('expenses', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(expense =>
            Serializer.deserialize(expense)
          ),
        });
      });
  }

  create(data) {
    return this._client
      .fetch('expenses', 'POST', Serializer.serialize(data))
      .then(({ response, json }) => {
        return ExpensesEndpoint._buildResponse(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(`expenses/${data.id}`, 'PATCH', Serializer.serialize(data))
      .then(({ response, json }) => {
        return ExpensesEndpoint._buildResponse(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`expenses/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return ExpensesEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = Serializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default ExpensesEndpoint;
