import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './PackageDialog.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { SectionList, SectionListItem } from 'ui/SectionList';
import { FormRow, FormColumn, TextInput, FormLabel } from 'ui/Form';
import { MoneyInput, MoneyText } from 'ui/Money';
import { EllipsisText } from 'ui/Text';
import Icon from 'ui/Icon';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class PackageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.package_treatment },
      errors: {},
      isSaving: false,
      loadingState: loadingStates.fetching,
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchTreatments } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        loadingState: loadingStates.fetching,
        data: { ...this.props.package_treatment },
        errors: {},
      });

      fetchTreatments()
        .then(() => this.setState({ loadingState: loadingStates.present }))
        .catch(response => {
          console.error(response);
          this.setState({ loadingState: loadingStates.failed });
        });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _includeTreatment = treatmentId => {
    const { data } = this.state;
    let items = [...data.items];
    let item = items.find(i => i.treatment_id === treatmentId);
    if (item) {
      item.amount += 1;
    } else {
      items.push({
        treatment_id: treatmentId,
        amount: 1,
      });
    }
    this._changeData('items', items);
  };

  _removeTreatments = treatmentId => {
    const { data } = this.state;
    let items = [...data.items];
    let item = items.find(i => i.treatment_id === treatmentId);
    if (item) {
      item.amount -= 1;
      if (item.amount === 0) {
        items = items.remove(item);
      }
      this._changeData('items', items);
    }
  };

  _save = () => {
    const { createPackage, updatePackage, onClose } = this.props;
    const { data } = this.state;

    let save = data.id ? updatePackage : createPackage;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _treatmentsHeaderNode = () => (
    <FormLabel
      value={locale().packages.treatments_included}
      error={
        this.state.errors.items
          ? locale().packages.include_treatments_error
          : null
      }
    />
  );

  includedTreatmentsCostNode = () => {
    const { treatments } = this.props;
    const { data } = this.state;

    let cost = data.items.reduce((total, item) => {
      let treatment = treatments.find(t => t.id === item.treatment_id);
      return total + item.amount * treatment.price;
    }, 0);

    return <MoneyText value={cost} />;
  };

  _numberOfIncludedTreatmentsLabel = (number, price) => (
    <div className={styles['includesAmount']}>
      <span>{number} x&nbsp;</span>
      <MoneyText value={price} />
    </div>
  );

  _treatments = () => this.props.treatments.sortBy('name');

  _mobile = () => {
    const { data } = this.state;

    return (
      <SectionList
        leftHeadline={this._treatmentsHeaderNode()}
        rightHeadline={this.includedTreatmentsCostNode()}
      >
        {this._treatments().map(treatment => {
          let includedTreatment = data.items.find(
            item => item.treatment_id === treatment.id
          );
          let numberOfIncludedTreatments = includedTreatment
            ? includedTreatment.amount
            : 0;

          return (
            <SectionListItem
              key={treatment.id}
              title={treatment.name}
              extra={this._numberOfIncludedTreatmentsLabel(
                numberOfIncludedTreatments,
                treatment.price
              )}
              extraColor={includedTreatment ? '#46AC5A' : '#1A1C1F'}
              rightContent={
                <div className={styles['addActions']}>
                  <Icon
                    icon={'add'}
                    size={32}
                    expand={true}
                    onClick={() => this._includeTreatment(treatment.id)}
                  />
                  <Icon
                    icon={'remove'}
                    size={32}
                    expand={true}
                    onClick={() => this._removeTreatments(treatment.id)}
                  />
                </div>
              }
            />
          );
        })}
      </SectionList>
    );
  };

  _desktop = () => {
    const { data } = this.state;

    return (
      <div className={styles['treatments']}>
        <div className={styles['header']}>
          {this._treatmentsHeaderNode()}
          {this.includedTreatmentsCostNode()}
        </div>
        {this._treatments().map(treatment => {
          let includedTreatment = data.items.find(
            item => item.treatment_id === treatment.id
          );
          let numberOfIncludedTreatments = includedTreatment
            ? includedTreatment.amount
            : 0;

          return (
            <div key={treatment.id} className={styles['treatment']}>
              <div className={styles['name']}>
                <EllipsisText>{treatment.name}</EllipsisText>
              </div>
              <div
                className={classNames(styles['includes'], {
                  [styles['included']]: numberOfIncludedTreatments > 0,
                })}
              >
                {this._numberOfIncludedTreatmentsLabel(
                  numberOfIncludedTreatments,
                  treatment.price
                )}
                <div className={styles['actions']}>
                  <Icon
                    icon={'add'}
                    onClick={() => this._includeTreatment(treatment.id)}
                  />
                  <Icon
                    icon={'remove'}
                    onClick={() => this._removeTreatments(treatment.id)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { open, onClose } = this.props;
    const { data, errors, isSaving, loadingState } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={data.id ? locale().packages.edit : locale().packages.new}
        inProgress={isSaving}
        padding={false}
        error={errors._global}
        loadingState={loadingState}
        onSave={this._save}
        footer={
          <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        }
      >
        <div className={styles['form']}>
          <FormRow>
            <FormColumn>
              <FormLabel value={locale().packages.name} error={errors.name} />
              <TextInput
                value={data.name}
                onChange={value => this._changeData('name', value)}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <FormLabel value={locale().packages.price} error={errors.price} />
              <MoneyInput
                value={data.price}
                onChange={value => this._changeData('price', value)}
              />
            </FormColumn>
          </FormRow>
        </div>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingPackage(state),
    package_treatment: selectors.getEditingPackage(state),
    treatments: selectors.getTreatments(state), // They are not being fetched in this component but in ServicesOverview
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => dispatch(actions.finishPackageEditing()),
    fetchTreatments: () => dispatch(actions.fetchTreatments()),
    createPackage: data => dispatch(actions.createPackage(data)),
    updatePackage: data => dispatch(actions.updatePackage(data)),
  };
};

PackageDialog = connect(mapStateToProps, mapDispatchToProps)(PackageDialog);

export default PackageDialog;
