import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_ServicesListItem.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionListItem } from 'ui/SectionList';
import { CardRow } from 'ui/Card';
import { MoneyText } from 'ui/Money';
import { Checkbox } from 'ui/Form';
import Icon from 'ui/Icon';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions, selectors } from 'util/redux';

class ServicesListItem extends Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _destroyService = () => {
    const { destroyService, service } = this.props;

    destroyService(service.id);
  };

  _moreOptions = () => {
    const { service, newServicePayment } = this.props;

    return [
      {
        label: locale().service_payments.title,
        onClick: () => newServicePayment(service.id),
      },
      {
        label: locale().actions.destroy,
        onClick: () => this._openConfirmationDialog(service.id),
        type: 'attention',
      },
    ];
  };

  _isPackage = () => this.props.service.number_of_treatments > 1;

  _isEverythingScheduled = () =>
    this.props.service.number_of_treatments ===
    this.props.service.number_of_active_appointments;

  _scheduledText = () => {
    const { service } = this.props;

    if (this._isPackage()) {
      return locale().services.appointments_scheduled(
        service.number_of_active_appointments,
        service.number_of_treatments
      );
    } else if (service.last_appointment_cancelled) {
      return locale().appointments.cancelled;
    } else if (!this._isEverythingScheduled()) {
      return locale().appointments.not_scheduled;
    }

    return undefined;
  };

  _debtNode = () => {
    const { service } = this.props;

    if (service.debt > 0) {
      return (
        <div className={styles['debt']}>
          <MoneyText value={service.debt} />
        </div>
      );
    } else {
      return null;
    }
  };

  _mobile = () => {
    const { showService, service } = this.props;

    return (
      <SectionListItem
        title={service.name}
        extra={this._scheduledText()}
        extraColor={this._isEverythingScheduled() ? undefined : '#d82b2b'}
        rightContent={service.effectively_active_from.format('dd.MM.yyyy.')}
        extraRightContent={this._debtNode()}
        contextMenuItems={this._moreOptions()}
        onClick={() => showService(service.id)}
      />
    );
  };

  _desktop = () => {
    const {
      showService,
      showPatientInvoice,
      service,
      serviceIdsForInvoicing,
      toggleServiceForInvoicing,
    } = this.props;

    return (
      <CardRow
        onClick={() => showService(service.id)}
        contextMenuItems={this._moreOptions()}
      >
        <div className={styles['service']}>
          <div className={styles['left']}>
            <div className={styles['name']}>
              {service.invoiced && (
                <div className={styles['invoiced']}>
                  <Icon icon={'receipt_long'} size={18} />
                </div>
              )}
              {!service.invoiced && (
                <Checkbox
                  onChange={() => toggleServiceForInvoicing(service.id)}
                  value={serviceIdsForInvoicing.includes(service.id)}
                />
              )}
              <span>{service.name}</span>
            </div>
            <div
              className={classNames(styles['scheduled'], {
                [styles['not']]: !this._isEverythingScheduled(),
              })}
            >
              {this._scheduledText()}
            </div>
          </div>
          <div className={styles['right']}>
            {this._debtNode()}
            {service.patient_invoice && (
              <div
                className={styles['invoice']}
                onClick={e => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();

                  showPatientInvoice(service.patient_invoice.id);
                }}
              >
                {service.patient_invoice.number}
              </div>
            )}
            <div className={styles['date']}>
              {service.effectively_active_from.format('dd.MM.yyyy.')}
            </div>
          </div>
        </div>
      </CardRow>
    );
  };

  render() {
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._destroyService}
          title={locale().system.wait_notice}
          description={locale().services.destroy_service_notice}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    serviceIdsForInvoicing: selectors.getSelectedServiceIdsForInvoicing(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showService: id => dispatch(actions.showService(id)),
    showPatientInvoice: invoiceId =>
      dispatch(actions.showPatientInvoice(invoiceId)),
    newServicePayment: serviceId =>
      dispatch(actions.newServicePayment(serviceId)),
    destroyService: id => dispatch(actions.destroyService(id)),
    toggleServiceForInvoicing: id =>
      dispatch(actions.toggleServiceForInvoicing(id)),
  };
};

ServicesListItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesListItem);

export default ServicesListItem;
