class ActionCenter {
  constructor() {
    this._actions = {};
  }

  subscribe = (actionId, callback) => {
    let callbackList = this._actions[actionId] || [];
    callbackList = [...callbackList, callback];
    this._actions[actionId] = callbackList;
  };

  unsubscribe = (actionId, callback) => {
    let callbackList = this._actions[actionId];
    if (!callbackList) {
      throw new Error(
        `Calling unsubscribe for ${actionId} but subscribe hasn't been called`
      );
    }
    this._actions[actionId] = callbackList.remove(callback);
  };

  publish = (actionId, ...params) => {
    (this._actions[actionId] || []).forEach(callback => callback(...params));
  };
}

export default new ActionCenter();
