import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { push } from 'connected-react-router';
import {
  entityTypes,
  clearQuery,
  receiveQuery,
  receiveEntities,
  makeGetEntityList,
} from './_entities';

const START_EMPLOYEE_EDITING = 'START_EMPLOYEE_EDITING';
const FINISH_EMPLOYEE_EDITING = 'FINISH_EMPLOYEE_EDITING';

export default (
  state = {
    editingEmployee: emptyEmployeeData(),
    employeeDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_EMPLOYEE_EDITING:
      return {
        ...state,
        editingEmployee: action.employee,
        employeeDialogOpen: true,
      };
    case FINISH_EMPLOYEE_EDITING:
      return {
        ...state,
        editingEmployee: emptyEmployeeData(),
        employeeDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const openEmployeesPage = () => push('/employees');

const emptyEmployeeData = () => ({
  name: '',
  email: '',
  title: '',
});

const editEmployee = employee => {
  return {
    type: START_EMPLOYEE_EDITING,
    employee,
  };
};

const finishEmployeeEditing = () => {
  return {
    type: FINISH_EMPLOYEE_EDITING,
  };
};

const fetchEmployees = data => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.employee));
    return Api.employees.query(data).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.employee, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateEmployee = data => {
  return dispatch => {
    return Api.employees.update(data).then(response => {
      if (response.isOk()) {
        const employee = response.data();
        dispatch(receiveEntities(entityTypes.employee, [employee]));
        dispatch(systemActions.showToast(locale().employees.employee_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const archiveEmployee = id => {
  return dispatch => {
    return Api.employees.archive(id).then(response => {
      if (response.isOk()) {
        const employee = response.data();
        dispatch(receiveEntities(entityTypes.employee, [employee]));
        dispatch(systemActions.showToast(locale().employees.employee_archived));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const activateEmployee = id => {
  return dispatch => {
    return Api.employees.activate(id).then(response => {
      if (response.isOk()) {
        const employee = response.data();
        dispatch(receiveEntities(entityTypes.employee, [employee]));
        dispatch(
          systemActions.showToast(locale().employees.employee_activated)
        );
      } else {
        const errorMessages = {
          subscription_exceeded: locale().employees
            .subscription_exceeded_activate_user_error,
          activation_freeze_period: locale().employees
            .activation_freeze_period_error,
        };
        dispatch(
          systemActions.toastError(
            errorMessages[response.data().type] || locale().system.global_error
          )
        );

        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openEmployeesPage,
  editEmployee,
  finishEmployeeEditing,
  fetchEmployees,
  updateEmployee,
  archiveEmployee,
  activateEmployee,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getEmployees = state => getEntityList(state, entityTypes.employee);

const getEditingEmployee = state => state.editingEmployee;

const isEditingEmployee = state => state.employeeDialogOpen;

export const selectors = {
  getEmployees,
  getEditingEmployee,
  isEditingEmployee,
};
