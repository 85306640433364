import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_NotesList.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from './../_EmptyState';
import PatientNotesList from 'patients/PatientNotesList';
import ProgressChart from './_ProgressChart';
import notesImg from 'media/images/patients/notes.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class NotesList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchNotesForPatient } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchNotesForPatient()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  render() {
    const { notes } = this.props;
    const { loadingState } = this.state;

    const notesWithProgressRate = notes.filter(n => !!n.progress_rate);

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (notes.length === 0) {
      bodyNode = (
        <EmptyState
          intro={locale().patient_notes.empty_state_heading}
          image={notesImg}
        />
      );
    } else {
      bodyNode = (
        <div className={styles['wrapper']}>
          {notesWithProgressRate.length > 1 && (
            <div className={styles['chart']}>
              <ProgressChart notes={notesWithProgressRate} />
            </div>
          )}
          <div className={styles['notes']}>
            <PatientNotesList notes={notes} />
          </div>
        </div>
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    notes: selectors.getNotesForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    fetchNotesForPatient: () =>
      dispatch(actions.fetchNotesForPatient(patientId)),
  };
};

NotesList = connect(mapStateToProps, mapDispatchToProps)(NotesList);

export default NotesList;
