import React, { Component } from 'react';
import locale from 'util/locale';
import supportedPaymentTypes from 'util/supported_payment_types';
import PropTypes from 'prop-types';
import styles from './_ProfitAndLossReport.module.scss';
import { SectionList } from 'ui/SectionList';
import { Card } from 'ui/Card';
import Doughnut from 'ui/Chart/Doughnut';
import { MoneyText } from 'ui/Money';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class ProfitAndLossReport extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
  };

  _items = () => {
    const { report } = this.props;

    return [
      {
        name: 'income_report',
        title: locale().finances.income_report_title,
        total: report.income_report.total,
        chartStats: report.income_report.amount_per_type.map(elem => ({
          label: supportedPaymentTypes()[elem.type].label,
          amount: elem.amount,
        })),
      },
      {
        name: 'expense_report',
        title: locale().finances.expense_report_title,
        total: report.expense_report.total,
        chartStats: report.expense_report.amount_per_type.map(elem => ({
          label: elem.type,
          amount: elem.amount,
        })),
      },
    ];
  };

  _headline = () => {
    const { report } = this.props;
    const format = 'dd. MMM yyyy.';

    return `${report.from_date.format(format)} - ${report.to_date.format(
      format
    )} `;
  };

  _body = () => {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['period']}>{this._headline()}</div>
        {this._items().map(item => (
          <React.Fragment key={item.name}>
            <div className={styles['title']}>
              <span>{item.title}</span>
            </div>
            <div className={styles['report']}>
              <div className={styles['table']}>
                {item.chartStats
                  .sortBy('amount')
                  .reverse()
                  .map((elem, i) => (
                    <div key={i} className={styles['row']}>
                      <div className={styles['label']}>{elem.label}</div>
                      <div className={styles['amount']}>
                        <MoneyText value={elem.amount} />
                      </div>
                    </div>
                  ))}
                <div className={styles['total']}>
                  <MoneyText value={item.total} />
                </div>
              </div>
              <div className={styles['chart']}>
                <Doughnut
                  stats={item.chartStats}
                  labelsOverride={locale().finances.chart_label}
                />
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  _mobile = () => (
    <SectionList leftHeadline={this._headline()}>{this._body()}</SectionList>
  );

  _desktop = () => <Card>{this._body()}</Card>;

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default ProfitAndLossReport;
