import locale from 'util/locale';

const defaultRanges = () => [
  {
    label: locale().dates.today,
    value: {
      from_date: new Date().startOfWork(),
      to_date: new Date().endOfWork(),
    },
  },
  {
    label: locale().dates.week,
    value: {
      from_date: new Date().startOfWork(),
      to_date: new Date().endOfWork().addDays(7),
    },
  },
  {
    label: locale().dates.month,
    value: {
      from_date: new Date().startOfWork(),
      to_date: new Date().endOfWork().addDays(30),
    },
  },
  {
    label: locale().dates.custom,
    value: null,
  },
];

const periodRanges = () => [
  {
    label: locale().dates.current_month,
    value: {
      from_date: new Date().beginningOfMonth(),
      to_date: new Date().endOfMonth(),
    },
  },
  {
    label: locale().dates.last_month,
    value: {
      from_date: new Date().subMonths(1).beginningOfMonth(),
      to_date: new Date().subMonths(1).endOfMonth(),
    },
  },
  {
    label: locale().dates.current_year,
    value: {
      from_date: new Date().beginningOfYear(),
      to_date: new Date().endOfYear(),
    },
  },
  {
    label: locale().dates.last_year,
    value: {
      from_date: new Date().subYears(1).beginningOfYear(),
      to_date: new Date().subYears(1).endOfYear(),
    },
  },
];

export { defaultRanges, periodRanges };
