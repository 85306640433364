import React, { Component } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_Accordion.module.scss';
import Icon from 'ui/Icon';
import { connect, actions, selectors } from 'util/redux';

class Accordion extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        extra: PropTypes.node,
        content: PropTypes.node.isRequired,
      })
    ),
    firstOpened: PropTypes.bool,
    titleClassName: PropTypes.string,
  };

  static defaultProps = {
    firstOpened: false,
  };

  _toggle = id => {
    const { expandedItemIds, updateUrlParams, currentQuery } = this.props;
    let newItemIds;

    if (expandedItemIds.includes(id)) {
      newItemIds = expandedItemIds.remove(id);
    } else {
      newItemIds = [...expandedItemIds, id];
    }

    updateUrlParams({ ...currentQuery, expanded: newItemIds });
  };

  render() {
    const { items, firstOpened, titleClassName, expandedItemIds } = this.props;

    return (
      <React.Fragment>
        {items.map((item, i) => {
          const expanded = expandedItemIds.includes(item.id);
          const alwaysOpened = firstOpened && i === 0;

          return (
            <div key={item.id} className={styles['item']}>
              {alwaysOpened && item.content}
              {!alwaysOpened && (
                <div className={classNames(styles['head'], titleClassName)}>
                  <div
                    className={styles['title']}
                    onClick={() => this._toggle(item.id)}
                  >
                    <span>{item.title}</span>
                    {!expanded && (
                      <Icon icon={'keyboard_arrow_right'} color={'#949bac'} />
                    )}
                    {expanded && (
                      <Icon icon={'keyboard_arrow_down'} color={'#949bac'} />
                    )}
                  </div>
                  {item.extra}
                </div>
              )}
              {expanded && item.content}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const rawQuery = qs.parse(selectors.getSearchParams(state));

  return {
    currentQuery: rawQuery,
    expandedItemIds: rawQuery.expanded || [],
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateUrlParams: urlParams => dispatch(actions.updateUrlParams(urlParams)),
  };
};

Accordion = connect(mapStateToProps, mapDispatchToProps)(Accordion);

export default Accordion;
