import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'ui/Form';
import { formatMoney } from 'util/helpers';
import { connect, selectors } from 'util/redux';

const hideStyle = { position: 'fixed', top: '-10000px', left: '-10000px' };

class MoneyInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      innerValue: props.value || 0,
      focused: false,
    };
  }

  _onChange = value => {
    const { onChange } = this.props;
    this.setState({ innerValue: value });
    onChange(parseFloat(value || 0).toFixed(2));
  };

  _onFocus = () => {
    this.setState({ focused: true });
    setTimeout(() => this._ctrl.select(), 10);
  };

  _onBlur = () => {
    const { innerValue } = this.state;
    this.setState({ focused: false });

    if (!innerValue) {
      this.setState({ innerValue: 0 });
    }
  };

  render() {
    const { currentUser } = this.props;
    const { innerValue, focused } = this.state;

    return (
      <React.Fragment>
        <div style={focused ? hideStyle : null}>
          <TextInput
            value={formatMoney(
              parseFloat(innerValue).toFixed(2),
              currentUser.organisation.currency
            )}
            onChange={() => {}}
            onFocus={this._onFocus}
            type={'text'}
          />
        </div>
        <div style={focused ? null : hideStyle}>
          <TextInput
            value={innerValue}
            onChange={this._onChange}
            onBlur={this._onBlur}
            type={'number'}
            provideController={ctrl => (this._ctrl = ctrl)}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
  };
};

MoneyInput = connect(mapStateToProps)(MoneyInput);

export default MoneyInput;
