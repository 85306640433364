import Response from '../_response';
import {
  ServiceSerializer,
  ServiceItemSerializer,
  ServicePaymentSerializer,
} from './../_serializers';

class ServicesEndpoint {
  constructor(client) {
    this._client = client;
  }

  create(data) {
    return this._client
      .fetch('services', 'POST', data)
      .then(({ response, json }) => {
        return ServicesEndpoint._buildResponse(response, json);
      });
  }

  get(id) {
    return this._client
      .fetch(`services/${id}`, 'GET')
      .then(({ response, json }) => {
        return ServicesEndpoint._buildResponse(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(`services/${data.id}`, 'PATCH', ServiceSerializer.serialize(data))
      .then(({ response, json }) => {
        return ServicesEndpoint._buildResponse(response, json);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`services/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, ServiceSerializer.deserialize(json));
      });
  }

  items(id) {
    return this._client
      .fetch(`services/${id}/items`, 'GET')
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(item =>
              ServiceItemSerializer.deserialize(item)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  payments(id) {
    return this._client
      .fetch(`services/${id}/payments`, 'GET')
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(payment =>
              ServicePaymentSerializer.deserialize(payment)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = ServiceSerializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default ServicesEndpoint;
