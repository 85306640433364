import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectNBottomSheet } from 'ui/Form';

class DiagnoseSelectUI extends Component {
  static propTypes = {
    diagnoses: PropTypes.array.isRequired,
  };

  _options = () => {
    const { diagnoses } = this.props;

    return diagnoses.sortBy('name').map(diagnose => ({
      value: diagnose.id,
      label: diagnose.name,
    }));
  };

  render() {
    return <SelectNBottomSheet options={this._options()} {...this.props} />;
  }
}

export default DiagnoseSelectUI;
