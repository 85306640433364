import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_ExpensesListItem.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { CardRow } from 'ui/Card';
import { SectionListItem } from 'ui/SectionList';
import { MoneyText } from 'ui/Money';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class ExpensesListItem extends Component {
  static propTypes = {
    expense: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._contextMenuItems = [
      {
        label: locale().actions.edit,
        onClick: this._editExpense,
      },
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editExpense = () => this.props.editExpense(this.props.expense);

  _expenseDate = () => this.props.expense.date.format('dd.MM.yyyy');

  _mobile = () => {
    const { expense } = this.props;

    return (
      <SectionListItem
        title={this._expenseDate()}
        extra={expense.expense_type.name}
        extraColor={'#46ac5a'}
        rightContent={<MoneyText value={expense.amount} />}
        contextMenuItems={this._contextMenuItems}
      />
    );
  };

  _desktop = () => {
    const { expense } = this.props;

    return (
      <CardRow rowSize={'small'} contextMenuItems={this._contextMenuItems}>
        <div className={styles['wrapper']}>
          <div className={styles['left']}>
            <div>{expense.expense_type.name}</div>
            <div className={styles['date']}> {this._expenseDate()}</div>
          </div>
          {expense.description && (
            <div className={styles['middle']}>
              <div className={styles['circle']} />
              <div className={styles['description']}>{expense.description}</div>
            </div>
          )}
          <div className={styles['amount']}>
            <MoneyText value={expense.amount} />
          </div>
        </div>
      </CardRow>
    );
  };

  render() {
    const { destroyExpense } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={destroyExpense}
          title={locale().expenses.destroy_expense_head}
          description={locale().expenses.destroy_expense_notice}
        />
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.expense.id;

  return {
    destroyExpense: () => dispatch(actions.destroyExpense(id)),
    editExpense: expense => dispatch(actions.editExpense(expense)),
  };
};

ExpensesListItem = connect(null, mapDispatchToProps)(ExpensesListItem);

export default ExpensesListItem;
