import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { Card } from 'ui/Card';
import { SectionList } from 'ui/SectionList';
import TreatmentsListItem from './_TreatmentsListItem';

class TreatmentsList extends Component {
  static propTypes = {
    treatments: PropTypes.array.isRequired,
  };

  _body = () => (
    <React.Fragment>
      {this.props.treatments.sortBy('name').map(treatment => (
        <TreatmentsListItem key={treatment.id} treatment={treatment} />
      ))}
    </React.Fragment>
  );

  _mobile = () => (
    <SectionList leftHeadline={locale().treatments.title}>
      {this._body()}
    </SectionList>
  );

  _desktop = () => (
    <Card leftHeadline={locale().treatments.title}>{this._body()}</Card>
  );

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default TreatmentsList;
