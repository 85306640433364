import React, { Component } from 'react';
import ExpenseTypeSelectUI from './ExpenseTypeSelectUI';
import { connect, actions, selectors } from 'util/redux';

class ExpenseTypeSelect extends Component {
  componentDidMount() {
    const { fetchExpenseTypes } = this.props;
    fetchExpenseTypes();
  }

  render() {
    return <ExpenseTypeSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    expenseTypes: selectors.getExpenseTypes(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchExpenseTypes: () => dispatch(actions.fetchExpenseTypes()),
  };
};

ExpenseTypeSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseTypeSelect);

export default ExpenseTypeSelect;
