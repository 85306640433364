import Api from 'util/api/api';

import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  makeGetEntityList,
  getEntityById,
  clearQuery,
} from './_entities';

// ACTIONS

const fetchWorkingStats = data => {
  return dispatch => {
    return Api.userStats.getWorkingStats(data).then(response => {
      dispatch(clearQuery(entityTypes.working_stats));

      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.working_stats, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const fetchOneWorkingStats = (userId, data) => {
  return dispatch => {
    return Api.userStats.getWorkingStatsForUser(userId, data).then(response => {
      if (response.isOk()) {
        const oneWorkingStats = response.data();
        dispatch(receiveEntities(entityTypes.working_stats, [oneWorkingStats]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  fetchWorkingStats,
  fetchOneWorkingStats,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getWorkingStats = state =>
  getEntityList(state, entityTypes.working_stats);

const getOneWorkingStats = (state, id) =>
  getEntityById(state, entityTypes.working_stats, id);

export const selectors = {
  getWorkingStats,
  getOneWorkingStats,
};
