import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './CurrencySelect.module.scss';
import { Select } from 'ui/Form';
import supportedCurrencies from 'util/supported_currencies';

class CurrencySelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static optionRenderer = option => (
    <div className={styles['currency']}>
      <div className={styles['icon']}>{option.icon}</div>
      <div>{option.label}</div>
    </div>
  );

  render() {
    const { value, onChange } = this.props;
    const currencies = supportedCurrencies();

    return (
      <Select
        value={value}
        onChange={onChange}
        clearable={false}
        options={Object.keys(currencies)
          .sortBy(key => currencies[key].label)
          .map(key => {
            const currency = currencies[key];

            return {
              icon: currency.icon,
              label: currency.label,
              value: currency.value,
            };
          })}
        optionRenderer={CurrencySelect.optionRenderer}
      />
    );
  }
}

export default CurrencySelect;
