import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './RadioButton.module.scss';
import classNames from 'classnames';

class RadioButton extends Component {
  static propTypes = {
    value: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const { value, label, onClick } = this.props;

    return (
      <div className={styles['withLabel']} onClick={onClick}>
        <div className={styles['wrapper']}>
          <div
            className={classNames(styles['inner'], {
              [styles['checked']]: value,
            })}
          />
        </div>
        {label}
      </div>
    );
  }
}

export default RadioButton;
