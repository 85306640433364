import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_InvitationDialog.module.scss';
import CenterDialog from 'ui/Dialog/CenterDialog';
import { Button, LinkButton } from 'ui/Button';
import { Form, FormRow, FormColumn, TextInput, FormLabel } from 'ui/Form';
import { connect, actions } from 'util/redux';

class InvitationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      data: {
        email: '',
      },
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: {
          email: '',
        },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _send = () => {
    const { createInvitation, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createInvitation(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else if (response.isBadRequest && response.isBadRequest()) {
          const type = response.data().type;

          let error;
          if (type === 'invitation_exists') {
            error = locale().employees.invitation_exists_error;
          } else if (type === 'subscription_exceeded') {
            error = locale().employees.subscription_exceeded_error;
          } else {
            error = locale().system.global_error;
          }
          this.setState({
            errors: {
              _global: error,
            },
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, isSaving, errors } = this.state;

    let globalErrorNode;
    if (errors._global) {
      globalErrorNode = (
        <div className={styles['globalError']}>{errors._global}</div>
      );
    }

    return (
      <CenterDialog open={open} onClose={onClose}>
        <div className={styles['wrapper']}>
          <div className={styles['content']}>
            <div className={styles['headline']}>
              <span>{locale().employees.send_invitation}</span>
              <LinkButton value={locale().actions.close} onClick={onClose} />
            </div>
            <div className={styles['description']}>
              {!globalErrorNode && locale().employees.send_invitation_info}
              {globalErrorNode}
            </div>

            <Form onSubmit={this._send}>
              <FormRow>
                <FormColumn>
                  <FormLabel
                    value={locale().employees.invitation_email}
                    error={errors.email}
                  />
                  <TextInput
                    value={data.email}
                    type={'email'}
                    onChange={value => this._changeData('email', value)}
                  />
                </FormColumn>
                <FormColumn>
                  <div className={styles['sendButton']}>
                    <Button
                      value={locale().actions.send}
                      type={'submit'}
                      disabled={isSaving}
                    />
                  </div>
                </FormColumn>
              </FormRow>
            </Form>
          </div>
        </div>
      </CenterDialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createInvitation: data => dispatch(actions.createInvitation(data)),
  };
};

InvitationDialog = connect(null, mapDispatchToProps)(InvitationDialog);

export default InvitationDialog;
