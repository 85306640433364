import React, { Component } from 'react';
import Toast from 'ui/Activity/Toast';
import PatientReferralDialog from 'patients/PatientReferralDialog';
import PatientDialog from 'patients/PatientDialog';
import TreatmentDialog from 'services/TreatmentDialog';
import PackageDialog from 'services/PackageDialog';
import PatientNoteDialog from 'patients/PatientNoteDialog';
import PaymentsDialog from 'patients/PaymentsDialog';
import InflowDialog from 'patients/InflowDialog';
import LocationDialog from 'locations/LocationDialog';
import ServiceDialog from 'patients/ServiceDialog';
import AssignServiceDialog from 'patients/AssignServiceDialog';
import PatientReportDialog from 'patients/PatientReportDialog';
import PatientDocumentDialog from 'patients/PatientDocumentDialog';
import PatientInvoiceDialog from 'patients/PatientInvoiceDialog';
import PostDialog from 'posts/PostDialog';
import ExpenseDialog from 'finances/ExpenseDialog';
import ExpenseTypeDialog from 'finances/ExpenseTypeDialog';
import EmployeeDialog from 'employees/EmployeeDialog';

class RenderAlways extends Component {
  render() {
    return (
      <React.Fragment>
        <Toast />
        <PatientReferralDialog />
        <PatientDialog />
        <TreatmentDialog />
        <PackageDialog />
        <PatientNoteDialog />
        <PaymentsDialog />
        <InflowDialog />
        <LocationDialog />
        <ServiceDialog />
        <AssignServiceDialog />
        <PatientReportDialog />
        <PatientDocumentDialog />
        <PatientInvoiceDialog />
        <PostDialog />
        <ExpenseDialog />
        <ExpenseTypeDialog />
        <EmployeeDialog />
      </React.Fragment>
    );
  }
}

export default RenderAlways;
