import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextArea } from 'ui/Form';
import DatePicker from 'ui/DatePicker';
import RatePicker from 'ui/Form/RatePicker';
import { connect, actions, selectors } from 'util/redux';

class PatientNoteDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      errors: {},
      note: { ...props.note },
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        isSaving: false,
        errors: {},
        note: { ...this.props.note },
      });
    }
  }

  _changeNote = (key, field) => {
    const { note } = this.state;
    this.setState({
      note: {
        ...note,
        [key]: field,
      },
    });
  };

  _saveNotes = () => {
    const { createPatientNote, updatePatientNote, onClose } = this.props;
    const { note } = this.state;

    const saveAction = !!note.id ? updatePatientNote : createPatientNote;

    this.setState({ isSaving: true, errors: {} });
    saveAction(note)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { isSaving, errors, note } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        onSave={this._saveNotes}
        title={locale().patient_notes.notes_head}
        error={errors._global}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_notes.date}
              error={errors.date}
            />
            <DatePicker
              value={note.date}
              onChange={value => this._changeNote('date', value)}
              dateOnly={true}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_notes.progress_rate}
              error={errors.progress_rate}
            />
            <RatePicker
              value={note.progress_rate}
              onChange={value => this._changeNote('progress_rate', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().patient_notes.add_notes}
              error={errors.content}
            />
            <TextArea
              autosize={true}
              autofocus={true}
              value={note.content}
              onChange={value => this._changeNote('content', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    note: selectors.getEditingPatientNote(state),
    open: selectors.isEditingPatientNote(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPatientNote: data => dispatch(actions.createPatientNote(data)),
    updatePatientNote: data => dispatch(actions.updatePatientNote(data)),
    onClose: () => dispatch(actions.finishPatientNoteEditing()),
  };
};

PatientNoteDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientNoteDialog);

export default PatientNoteDialog;
