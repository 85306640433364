import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_Sidebar.module.scss';
import classNames from 'classnames';
import Icon from 'ui/Icon';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import ColorPicker from 'ui/ColorPicker';

class Sidebar extends Component {
  static propTypes = {
    document: PropTypes.object.isRequired,
    sideContent: PropTypes.node,
    onUpdate: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    errorOccurred: PropTypes.bool.isRequired,
    onDestroy: PropTypes.func.isRequired,
    destroyConfirmation: PropTypes.shape({
      headText: PropTypes.string.isRequired,
      noticeText: PropTypes.string.isRequired,
    }).isRequired,
    pdfUrl: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
      colorPickerVisible: false,
    };
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _showColorPicker = () => {
    document.addEventListener('click', this._hideColorPicker);
    this.setState({ colorPickerVisible: true });
  };

  _hideColorPicker = () => {
    document.removeEventListener('click', this._hideColorPicker);
    this.setState({ colorPickerVisible: false });
  };

  _updateColor = color => {
    const { onUpdate } = this.props;
    onUpdate('color', color);
  };

  _openPdf = () => {
    const { isSaving } = this.props;

    if (!isSaving) {
      window.open(this.props.pdfUrl, '_blank');
    }
  };

  render() {
    const {
      document,
      sideContent,
      isSaving,
      errorOccurred,
      onDestroy,
      destroyConfirmation,
    } = this.props;
    const { confirmationDialogOpen, colorPickerVisible } = this.state;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['sidebar']}>
          <div
            className={classNames(styles['action'], {
              [styles['disabled']]: isSaving,
            })}
            onClick={this._openPdf}
          >
            <Icon icon={'description'} size={20} />
            <div className={styles['label']}>{locale().actions.get_pdf}</div>
          </div>
          <div className={styles['action']} onClick={this._showColorPicker}>
            <Icon icon={'color_lens'} size={20} />
            <div className={styles['label']}>
              {locale().common_labels.choose_color}
            </div>
          </div>
          <div
            className={classNames(styles['action'], styles['attention'])}
            onClick={this._openConfirmationDialog}
          >
            <Icon icon={'delete'} size={20} />
            <div className={styles['label']}>{locale().actions.destroy}</div>
          </div>
        </div>
        {colorPickerVisible && (
          <div className={styles['colorPicker']}>
            <ColorPicker color={document.color} onChange={this._updateColor} />
          </div>
        )}
        {sideContent && (
          <div className={styles['sideContent']}>{sideContent}</div>
        )}
        <div className={styles['actionNotes']}>
          {isSaving && (
            <div className={styles['saving']}>
              {locale().system.saving}
              ...
            </div>
          )}
          {errorOccurred && (
            <div className={styles['error']}>
              {locale().system.global_error}
              ...
            </div>
          )}
        </div>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={onDestroy}
          title={destroyConfirmation.headText}
          description={destroyConfirmation.noticeText}
        />
      </div>
    );
  }
}

export default Sidebar;
