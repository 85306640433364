import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_FinancialStats.module.scss';
import { MoneyText } from 'ui/Money';

class FinancialStats extends Component {
  static propTypes = {
    incomeStats: PropTypes.object.isRequired,
    expenseStats: PropTypes.object.isRequired,
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.node.isRequired,
      }).isRequired
    ),
  };

  _stats = () => {
    const { incomeStats, expenseStats } = this.props;

    let currentMonthIncome = incomeStats.payments.find(p =>
      p.date.isAtSameYearAndMonth(new Date())
    );
    let currentMonthExpense = expenseStats.expenses.find(p =>
      p.date.isAtSameYearAndMonth(new Date())
    );

    return [
      {
        color: '#46ac5a',
        label: locale().dashboard.finance_income,
        value: (
          <MoneyText
            value={currentMonthIncome ? currentMonthIncome.amount : 0}
          />
        ),
      },
      {
        color: '#f37a51',
        label: locale().dashboard.finance_debt,
        value: <MoneyText value={incomeStats.debt} />,
      },
      {
        color: '#d82b2b',
        label: locale().dashboard.finance_expense,
        value: (
          <MoneyText
            value={currentMonthExpense ? currentMonthExpense.amount : 0}
          />
        ),
      },
    ];
  };

  render() {
    return (
      <React.Fragment>
        {this._stats().map((stat, i) => (
          <div key={`${stat.label}-${i}`} className={styles['statsRow']}>
            <div className={styles['rowHead']}>
              <div
                className={styles['box']}
                style={{ background: stat.color }}
              />
              <span>{stat.label}</span>
            </div>
            {stat.value}
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default FinancialStats;
