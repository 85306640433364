import Response from './../_response';
import { PatientReportSerializer } from './../_serializers';

class PatientReportsEndpoint {
  constructor(client) {
    this._client = client;
  }

  get(id) {
    return this._client
      .fetch(`patient_reports/${id}`, 'GET')
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientReportSerializer.deserialize(json)
        );
      });
  }

  create(data) {
    return this._client
      .fetch('patient_reports', 'POST', PatientReportSerializer.serialize(data))
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientReportSerializer.deserialize(json)
        );
      });
  }

  update(id, data) {
    return this._client
      .fetch(`patient_reports/${id}`, 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientReportSerializer.deserialize(json)
        );
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patient_reports/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(
          response,
          PatientReportSerializer.deserialize(json)
        );
      });
  }
}

export default PatientReportsEndpoint;
