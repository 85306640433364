import React from 'react';
import ReactDOM from 'react-dom';
import 'util/monkeypatch';
import './index.scss';
import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from 'util/redux/reducer';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import AppRoot from './AppRoot';

Promise.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false,
  },
});

const browserHistory = createBrowserHistory();

const store = createStore(
  createRootReducer(browserHistory),
  compose(
    applyMiddleware(thunkMiddleware),
    applyMiddleware(routerMiddleware(browserHistory)),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

ReactDOM.render(
  <React.StrictMode>
    <AppRoot store={store} history={browserHistory} />
  </React.StrictMode>,
  document.getElementById('root')
);
