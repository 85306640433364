import React, { Component } from 'react';
import locale from 'util/locale';
import classNames from 'classnames';
import styles from './_AccountMenu.module.scss';
import { connect, actions, selectors } from 'util/redux';

class AccountMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this._items = [
      {
        title: locale().account.title,
        onClick: props.openUserAccountPage,
        show: true,
      },
      {
        title: locale().subscription.title,
        buildTitle: this._subscriptionTitle,
        onClick: props.openUserSubscriptionPage,
        show: props.user.permissions.manage_subscription,
        accent: true,
      },
      {
        title: locale().system.help,
        onClick: () => window.open('https://logovel.com/help'),
        show: true,
      },
      {
        title: locale().actions.log_out,
        onClick: props.logout,
        show: true,
      },
    ];
  }

  _subscriptionTitle = () => {
    const { user } = this.props;
    const subscription = user.organisation.subscription;

    let subscriptionTitle = locale().subscription.title;
    if (!subscription) {
      return subscriptionTitle;
    }

    const daysDiff = new Date().daysTo(subscription.valid_until);
    if (daysDiff >= 0) {
      subscriptionTitle = `${subscriptionTitle} [${locale().subscription.valid_until(
        daysDiff
      )}]`;
    }

    return subscriptionTitle;
  };

  _open = () => {
    this.setState({ open: true });
    document.addEventListener('click', this._close);
  };

  _close = () => {
    this.setState({ open: false });
    document.removeEventListener('click', this._close);
  };

  render() {
    const { user } = this.props;
    const { open } = this.state;

    let dropdownNode;
    if (open) {
      dropdownNode = (
        <div
          className={styles['dropdown']}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <div className={styles['name']}>{user.name}</div>
          <div className={styles['businessName']}>{user.organisation.name}</div>
          <div className={styles['items']}>
            {this._items
              .filter(item => item.show)
              .map(item => (
                <div
                  key={item.title}
                  className={classNames(styles['item'], {
                    [styles['accent']]: item.accent,
                  })}
                  onClick={() => {
                    this._close();
                    item.onClick();
                  }}
                >
                  {item.buildTitle ? item.buildTitle() : item.title}
                </div>
              ))}
          </div>
        </div>
      );
    }

    return (
      <div className={styles['wrapper']}>
        <div className={styles['circle']} onClick={this._open}>
          {user.initials}
        </div>
        {dropdownNode}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout()),
    openUserAccountPage: () => dispatch(actions.openUserAccountPage()),
    openUserSubscriptionPage: () =>
      dispatch(actions.openUserSubscriptionPage()),
  };
};

AccountMenu = connect(mapStateToProps, mapDispatchToProps)(AccountMenu);

export default AccountMenu;
