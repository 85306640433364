import React from 'react';

const en = {
  onboard: {
    invalid_email_or_password_error: 'Invalid email or password.',
    user_name: 'Your name',
    organisation_name: 'Practice name',
    organisation_currency: 'Choose your currency',
    language: 'Select your language',
    email: 'Email',
    password: 'Your password',
    choose_password: 'Choose password',
    forgot_password_question: 'Forgot password?',
    need_account_question: 'Need an account?',
    login_head: 'Log in to your Logovel account',
    login_intro: 'Track patients and their appointments',
    invalid_invitation_error: 'Invitation is invalid.',
    registration_through_invitation_info: 'Registration via invitation',
    subscription_exceeded_error:
      'Current subscription does not allow more accounts.',
    agree_to_terms: 'By creating an account you agree to the',
    terms_of_service: 'Terms of Service',
    registration_head: 'Application for defectologists',
    registration_intro: 'Patients and treatments in the cloud',
    registration_small_head: 'Create your Logovel account',
    registration_small_intro: 'Forget about papers and pencils',
    already_have_account_question: 'Have account?',
    send_reset_instructions: 'Send instructions',
    reset_password_instructions_sent:
      'Instructions for resetting password has been sent to your email.',
    forgot_password_info:
      "No problem. Enter your email and we'll send you reset instructions.",
    know_password_question: 'Know your password?',
    reset_password_head: 'Change password',
    reset_password_intro: 'Choose new password for your Logovel account',
    reset_password_invalid_link_error: 'Reset link is invalid.',
    reset_password_link_expired_error:
      'Link expired. Please send reset instructions again.',
    reset_password_successful_info:
      'Password changed. Use it to login to your account.',
    new_password: 'New password',
  },
  dashboard: {
    title: 'Home',
    greeting: 'Overview of your practice',
    finance_overview_head: 'Finance for current month',
    finance_income: 'Income',
    finance_debt: 'Debt',
    finance_expense: 'Expenses',
    finance_chart_head: 'Monthly income',
    quick_actions: 'Quick actions',
  },
  practice: {
    title: 'Practice',
  },
  schedule: {
    title: 'Schedule',
    empty_state_heading: 'No appointments on selected date.',
    number_of_participants: 'Number of participants',
    no_location_name: 'No location',
    no_location_short_name: '*',
    resources_type: {
      user: 'Therapists',
      location: 'Locations',
    },
  },
  patients: {
    title: 'Patients',
    options: 'Options',
    search_by: 'Search by name or phone number...',
    number_of_patients: 'Count',
    active_patients: 'Active patients',
    archived_patients: 'Archived patients',
    archived: 'Archived',
    new: 'New patient',
    edit: 'Edit patient',
    empty_state_heading: 'Your patients will show up here.',
    empty_state_intro: "Why don't you create one now?",
    name: 'Full name',
    gender: 'Gender',
    birth_date: 'Birth date',
    birthday_today: 'Birthday is today!',
    birthday_soon: 'Birthday is coming',
    birthday_recently: 'Birthday was recently',
    diagnose: 'Diagnosis',
    patient_referral: 'How did patient hear about practice?',
    parent_name: 'Parent name',
    phone_number: 'Phone number',
    email: 'Email',
    assign_to_who: 'Who will treat the patient?',
    anamnesis: 'Anamnesis and notes',
    anamnesis_info: 'Add anamnesis data or some notes...',
    add_birth_date: 'Add birth date...',
    male: 'Male',
    female: 'Female',
    patient_saved: 'Patient saved.',
    patient_archived: 'Patient archived.',
    patient_activated: 'Patient activated.',
    patient_destroyed: 'Patient deleted.',
    no_debt: 'Payed',
    destroy_patient_head: 'Delete patient',
    destroy_patient_notice:
      'By deleting patient you are removing all their treatments and payments. Action can not be undone!',
    not_found:
      'Patient not found. Either it has been deleted or it does not exist.',
    patient_data_head: 'Patient data',
  },
  appointments: {
    title: 'Appointments',
    empty_state_description:
      "All patient's scheduled and cancelled treatments will be listed here.",
    details: 'Details',
    not_scheduled: 'Not scheduled',
    schedule_an_appointment: 'Schedule',
    appointment_scheduled: 'Appointment scheduled',
    scheduled_for: 'Scheduled for',
    cancelled: 'Cancelled',
    cancel_appointment: 'Cancel appointment',
    appointment_canceled: 'Appointment canceled.',
    cancel_appointment_notice:
      'Are you sure you want to cancel this appointment?',
    cancel_debt: 'Cancel service debt',
    cancellation_reason: 'Optional reason of cancellation',
    appointment_taken_error: 'Appointment is taken.',
    location_taken_error: 'Location is taken on selected date.',
    users_required: 'At least one therapist must participate in the treatment.',
    events_required:
      'Fill the data about patients that participate in the treatment.',
    already_scheduled: 'Treatment already scheduled for selected service.',
    group_treatment: 'Group treatment',
    appointment_user: 'Who participates in the treatment?',
    select_patient: 'Select patient',
    select_service: 'Select service',
    select_service_item: 'Select treatment',
    add_patient: 'Include another patient',
    not_found:
      'Appointment not found. Either it has been cancelled or it does not exist.',
    show_service: 'Show service',
  },
  patient_referrals: {
    title: 'Referrals',
    all_patient_referrals: 'All referrals',
    name: 'Name',
    destroy_patient_referral_head: 'Delete referral',
    destroy_patient_referral_notice:
      'By deleting referral, you are removing it from all patients. Action can not be undone!',
    patient_referral_saved: 'Referral saved.',
    patient_referral_destroyed: 'Referral deleted.',
  },
  diagnoses: {
    title: 'Diagnoses',
    all_diagnoses: 'All diagnoses',
    name: 'Name',
    destroy_diagnose_head: 'Delete diagnosis',
    destroy_diagnose_notice:
      'By deleting diagnosis, you are removing it from all patients. Action can not be undone!',
    diagnose_saved: 'Diagnosis saved.',
    diagnose_destroyed: 'Diagnosis deleted.',
  },
  services: {
    title: 'Services',
    new: 'New service',
    edit: 'Edit service',
    assign: 'Choose service',
    activated_on: 'Activated on',
    price: 'Price',
    empty_state_description:
      "All patient's treatments will be listed here. When scheduling an appointment, patient debt will be increased for the service price.",
    service_assigned: 'Service assigned.',
    service_updated: 'Service updated.',
    service_destroyed: 'Service deleted.',
    destroy_service_head: 'Delete service',
    destroy_service_notice:
      'By deleting service you are removing all scheduled appointments and payments for this service. Action can not be undone!',
    appointments_scheduled: (part, sum) => `${part}/${sum} scheduled`,
    service_not_selected: 'Select service first.',
    no_services_notice: () => (
      <React.Fragment>
        No services created. Visit services page and create one, or click the
        New service button.
      </React.Fragment>
    ),
    payed_amount: 'Payed',
    not_found:
      'Service not found. Either it has been deleted or it does not exist.',
  },
  service_items: {
    destroy_service_item_head:
      'Are you sure you want to delete this treatment?',
    destroy_service_item_notice:
      'By deleting it you are also removing all notes for it. Action can not be undone!',
    service_item_destroyed: 'Treatment removed.',
    select_treatment: 'Choose treatment',
    add_treatment: 'Include treatment',
  },
  patient_notes: {
    title: 'Notes',
    notes_dropdown: 'Appointment notes overview',
    notes_head: 'Patient notes',
    date: 'Date',
    add_notes: 'Add notes',
    progress_rate: 'Rate the progress',
    empty_state_heading:
      'Track patient progress with writing notes after each treatment.',
    notes_saved: 'Notes saved.',
    notes_destroyed: 'Notes deleted.',
    destroy_patient_note_head: 'Delete note',
    destroy_patient_note_notice: 'Are you sure? Action can not be undone.',
  },
  service_payments: {
    title: 'Payments overview',
    title_short: 'Payments',
    empty_state_description: 'Payments from this patient will be listed here.',
    new: 'Add payment',
    payment_hint:
      'This payment will cover multiple services starting from the oldest one.',
    overflow_error: 'Amount must be less than debt for this service.',
    inflow_overflow_error: 'Amount must be less than debt for this patient.',
    patient: 'Patient',
    date: 'Date',
    payment_type: 'Payment type',
    amount: 'Amount',
    service_payment_saved: 'Payment saved.',
    service_payment_destroyed: 'Payment deleted.',
  },
  patient_reports: {
    title: 'Reports',
    new: 'New report',
    empty_state_heading:
      'Create personal reports for each patient. Attach your logo, choose a color and export to PDF.',
    not_found:
      'Report not found. Either it has been deleted or it does not exist.',
    date: 'Date',
    patient_name: 'Name',
    patient_birth_date: 'Birth date',
    report_type: 'Report type',
    section_title: 'Enter title',
    section_content: 'Enter content',
    add_section: 'Add section',
    patient_report_destroyed: 'Report deleted.',
    destroy_patient_report_head: 'Delete report',
    destroy_patient_report_notice: 'Are you sure? Action can not be undone.',
  },
  patient_documents: {
    title: 'Storage',
    new: 'Upload file',
    edit: 'Change info',
    empty_state_heading:
      'You can upload here all patient documents, images or videos.',
    document_title: 'Document title',
    date: 'Date',
    file: 'Select file',
    document_created: 'File saved.',
    document_saved: 'Changes saved.',
    document_destroyed: 'File deleted.',
    destroy_document_head: 'Delete file',
    destroy_document_notice: 'Action can not be undone.',
  },
  patient_invoices: {
    new: 'New invoice',
    invoice: 'Invoice',
    new_disabled_reason: 'Select services',
    number: 'Invoice number (leave blank to autogenerate)',
    date: 'Invoice date',
    due_date: 'Due date',
    no_due_date: 'No due date',
    invoice_saved: 'Invoice saved.',
    invoice_destroyed: 'Invoice deleted.',
    not_found:
      'Invoice not found. Either it has been deleted or it does not exist.',
    invoice_receiver: 'Client details',
    instructions: 'Other info and instructions (optional)',
    destroy_patient_invoice_head: 'Delete invoice',
    destroy_patient_invoice_notice: 'Action can not be undone.',
    group_services: 'Group services',
    ungroup_services: 'Split services',
    line_item_name: 'Item name',
    services_grouped: 'Services grouped.',
    services_ungrouped: 'Services split.',
  },
  treatments: {
    title: 'Treatments',
    new: 'New treatment',
    edit: 'Edit treatment',
    treatment_saved: 'Treatment saved.',
    treatment_destroyed: 'Treatment deleted.',
    empty_state_heading:
      'All of your services and their prices will be listed here.',
    empty_state_intro:
      'Create single treatment and after that you can make package of treatments.',
    name: 'Name',
    duration: 'Duration',
    price: 'Price',
    destroy_treatment_head: 'Delete treatment',
    destroy_treatment_notice:
      'By destroying treatment you are removing it from package, if exists. All of your scheduled appointments will remain.',
  },
  packages: {
    title: 'Packages',
    new: 'New package',
    edit: 'Edit package',
    package_saved: 'Package saved.',
    package_destroyed: 'Package deleted.',
    empty_state_heading: 'Wanna create package of treatments?',
    empty_state_intro:
      'Select multiple treatments and make package with lower price.',
    treatments_included: 'Included treatments',
    include_treatments_error: 'Include treatments in this package',
    name: 'Name',
    price: 'Price',
    destroy_package_head: 'Delete package',
    destroy_package_notice:
      'By doing so you are not removing scheduled appointments from this package. They will remain.',
    number_of_treatments: numberOfTreatments =>
      'treatment'.plural(numberOfTreatments),
  },
  finances: {
    title: 'Finance',
    income_report_title: 'Income in period',
    expense_report_title: 'Expenses in period',
    chart_label: 'Sum',
  },
  expenses: {
    title: 'Business expenses',
    new: 'Log an expense',
    edit: 'Edit expense',
    date: 'Date',
    expense_type: 'Expense type',
    amount: 'Amount',
    description: 'Description',
    expense_saved: 'Expense saved.',
    expense_destroyed: 'Expense deleted.',
    destroy_expense_head: 'Delete business expense',
    destroy_expense_notice: 'Action can not be undone.',
  },
  expense_types: {
    title: 'Expense types',
    new: 'New expense type',
    edit: 'Edit expense type',
    name: 'Name',
    expense_type_saved: 'Expense type saved.',
    expense_type_destroyed: 'Expense type deleted.',
    destroy_expense_type_head: 'Just a sec!',
    destroy_expense_type_notice:
      'If you delete this expense type, you will also delete all logged expenses for this type! Action can not be undone.',
  },
  locations: {
    title: 'Locations',
    personal_location: 'No location',
    all_locations: 'All locations',
    new: 'New location',
    edit: 'Edit location',
    empty_state_heading:
      'All your locations and shared resources will be listed here.',
    empty_state_intro:
      'If you work with multiple therapists, you can enter capacity for each resource to avoid overlapping in schedule.',
    name: 'Name',
    capacity: 'Capacity',
    location_saved: 'Location saved.',
    location_destroyed: 'Location deleted.',
    location_label: 'Label in schedule',
    destroy_location_head: 'Delete location',
    destroy_location_notice:
      'By deleting location, you are moving all appointments in this location out of it. It might come to appointment overlapping.',
  },
  employees: {
    title: 'Therapists',
    all_employees: 'All therapists',
    empty_state_heading: 'You can run your practice from this page.',
    empty_state_intro: 'Invite therapist and assign them patients.',
    send_invitation: 'Send invitation',
    invitation_sent: 'Invitation sent.',
    send_invitation_info: (
      <React.Fragment>
        Enter email address of therapist and send them a registration link.
        <br />
        <br />
        They won't be able to see any income stats but they can add payments.
        <br />
        <br />
        All data is shared and you will be able to see any change on patients
        made by therapists.
      </React.Fragment>
    ),
    invitation_email: 'Email',
    invitation_exists_error: 'Invitation has already been sent.',
    subscription_exceeded_error:
      'Current subscription does not allow more invitations. Contact support at info@logovel.com',
    subscription_exceeded_activate_user_error:
      'Subscription number of accounts is at its limit.',
    activation_freeze_period_error:
      'At least 24h must pass between archiving and activating account.',
    revoke_invitation: 'Revoke',
    invitation_destroyed: 'Invitation deleted.',
    destroy_invitation_head: 'Call off invitation for',
    destroy_invitation_notice:
      'You can send it again. Therapist can use other email for registration.',
    employee_archived: 'User archived.',
    employee_activated: 'User activated.',
    worked_with_patients: 'Treated patients in selected period',
    assessment: 'Impact assessment',
    edit_employee: 'Edit therapist',
    name: 'Name',
    employee_title: 'Title',
    email: 'Email',
    employee_saved: 'Therapist saved.',
    admin: 'Admin',
    therapist: 'Therapist',
  },
  posts: {
    title: 'News',
    new: 'New post',
    edit: 'Edit post',
    post_title: 'Title',
    post_content: 'Content',
    post_created: 'Post created.',
    post_updated: 'Post updated.',
    post_destroyed: 'Post destroyed.',
    empty_state_heading: 'Welcome to Logovel news feed!',
    empty_state_intro:
      'Post anything you may found of interest to you and your coworkers such as news, links, reminders...',
    destroy_post_head: 'Delete post',
    destroy_post_notice: 'Are you sure? Action can not be undone.',
  },
  account: {
    title: 'My account',
    user_data_head: 'Your info',
    organisation_data_head: 'Practice info',
    security_head: 'Security',
    name: 'Your name',
    email: 'Email',
    user_title: 'Your title',
    organisation_name: 'Name of your practice',
    organisation_email: 'Email of practice',
    organisation_website: 'Website',
    organisation_address: 'Address',
    organisation_phone: 'Phone number',
    company_identifier: 'Company identifier',
    company_identifier_short: 'Company ID',
    tax_number: 'Tax ID',
    current_password_wrong_error: 'Current password is incorrect.',
    current_password: 'Current password',
    new_password: 'New password',
    change_password: 'Change password',
    info_saved: 'Info saved.',
    password_changed: 'Password changed.',
    logo: 'Logo',
    storage_limit_reached:
      'Storage limit reached. Contact support for increase.',
  },
  notifications: {
    title: 'Notifications',
    empty_state_heading: 'Notifications',
    empty_state_intro: 'Everything that you need to take a look at.',
    patient_inactive: {
      title: 'Patient inactive',
      content: "hasn't come in for awhile. Do you want to archive the patient?",
    },
    post_created: {
      title: 'New post',
    },
  },
  subscription: {
    title: 'Subscription',
    valid_until: days => `${days} ${'day'.plural(days)} left`,
    headline: 'Subscribe to Logovel',
    intro_1:
      'Subscription is without contract and you can cancel it at any time.',
    intro_2:
      'With the invoice you are receiving, Logovel is a tax deduction too!',
    active_and_valid_until: date => `Subscription is active until ${date}`,
    included: [
      { label: 'Managing patients' },
      { label: 'Scheduling treatments' },
      { label: 'Smart scheduling' },
      { label: 'Managing services and packages' },
      { label: 'Logging payments' },
      { label: 'Writing treatment notes' },
      { label: 'Generating personalized reports' },
      { label: 'Practice management', admin: true },
      { label: 'Finance overview', admin: true },
      { label: "Therapists' assessment overview", admin: true },
    ],
    monthly: 'monthly',
    yearly: 'yearly',
    admin_account: 'Admin account',
    therapist_account: 'Therapist account',
    save_money: 'Save',
    with_yearly_subscription: 'with yearly subscription',
    contact_for_payment_instructions: email =>
      `Contact us on ${email} for payment instructions.`,
  },
  actions: {
    log_in: 'Log in',
    log_out: 'Log out',
    register: 'Register',
    save: 'Save',
    edit: 'Edit',
    close: 'Close',
    activate: 'Activate',
    archive: 'Archive',
    destroy: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel',
    back: 'Back',
    send: 'Send',
    download: 'Download',
    load_more: 'Load more',
    get_pdf: 'Get PDF',
  },
  common_labels: {
    choose_color: 'Choose color',
    organisation_data_notice: 'You can edit practice data on My account page.',
  },
  system: {
    help: 'Help',
    global_error: 'Oops there was an error. Please try again later.',
    too_many_requests: 'To many requests. Please try again later.',
    wait_notice: 'Just a sec!',
    more: 'More',
    show_more: 'Show more',
    new: 'New',
    saving: 'Saving',
    no_options: 'No options',
    coming_soon: 'Coming soon to Logovel!',
    new_api_version:
      'New application version is available. Please refresh page in browser.',
  },
  validations: {
    field_required: 'Field is required',
  },
  dates: {
    today: 'Today',
    week: '7 days',
    month: 'A month',
    custom: 'Custom period',
    current_month: 'Current month',
    last_month: 'Last month',
    current_year: 'Current year',
    last_year: 'Last year',
    time_caption: 'Time',
  },
  days: {
    week: '7 days',
    two_weeks: '14 days',
    month: '30 days',
  },
  currencies: {
    aud: 'Australian dollar',
    bam: 'B&H KM',
    cad: 'Canadian dollar',
    chf: 'Swiss franc',
    gbp: 'British pound',
    eur: 'Euro',
    hrk: 'Croatian kuna',
    huf: 'Hungarian forint',
    nok: 'Norwegian krone',
    rsd: 'Serbian dinar',
    rub: 'Russian ruble',
    sek: 'Swedish krona',
    usd: 'US dollar',
  },
  payment_types: {
    direct: 'Cache payment',
    account: 'Bank account',
  },
};

export default en;
