Function.prototype.debounce = function (wait = 1000) {
  let callingFn = this;
  let timeout;

  return function () {
    let context = this;
    let args = arguments;

    clearTimeout(timeout);

    return new Promise(resolve => {
      timeout = setTimeout(() => {
        timeout = null;
        resolve(callingFn.apply(context, args));
      }, wait);
    });
  };
};
