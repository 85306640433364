import { language } from 'util/locale';

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.leftPad = function (minLength = 2, pad = '0') {
  if (this.length < minLength) {
    return `${Array(minLength - this.length + 1).join(pad)}${this}`;
  } else {
    return this;
  }
};

String.timeAt = function (number) {
  const hour = Math.floor(number);
  const minute = (number - hour) * 60;

  return `${hour}`.leftPad() + ':' + `${minute}`.leftPad();
};

String.prototype.plural = function (number) {
  if (['sr', 'hr'].includes(language())) {
    return `${this}${number % 10 === 1 && number % 100 !== 11 ? '' : 'a'}`;
  } else if (language() === 'en') {
    return `${this}${number === 1 ? '' : 's'}`;
  } else {
    return this;
  }
};

String.randomize = function (length) {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
