import React, { Component } from 'react';
import PropTypes from 'prop-types';
import EmployeeSelectUI from './EmployeeSelectUI';
import MultipleEmployeeSelectUI from './MultipleEmployeeSelectUI';
import { connect, actions, selectors } from 'util/redux';

class EmployeeSelect extends Component {
  static propTypes = {
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    multiple: false,
  };

  componentDidMount() {
    const { fetchEmployees } = this.props;
    fetchEmployees();
  }

  render() {
    const { multiple } = this.props;
    const Component = multiple ? MultipleEmployeeSelectUI : EmployeeSelectUI;

    return <Component {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    employees: selectors.getEmployees(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEmployees: () => dispatch(actions.fetchEmployees({ archived: false })),
  };
};

EmployeeSelect = connect(mapStateToProps, mapDispatchToProps)(EmployeeSelect);

export default EmployeeSelect;
