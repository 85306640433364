import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PatientsListItem.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionListItem } from 'ui/SectionList';
import { MoneyText } from 'ui/Money';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import Dropdown from 'ui/Dropdown';
import Icon, { BirthdayIcon } from 'ui/Icon';
import { connect, actions } from 'util/redux';

class PatientsListItem extends Component {
  static propTypes = {
    patient: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
  }

  _moreActionsItems = () => {
    const { patient, editPatient } = this.props;
    const items = [
      {
        label: locale().actions.edit,
        onClick: () => editPatient(patient),
      },
      {
        label: patient.archived
          ? locale().actions.activate
          : locale().actions.archive,
        onClick: this._changeActiveState,
      },
    ];

    if (patient.archived) {
      items.push({
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      });
    }

    return items;
  };

  _debtNode = () => {
    const { patient } = this.props;

    if (patient.debt > 0) {
      return (
        <div className={styles['debt']}>
          <MoneyText value={patient.debt} />
        </div>
      );
    } else {
      return locale().patients.no_debt;
    }
  };

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _changeActiveState = () => {
    const { patient, activatePatient, archivePatient } = this.props;

    if (patient.archived) {
      activatePatient(patient.id);
    } else {
      archivePatient(patient.id);
    }
  };

  _showPatient = () => this.props.showPatient(this.props.patient.id);

  _onDestroy = () => {
    const { patient, destroyPatient } = this.props;
    destroyPatient(patient.id);
  };

  _circle = () => (
    <div
      style={{ backgroundColor: this.props.patient.color }}
      className={styles['circle']}
    >
      {this.props.patient.initials}
    </div>
  );

  _mobile = () => {
    const { patient } = this.props;

    return (
      <React.Fragment>
        <SectionListItem
          leftContent={this._circle()}
          title={patient.name}
          extra={patient.diagnose ? patient.diagnose.name : ''}
          rightContent={this._debtNode()}
          onClick={this._showPatient}
          contextMenuItems={this._moreActionsItems()}
        />
      </React.Fragment>
    );
  };

  _desktop = () => {
    const { patient } = this.props;

    return (
      <div className={styles['patient']}>
        <div className={styles['inner']} onClick={this._showPatient}>
          <div className={styles['top']}>
            <div>
              <BirthdayIcon birthDate={patient.birth_date} />
            </div>
            <Dropdown items={this._moreActionsItems()} />
          </div>
          <div className={styles['header']}>
            {this._circle()}
            <div className={styles['name']}>{patient.name}</div>
            <div className={styles['diagnose']}>
              {patient.diagnose && patient.diagnose.name}
            </div>
          </div>
          <div className={styles['content']}>
            <div className={styles['item']}>
              <Icon icon={'cake'} size={18} />
              <div className={styles['value']}>
                {patient.birth_date
                  ? patient.birth_date.format('d. MMMM yyyy.')
                  : '-'}
              </div>
            </div>
            <div className={styles['item']}>
              <Icon icon={'person'} size={18} />
              <div className={styles['value']}>
                {patient.parent_name || '-'}
              </div>
            </div>
            <div className={styles['item']}>
              <Icon icon={'phone'} size={18} />
              <div className={styles['value']}>
                {patient.phone_number || '-'}
              </div>
            </div>
            <div className={styles['item']}>
              <Icon icon={'info'} size={18} />
              <div className={styles['value']}>{patient.notes || '-'}</div>
            </div>
            <div className={styles['item']}>
              <Icon icon={'local_atm'} size={18} />
              <div className={styles['value']}>{this._debtNode()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { patient } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._onDestroy}
          title={`${locale().patients.destroy_patient_head}: ${patient.name}`}
          description={locale().patients.destroy_patient_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showPatient: id => dispatch(actions.showPatient(id)),
    editPatient: patient => dispatch(actions.editPatient(patient)),
    activatePatient: id => dispatch(actions.activatePatient(id)),
    archivePatient: id => dispatch(actions.archivePatient(id)),
    destroyPatient: id => dispatch(actions.destroyPatient(id)),
  };
};

PatientsListItem = connect(null, mapDispatchToProps)(PatientsListItem);

export default PatientsListItem;
