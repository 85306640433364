import React, { Component } from 'react';
import locale from 'util/locale';
import PermissionsRender from 'ui/PermissionsRender';
import Navigator from 'navigation/Navigator';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import EmptyState from 'ui/EmptyState';
import emptyStateImg from 'media/images/employees/emptyState.png';
import HeaderBar from 'ui/HeaderBar';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import InvitationsList from './_InvitationsList';
import EmployeesList from './_EmployeesList';
import InvitationDialog from './_InvitationDialog';
import DateRange, { MobileDateRange } from 'ui/DateRange';
import { Button } from 'ui/Button';
import Icon, { DownloadIcon } from 'ui/Icon';
import FAB from 'ui/FAB';
import { periodRanges } from 'util/date_ranges';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class EmployeesOverview extends Component {
  constructor(props) {
    super(props);
    this._periodRangesWithCustom = [
      ...periodRanges(),
      {
        label: locale().dates.custom,
        value: null,
      },
    ];
    this.state = {
      range: this._periodRangesWithCustom[0].value,
      loadingState: loadingStates.fetching,
      dateRangeBottomSheetOpen: false,
      invitationDialogOpen: false,
    };
  }

  componentDidMount() {
    const { fetchInvitations, fetchEmployees } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([fetchEmployees(), this._fetchStats(), fetchInvitations()])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(error => {
        console.error(error);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  _fetchStats = () => {
    const { fetchWorkingStats, fetchAppointmentStats } = this.props;
    const { range } = this.state;

    return Promise.all([
      fetchWorkingStats(range),
      fetchAppointmentStats(range),
    ]);
  };

  _openDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: true });

  _closeDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: false });

  _openInvitationDialog = () => this.setState({ invitationDialogOpen: true });

  _closeInvitationDialog = () => this.setState({ invitationDialogOpen: false });

  _noCoworkers = () => this.props.employees.length === 1;

  _changeRange = range => this.setState({ range }, this._fetchStats);

  _downloadCsv = () => {
    const { appointmentStats } = this.props;

    if (appointmentStats) {
      window.open(appointmentStats.csv_url, '_blank');
    }
  };

  _mobileHeader = () => (
    <React.Fragment>
      <HeaderBar
        title={locale().employees.title}
        rightContent={
          <Icon
            icon={'event_note'}
            color={'#FFFFFF'}
            expand={true}
            onClick={this._openDateRangeBottomSheet}
          />
        }
      />
      <MobileDateRange
        open={this.state.dateRangeBottomSheetOpen}
        onClose={this._closeDateRangeBottomSheet}
        ranges={this._periodRangesWithCustom.slice(0, -1)}
        onChange={this._changeRange}
        range={this.state.range}
      />
    </React.Fragment>
  );

  _desktopHeader = () => (
    <Toolbar>
      <ToolbarGroup>
        <DateRange
          ranges={this._periodRangesWithCustom}
          onChange={this._changeRange}
          range={this.state.range}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <DownloadIcon onClick={this._downloadCsv} />
        <Button
          value={locale().employees.send_invitation}
          onClick={this._openInvitationDialog}
        />
      </ToolbarGroup>
    </Toolbar>
  );

  render() {
    const { employees, workingStats, invitations } = this.props;
    const { loadingState, invitationDialogOpen } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (invitations.length === 0 && this._noCoworkers()) {
      bodyNode = (
        <React.Fragment>
          <EmptyState
            heading={locale().employees.empty_state_heading}
            intro={locale().employees.empty_state_intro}
            image={emptyStateImg}
            actionText={locale().employees.send_invitation}
            onClick={this._openInvitationDialog}
          />
        </React.Fragment>
      );
    } else {
      bodyNode = (
        <React.Fragment>
          <MobileDesktopRender desktop={this._desktopHeader} />
          {invitations.length > 0 && (
            <InvitationsList invitations={invitations} />
          )}
          {!this._noCoworkers() && (
            <EmployeesList employees={employees} stats={workingStats} />
          )}
          <FAB onClick={this._openInvitationDialog} />
        </React.Fragment>
      );
    }

    return (
      <PermissionsRender permission={'manage_employees'} redirect={true}>
        <Navigator title={locale().employees.title} mountedAs={'employees'}>
          <React.Fragment>
            <MobileDesktopRender mobile={this._mobileHeader} />
            {bodyNode}
            <InvitationDialog
              open={invitationDialogOpen}
              onClose={this._closeInvitationDialog}
            />
          </React.Fragment>
        </Navigator>
      </PermissionsRender>
    );
  }
}

const mapStateToProps = state => {
  return {
    employees: selectors.getEmployees(state),
    workingStats: selectors.getWorkingStats(state),
    invitations: selectors.getInvitations(state),
    appointmentStats: selectors.getAppointmentStats(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchEmployees: () => dispatch(actions.fetchEmployees()),
    fetchWorkingStats: range => dispatch(actions.fetchWorkingStats(range)),
    fetchInvitations: () => dispatch(actions.fetchInvitations()),
    fetchAppointmentStats: range =>
      dispatch(actions.fetchAppointmentStats(range)),
  };
};

EmployeesOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesOverview);

export default EmployeesOverview;
