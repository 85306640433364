import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ResourcesGroup extends Component {
  static propTypes = {
    appointmentsData: PropTypes.arrayOf(
      PropTypes.shape({
        appointment: PropTypes.object.isRequired,
        height: PropTypes.number.isRequired,
      })
    ).isRequired,
    itemComponent: PropTypes.object.isRequired,
    itemLabelExtractor: PropTypes.func.isRequired,
    itemsGrouped: PropTypes.bool.isRequired,
  };

  _appointments = () =>
    this.props.appointmentsData.map(data => data.appointment);

  render() {
    const { appointmentsData, itemLabelExtractor, itemsGrouped } = this.props;

    const Component = this.props.itemComponent;

    if (itemsGrouped) {
      return <Component appointments={this._appointments()} />;
    } else {
      return appointmentsData.map((data, i) => (
        <Component
          key={data.appointment.id}
          appointment={data.appointment}
          labelExtractor={itemLabelExtractor}
          indexInCell={i}
          height={data.height}
          widthCoef={appointmentsData.length}
        />
      ));
    }
  }
}

export default ResourcesGroup;
