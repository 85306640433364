import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import ActionCenter from 'util/action_center';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_EXPENSE_TYPE_EDITING = 'START_EXPENSE_TYPE_EDITING';
const FINISH_EXPENSE_TYPE_EDITING = 'FINISH_EXPENSE_TYPE_EDITING';

export default (
  state = {
    editingExpenseType: emptyExpenseTypeData(),
    expenseTypeDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_EXPENSE_TYPE_EDITING:
      return {
        ...state,
        editingExpenseType: action.expenseType,
        expenseTypeDialogOpen: true,
      };
    case FINISH_EXPENSE_TYPE_EDITING:
      return {
        ...state,
        editingExpenseType: emptyExpenseTypeData(),
        expenseTypeDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyExpenseTypeData = () => ({
  name: '',
});

const newExpenseType = () => {
  return {
    type: START_EXPENSE_TYPE_EDITING,
    expenseType: emptyExpenseTypeData(),
  };
};

const editExpenseType = expenseType => {
  return {
    type: START_EXPENSE_TYPE_EDITING,
    expenseType,
  };
};

const finishExpenseTypeEditing = () => {
  return {
    type: FINISH_EXPENSE_TYPE_EDITING,
  };
};

const fetchExpenseTypes = () => {
  return dispatch => {
    return Api.expenseTypes.query().then(response => {
      if (response.isOk()) {
        dispatch(clearQuery(entityTypes.expense_type));
        const query = response.data();
        dispatch(receiveQuery(entityTypes.expense_type, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createExpenseType = data => {
  return dispatch => {
    return Api.expenseTypes.create(data).then(response => {
      if (response.isOk()) {
        const expenseType = response.data();
        dispatch(receiveEntities(entityTypes.expense_type, [expenseType]));
        dispatch(
          systemActions.showToast(locale().expense_types.expense_type_saved)
        );
        ActionCenter.publish('createExpenseType');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateExpenseType = data => {
  return dispatch => {
    return Api.expenseTypes.update(data).then(response => {
      if (response.isOk()) {
        const expenseType = response.data();
        dispatch(receiveEntities(entityTypes.expense_type, [expenseType]));
        dispatch(
          systemActions.showToast(locale().expense_types.expense_type_saved)
        );
        ActionCenter.publish('updateExpenseType');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyExpenseType = id => {
  return dispatch => {
    return Api.expenseTypes.destroy(id).then(response => {
      if (response.isOk()) {
        const expenseType = response.data();
        dispatch(removeEntities(entityTypes.expense_type, [expenseType.id]));
        dispatch(
          systemActions.showToast(locale().expense_types.expense_type_destroyed)
        );
        ActionCenter.publish('destroyExpenseType');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newExpenseType,
  editExpenseType,
  finishExpenseTypeEditing,
  fetchExpenseTypes,
  createExpenseType,
  updateExpenseType,
  destroyExpenseType,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getExpenseTypes = state => getEntityList(state, entityTypes.expense_type);

const getEditingExpenseType = state => state.editingExpenseType;

const isEditingExpenseType = state => state.expenseTypeDialogOpen;

export const selectors = {
  getExpenseTypes,
  getEditingExpenseType,
  isEditingExpenseType,
};
