import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_EmployeesListItem.module.scss';
import Icon from 'ui/Icon';
import Dropdown from 'ui/Dropdown';
import { connect, actions, selectors } from 'util/redux';

class EmployeesListItem extends Component {
  static propTypes = {
    employee: PropTypes.object.isRequired,
    statsId: PropTypes.string.isRequired,
  };

  _moreActionsItems = () => {
    const { currentUser, employee, editEmployee } = this.props;

    let items = [
      {
        label: locale().employees.edit_employee,
        onClick: () => editEmployee(employee),
      },
    ];

    let archivableItem = {
      label: employee.archived
        ? locale().actions.activate
        : locale().actions.archive,
      onClick: this._changeActiveState,
    };

    if (currentUser.id !== employee.id) {
      items.push(archivableItem);
    }

    return items;
  };

  _changeActiveState = () => {
    const { employee, archiveEmployee, activateEmployee } = this.props;

    if (employee.archived) {
      activateEmployee(employee.id);
    } else {
      archiveEmployee(employee.id);
    }
  };

  _showMore = () => {
    const { fetchOneWorkingStats, employee, stats } = this.props;

    fetchOneWorkingStats(employee.id, {
      from_date: stats.from_date,
      to_date: stats.to_date,
    });
  };

  _expanded = () =>
    this.props.stats && this.props.stats.patients_in_period !== undefined;

  render() {
    const { employee, stats, showPatient } = this.props;

    return (
      <div
        className={classNames(styles['wrapper'], {
          [styles['archived']]: employee.archived,
        })}
      >
        <div className={styles['actions']}>
          <Dropdown items={this._moreActionsItems()} />
        </div>
        <div className={styles['meta']}>
          <div
            className={styles['circle']}
            style={{ background: employee.color }}
          >
            {employee.initials}
          </div>
          <div className={styles['info']}>
            <div className={styles['name']}>{employee.name}</div>
            {employee.title && (
              <div className={styles['text']}>{employee.title}</div>
            )}
            <div className={styles['text']}>{employee.email}</div>
          </div>
        </div>
        {!employee.archived && (
          <React.Fragment>
            <div className={styles['time']}>
              <Icon icon={'access_time'} size={22} />
              <div className={styles['minutes']}>
                {Date.formatMinutes(stats.minutes_working)}
              </div>
            </div>
            <div className={styles['header']}>
              {locale().employees.assessment}
            </div>
            <div className={styles['bar']}>
              <div
                className={styles['fill']}
                style={{ width: `${stats.assessment * 100}%` }}
              />
            </div>
          </React.Fragment>
        )}
        {!this._expanded() && !employee.archived && (
          <div className={styles['expand']} onClick={this._showMore}>
            {locale().system.show_more}
          </div>
        )}
        {this._expanded() && (
          <React.Fragment>
            <div className={styles['header']}>
              {locale().employees.worked_with_patients} (
              {stats.patients_in_period.length})
            </div>
            <div className={styles['patients']}>
              {stats.patients_in_period.sortBy('name').map(patient => (
                <div
                  key={patient.id}
                  className={styles['item']}
                  onClick={() => showPatient(patient.id)}
                >
                  {patient.name}
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: selectors.getCurrentUser(state),
    stats: selectors.getOneWorkingStats(state, ownProps.statsId),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOneWorkingStats: (userId, data) =>
      dispatch(actions.fetchOneWorkingStats(userId, data)),
    editEmployee: employee => dispatch(actions.editEmployee(employee)),
    archiveEmployee: id => dispatch(actions.archiveEmployee(id)),
    activateEmployee: id => dispatch(actions.activateEmployee(id)),
    showPatient: id => dispatch(actions.showPatient(id)),
  };
};

EmployeesListItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesListItem);

export default EmployeesListItem;
