import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_DocumentTextArea.module.scss';
import classNames from 'classnames';
import { TextArea } from 'ui/Form';

class DocumentTextArea extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    alignRight: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    alignRight: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  _onChange = value => this.setState({ value });

  _onBlur = () => {
    const { onChange } = this.props;
    const { value } = this.state;

    if (this.props.value !== value) {
      onChange(value);
    }
  };

  render() {
    const { placeholder, alignRight } = this.props;
    const { value } = this.state;

    return (
      <TextArea
        value={value}
        onChange={this._onChange}
        onBlur={this._onBlur}
        className={classNames(styles['textArea'], {
          [styles['right']]: alignRight,
        })}
        placeholder={placeholder}
      />
    );
  }
}

export default DocumentTextArea;
