import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import { connect, actions, selectors } from 'util/redux';

class ExpenseTypeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.expenseType },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.expenseType },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createExpenseType, updateExpenseType, onClose } = this.props;
    const { data } = this.state;

    let save = data.id ? updateExpenseType : createExpenseType;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, isSaving, errors } = this.state;

    return (
      <SidebarDialog
        onClose={onClose}
        open={open}
        title={
          data.id ? locale().expense_types.edit : locale().expense_types.new
        }
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().expense_types.name}
              error={errors.name}
            />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
              dateOnly={true}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingExpenseType(state),
    expenseType: selectors.getEditingExpenseType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createExpenseType: data => dispatch(actions.createExpenseType(data)),
    updateExpenseType: data => dispatch(actions.updateExpenseType(data)),
    onClose: () => dispatch(actions.finishExpenseTypeEditing()),
  };
};

ExpenseTypeDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseTypeDialog);

export default ExpenseTypeDialog;
