import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

let domNode = null;

class Portal extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    if (!domNode) {
      domNode = document.body.appendChild(document.createElement('div'));
      domNode.dataset.info = 'react portal';
    }

    return ReactDOM.createPortal(this.props.children, domNode);
  }
}

export default Portal;
