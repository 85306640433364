import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './FormLabel.module.scss';
import classNames from 'classnames';

class FormLabel extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['normal']),
    error: PropTypes.string,
    newFeature: PropTypes.bool,
  };

  static defaultProps = {
    size: 'normal',
    error: null,
    newFeature: false,
  };

  render() {
    const { value, size, error, newFeature } = this.props;

    let valueHint;
    if (error) {
      valueHint = <div className={styles['hint']}>{value}</div>;
    }

    return (
      <div className={styles['outer']}>
        {valueHint}
        <label
          className={classNames(styles['wrapper'], styles[size], {
            [styles['error']]: error !== null,
          })}
        >
          <span>{error || value}</span>
          {newFeature && (
            <div className={styles['new']}>{locale().system.new}</div>
          )}
        </label>
      </div>
    );
  }
}

export default FormLabel;
