import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_ReportsList.module.scss';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from './../_EmptyState';
import { CardRow } from 'ui/Card';
import reportImg from 'media/images/patients/report.jpg';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class ReportsList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchReportsForPatient } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchReportsForPatient()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  render() {
    const { reports, showPatientReport } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (reports.length === 0) {
      bodyNode = (
        <EmptyState
          image={reportImg}
          intro={locale().patient_reports.empty_state_heading}
        />
      );
    } else {
      bodyNode = (
        <div className={styles['wrapper']}>
          {reports
            .sortBy('date')
            .reverse()
            .map(report => (
              <CardRow
                key={report.id}
                onClick={() => showPatientReport(report.id)}
              >
                <div className={styles['reportType']}>
                  {report.report_type || '-'}
                </div>
                <div className={styles['date']}>
                  {report.date.format('dd. MMM yyyy.')}
                </div>
              </CardRow>
            ))}
        </div>
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    reports: selectors.getReportsForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    showPatientReport: id => dispatch(actions.showPatientReport(id)),
    fetchReportsForPatient: () =>
      dispatch(actions.fetchReportsForPatient(patientId)),
  };
};

ReportsList = connect(mapStateToProps, mapDispatchToProps)(ReportsList);

export default ReportsList;
