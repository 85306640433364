import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PackagesList.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { Card } from 'ui/Card';
import { SectionList } from 'ui/SectionList';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import { Button } from 'ui/Button';
import EmptyState from 'ui/EmptyState';
import emptyStateImg from 'media/images/services/packages.png';
import PackagesListItem from './_PackagesListItem';
import { connect, actions } from 'util/redux';

class PackagesList extends Component {
  static propTypes = {
    packages: PropTypes.array.isRequired,
  };

  _body = () => (
    <React.Fragment>
      {this.props.packages.sortBy('name').map(packageTreatment => (
        <PackagesListItem
          key={packageTreatment.id}
          packageTreatment={packageTreatment}
        />
      ))}
    </React.Fragment>
  );

  _mobile = () => {
    const { packages } = this.props;

    if (packages.length === 0) {
      return (
        <EmptyState
          heading={locale().packages.empty_state_heading}
          image={emptyStateImg}
          intro={locale().packages.empty_state_intro}
        />
      );
    } else {
      return (
        <SectionList leftHeadline={locale().packages.title}>
          {this._body()}
        </SectionList>
      );
    }
  };

  _desktop = () => {
    const { packages, newPackage } = this.props;

    if (packages.length === 0) {
      return (
        <div className={styles['emptyStateWrapper']}>
          <EmptyState
            heading={locale().packages.empty_state_heading}
            image={emptyStateImg}
            intro={locale().packages.empty_state_intro}
            actionText={locale().packages.new}
            onClick={newPackage}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <Toolbar>
            <ToolbarGroup />
            <ToolbarGroup>
              <Button value={locale().packages.new} onClick={newPackage} />
            </ToolbarGroup>
          </Toolbar>
          <Card leftHeadline={locale().packages.title}>{this._body()}</Card>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    newPackage: () => dispatch(actions.newPackage()),
  };
};

PackagesList = connect(null, mapDispatchToProps)(PackagesList);

export default PackagesList;
