import React, { Component } from 'react';
import PropTypes from 'prop-types';
import locale from 'util/locale';
import styles from './ImagePicker.module.scss';
import Icon from 'ui/Icon';

class ImagePicker extends Component {
  static propTypes = {
    url: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

  static defaultProps = {
    url: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      progress: null,
    };
    this._picker = React.createRef();
  }

  _triggerFilePicker = () => {
    if (this._picker) {
      this._picker.current.click();
    }
  };

  _onChange = e => {
    const { onChange } = this.props;
    const file = e.target.files[0];

    this.setState({ progress: 0, errors: {} });
    onChange(file, progress => this.setState({ progress }))
      .then(() => {
        this.setState({ progress: null });
      })
      .catch(response => {
        console.error(response);

        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              file: locale().system.global_error,
            },
          });
        }
        this.setState({ progress: null });
      });
  };

  render() {
    const { url, placeholder, onRemove } = this.props;
    const { errors, progress } = this.state;

    let imageNode;
    if (url) {
      imageNode = (
        <React.Fragment>
          <div className={styles['remove']}>
            <Icon icon={'close'} expand={true} onClick={onRemove} />
          </div>
          <img className={styles['image']} src={url} alt={''} />
        </React.Fragment>
      );
    } else if (progress === null) {
      imageNode = (
        <div className={styles['uploader']} onClick={this._triggerFilePicker}>
          <React.Fragment>
            <Icon icon={'image'} size={30} />
            <span>{placeholder}</span>
            <input
              type={'file'}
              style={{ position: 'absolute', left: '-10000px' }}
              onChange={this._onChange}
              ref={this._picker}
            />
          </React.Fragment>
        </div>
      );
    } else {
      imageNode = <div className={styles['progress']}>{progress}%</div>;
    }

    return (
      <div className={styles['outerWrapper']}>
        {imageNode}
        {errors.logo && <div className={styles['error']}>{errors.logo}</div>}
      </div>
    );
  }
}

export default ImagePicker;
