import Response from './../_response';
import { ServicePaymentSerializer } from './../_serializers';

class ServicePaymentsEndpoint {
  constructor(client) {
    this._client = client;
  }

  create(data) {
    return this._client
      .fetch(
        'service_payments',
        'POST',
        ServicePaymentSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = ServicePaymentSerializer.deserialize(json);
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  destroy(id) {
    return this._client
      .fetch(`service_payments/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(
          response,
          ServicePaymentSerializer.deserialize(json)
        );
      });
  }

  inflow(data) {
    return this._client
      .fetch(
        `service_payments/inflow`,
        'POST',
        ServicePaymentSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(p =>
              ServicePaymentSerializer.deserialize(p)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }
}

export default ServicePaymentsEndpoint;
