import React, { Component } from 'react';
import locale from 'util/locale';
import PermissionsRender from 'ui/PermissionsRender';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import HeaderBar from 'ui/HeaderBar';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import FAB from 'ui/FAB';
import Icon from 'ui/Icon';
import DateRange, { MobileDateRange } from 'ui/DateRange';
import { Button } from 'ui/Button';
import ProfitAndLossReport from './_ProfitAndLossReport';
import ExpenseTypesList from './_ExpenseTypesList';
import ExpensesList from './_ExpensesList';
import { periodRanges } from 'util/date_ranges';
import ActionCenter from 'util/action_center';
import { loadingStates, actions, connect, selectors } from 'util/redux';

class FinancesOverview extends Component {
  constructor(props) {
    super(props);
    this._periodRanges = periodRanges();
    this._periodRangesWithCustom = [
      ...this._periodRanges,
      {
        label: locale().dates.custom,
        value: null,
      },
    ];
    this.state = {
      range: this._periodRangesWithCustom[0].value,
      loadingState: loadingStates.fetching,
      dateRangeBottomSheetOpen: false,
    };
  }

  componentDidMount() {
    ActionCenter.subscribe('createExpense', this._reloadPLReport);
    ActionCenter.subscribe('updateExpense', this._reloadPLReport);
    ActionCenter.subscribe('destroyExpense', this._reloadPLReport);
    ActionCenter.subscribe('createExpenseType', this._reloadPLReport);
    ActionCenter.subscribe('updateExpenseType', this._reloadPLReport);
    ActionCenter.subscribe('destroyExpenseType', this._reloadPLReport);
    ActionCenter.subscribe('destroyExpenseType', this._reloadExpenses);
    this._fetch();
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('createExpense', this._reloadPLReport);
    ActionCenter.unsubscribe('updateExpense', this._reloadPLReport);
    ActionCenter.unsubscribe('destroyExpense', this._reloadPLReport);
    ActionCenter.unsubscribe('createExpenseType', this._reloadPLReport);
    ActionCenter.unsubscribe('updateExpenseType', this._reloadPLReport);
    ActionCenter.unsubscribe('destroyExpenseType', this._reloadPLReport);
    ActionCenter.unsubscribe('destroyExpenseType', this._reloadExpenses);
  }

  _openDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: true });

  _closeDateRangeBottomSheet = () =>
    this.setState({ dateRangeBottomSheetOpen: false });

  _fetch = () => {
    const {
      fetchProfitAndLossReport,
      fetchExpenseTypes,
      fetchExpenses,
    } = this.props;
    const { range } = this.state;

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([
      fetchProfitAndLossReport(range),
      fetchExpenseTypes(),
      fetchExpenses(range),
    ])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  };

  _reloadPLReport = () => this.props.fetchProfitAndLossReport(this.state.range);

  _reloadExpenses = () => this.props.fetchExpenses(this.state.range);

  _changeRange = range => this.setState({ range }, this._fetch);

  _mobileHeader = () => {
    const { newExpense, newExpenseType } = this.props;
    const { dateRangeBottomSheetOpen, range } = this.state;

    return (
      <React.Fragment>
        <HeaderBar
          title={locale().finances.title}
          rightContent={
            <React.Fragment>
              <Icon
                icon={'assessment'}
                color={'#FFFFFF'}
                expand={true}
                onClick={newExpenseType}
              />
              <Icon
                icon={'event_note'}
                color={'#FFFFFF'}
                expand={true}
                onClick={this._openDateRangeBottomSheet}
              />
            </React.Fragment>
          }
        />
        <MobileDateRange
          open={dateRangeBottomSheetOpen}
          onClose={this._closeDateRangeBottomSheet}
          ranges={this._periodRanges}
          onChange={this._changeRange}
          range={range}
        />
        <FAB onClick={newExpense} />
      </React.Fragment>
    );
  };

  _desktopHeader = () => (
    <Toolbar>
      <ToolbarGroup>
        <DateRange
          ranges={this._periodRangesWithCustom}
          onChange={this._changeRange}
          range={this.state.range}
        />
      </ToolbarGroup>
      <ToolbarGroup>
        <Button
          value={locale().expense_types.new}
          onClick={this.props.newExpenseType}
          actionType={'normal'}
        />
        <Button value={locale().expenses.new} onClick={this.props.newExpense} />
      </ToolbarGroup>
    </Toolbar>
  );

  render() {
    const { profitAndLossReport, expenseTypes, expenses } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else {
      bodyNode = (
        <React.Fragment>
          <ProfitAndLossReport report={profitAndLossReport} />
          {expenseTypes.length > 0 && (
            <ExpenseTypesList expenseTypes={expenseTypes.sortBy('name')} />
          )}
          {expenses.length > 0 && (
            <ExpensesList expenses={expenses.sortBy('date').reverse()} />
          )}
        </React.Fragment>
      );
    }

    return (
      <PermissionsRender permission={'finance_overview'} redirect={true}>
        <Navigator title={locale().finances.title} mountedAs={'finances'}>
          <React.Fragment>
            <MobileDesktopRender
              mobile={this._mobileHeader}
              desktop={this._desktopHeader}
            />
            {bodyNode}
          </React.Fragment>
        </Navigator>
      </PermissionsRender>
    );
  }
}

const mapStateToProps = state => {
  return {
    profitAndLossReport: selectors.getProfitAndLossReport(state),
    expenseTypes: selectors.getExpenseTypes(state),
    expenses: selectors.getExpenses(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProfitAndLossReport: data =>
      dispatch(actions.fetchProfitAndLossReport(data)),
    fetchExpenseTypes: () => dispatch(actions.fetchExpenseTypes()),
    fetchExpenses: data => dispatch(actions.fetchExpenses(data)),
    newExpense: () => dispatch(actions.newExpense()),
    newExpenseType: () => dispatch(actions.newExpenseType()),
  };
};

FinancesOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancesOverview);

export default FinancesOverview;
