import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel, TextArea } from 'ui/Form';
import { MoneyInput } from 'ui/Money';
import DatePicker from 'ui/DatePicker';
import ExpenseTypeSelect from 'finances/ExpenseTypeSelect';
import { connect, actions, selectors } from 'util/redux';

class ExpenseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.expense },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.expense },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createExpense, updateExpense, onClose } = this.props;
    const { data } = this.state;

    let save = data.id ? updateExpense : createExpense;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, isSaving, errors } = this.state;

    return (
      <SidebarDialog
        onClose={onClose}
        open={open}
        title={data.id ? locale().expenses.edit : locale().expenses.new}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().expenses.date} error={errors.date} />
            <DatePicker
              value={data.date}
              onChange={value => this._changeData('date', value)}
              dateOnly={true}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().expenses.expense_type}
              error={errors.expense_type_id}
            />
            <ExpenseTypeSelect
              value={data.expense_type_id}
              onChange={value => this._changeData('expense_type_id', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().expenses.amount} error={errors.amount} />
            <MoneyInput
              value={data.amount}
              onChange={value => this._changeData('amount', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().expenses.description}
              error={errors.description}
            />
            <TextArea
              value={data.description}
              onChange={value => this._changeData('description', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingExpense(state),
    expense: selectors.getEditingExpense(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createExpense: data => dispatch(actions.createExpense(data)),
    updateExpense: data => dispatch(actions.updateExpense(data)),
    onClose: () => dispatch(actions.finishExpenseEditing()),
  };
};

ExpenseDialog = connect(mapStateToProps, mapDispatchToProps)(ExpenseDialog);

export default ExpenseDialog;
