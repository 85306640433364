import { createSelector } from 'reselect';

const RECEIVE_QUERY = 'ENTITIES/RECEIVE_QUERY';
const RECEIVE_ENTITIES = 'ENTITIES/RECEIVE_ENTITIES';
const REMOVE_ENTITIES = 'ENTITIES/REMOVE_ENTITIES';
const CLEAR_QUERY = 'ENTITIES/CLEAR_QUERY';

const entityTypes = {
  diagnose: 'diagnose',
  patient_referral: 'patient_referral',
  patient: 'patient',
  service: 'service',
  service_item: 'service_item',
  patient_note: 'patient_note',
  appointment: 'appointment',
  service_payment: 'service_payment',
  patient_report: 'patient_report',
  patient_document: 'patient_document',
  patient_invoice: 'patient_invoice',
  treatment: 'treatment',
  package: 'package',
  employee: 'employee',
  invitation: 'invitation',
  location: 'location',
  subscription_plan: 'subscription_plan',
  post: 'post',
  expense: 'expense',
  expense_type: 'expense_type',
  working_stats: 'working_stats',
  notification: 'notification',
};

export default (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_QUERY:
      return handleReceiveQuery(state, action);
    case RECEIVE_ENTITIES:
      return handleReceiveEntities(state, action);
    case REMOVE_ENTITIES:
      return handleRemoveEntities(state, action);
    case CLEAR_QUERY:
      return handleClearQuery(state, action);
    default:
      return state;
  }
};

const getStateForEntityType = (state, entityType) => {
  if (state[entityType]) {
    return state[entityType];
  } else {
    return {
      byId: {},
    };
  }
};

const receiveQuery = (entityType, query) => {
  return {
    type: RECEIVE_QUERY,
    entityType,
    query,
  };
};

const handleReceiveQuery = (state, action) => {
  const newState = handleReceiveEntities(state, {
    entityType: action.entityType,
    entities: action.query.resources,
  });

  const query = { ...action.query };
  delete query.resources;

  return {
    ...newState,
    [action.entityType]: {
      ...newState[action.entityType],
      query,
    },
  };
};

const receiveEntities = (entityType, entities) => {
  return {
    type: RECEIVE_ENTITIES,
    entityType,
    entities,
  };
};

const handleReceiveEntities = (state, action) => {
  let entityTypeState = getStateForEntityType(state, action.entityType);
  let receivedEntitiesById = {};
  action.entities.forEach(entity => (receivedEntitiesById[entity.id] = entity));
  return {
    ...state,
    [action.entityType]: {
      ...entityTypeState,
      byId: {
        ...entityTypeState.byId,
        ...receivedEntitiesById,
      },
    },
  };
};

const removeEntities = (entityType, entityIds) => {
  return {
    type: REMOVE_ENTITIES,
    entityType,
    entityIds,
  };
};

const handleRemoveEntities = (state, action) => {
  let entityTypeState = getStateForEntityType(state, action.entityType);
  let newEntitiesById = {};
  Object.keys(entityTypeState.byId).forEach(id => {
    if (action.entityIds.indexOf(id) === -1) {
      newEntitiesById[id] = entityTypeState.byId[id];
    }
  });
  return {
    ...state,
    [action.entityType]: {
      ...entityTypeState,
      byId: newEntitiesById,
    },
  };
};

const clearQuery = entityType => {
  return {
    type: CLEAR_QUERY,
    entityType,
  };
};

const handleClearQuery = (state, action) => {
  const entityTypeState = getStateForEntityType(state, action.entityType);
  return {
    ...state,
    [action.entityType]: {
      ...entityTypeState,
      query: {},
      byId: {},
    },
  };
};

const getByIdForType = (state, entityType) => (state[entityType] || {}).byId;

const makeGetEntityList = () => {
  return createSelector([getByIdForType], entitiesOfTypeById => {
    if (!entitiesOfTypeById) {
      return [];
    } else {
      return Object.values(entitiesOfTypeById);
    }
  });
};

const getQuery = (state, entityType) => {
  return getStateForEntityType(state, entityType).query || {};
};

const getEntityById = (state, entityType, id) => {
  return getStateForEntityType(state, entityType).byId[id] || null;
};

export {
  entityTypes,
  receiveQuery,
  receiveEntities,
  removeEntities,
  clearQuery,
  makeGetEntityList,
  getQuery,
  getEntityById,
};
