import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';
import ActionCenter from 'util/action_center';

const START_PATIENT_REFERRAL_EDITING = 'START_PATIENT_REFERRAL_EDITING';
const FINISH_PATIENT_REFERRAL_EDITING = 'FINISH_PATIENT_REFERRAL_EDITING';

export default (
  state = {
    editingPatientReferral: emptyPatientReferralData(),
    patientReferralDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_PATIENT_REFERRAL_EDITING:
      return {
        ...state,
        editingPatientReferral: action.referral,
        patientReferralDialogOpen: true,
      };
    case FINISH_PATIENT_REFERRAL_EDITING:
      return {
        ...state,
        editingPatientReferral: emptyPatientReferralData(),
        patientReferralDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyPatientReferralData = () => ({
  name: '',
});

const newPatientReferral = () => {
  return {
    type: START_PATIENT_REFERRAL_EDITING,
    expense: emptyPatientReferralData(),
  };
};

const finishPatientReferralEditing = () => {
  return {
    type: FINISH_PATIENT_REFERRAL_EDITING,
  };
};

const fetchPatientReferrals = () => {
  return dispatch => {
    return Api.patient_referrals.query().then(response => {
      if (response.isOk()) {
        dispatch(clearQuery(entityTypes.patient_referral));
        const query = response.data();
        dispatch(receiveQuery(entityTypes.patient_referral, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatientReferral = data => {
  return dispatch => {
    return Api.patient_referrals.create(data).then(response => {
      if (response.isOk()) {
        const patient_referral = response.data();
        dispatch(
          receiveEntities(entityTypes.patient_referral, [patient_referral])
        );
        dispatch(
          systemActions.showToast(
            locale().patient_referrals.patient_referral_saved
          )
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPatientReferral = id => {
  return dispatch => {
    return Api.patient_referrals.destroy(id).then(response => {
      if (response.isOk()) {
        const patient_referral = response.data();
        dispatch(
          removeEntities(entityTypes.patient_referral, [patient_referral.id])
        );
        dispatch(
          systemActions.showToast(
            locale().patient_referrals.patient_referral_destroyed
          )
        );
        ActionCenter.publish('destroyPatientReferral');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPatientReferral,
  finishPatientReferralEditing,
  fetchPatientReferrals,
  createPatientReferral,
  destroyPatientReferral,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getPatientReferrals = state =>
  getEntityList(state, entityTypes.patient_referral);

const getEditingPatientReferral = state => state.editingPatientReferral;

const isEditingPatientReferral = state => state.patientReferralDialogOpen;

export const selectors = {
  getPatientReferrals,
  getEditingPatientReferral,
  isEditingPatientReferral,
};
