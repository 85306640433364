class LocalStorage {
  constructor() {
    this.keys = {
      language: 'language',
      schedule: {
        resources_type: 'schedule.resources_type',
      },
    };

    if (typeof Storage !== 'undefined') {
      this._storage = window.localStorage;
    } else {
      console.warn('Storage not present');
      this._storage = {
        getItem: () => {},
        setItem: () => {},
        removeItem: () => {},
      };
    }
  }

  get(id) {
    const item = this._storage.getItem(id);
    if (item === 'undefined' || item === undefined) {
      return undefined;
    } else {
      return JSON.parse(item);
    }
  }

  set(id, item) {
    this._storage.setItem(id, JSON.stringify(item));
  }

  remove(id) {
    this._storage.removeItem(id);
  }
}

export default new LocalStorage();
