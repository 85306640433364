import React, { Component } from 'react';
import locale from 'util/locale';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import Invoice from './_Invoice';
import Document from './../_Document';
import DueDatePicker from './_DueDatePicker';
import { FormLabel } from 'ui/Form';
import GroupServicesDialog from './_GroupServicesDialog';
import { loadingStates, actions, connect, selectors } from 'util/redux';

class PatientInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      data: {},
      isSaving: false,
      errorOccurred: false,
      groupServicesDialogOpen: false,
    };

    this._debouncedSave = this._savePatientInvoice.debounce(250);
  }

  componentDidMount() {
    this._fetchInvoice();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.invoice !== this.props.invoice) {
      this.setState({ data: { ...this.props.invoice } });
    }
  }

  _fetchInvoice = () => {
    const { fetchOnePatientInvoice } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchOnePatientInvoice()
      .then(() => {
        this.setState({ loadingState: loadingStates.present });
      })
      .catch(response => {
        console.error(response);
        if (response.isNotFound && response.isNotFound()) {
          this.setState({ loadingState: loadingStates.missing });
        } else {
          this.setState({ loadingState: loadingStates.failed });
        }
      });
  };

  _updatePatientInvoice = (key, value) => {
    const { data } = this.state;
    this.setState({ data: { ...data, [key]: value } }, this._invokeSaving);
  };

  _invokeSaving = () => {
    this.setState({ isSaving: true, errorOccurred: false });
    this._debouncedSave();
  };

  _savePatientInvoice = () => {
    const { updatePatientInvoice } = this.props;
    const { data } = this.state;

    updatePatientInvoice(data)
      .then(() => this.setState({ isSaving: false }))
      .catch(response => {
        console.error(response);
        this.setState({ isSaving: false, errorOccurred: true });
      });
  };

  _destroyPatientInvoice = () => {
    const { destroyPatientInvoice } = this.props;
    destroyPatientInvoice();
  };

  _uploadLogo = (logo, onProgress) => {
    const { createOrganisationLogo } = this.props;
    return createOrganisationLogo({ logo }, onProgress).then(
      this._fetchInvoice
    );
  };

  _removeLogo = () => {
    const { destroyOrganisationLogo } = this.props;
    destroyOrganisationLogo().then(this._fetchInvoice);
  };

  _openGroupServicesDialog = () =>
    this.setState({ groupServicesDialogOpen: true });

  _closeGroupServicesDialog = () =>
    this.setState({ groupServicesDialogOpen: false });

  render() {
    const { invoice, ungroupPatientInvoiceServices } = this.props;
    const {
      loadingState,
      data,
      isSaving,
      errorOccurred,
      groupServicesDialogOpen,
    } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else if (loadingState === loadingStates.missing) {
      bodyNode = <FailureInfo info={locale().patient_invoices.not_found} />;
    } else {
      bodyNode = (
        <React.Fragment>
          <Document
            stripe={10}
            document={data}
            onUpdate={this._updatePatientInvoice}
            isSaving={isSaving}
            errorOccurred={errorOccurred}
            onDestroy={this._destroyPatientInvoice}
            destroyConfirmation={{
              headText: locale().patient_invoices.destroy_patient_invoice_head,
              noticeText: locale().patient_invoices
                .destroy_patient_invoice_notice,
            }}
            pdfUrl={invoice.pdf_url}
            sideContent={
              <div>
                <FormLabel value={locale().patient_invoices.due_date} />
                <DueDatePicker
                  value={data.due_date_days}
                  onChange={value =>
                    this._updatePatientInvoice('due_date_days', value)
                  }
                />
              </div>
            }
          >
            <Invoice
              invoice={data}
              onUpdate={this._updatePatientInvoice}
              onUploadLogo={this._uploadLogo}
              onRemoveLogo={this._removeLogo}
              onGroupServicesDialog={this._openGroupServicesDialog}
              onUngroupServicesDialog={ungroupPatientInvoiceServices}
            />
          </Document>
          <GroupServicesDialog
            open={groupServicesDialogOpen}
            onClose={this._closeGroupServicesDialog}
            patientInvoiceId={invoice.id}
          />
        </React.Fragment>
      );
    }

    return bodyNode;
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    invoice: selectors.getPatientInvoice(state, id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    fetchOnePatientInvoice: () => dispatch(actions.fetchOnePatientInvoice(id)),
    updatePatientInvoice: data => dispatch(actions.updatePatientInvoice(data)),
    destroyPatientInvoice: () =>
      dispatch(actions.destroyPatientInvoice(id, true)),
    createOrganisationLogo: (data, onProgress) =>
      dispatch(actions.createOrganisationLogo(data, onProgress)),
    destroyOrganisationLogo: () => dispatch(actions.destroyOrganisationLogo()),
    ungroupPatientInvoiceServices: () =>
      dispatch(actions.ungroupPatientInvoiceServices(id)),
  };
};

PatientInvoice = connect(mapStateToProps, mapDispatchToProps)(PatientInvoice);

export default PatientInvoice;
