import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui/Card';
import EmployeesListItem from './_EmployeesListItem';

class EmployeesList extends Component {
  static propTypes = {
    employees: PropTypes.array.isRequired,
    stats: PropTypes.array.isRequired,
  };

  _renderItem = employee => {
    const { stats } = this.props;

    const statsForEmployee = stats.find(s => s.user.id === employee.id);

    if (!statsForEmployee) {
      return null; // When re fetching, for moment it is null
    }

    return (
      <EmployeesListItem
        key={employee.id}
        employee={employee}
        statsId={statsForEmployee.id}
      />
    );
  };

  render() {
    const { employees } = this.props;

    return (
      <React.Fragment>
        <Card>
          {employees
            .filter(e => !e.archived)
            .sortBy('name')
            .map(this._renderItem)}
          {employees
            .filter(e => e.archived)
            .sortBy('name')
            .map(this._renderItem)}
        </Card>
      </React.Fragment>
    );
  }
}

export default EmployeesList;
