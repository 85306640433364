import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Line from 'ui/Chart/Line';

class ProgressChart extends Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
  };

  _labelFormat = date => date.format('dd. MMM');

  render() {
    const notes = this.props.notes.sortBy('date').slice(-20);

    return (
      <Line
        listOfDataForEntities={[
          {
            colors: {
              border: 'rgba(212, 181, 28, 1)',
              background: 'rgba(212, 181, 28, 0.1)',
              point: '#d4b51c',
            },
            label: 'notes',
            entities: notes.map(n => ({ value: n.progress_rate })),
          },
        ]}
        labels={{
          values: notes.map(n => n.date),
          mobileFormat: this._labelFormat,
          desktopFormat: this._labelFormat,
        }}
        ticks={{
          minFallback: 0,
          maxFallback: 5,
          stepFallback: 1,
        }}
      />
    );
  }
}

export default ProgressChart;
