import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './EmployeeSelectUI.module.scss';
import { Select } from 'ui/Form';
import BottomSheet from 'ui/Dialog/BottomSheet';
import MobileDesktopRender from 'ui/MobileDesktopRender';

class EmployeeSelectUI extends Component {
  static propTypes = {
    value: PropTypes.string,
    employees: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    clearable: PropTypes.bool,
    renderOnMobile: PropTypes.bool,
    mobileClearable: PropTypes.bool,
    mobileOpen: PropTypes.bool,
    onMobileClose: PropTypes.func,
  };

  static defaultProps = {
    clearable: true,
    renderOnMobile: true,
    mobileClearable: false,
    mobileOpen: false,
    onMobileClose: () => {},
  };

  static optionRenderer = option => (
    <div className={styles['employee']}>
      <div className={styles['circle']} style={{ background: option.color }} />
      {option.label}
    </div>
  );

  _options = () => {
    const { employees } = this.props;

    return employees.sortBy('name').map(employee => ({
      value: employee.id,
      label: employee.name,
      color: employee.color,
    }));
  };

  _mobile = () => {
    const {
      value,
      placeholder,
      mobileOpen,
      onMobileClose,
      onChange,
      mobileClearable,
    } = this.props;

    let options = this._options();
    if (mobileClearable) {
      options = [
        { value: null, label: placeholder, color: '#949bac' },
        ...options,
      ];
    }

    return (
      <BottomSheet
        open={mobileOpen}
        onClose={onMobileClose}
        items={options}
        itemRenderer={EmployeeSelectUI.optionRenderer}
        onSelect={onChange}
        selectedItem={value}
      />
    );
  };

  _desktop = () => {
    const { value, onChange, placeholder, clearable } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        searchable={false}
        clearable={clearable}
        options={this._options()}
        optionRenderer={EmployeeSelectUI.optionRenderer}
      />
    );
  };

  render() {
    const { renderOnMobile } = this.props;

    if (renderOnMobile) {
      return this._desktop();
    } else {
      return (
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      );
    }
  }
}

export default EmployeeSelectUI;
