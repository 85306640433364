import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { FormRow, FormColumn, FormLabel } from 'ui/Form';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import TreatmentSelect from 'services/TreatmentSelect';
import { connect, actions } from 'util/redux';

class NewServiceItemDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    serviceId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this._emptyData(),
      errors: {},
      isSaving: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: this._emptyData(),
        errors: {},
      });
    }
  }

  _emptyData = () => ({ treatmentId: null });

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createServiceItem, onClose, serviceId } = this.props;
    const { data } = this.state;

    if (!data.treatmentId) {
      this.setState({
        errors: { treatment_id: locale().validations.field_required },
      });
      return;
    }

    this.setState({ errors: {}, isSaving: true });
    createServiceItem({
      treatment_id: data.treatmentId,
      service_id: serviceId,
    })
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, errors } = this.state;

    return (
      <SidebarDialog
        onClose={onClose}
        open={open}
        title={locale().service_items.add_treatment}
        saveText={locale().service_items.add_treatment}
        onSave={this._save}
      >
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().service_items.select_treatment}
              error={errors.treatment_id}
            />
            <TreatmentSelect
              value={data.treatmentId}
              onChange={value => this._changeData('treatmentId', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createServiceItem: data => dispatch(actions.createServiceItem(data)),
  };
};

NewServiceItemDialog = connect(null, mapDispatchToProps)(NewServiceItemDialog);

export default NewServiceItemDialog;
