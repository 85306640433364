import React, { Component } from 'react';
import LocationSelectUI from './LocationSelectUI';
import { connect, actions, selectors } from 'util/redux';

class LocationSelect extends Component {
  componentDidMount() {
    const { fetchLocations } = this.props;
    fetchLocations();
  }

  render() {
    return <LocationSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    locations: selectors.getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchLocations: () => dispatch(actions.fetchLocations()),
  };
};

LocationSelect = connect(mapStateToProps, mapDispatchToProps)(LocationSelect);

export default LocationSelect;
