import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './RatePicker.module.scss';
import Icon from 'ui/Icon';

class RatePicker extends Component {
  static propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    max: PropTypes.number,
    size: PropTypes.oneOf(['large', 'small']),
  };

  static defaultProps = {
    value: 0,
    readOnly: false,
    max: 5,
    size: 'large',
  };

  constructor(props) {
    super(props);
    this.state = {
      hoveredStarInd: -1,
    };
  }

  _sizes = {
    large: 32,
    small: 16,
  };

  _colors = {
    star: '#d4b51c',
    star_outline: '#dce0e7',
  };

  _starHovered = ind => {
    const { readOnly } = this.props;

    if (readOnly) {
      return;
    }

    this.setState({ hoveredStarInd: ind });
  };

  _starLeaved = () => {
    const { readOnly } = this.props;

    if (readOnly) {
      return;
    }

    this.setState({ hoveredStarInd: -1 });
  };

  _onChange = ind => {
    const { readOnly, onChange } = this.props;

    if (readOnly) {
      return;
    }

    onChange(ind + 1);
  };

  _icon = ind => {
    const { value } = this.props;
    const { hoveredStarInd } = this.state;

    if (hoveredStarInd >= ind || (hoveredStarInd === -1 && value - 1 >= ind)) {
      return 'star';
    } else {
      return 'star_outline';
    }
  };

  render() {
    const { size, max } = this.props;

    return (
      <div className={styles['stars']}>
        {Array(max)
          .fill(0)
          .map((_, ind) => {
            const icon = this._icon(ind);
            return (
              <div
                key={ind}
                onMouseEnter={() => this._starHovered(ind)}
                onMouseLeave={this._starLeaved}
                onClick={() => this._onChange(ind)}
              >
                <Icon
                  icon={icon}
                  size={this._sizes[size]}
                  color={this._colors[icon]}
                />
              </div>
            );
          })}
      </div>
    );
  }
}

export default RatePicker;
