import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './InflowDialog.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel } from 'ui/Form';
import PatientSelect from 'patients/PatientSelect';
import { MoneyInput, PaymentTypeSelect } from 'ui/Money';
import DatePicker from 'ui/DatePicker';
import { connect, actions, selectors } from 'util/redux';

class InflowDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      errors: {},
      data: { ...props.editingInflow },
      patientKnown: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { editingInflow } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...editingInflow },
        patientKnown: !!editingInflow.patient_id,
        errors: {},
      });
    }
  }

  _createPayment = () => {
    const { inflowServicePayments, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    inflowServicePayments(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else if (response.isBadRequest && response.isBadRequest()) {
          this.setState({
            errors: {
              _global: locale().service_payments.inflow_overflow_error,
            },
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _changeData = (key, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [key]: value,
      },
    });
  };

  _patientSelectNode = () => {
    const { errors, data, patientKnown } = this.state;

    if (patientKnown) {
      return null;
    }

    return (
      <FormRow>
        <FormColumn>
          <FormLabel
            value={locale().service_payments.patient}
            error={errors.patient_id}
          />
          <PatientSelect
            value={data.patient_id}
            onChange={value => this._changeData('patient_id', value)}
            renderer={'debt'}
          />
        </FormColumn>
      </FormRow>
    );
  };

  _dateInputNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel value={locale().service_payments.date} error={errors.date} />
        <DatePicker
          value={data.date}
          onChange={value => this._changeData('date', value)}
          dateOnly={true}
        />
      </FormColumn>
    );
  };

  _paymentTypeSelectNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel
          value={locale().service_payments.payment_type}
          error={errors.payment_type}
        />
        <PaymentTypeSelect
          value={data.payment_type}
          onChange={value => this._changeData('payment_type', value)}
        />
      </FormColumn>
    );
  };

  _amountInputNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel
          value={locale().service_payments.amount}
          error={errors.amount}
        />
        <MoneyInput
          value={data.amount}
          onChange={value => this._changeData('amount', value)}
        />
      </FormColumn>
    );
  };

  _mobileForm = () => (
    <React.Fragment>
      {this._patientSelectNode()}
      <FormRow>{this._dateInputNode()}</FormRow>
      <FormRow>{this._paymentTypeSelectNode()}</FormRow>
      <FormRow>{this._amountInputNode()}</FormRow>
    </React.Fragment>
  );

  _desktopForm = () => (
    <React.Fragment>
      {this._patientSelectNode()}
      <FormRow>
        {this._dateInputNode()}
        {this._paymentTypeSelectNode()}
      </FormRow>
      <FormRow>{this._amountInputNode()}</FormRow>
    </React.Fragment>
  );

  render() {
    const { open, onClose } = this.props;
    const { errors, isSaving } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={locale().service_payments.new}
        onSave={this._createPayment}
        saveText={locale().service_payments.new}
        error={errors._global}
        inProgress={isSaving}
        footer={
          <div className={styles['hint']}>
            {locale().service_payments.payment_hint}
          </div>
        }
      >
        <MobileDesktopRender
          mobile={this._mobileForm}
          desktop={this._desktopForm}
        />
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingInflow(state),
    editingInflow: selectors.getEditingInflow(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    inflowServicePayments: data =>
      dispatch(actions.inflowServicePayments(data)),
    onClose: () => dispatch(actions.finishInflowEditing()),
  };
};

InflowDialog = connect(mapStateToProps, mapDispatchToProps)(InflowDialog);

export default InflowDialog;
