import locale from 'util/locale';
import Api from 'util/api/api';
import { push } from 'connected-react-router';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_LOCATION_EDITING = 'START_LOCATION_EDITING';
const FINISH_LOCATION_EDITING = 'FINISH_LOCATION_EDITING';

export default (
  state = {
    editingLocation: emptyLocationData(),
    locationDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_LOCATION_EDITING:
      return {
        ...state,
        editingLocation: action.location,
        locationDialogOpen: true,
      };
    case FINISH_LOCATION_EDITING:
      return {
        ...state,
        editingLocation: emptyLocationData(),
        locationDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyLocationData = () => ({
  name: '',
  capacity: 1,
});

const newLocation = () => {
  return {
    type: START_LOCATION_EDITING,
    location: emptyLocationData(),
  };
};

const editLocation = location => {
  return {
    type: START_LOCATION_EDITING,
    location,
  };
};

const finishLocationEditing = () => {
  return {
    type: FINISH_LOCATION_EDITING,
  };
};

const openLocationsPage = () => push('/locations');

const fetchLocations = () => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.location));
    return Api.locations.query().then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.location, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createLocation = data => {
  return dispatch => {
    return Api.locations.create(data).then(response => {
      if (response.isOk()) {
        const location = response.data();
        dispatch(receiveEntities(entityTypes.location, [location]));
        dispatch(systemActions.showToast(locale().locations.location_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateLocation = data => {
  return dispatch => {
    return Api.locations.update(data).then(response => {
      if (response.isOk()) {
        const location = response.data();
        dispatch(receiveEntities(entityTypes.location, [location]));
        dispatch(systemActions.showToast(locale().locations.location_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyLocation = id => {
  return dispatch => {
    return Api.locations.destroy(id).then(response => {
      if (response.isOk()) {
        const location = response.data();
        dispatch(removeEntities(entityTypes.location, [location.id]));
        dispatch(
          systemActions.showToast(locale().locations.location_destroyed)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openLocationsPage,
  newLocation,
  editLocation,
  finishLocationEditing,
  fetchLocations,
  createLocation,
  updateLocation,
  destroyLocation,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getLocations = state => getEntityList(state, entityTypes.location);

const getEditingLocation = state => state.editingLocation;

const isEditingLocation = state => state.locationDialogOpen;

export const selectors = {
  getLocations,
  getEditingLocation,
  isEditingLocation,
};
