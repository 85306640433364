import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, TextInput, TextArea, FormLabel } from 'ui/Form';
import { connect, actions, selectors } from 'util/redux';

class PostDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.post },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.post },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { createPost, updatePost, onClose } = this.props;
    const { data } = this.state;

    let save = data.id ? updatePost : createPost;

    this.setState({ isSaving: true, errors: {} });
    save(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, isSaving, errors } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={data.id ? locale().posts.edit : locale().posts.new}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().posts.post_title} error={errors.title} />
            <TextInput
              value={data.title}
              onChange={value => this._changeData('title', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().posts.post_content}
              error={errors.content}
            />
            <TextArea
              value={data.content}
              onChange={value => this._changeData('content', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingPost(state),
    post: selectors.getEditingPost(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createPost: data => dispatch(actions.createPost(data)),
    updatePost: data => dispatch(actions.updatePost(data)),
    onClose: () => dispatch(actions.finishPostEditing()),
  };
};

PostDialog = connect(mapStateToProps, mapDispatchToProps)(PostDialog);

export default PostDialog;
