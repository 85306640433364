import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Line from 'ui/Chart/Line';
import { formatMoney } from 'util/helpers';
import { connect, selectors } from 'util/redux';

class MoneyFlowChart extends Component {
  static propTypes = {
    payments: PropTypes.array.isRequired,
    expenses: PropTypes.array.isRequired,
  };

  _labelsValues = () => {
    let labels = [];

    for (let i = 11; i >= 0; i--) {
      labels.push(new Date().subMonths(i));
    }

    return labels;
  };

  _fillRecords = records => {
    let filledRecords = [...records];

    this._labelsValues().forEach(date => {
      const statsAtDate = records.find(p => p.date.isAtSameYearAndMonth(date));

      if (!statsAtDate) {
        filledRecords.push({
          amount: 0,
          date: date,
        });
      }
    });

    return filledRecords.sortBy('date');
  };

  _mobileLabelFormat = date => date.format('MMM').capitalize();

  _desktopLabelFormat = date => date.format('MMMM').capitalize();

  _formatTicks = value => {
    let parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const formatted = parts.join('.');
    return formatMoney(formatted, this.props.currentUser.organisation.currency);
  };

  _formatTooltip = value =>
    formatMoney(value, this.props.currentUser.organisation.currency);

  render() {
    return (
      <Line
        listOfDataForEntities={[
          {
            label: 'incomes',
            colors: {
              border: 'rgba(70, 172, 90, 1)',
              background: 'rgba(70, 172, 90, 0.1)',
              point: '#46ac5a',
            },
            entities: this._fillRecords(this.props.payments).map(payment => ({
              value: payment.amount,
            })),
          },
          {
            label: 'expenses',
            colors: {
              border: 'rgba(216, 43, 43, 1)',
              background: 'rgba(216, 43, 43, 0.1)',
              point: '#d82b2b',
            },
            entities: this._fillRecords(this.props.expenses).map(payment => ({
              value: payment.amount,
            })),
          },
        ]}
        labels={{
          values: this._labelsValues(),
          mobileFormat: this._mobileLabelFormat,
          desktopFormat: this._desktopLabelFormat,
        }}
        ticks={{
          format: this._formatTicks,
          minFallback: 0,
          maxFallback: 25000,
          stepFallback: 5000,
        }}
        tooltip={{ format: this._formatTooltip }}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
  };
};

MoneyFlowChart = connect(mapStateToProps)(MoneyFlowChart);

export default MoneyFlowChart;
