import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './PaymentsDialog.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { SectionList, SectionListItem } from 'ui/SectionList';
import { FormRow, FormColumn, FormLabel } from 'ui/Form';
import { MoneyInput, MoneyText, PaymentTypeSelect } from 'ui/Money';
import DatePicker from 'ui/DatePicker';
import Icon, { PaymentIcon } from 'ui/Icon';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class PaymentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
      isSaving: false,
      errors: {},
      data: { ...props.servicePayment },
    };
  }

  componentDidUpdate(prevProps) {
    const {
      fetchOneService,
      fetchPaymentsForService,
      servicePayment,
    } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...servicePayment },
        errors: {},
        loadingState: loadingStates.fetching,
      });

      Promise.all([
        fetchOneService(servicePayment.service_id),
        fetchPaymentsForService(servicePayment.service_id),
      ])
        .then(() => {
          this.setState({
            data: {
              ...servicePayment,
              amount: this.props.service.debt,
            },
            loadingState: loadingStates.present,
          });
        })
        .catch(response => {
          console.error(response);
          this.setState({ loadingState: loadingStates.failed });
        });
    }
  }

  _createPayment = () => {
    const { createServicePayment, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createServicePayment(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else if (response.isBadRequest && response.isBadRequest()) {
          this.setState({
            errors: {
              _global: locale().service_payments.overflow_error,
            },
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _destroyPayment = paymentId => {
    const { destroyServicePayment } = this.props;

    this.setState({ errors: {} });
    destroyServicePayment(paymentId).catch(response => {
      console.error(response);
      this.setState({
        errors: {
          _global: locale().system.global_error,
        },
      });
    });
  };

  _changeData = (key, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [key]: value,
      },
    });
  };

  _paymentTypeIcon = paymentType => (
    <PaymentIcon paymentType={paymentType} size={17} />
  );

  _mobileFooter = () => {
    const { payments } = this.props;

    return (
      <div className={styles['payments']}>
        <SectionList>
          {payments.sortBy('date').map(payment => (
            <SectionListItem
              key={payment.id}
              leftContent={this._paymentTypeIcon(payment.payment_type)}
              title={payment.date.format('dd.MM.yyyy.')}
              rightContent={<MoneyText value={payment.amount} />}
              contextMenuItems={[
                {
                  label: locale().actions.destroy,
                  onClick: () => this._destroyPayment(payment.id),
                  type: 'attention',
                },
              ]}
            />
          ))}
        </SectionList>
      </div>
    );
  };

  _desktopFooter = () => {
    const { payments } = this.props;

    return (
      <div className={styles['payments']}>
        {payments.sortBy('date').map(payment => (
          <div key={payment.id} className={styles['payment']}>
            <div className={styles['item']}>
              {this._paymentTypeIcon(payment.payment_type)}
              <div className={styles['date']}>
                {payment.date.format('dd.MM.yyyy.')}
              </div>
            </div>

            <div className={styles['item']}>
              <MoneyText value={payment.amount} />
              <div className={styles['delete']}>
                <Icon
                  icon={'delete'}
                  onClick={() => this._destroyPayment(payment.id)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  _dateInputNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel value={locale().service_payments.date} error={errors.date} />
        <DatePicker
          value={data.date}
          onChange={value => this._changeData('date', value)}
          dateOnly={true}
        />
      </FormColumn>
    );
  };

  _paymentTypeSelectNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel
          value={locale().service_payments.payment_type}
          error={errors.payment_type}
        />
        <PaymentTypeSelect
          value={data.payment_type}
          onChange={value => this._changeData('payment_type', value)}
        />
      </FormColumn>
    );
  };

  _amountInputNode = () => {
    const { errors, data } = this.state;

    return (
      <FormColumn>
        <FormLabel
          value={locale().service_payments.amount}
          error={errors.amount}
        />
        <MoneyInput
          value={data.amount}
          onChange={value => this._changeData('amount', value)}
        />
      </FormColumn>
    );
  };

  _mobileForm = () => (
    <React.Fragment>
      <FormRow>{this._dateInputNode()}</FormRow>
      <FormRow>{this._paymentTypeSelectNode()}</FormRow>
      <FormRow>{this._amountInputNode()}</FormRow>
    </React.Fragment>
  );

  _desktopForm = () => (
    <React.Fragment>
      <FormRow>
        {this._dateInputNode()}
        {this._paymentTypeSelectNode()}
      </FormRow>
      <FormRow>{this._amountInputNode()}</FormRow>
    </React.Fragment>
  );

  render() {
    const { open, onClose, payments } = this.props;
    const { errors, loadingState, isSaving } = this.state;

    let footer;
    if (payments.length > 0) {
      footer = (
        <MobileDesktopRender
          mobile={this._mobileFooter}
          desktop={this._desktopFooter}
        />
      );
    }

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={locale().service_payments.title}
        onSave={this._createPayment}
        saveText={locale().service_payments.new}
        loadingState={loadingState}
        error={errors._global}
        inProgress={isSaving}
        footer={footer}
      >
        <MobileDesktopRender
          mobile={this._mobileForm}
          desktop={this._desktopForm}
        />
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  const servicePayment = selectors.getEditingServicePayment(state);

  return {
    open: selectors.isEditingServicePayment(state),
    service: selectors.getService(state, servicePayment.service_id),
    servicePayment: servicePayment,
    payments: selectors.getPaymentsForService(state, servicePayment.service_id),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOneService: serviceId => dispatch(actions.fetchOneService(serviceId)),
    fetchPaymentsForService: serviceId =>
      dispatch(actions.fetchPaymentsForService(serviceId)),
    createServicePayment: data => dispatch(actions.createServicePayment(data)),
    destroyServicePayment: id => dispatch(actions.destroyServicePayment(id)),
    onClose: () => dispatch(actions.finishServicePaymentEditing()),
  };
};

PaymentDialog = connect(mapStateToProps, mapDispatchToProps)(PaymentDialog);

export default PaymentDialog;
