Object.map = function (object, func, ctx) {
  ctx = ctx || this;
  const result = {};

  Object.keys(object).forEach(function (k) {
    result[k] = func.call(ctx, object[k], k, object);
  });

  return result;
};

Object.filter = function (object, func, ctx) {
  ctx = ctx || this;
  const result = {};

  Object.keys(object).forEach(function (k) {
    if (func.call(ctx, object[k], k, object)) {
      result[k] = object[k];
    }
  });

  return result;
};

Object.same = function (object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  } else {
    return !keys1.find(key => object1[key] !== object2[key]);
  }
};
