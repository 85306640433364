import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './_PatientsList.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionList } from 'ui/SectionList';
import PatientListItem from './_PatientsListItem';

class PatientsList extends Component {
  static propTypes = {
    patients: PropTypes.array.isRequired,
  };

  _body = () => (
    <React.Fragment>
      {this.props.patients.map(patient => (
        <PatientListItem key={patient.id} patient={patient} />
      ))}
    </React.Fragment>
  );

  _mobile = () => <SectionList>{this._body()}</SectionList>;

  _desktop = () => <div className={styles['wrapper']}>{this._body()}</div>;

  render() {
    const { patients } = this.props;

    if (patients.length === 0) {
      return null;
    }

    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default PatientsList;
