import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './FilePicker.module.scss';
import Icon from 'ui/Icon';

class FilePicker extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this._picker = React.createRef();
  }

  _triggerFilePicker = () => {
    if (this._picker) {
      this._picker.current.click();
    }
  };

  _onChange = e => {
    const { onChange } = this.props;
    const file = e.target.files[0];

    onChange(file);
  };

  render() {
    const { placeholder } = this.props;

    return (
      <div className={styles['wrapper']} onClick={this._triggerFilePicker}>
        <div className={styles['placeholder']}>{placeholder}</div>
        <div className={styles['icon']}>
          <Icon icon={'cloud_upload'} size={22} />
        </div>
        <input
          type={'file'}
          style={{ position: 'absolute', left: '-10000px' }}
          onChange={this._onChange}
          ref={this._picker}
        />
      </div>
    );
  }
}

export default FilePicker;
