import locale, { setLanguage } from 'util/locale';
import Api from 'util/api/api';
import { push } from 'connected-react-router';
import { actions as systemActions } from './_system';

const RECEIVE_CURRENT_USER = 'RECEIVE_CURRENT_USER';
const PATCH_CURRENT_USER = 'PATCH_CURRENT_USER';

export default (
  state = {
    user: null,
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_CURRENT_USER:
      return {
        ...state,
        user: action.user,
      };
    case PATCH_CURRENT_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

// ACTIONS

const openUserAccountPage = () => push('/account');

const openUserSubscriptionPage = () => push('/subscription');

const receiveCurrentUser = user => {
  if (user) {
    setLanguage(user.language);
  }

  return {
    type: RECEIVE_CURRENT_USER,
    user,
  };
};

// Use only for sub resources (like organisation)
const patchCurrentUser = data => {
  return {
    type: PATCH_CURRENT_USER,
    data,
  };
};

const fetchCurrentUser = () => {
  return dispatch => {
    return Api.user.get().then(response => {
      if (response.isOk()) {
        dispatch(receiveCurrentUser(response.data()));
      }
    });
  };
};

const updateCurrentUserDetails = data => {
  return dispatch => {
    return Api.user.update(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveCurrentUser(response.data()));
        dispatch(systemActions.showToast(locale().account.info_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updateCurrentOrganisation = data => {
  return dispatch => {
    return Api.user.updateCurrentOrganisation(data).then(response => {
      if (response.isOk()) {
        dispatch(patchCurrentUser({ organisation: { ...response.data() } }));
        dispatch(systemActions.showToast(locale().account.info_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createOrganisationLogo = (data, onProgress) => {
  return dispatch => {
    return Api.user.createOrganisationLogo(data, onProgress).then(response => {
      if (response.isOk()) {
        dispatch(patchCurrentUser({ organisation: { ...response.data() } }));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyOrganisationLogo = () => {
  return dispatch => {
    return Api.user.destroyOrganisationLogo().then(response => {
      if (response.isOk()) {
        dispatch(patchCurrentUser({ organisation: { ...response.data() } }));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const removeCurrentUser = () => {
  return dispatch => {
    dispatch(receiveCurrentUser(null));
  };
};

export const actions = {
  openUserAccountPage,
  openUserSubscriptionPage,
  fetchCurrentUser,
  updateCurrentUserDetails,
  updateCurrentOrganisation,
  createOrganisationLogo,
  destroyOrganisationLogo,
  removeCurrentUser,
};

// SELECTORS

const isCurrentUserLoggedIn = state => state.user !== null;

const getCurrentUser = state => state.user;

export const selectors = {
  isCurrentUserLoggedIn,
  getCurrentUser,
};
