import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import { Select } from 'ui/Form';

class DueDatePicker extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  _options = () => [
    {
      value: 7,
      label: locale().days.week,
    },
    {
      value: 14,
      label: locale().days.two_weeks,
    },
    {
      value: 30,
      label: locale().days.month,
    },
    {
      value: null,
      label: locale().patient_invoices.no_due_date,
    },
  ];

  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        clearable={false}
        searchable={false}
      />
    );
  }
}

export default DueDatePicker;
