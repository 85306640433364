import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'ui/Card';
import InvitationsListItem from './_InvitationsListItem';

class InvitationsList extends Component {
  static propTypes = {
    invitations: PropTypes.array.isRequired,
  };

  render() {
    const { invitations } = this.props;

    return (
      <Card>
        {invitations.map(invitation => (
          <InvitationsListItem key={invitation.id} invitation={invitation} />
        ))}
      </Card>
    );
  }
}

export default InvitationsList;
