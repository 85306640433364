import Response from './../_response';
import {
  createResourceSerializer,
  DateSerializer,
  createCollectionSerializer,
  PatientSerializer,
} from './../_serializers';

// Working stats

const WorkingStatsSerializer = createResourceSerializer({
  from_date: DateSerializer,
  to_date: DateSerializer,
  patients_in_period: createCollectionSerializer(PatientSerializer),
});

class UserStatsEndpoint {
  constructor(client) {
    this._client = client;
  }

  getWorkingStats(data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch('working_stats', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(item =>
            WorkingStatsSerializer.deserialize(item)
          ),
        });
      });
  }

  getWorkingStatsForUser(userId, data) {
    let query = this._serializeDateRange(data);

    return this._client
      .fetch(`working_stats/${userId}`, 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, WorkingStatsSerializer.deserialize(json));
      });
  }

  _serializeDateRange = data => {
    let query = { ...data };

    if (data.from_date) {
      query.from_date = DateSerializer.serialize(data.from_date);
    }
    if (data.to_date) {
      query.to_date = DateSerializer.serialize(data.to_date);
    }

    return query;
  };
}

export default UserStatsEndpoint;
