import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Summary.module.scss';
import classNames from 'classnames';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Icon from 'ui/Icon';

class Summary extends Component {
  static propTypes = {
    mainDetails: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      wasOpen: false,
    };
  }

  _toggleDetails = () =>
    this.setState({
      open: !this.state.open,
      wasOpen: this.state.open,
    });

  _mobile = () => {
    const { mainDetails } = this.props;
    const { open, wasOpen } = this.state;

    return (
      <div className={styles['wrapper']}>
        <div className={styles['summary']} onClick={this._toggleDetails}>
          <div className={styles['main']}>{mainDetails}</div>
          <div
            className={classNames({
              [styles['open']]: open,
              [styles['closed']]: wasOpen,
            })}
          >
            <Icon icon={'expand_more'} size={28} />
          </div>
        </div>
        {open && <div className={styles['details']}>{this.props.children}</div>}
      </div>
    );
  };

  _desktop = () => this.props.children;

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default Summary;
