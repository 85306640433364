import React, { PureComponent } from 'react';
import locale, { language, setLanguage } from 'util/locale';
import styles from './RegisterOverview.module.scss';
import qs from 'qs';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import onboardImg from 'media/images/onboard/register.jpg';
import OnboardNav from './../_OnboardNav';
import ErrorBox from './../_ErrorBox';
import {
  Form,
  FormRow,
  FormColumn,
  FormLabel,
  TextInput,
  LanguageSelect,
} from 'ui/Form';
import { CurrencySelect } from 'ui/Money';
import { Button } from 'ui/Button';
import { connect, actions, selectors } from 'util/redux';

class RegisterOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        business_name: '',
        currency: null,
        email: '',
        password: '',
        language: language(),
      },
      invited: false,
      errors: {},
      isSaving: false,
    };
  }

  componentDidMount() {
    const { token, fetchOneInvitation } = this.props;

    if (token) {
      fetchOneInvitation(token)
        .then(() => {
          this.setState({
            data: {
              ...this.state.data,
              email: this.props.invitation.email,
              token,
            },
            invited: true,
          });
        })
        .catch(response => {
          console.error(response);
          if (response.isNotFound && response.isNotFound()) {
            this.setState({
              errors: { _global: locale().onboard.invalid_invitation_error },
            });
          }
        });
    }
  }

  _changeData = (key, value, callback) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [key]: value,
        },
      },
      callback
    );
  };

  _register = () => {
    const { register } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    register(data).catch(response => {
      console.error(response);
      if (response.isValidationError && response.isValidationError()) {
        this.setState({ errors: response.validationErrors().errorPerField() });
      } else if (response.isBadRequest && response.isBadRequest()) {
        this.setState({
          errors: { _global: locale().onboard.subscription_exceeded_error },
        });
      } else if (response.isToManyRequests && response.isToManyRequests()) {
        this.setState({
          errors: {
            _global: locale().system.too_many_requests,
          },
        });
      } else {
        this.setState({
          errors: {
            _global: locale().system.global_error,
          },
        });
      }
      this.setState({ isSaving: false });
    });
  };

  _globalError = () => <ErrorBox>{this.state.errors._global}</ErrorBox>;

  _registrationOverInvitation = () => !!this.state.data.token;

  _languageSelectNode = () => {
    const { data, errors } = this.state;

    return (
      <FormColumn>
        <FormLabel value={locale().onboard.language} error={errors.language} />
        <LanguageSelect
          value={data.language}
          onChange={value =>
            this._changeData('language', value, () => {
              setLanguage(value);
              this.forceUpdate(); // Don't do this ever again!
            })
          }
        />
      </FormColumn>
    );
  };

  _form = () => {
    const { data, errors, isSaving, invited } = this.state;

    let invitedNode;
    if (invited) {
      invitedNode = (
        <div className={styles['invited']}>
          {locale().onboard.registration_through_invitation_info}
        </div>
      );
    }

    return (
      <Form onSubmit={this._register}>
        {invitedNode}
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().onboard.user_name} error={errors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        {!this._registrationOverInvitation() && (
          <React.Fragment>
            <FormRow>
              <FormColumn>
                <FormLabel
                  value={locale().onboard.organisation_name}
                  error={errors.business_name}
                />
                <TextInput
                  value={data.business_name}
                  onChange={value => this._changeData('business_name', value)}
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <FormLabel
                  value={locale().onboard.organisation_currency}
                  error={errors.currency}
                />
                <CurrencySelect
                  value={data.currency}
                  onChange={value => this._changeData('currency', value)}
                />
              </FormColumn>
              {this._languageSelectNode()}
            </FormRow>
          </React.Fragment>
        )}
        {this._registrationOverInvitation() && (
          <FormRow>{this._languageSelectNode()}</FormRow>
        )}
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().onboard.email} error={errors.email} />
            <TextInput
              value={data.email}
              onChange={value => this._changeData('email', value)}
              type={'email'}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().onboard.choose_password}
              error={errors.password}
            />
            <TextInput
              value={data.password}
              onChange={value => this._changeData('password', value)}
              type={'password'}
            />
          </FormColumn>
        </FormRow>
        <div className={styles['action']}>
          <Button
            value={locale().actions.register}
            type={'submit'}
            disabled={isSaving}
            fill={true}
          />
        </div>
        <div className={styles['terms']}>
          {locale().onboard.agree_to_terms}
          &nbsp;
          <a
            href={'https://logovel.com/terms-of-service'}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <span>{locale().onboard.terms_of_service}</span>
          </a>
        </div>
      </Form>
    );
  };

  _mobile = () => (
    <div className={styles['mobileWrapper']}>
      {this._globalError()}
      {this._form()}
    </div>
  );

  _desktop = () => (
    <div className={styles['box']}>
      <div className={styles['promoWrap']}>
        <img src={onboardImg} className={styles['promo']} alt={''} />
        <div className={styles['darkBg']}>
          <div className={styles['text']}>
            <div className={styles['smallIntro']}>
              {locale().onboard.registration_head}
            </div>
            <div className={styles['promoHeader']}>
              {locale().onboard.registration_intro}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['form']}>
        <div className={styles['header']}>
          {locale().onboard.registration_small_head}
        </div>
        <div className={styles['intro']}>
          {locale().onboard.registration_small_intro}
        </div>
        {this._globalError()}
        {this._form()}
      </div>
    </div>
  );

  render() {
    const { openLoginPage } = this.props;

    return (
      <React.Fragment>
        <OnboardNav
          actionQuestion={locale().onboard.already_have_account_question}
          actionTitle={locale().actions.log_in}
          action={openLoginPage}
        />
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let searchQ = (ownProps.location.search || '').substring(1);
  const rawQuery = qs.parse(searchQ);

  return {
    token: rawQuery.token,
    invitation: selectors.getInvitationByToken(state, rawQuery.token),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOneInvitation: token => dispatch(actions.fetchOneInvitation(token)),
    openLoginPage: () => dispatch(actions.openLoginPage()),
    register: data => dispatch(actions.register(data)),
  };
};

RegisterOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterOverview);

export default RegisterOverview;
