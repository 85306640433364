import Response from './../_response';
import {
  createResourceSerializer,
  MoneyAmountSerializer,
} from '../_serializers';

const SubscriptionPlanSerializer = createResourceSerializer({
  head_price: MoneyAmountSerializer,
  employee_price: MoneyAmountSerializer,
});

class SubscriptionPlansEndpoint {
  constructor(client) {
    this._client = client;
  }

  query() {
    return this._client
      .fetch('subscription_plans', 'GET')
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(plan =>
            SubscriptionPlanSerializer.deserialize(plan)
          ),
        });
      });
  }
}

export default SubscriptionPlansEndpoint;
