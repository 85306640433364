import React, { Component } from 'react';
import PatientSelectUI from './PatientSelectUI';
import { connect, actions, selectors } from 'util/redux';

class PatientSelect extends Component {
  componentDidMount() {
    const { fetchPatients } = this.props;
    fetchPatients({ archived: false });
  }

  render() {
    return <PatientSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    patients: selectors.getPatients(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPatients: query => dispatch(actions.fetchPatients(query)),
  };
};

PatientSelect = connect(mapStateToProps, mapDispatchToProps)(PatientSelect);

export default PatientSelect;
