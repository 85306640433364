import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_CalendarView.module.scss';
import PageContainer from 'ui/PageContainer';
import { Card } from 'ui/Card';
import Icon from 'ui/Icon';
import CalendarResources, {
  Appointment,
  OccupyingBlock,
} from './_CalendarResources';
import { connect, selectors } from 'util/redux';

const HOURS_CHUNK = 4; // Works properly only with 4 for now

// When choosing height make sure time and patient name fits to 30 min appointments
const CELL_INFO = {
  appointment: {
    component: Appointment,
    itemsGrouped: false,
    nameExtractor: resource => resource.name,
    optimal_width: 150,
    max_optimal_width: 1135,
    height: 32,
    margin: 4,
    time: 75,
  },
  occupation: {
    component: OccupyingBlock,
    itemsGrouped: true,
    nameExtractor: resource => resource.short_name,
    optimal_width: 70,
    max_optimal_width: 70,
    height: 32,
    margin: 4,
    time: 75,
  },
};

const RESOURCE_INFO = {
  user: {
    resourcesToShow: props => {
      let usersToShow;
      if (!props.selectedUserId) {
        usersToShow = props.users.sortBy(user =>
          user.id === props.currentUser.id ? ' ' : user.name
        );
      } else {
        usersToShow = props.users.filter(
          user => user.id === props.selectedUserId
        );
      }

      return usersToShow;
    },
    appointmentsForResource: (props, resource) =>
      props.appointments.filter(
        appointment =>
          appointment.users.filter(u => u.id === resource.id).length > 0
      ),
    labelExtractor: appointment =>
      appointment.location
        ? {
            label: appointment.location.short_name,
            color: appointment.location.color,
          }
        : null,
  },
  location: {
    resourcesToShow: props => {
      let locationsToShow;
      if (!props.selectedLocationId) {
        const noLocation = {
          id: null,
          name: locale().schedule.no_location_name,
          short_name: locale().schedule.no_location_short_name,
        };
        locationsToShow = props.locations.sortBy('name');
        locationsToShow = [...locationsToShow, noLocation];
      } else {
        locationsToShow = props.locations.filter(
          location => location.id === props.selectedLocationId
        );
      }

      return locationsToShow;
    },
    appointmentsForResource: (props, resource) =>
      props.appointments.filter(appointment =>
        resource.id
          ? appointment.location && appointment.location.id === resource.id
          : !appointment.location
      ),
    labelExtractor: appointment => ({
      label: appointment.users[0].short_name,
      color: appointment.users[0].color,
    }),
  },
};

class CalendarView extends Component {
  static CELL_TYPES = Object.keys(CELL_INFO);
  static RESOURCE_TYPES = Object.keys(RESOURCE_INFO);

  static propTypes = {
    users: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    appointments: PropTypes.array.isRequired,
    selectedUserId: PropTypes.string,
    selectedLocationId: PropTypes.string,
    date: PropTypes.object.isRequired,
    cellType: PropTypes.oneOf(CalendarView.CELL_TYPES).isRequired,
    resourcesType: PropTypes.oneOf(CalendarView.RESOURCE_TYPES).isRequired,
  };

  static defaultProps = {
    selectedUserId: null,
    selectedLocationId: null,
  };

  constructor(props) {
    super(props);
    this.state = this._calculatePagination();
  }

  componentDidUpdate(prevProps) {
    const { selectedUserId, selectedLocationId, resourcesType } = this.props;

    if (
      prevProps.selectedUserId !== selectedUserId ||
      prevProps.selectedLocationId !== selectedLocationId ||
      prevProps.resourcesType !== resourcesType
    ) {
      this.setState(this._calculatePagination());
    }
  }

  // Fallback infos to *default* in case it is changed somehow to invalid value (from url)
  _cellInfo = () => CELL_INFO[this.props.cellType] || CELL_INFO.appointment;

  _resourceInfo = () =>
    RESOURCE_INFO[this.props.resourcesType] || RESOURCE_INFO.user;

  _calculatePagination = () => {
    const cellInfo = this._cellInfo();
    const resourceInfo = this._resourceInfo();

    // Calculating free screen size
    let screenSize;
    if (window.innerWidth > PageContainer.SCREEN_SIZE_WITH_NO_PADDING) {
      screenSize = PageContainer.MAX_WIDTH;
    } else {
      screenSize =
        window.innerWidth > PageContainer.MAX_WIDTH
          ? PageContainer.MAX_WIDTH
          : window.innerWidth;
      screenSize -= 2 * PageContainer.MAX_PADDING;
      screenSize -= 8; // Scrollbar
    }
    screenSize -= cellInfo.time; // Sub time cell size

    const numberOfResources = resourceInfo.resourcesToShow(this.props).length;
    const margins = 2 * cellInfo.margin;

    // Calculating optimal width cell for free screen
    let maxCellWidth =
      (screenSize - margins * numberOfResources) / numberOfResources;

    if (maxCellWidth > cellInfo.max_optimal_width) {
      maxCellWidth = cellInfo.max_optimal_width;
    } else if (maxCellWidth < cellInfo.optimal_width) {
      maxCellWidth = cellInfo.optimal_width;
    } else {
      maxCellWidth = Math.floor(maxCellWidth);
    }
    maxCellWidth += margins;

    // Calculating pagination - number of visible resources in view
    let paginationSize = Math.floor(screenSize / maxCellWidth);
    if (paginationSize < 1) {
      paginationSize = 1;
    }

    return {
      start: 0,
      end: paginationSize,
      paginationSize: paginationSize,
    };
  };

  _moveLeft = () => {
    const { start, end } = this.state;
    if (start > 0) {
      this.setState({ start: start - 1, end: end - 1 });
    }
  };

  _moveRight = () => {
    const { start, end } = this.state;

    if (end < this._resourceInfo().resourcesToShow(this.props).length) {
      this.setState({ start: start + 1, end: end + 1 });
    }
  };

  _getNearestChunk = decimalTime =>
    parseFloat(
      (Math.round(decimalTime * HOURS_CHUNK) / HOURS_CHUNK).toFixed(2)
    );

  _resourcesData = () => {
    const resourceInfo = this._resourceInfo();
    let minStartTime = 23.99,
      maxEndTime = 0;

    const resourcesData = resourceInfo
      .resourcesToShow(this.props)
      .map(resource => {
        const appointments = resourceInfo.appointmentsForResource(
          this.props,
          resource
        );

        // Finding min and max time
        const firstAppointmentForResource = appointments.sortBy(appointment =>
          appointment.date.getDecimalHours()
        )[0];

        if (firstAppointmentForResource) {
          const lastAppointmentForResource = appointments.sortBy(appointment =>
            appointment.date.addMinutes(appointment.duration).getDecimalHours()
          )[appointments.length - 1];

          const startTime = this._getNearestChunk(
            firstAppointmentForResource.date.getDecimalHours()
          );
          let endTime = lastAppointmentForResource.date
            .addMinutes(lastAppointmentForResource.duration)
            .getDecimalHours();

          // When it breaks over midnight
          if (endTime < lastAppointmentForResource.date.getDecimalHours()) {
            endTime = 23.99;
          }

          if (startTime < minStartTime) {
            minStartTime = startTime;
          }
          if (endTime > maxEndTime) {
            maxEndTime = endTime;
          }
        }

        return { resource, appointments };
      });

    return { resourcesData, minStartTime, maxEndTime };
  };

  render() {
    const { date } = this.props;
    const { start, end, paginationSize } = this.state;
    const { resourcesData, minStartTime, maxEndTime } = this._resourcesData();
    const arrowsVisible = resourcesData.length > paginationSize;

    const cellInfo = this._cellInfo();
    const resourceInfo = this._resourceInfo();

    return (
      <Card>
        <div className={styles['bar']}>
          <div className={styles['date']}>
            {date.format('EEEE, dd. MMMM yyyy.').capitalize()}
          </div>
          {arrowsVisible && (
            <div className={styles['arrows']}>
              <Icon
                icon={'arrow_back'}
                expand={true}
                onClick={this._moveLeft}
              />
              <Icon
                icon={'arrow_forward'}
                expand={true}
                onClick={this._moveRight}
              />
            </div>
          )}
        </div>
        <CalendarResources
          resourcesData={resourcesData.slice(start, end)}
          showResourcesHeader={resourcesData.length > 1}
          startTime={minStartTime}
          endTime={maxEndTime}
          cellInfo={{
            component: cellInfo.component,
            itemsGrouped: cellInfo.itemsGrouped,
            nameExtractor: cellInfo.nameExtractor,
            labelExtractor: resourceInfo.labelExtractor,
            cellsPerRow: paginationSize,
            height: cellInfo.height,
            margin: cellInfo.margin,
            time: cellInfo.time,
          }}
          hoursChunk={HOURS_CHUNK}
        />
      </Card>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
  };
};

CalendarView = connect(mapStateToProps)(CalendarView);

export default CalendarView;
