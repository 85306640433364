import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, FormLabel } from 'ui/Form';
import { MoneyInput } from 'ui/Money';
import { connect, selectors, actions } from 'util/redux';

class ServiceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.service },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.service },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { updateService, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    updateService(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { onClose, open } = this.props;
    const { data, errors, isSaving } = this.state;

    return (
      <SidebarDialog
        onClose={onClose}
        open={open}
        title={locale().services.edit}
        error={errors._global}
        onSave={this._save}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().services.price} error={errors.price} />
            <MoneyInput
              value={data.price}
              onChange={value => this._changeData('price', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingService(state),
    service: selectors.getEditingService(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateService: data => dispatch(actions.updateService(data)),
    onClose: () => dispatch(actions.finishServiceEditing()),
  };
};

ServiceDialog = connect(mapStateToProps, mapDispatchToProps)(ServiceDialog);

export default ServiceDialog;
