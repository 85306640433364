const makeExtractor = predicate => {
  let extractor;
  if (typeof predicate === 'function') {
    extractor = predicate;
  } else if (predicate) {
    extractor = obj => obj[predicate];
  } else {
    extractor = obj => obj;
  }
  return extractor;
};

Object.defineProperty(Array.prototype, 'sortBy', {
  enumerable: false,
  writable: true,
  value: function (...predicates) {
    const extractors = predicates.map(predicate => makeExtractor(predicate));
    return [...this].sort((a, b) => {
      for (let i = 0; i < extractors.length; i++) {
        const aVal = extractors[i](a);
        const bVal = extractors[i](b);
        if (aVal !== bVal) {
          return aVal < bVal ? -1 : 1;
        }
      }
      return 0;
    });
  },
});

Object.defineProperty(Array.prototype, 'groupBy', {
  enumerable: false,
  writable: true,
  value: function (...predicates) {
    const valuesComparator = (currentGroup, newGroup) => {
      let same = true;
      for (let i = 0; i < currentGroup.length; i++) {
        if (currentGroup[i] !== newGroup[i]) {
          same = false;
          break;
        }
      }
      return same;
    };
    const valueExtractors = predicates.map(predicate =>
      makeExtractor(predicate)
    );

    let groups = [];
    this.forEach(item => {
      const newGroup = valueExtractors.map(extractor => extractor(item));
      let group = groups.find(group => valuesComparator(group.value, newGroup));
      if (!group) {
        group = {
          value: newGroup,
          items: [],
        };
        groups.push(group);
      }
      group.items.push(item);
    });

    return groups;
  },
});

Object.defineProperty(Array.prototype, 'remove', {
  enumerable: false,
  writable: true,
  value: function (object) {
    const index = this.indexOf(object);
    if (index !== -1) {
      let arr = [...this];
      arr.splice(index, 1);
      return arr;
    } else {
      return this;
    }
  },
});
