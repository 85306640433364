import parseUrl from 'url-parse';
import 'whatwg-fetch';
import { language } from 'util/locale';

function appendFormData(formData, data, name) {
  name = name || '';
  if (typeof data === 'undefined') {
    return;
  }
  if (data === null) {
    formData.append(name, '');
  } else if (
    data instanceof File ||
    data instanceof Blob ||
    typeof data !== 'object'
  ) {
    formData.append(name, data);
  } else if (typeof data === 'object') {
    Object.keys(data).forEach(key => {
      let value = data[key];
      if (name === '') {
        appendFormData(formData, value, key);
      } else {
        appendFormData(formData, value, name + '[' + key + ']');
      }
    });
  } else {
    console.warn(`Skipping key ${name}. Unknown value type.`, data);
  }
}

// see: https://github.com/github/fetch/issues/89
function futch(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    let xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    Object.keys(opts.headers || {}).forEach(headerName => {
      xhr.setRequestHeader(headerName, opts.headers[headerName]);
    });
    xhr.onload = e =>
      res({
        json: () =>
          new Promise((res, rej) => {
            try {
              res(JSON.parse(e.target.responseText));
            } catch (e) {
              console.error(e);
              rej(e);
            }
          }),
        status: e.target.status,
        headers: {
          api_version: e.target.getResponseHeader('API-Version'),
          pagination_total: e.target.getResponseHeader('Pagination-Total'),
          pagination_page: e.target.getResponseHeader('Pagination-Page'),
          pagination_per_page: e.target.getResponseHeader(
            'Pagination-Per-Page'
          ),
        },
      });
    xhr.onerror = rej;
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          onProgress(Math.round((event.loaded / event.total) * 100));
        } else {
          onProgress(50);
        }
      };
    }
    let body = opts.body || {};
    let asFormData = false;
    Object.keys(body).forEach(key => {
      if (body[key] instanceof File) {
        asFormData = true;
      }
    });
    if (asFormData) {
      let formData = new FormData();
      appendFormData(formData, body);
      body = formData;
    } else {
      body = JSON.stringify(body);
      xhr.setRequestHeader('Content-Type', 'application/json');
    }
    xhr.send(body);
  });
}

const noop = () => {};

class HttpClient {
  constructor(config = {}) {
    if (!config.apiRoot) {
      throw 'apiRoot config must be provided';
    }
    this._apiRoot = config.apiRoot;
    this._onRequest = config.onRequest || noop;
    this._onResponse = config.onResponse || noop;
  }

  _retry(times, call) {
    return call().catch(failure => {
      console.error(failure);
      if (failure instanceof ProgressEvent && failure.type === 'error') {
        console.warn('Server connection failure detected');
        if (times > 0) {
          console.warn(`Will retry ${times} more times`);
          return new Promise((res, rej) => {
            setTimeout(() => {
              this._retry(times - 1, call)
                .then((...args) => {
                  console.log('Success! Connection is back up!');
                  res(...args);
                })
                .catch(rej);
            }, 1700);
          });
        } else {
          console.error(`Retry limit reached. Giving up...`);
          return Promise.reject(failure);
        }
      } else {
        return Promise.reject(failure);
      }
    });
  }

  fetch(path, method, params = {}, onProgress) {
    this._onRequest(path, method, params);
    return this._retry(5, () => {
      let config = {
        method: method,
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'App-Language': language(),
        },
      };
      if (['POST', 'PATCH', 'PUT', 'DELETE'].indexOf(method) !== -1) {
        config.body = params;
      }
      let urlString = `${this._apiRoot}/${path}`;
      if ('GET' === method && Object.keys(params).length > 0) {
        let urlObj = parseUrl(urlString);
        let newQuery = { ...urlObj.query };
        Object.keys(params).forEach(key => {
          if (
            params[key] !== '' &&
            params[key] !== null &&
            params[key] !== undefined
          ) {
            newQuery[key] = params[key];
          }
        });
        urlObj.set('query', newQuery);
        urlString = urlObj.toString();
      }
      return futch(urlString, config, onProgress).then(response => {
        return response.json().then(json => {
          return Promise.resolve(
            this._onResponse({ json, response })
          ).then(() => ({ json, response }));
        });
      });
    });
  }

  batchFetch(requests) {
    const apiPath = parseUrl(this._apiRoot).pathname;
    const ops = requests.map(request => {
      return {
        url: `${apiPath}/${request.path}`,
        method: request.method,
        params: request.params,
      };
    });
    return this.fetch(`batch`, 'POST', { ops, sequential: true }).then(
      ({ json, response }) => {
        if (response.status >= 200 && response.status < 300) {
          const jsons = json.results.map(jsonPart => ({
            response: { status: jsonPart.status },
            json: JSON.parse(jsonPart.body),
          }));
          return { json: jsons, response };
        } else {
          return { json, response };
        }
      }
    );
  }
}

export default HttpClient;
