import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_EmptyState.module.scss';

class EmptyState extends Component {
  static propTypes = {
    intro: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    extraSpace: PropTypes.bool,
  };

  static defaultProps = {
    extraSpace: true,
  };

  render() {
    const { intro, image, extraSpace } = this.props;

    return (
      <div
        className={classNames(styles['wrapper'], {
          [styles['extraSpace']]: extraSpace,
        })}
      >
        <img className={styles['image']} src={image} alt={''} />
        <div className={styles['intro']}>{intro}</div>
      </div>
    );
  }
}

export default EmptyState;
