import locale from 'util/locale';
import Api from 'util/api/api';
import { push } from 'connected-react-router';
import * as allActions from './../_actions';

// ACTIONS

const openLoginPage = () => push('/onboard/login');

const openRegisterPage = () => push('/onboard/register');

const openForgotPasswordPage = () => push('/onboard/forgot-password');

const login = (email, password) => {
  return dispatch => {
    return Api.user.login(email, password).then(response => {
      if (response.isOk()) {
        dispatch(allActions.fetchCurrentUser()).then(() =>
          dispatch(allActions.openDashboardPage())
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const logout = () => {
  return dispatch => {
    dispatch(allActions.removeCurrentUser());
    dispatch(allActions.openLoginPage());
    return Api.user.logout();
  };
};

const register = data => {
  return dispatch => {
    return Api.user.register(data).then(response => {
      if (response.isOk()) {
        dispatch(allActions.login(data.email, data.password));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const changePassword = data => {
  return dispatch => {
    return Api.user.changePassword(data).then(response => {
      if (response.isOk()) {
        dispatch(allActions.showToast(locale().account.password_changed));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const sendResetPasswordInstructions = email => {
  return () => {
    return Api.user.sendResetPasswordInstructions(email).then(response => {
      if (response.isOk()) {
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const resetPassword = data => {
  return () => {
    return Api.user.resetPassword(data).then(response => {
      if (response.isOk()) {
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  openLoginPage,
  openRegisterPage,
  openForgotPasswordPage,
  login,
  logout,
  register,
  changePassword,
  sendResetPasswordInstructions,
  resetPassword,
};
