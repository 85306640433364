import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_ServicePaymentsList.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Paginator from 'navigation/Paginator';
import Accordion from 'ui/Accordion';
import EmptyState from './../_EmptyState';
import { SectionListItem } from 'ui/SectionList';
import { CardRow } from 'ui/Card';
import { MoneyText } from 'ui/Money';
import { PaymentIcon } from 'ui/Icon';
import MultiOrgRender from 'ui/MultiOrgRender';
import paymentsImg from 'media/images/patients/payments.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class ServicePaymentsList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  _onFirstLoad = () => this.setState({ loadingState: loadingStates.present });

  _paymentTypeIcon = payment => (
    <PaymentIcon paymentType={payment.payment_type} size={18} />
  );

  _mobileItem = payment => {
    const { showService } = this.props;

    return (
      <SectionListItem
        leftContent={this._paymentTypeIcon(payment)}
        title={payment.service.name}
        extra={<MultiOrgRender>{payment.created_by.name}</MultiOrgRender>}
        onClick={() => showService(payment.service.id)}
        extraRightContent={payment.date.format('dd.MM.yyyy.')}
        rightContent={<MoneyText value={payment.amount} />}
        rightPadding={true}
      />
    );
  };

  _desktopItem = payment => {
    const { showService } = this.props;

    return (
      <CardRow
        rowSize={'small'}
        onClick={() => showService(payment.service.id)}
      >
        <div className={styles['payment']}>
          <div className={styles['service']}>
            {this._paymentTypeIcon(payment)}
            <span>{payment.service.name}</span>
          </div>
          <div className={styles['right']}>
            <MultiOrgRender>
              <div className={styles['createdBy']}>
                {payment.created_by.name}
              </div>
            </MultiOrgRender>
            <div className={styles['date']}>
              {payment.date.format('dd.MM.yyyy.')}
            </div>
            <div className={styles['money']}>
              <MoneyText value={payment.amount} />
            </div>
          </div>
        </div>
      </CardRow>
    );
  };

  _paymentsMap = () => {
    const { servicePayments } = this.props;
    const paymentsMap = {};

    servicePayments.forEach(payment => {
      const key = payment.date.format('yyyy-MM');

      paymentsMap[key] = [...(paymentsMap[key] || []), payment];
    });

    return paymentsMap;
  };

  render() {
    const { loadingState } = this.state;

    const firstFetchCompleted = loadingState === loadingStates.present;

    const paymentsMap = this._paymentsMap();
    const dateKeys = Object.keys(paymentsMap).sortBy('').reverse();

    return (
      <div className={styles['wrapper']}>
        <Paginator
          fetch={this.props.fetchPaymentsForPatient}
          onFirstLoad={this._onFirstLoad}
        >
          <Accordion
            items={dateKeys.map(dateKey => {
              const date = new Date.fromString(dateKey);

              return {
                id: dateKey,
                title: date.format('MMMM yyyy').capitalize(),
                content: (
                  <React.Fragment>
                    {paymentsMap[dateKey]
                      .sortBy('date')
                      .reverse()
                      .map(payment => (
                        <MobileDesktopRender
                          key={payment.id}
                          mobile={() => this._mobileItem(payment)}
                          desktop={() => this._desktopItem(payment)}
                        />
                      ))}
                  </React.Fragment>
                ),
              };
            })}
            titleClassName={styles['groupTitle']}
          />
        </Paginator>
        {firstFetchCompleted && this.props.servicePayments.length === 0 && (
          <EmptyState
            intro={locale().service_payments.empty_state_description}
            image={paymentsImg}
            extraSpace={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    servicePayments: selectors.getPaymentsForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    fetchPaymentsForPatient: ({ clearOld, page }) =>
      dispatch(actions.fetchPaymentsForPatient({ patientId, clearOld, page })),
    showService: serviceId => dispatch(actions.showService(serviceId)),
  };
};

ServicePaymentsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicePaymentsList);

export default ServicePaymentsList;
