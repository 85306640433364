import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './FailureInfo.module.scss';

class FailureInfo extends Component {
  static propTypes = {
    info: PropTypes.string,
    padding: PropTypes.bool,
  };

  static defaultProps = {
    info: locale().system.global_error,
    padding: true,
  };

  render() {
    const { info, padding } = this.props;

    return (
      <div
        className={classNames(styles['wrapper'], {
          [styles['padding']]: padding,
        })}
      >
        <span className={styles['info']}>{info}</span>
      </div>
    );
  }
}

export default FailureInfo;
