import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import Doughnut from 'ui/Chart/Doughnut';

class DiagnoseDoughnut extends Component {
  static propTypes = {
    stats: PropTypes.array.isRequired,
  };

  render() {
    const { stats } = this.props;

    return (
      <Doughnut
        stats={stats.map(s => ({
          label: s.diagnose_name,
          amount: s.number_of_patients,
        }))}
        labelsOverride={locale().patients.number_of_patients}
      />
    );
  }
}

export default DiagnoseDoughnut;
