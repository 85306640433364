import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './DashboardOverview.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import Navigator from 'navigation/Navigator';
import PermissionsRender from 'ui/PermissionsRender';
import { Toolbar, ToolbarGroup } from 'ui/Toolbar';
import Dropdown from 'ui/Dropdown';
import HeaderBar from 'ui/HeaderBar';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import DashboardBox from './_DashboardBox';
import FinancialStats from './_FinancialStats';
import GreetingNode from './_GreetingNode';
import MoneyFlowChart from './_MoneyFlowChart';
import { loadingStates, connect, actions, selectors } from 'util/redux';
import ActionCenter from 'util/action_center';

class DashboardOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    ActionCenter.subscribe('inflowServicePayments', this._reloadIncomeStats);
    ActionCenter.subscribe('createExpense', this._reloadExpenseStats);

    // Without this it will fetch for not-head users
    // because this component is mounted, although children are not

    // This is added only here because only this page is accessed by
    // not-head users (just on first login) - so just to avoid fetch and 403

    if (user.permissions.finance_overview) {
      this._fetch();
    }
  }

  componentWillUnmount() {
    ActionCenter.unsubscribe('inflowServicePayments', this._reloadIncomeStats);
    ActionCenter.unsubscribe('createExpense', this._reloadExpenseStats);
  }

  _quickActions = () => [
    {
      label: locale().patients.new,
      onClick: this.props.newPatient,
    },
    {
      label: locale().service_payments.new,
      onClick: this.props.newInflow,
    },
    {
      label: locale().expenses.new,
      onClick: this.props.newExpense,
    },
  ];

  _moneyStatsRange = () => ({
    from_date: new Date().subMonths(11).beginningOfMonth(),
    to_date: new Date().endOfMonth(),
  });

  _fetch = () => {
    const { fetchIncomeStats, fetchExpenseStats } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([
      fetchIncomeStats(this._moneyStatsRange()),
      fetchExpenseStats(this._moneyStatsRange()),
    ])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(() => this.setState({ loadingState: loadingStates.failed }));
  };

  _reloadIncomeStats = () =>
    this.props.fetchIncomeStats(this._moneyStatsRange());

  _reloadExpenseStats = () =>
    this.props.fetchExpenseStats(this._moneyStatsRange());

  _moneyFlowChart = () => (
    <MoneyFlowChart
      payments={this.props.incomeStats.payments}
      expenses={this.props.expenseStats.expenses}
    />
  );

  _financialStatsNode = () => {
    const { incomeStats, expenseStats } = this.props;

    return (
      <DashboardBox title={locale().dashboard.finance_overview_head}>
        <FinancialStats incomeStats={incomeStats} expenseStats={expenseStats} />
      </DashboardBox>
    );
  };

  _mobileHeader = () => (
    <HeaderBar
      title={'Logovel'}
      rightContent={
        <Dropdown items={this._quickActions()} icon={'more_vert'} />
      }
    />
  );

  _mobile = () => (
    <React.Fragment>
      <GreetingNode />
      <DashboardBox title={locale().dashboard.finance_chart_head}>
        {this._moneyFlowChart()}
      </DashboardBox>
      {this._financialStatsNode()}
    </React.Fragment>
  );

  _desktop = () => {
    return (
      <React.Fragment>
        <Toolbar>
          <ToolbarGroup>
            <GreetingNode />
          </ToolbarGroup>
          <ToolbarGroup>
            <Dropdown
              title={locale().dashboard.quick_actions}
              items={this._quickActions()}
            />
          </ToolbarGroup>
        </Toolbar>
        <div className={styles['wrapper']}>
          <div className={styles['left']}>
            <DashboardBox
              title={locale().dashboard.finance_chart_head}
              titleClassName={styles['moneyFlowChartTitle']}
            >
              {this._moneyFlowChart()}
            </DashboardBox>
          </div>
          <div className={styles['right']}>{this._financialStatsNode()}</div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else {
      bodyNode = (
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
      );
    }

    return (
      <PermissionsRender permission={'finance_overview'} redirect={true}>
        <Navigator mountedAs={'dashboard'}>
          <React.Fragment>
            <MobileDesktopRender mobile={this._mobileHeader} />
            {bodyNode}
          </React.Fragment>
        </Navigator>
      </PermissionsRender>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: selectors.getCurrentUser(state),
    incomeStats: selectors.getIncomeStats(state),
    expenseStats: selectors.getExpenseStats(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchIncomeStats: data => dispatch(actions.fetchIncomeStats(data)),
    fetchExpenseStats: data => dispatch(actions.fetchExpenseStats(data)),
    newPatient: () => dispatch(actions.newPatient()),
    newInflow: () => dispatch(actions.newInflow()),
    newExpense: () => dispatch(actions.newExpense()),
  };
};

DashboardOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardOverview);

export default DashboardOverview;
