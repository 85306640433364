import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './NotificationsIcon.module.scss';
import Icon from 'ui/Icon';
import { connect, actions, selectors } from 'util/redux';

class NotificationsIcon extends Component {
  static propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  };

  componentDidMount() {
    this._fetch();

    // Every 2 minutes
    this._notificationsRefresher = window.setInterval(this._fetch, 120000);
  }

  componentWillUnmount() {
    window.clearInterval(this._notificationsRefresher);
  }

  _fetch = () => {
    const { fetchNotifications } = this.props;
    fetchNotifications();
  };

  render() {
    const { notifications, size, color, icon, onClick } = this.props;

    let indicator = notifications.filter(n => !n.seen_at).length;
    if (indicator > 9) {
      indicator = '9+';
    }

    return (
      <div className={styles['wrapper']}>
        {indicator !== 0 && (
          <div className={styles['indicator']}>{indicator}</div>
        )}
        <Icon size={size} color={color} icon={icon} onClick={onClick} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    notifications: selectors.getNotifications(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNotifications: () =>
      dispatch(actions.fetchNotifications({ unacknowledged_only: true })),
  };
};

NotificationsIcon = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsIcon);

export default NotificationsIcon;
