import locale from 'util/locale';
import Api from 'util/api/api';
import ActionCenter from 'util/action_center';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  makeGetEntityList,
  removeEntities,
} from './_entities';

const START_NOTES_EDITING = 'START_NOTES_EDITING';
const FINISH_NOTES_EDITING = 'FINISH_NOTES_EDITING';

export default (
  state = {
    editingPatientNote: emptyPatientNoteData(),
    notesDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_NOTES_EDITING:
      return {
        ...state,
        editingPatientNote: action.note,
        notesDialogOpen: true,
      };
    case FINISH_NOTES_EDITING:
      return {
        ...state,
        editingPatientNote: emptyPatientNoteData(),
        notesDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyPatientNoteData = () => ({
  content: '',
  date: new Date(),
  progress_rate: null,
});

const newPatientNote = data => {
  return {
    type: START_NOTES_EDITING,
    note: { ...emptyPatientNoteData(), ...data },
  };
};

const editPatientNote = note => {
  return {
    type: START_NOTES_EDITING,
    note,
  };
};

const finishPatientNoteEditing = () => {
  return {
    type: FINISH_NOTES_EDITING,
  };
};

const fetchNotesForPatient = patientId => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.patient_note));
    return Api.patients.notes(patientId).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.patient_note, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatientNote = data => {
  return dispatch => {
    return Api.patientNotes.create(data).then(response => {
      if (response.isOk()) {
        const note = response.data();
        dispatch(receiveEntities(entityTypes.patient_note, [note]));
        ActionCenter.publish('createPatientNote');
        dispatch(systemActions.showToast(locale().patient_notes.notes_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updatePatientNote = data => {
  return dispatch => {
    return Api.patientNotes.update(data).then(response => {
      if (response.isOk()) {
        const note = response.data();
        dispatch(receiveEntities(entityTypes.patient_note, [note]));
        dispatch(systemActions.showToast(locale().patient_notes.notes_saved));
        ActionCenter.publish('updatePatientNote');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPatientNote = id => {
  return dispatch => {
    return Api.patientNotes.destroy(id).then(response => {
      if (response.isOk()) {
        const note = response.data();
        dispatch(removeEntities(entityTypes.patient_note, [note.id]));
        dispatch(
          systemActions.showToast(locale().patient_notes.notes_destroyed)
        );
        ActionCenter.publish('destroyPatientNote');
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPatientNote,
  editPatientNote,
  finishPatientNoteEditing,
  fetchNotesForPatient,
  createPatientNote,
  updatePatientNote,
  destroyPatientNote,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getNotesForPatient = (state, patientId) =>
  getEntityList(state, entityTypes.patient_note).filter(
    note => note.patient_id === patientId
  );

const getEditingPatientNote = state => state.editingPatientNote;

const isEditingPatientNote = state => state.notesDialogOpen;

export const selectors = {
  getNotesForPatient,
  getEditingPatientNote,
  isEditingPatientNote,
};
