import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './MultipleSelect.module.scss';
import Select from './Select';
import Icon from 'ui/Icon';

class MultipleSelect extends Component {
  static propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    optionRenderer: PropTypes.func,
  };

  static defaultProps = {
    values: [],
  };

  _valueIncluded = value => !!this.props.values.find(v => v === value);

  _addValue = value => {
    const { values, onChange } = this.props;

    if (!this._valueIncluded(value)) {
      onChange([...values, value]);
    }
  };

  _removeValue = value => {
    const { values, onChange } = this.props;

    if (this._valueIncluded(value)) {
      onChange(values.filter(v => v !== value));
    }
  };

  render() {
    const { options, placeholder, optionRenderer } = this.props;

    return (
      <React.Fragment>
        <Select
          onChange={this._addValue}
          options={options}
          placeholder={placeholder}
          clearable={false}
          searchable={false}
          optionRenderer={optionRenderer}
        />
        <div className={styles['values']}>
          {options
            .filter(option => this._valueIncluded(option.value))
            .map((option, i) => (
              <div
                key={i}
                className={styles['value']}
                onClick={() => this._removeValue(option.value)}
              >
                <div className={styles['label']}>{option.label}</div>
                <div className={styles['close']}>
                  <Icon icon={'close'} size={16} />
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default MultipleSelect;
