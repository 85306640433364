import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { Card } from 'ui/Card';
import { SectionList } from 'ui/SectionList';
import ExpensesListItem from './_ExpensesListItem';

class ExpensesList extends Component {
  static propTypes = {
    expenses: PropTypes.array.isRequired,
  };

  _body = () => (
    <React.Fragment>
      {this.props.expenses.map(expense => (
        <ExpensesListItem key={expense.id} expense={expense} />
      ))}
    </React.Fragment>
  );

  _leftHeadline = () => locale().expenses.title;

  _mobile = () => (
    <SectionList leftHeadline={this._leftHeadline()}>
      {this._body()}
    </SectionList>
  );

  _desktop = () => (
    <Card leftHeadline={this._leftHeadline()}>{this._body()}</Card>
  );

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default ExpensesList;
