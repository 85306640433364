import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { loadingStates, actions, selectors } from 'util/redux';

import PageContainer from 'ui/PageContainer';
import DesktopNav from 'navigation/DesktopNav';
import MobileNav from 'navigation/MobileNav';
import RenderAlways from './RenderAlways';
import LoginOverview from 'onboard/LoginOverview';
import RegisterOverview from 'onboard/RegisterOverview';
import ForgotPassword from 'onboard/ForgotPassword';
import ResetPassword from 'onboard/ResetPassword';
import DashboardOverview from './dashboard/DashboardOverview';
import ScheduleOverview from 'schedule/ScheduleOverview';
import AppointmentShow from 'schedule/AppointmentShow';
import PatientsOverview from 'patients/PatientsOverview';
import ServicesOverview from 'services/ServicesOverview';
import FinancesOverview from 'finances/FinancesOverview';
import PatientShow from 'patients/PatientShow';
import PatientService from 'patients/PatientService';
import PatientReport from 'patients/PatientReport';
import PatientInvoice from 'patients/PatientInvoice';
import UserAccountOverview from 'user-account/UserAccountOverview';
import UserSubscriptionOverview from 'user-subscription/UserSubscriptionOverview';
import EmployeesOverview from 'employees/EmployeesOverview';
import LocationsOverview from 'locations/LocationsOverview';
import PostsOverview from 'posts/PostsOverview';
import NotificationsOverview from 'notifications/NotificationsOverview';

class AppRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { store } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    Promise.all([store.dispatch(actions.fetchCurrentUser())])
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(response => {
        console.error(response);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  render() {
    const { store, history } = this.props;
    const { loadingState } = this.state;

    if (loadingState === loadingStates.fetching) {
      return null;
    }

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route
              path={'/onboard'}
              render={() => {
                if (selectors.isCurrentUserLoggedIn(store.getState())) {
                  return <Redirect to={'/'} />;
                } else {
                  return (
                    <PageContainer>
                      <Route
                        exact
                        path={'/onboard/login'}
                        component={LoginOverview}
                      />
                      <Route
                        exact
                        path={'/onboard/register'}
                        component={RegisterOverview}
                      />
                      <Route
                        exact
                        path={'/onboard/forgot-password'}
                        component={ForgotPassword}
                      />
                      <Route
                        exact
                        path={'/onboard/reset-password'}
                        component={ResetPassword}
                      />
                    </PageContainer>
                  );
                }
              }}
            />
            <Route
              path={'/'}
              render={() => {
                if (!selectors.isCurrentUserLoggedIn(store.getState())) {
                  // api onResponse will handle redirect
                  return null;
                } else {
                  return (
                    <React.Fragment>
                      <DesktopNav />
                      <PageContainer>
                        <Switch>
                          <Route
                            exact
                            path={'/'}
                            component={DashboardOverview}
                          />
                          <Route
                            exact
                            path={'/schedule'}
                            component={ScheduleOverview}
                          />
                          <Route
                            exact
                            path={'/schedule/:id'}
                            component={AppointmentShow}
                          />
                          <Route
                            exact
                            path={'/patients'}
                            component={PatientsOverview}
                          />
                          <Route
                            exact
                            path={'/services'}
                            component={ServicesOverview}
                          />
                          <Route
                            exact
                            path={'/finance'}
                            component={FinancesOverview}
                          />
                          <Route
                            exact
                            path={'/patients/:id'}
                            component={PatientShow}
                          />
                          <Route
                            exact
                            path={'/services/:id'}
                            component={PatientService}
                          />
                          <Route
                            exact
                            path={'/reports/:id'}
                            component={PatientReport}
                          />
                          <Route
                            exact
                            path={'/invoices/:id'}
                            component={PatientInvoice}
                          />
                          <Route
                            exact
                            path={'/account'}
                            component={UserAccountOverview}
                          />
                          <Route
                            exact
                            path={'/subscription'}
                            component={UserSubscriptionOverview}
                          />
                          <Route
                            exact
                            path={'/employees'}
                            component={EmployeesOverview}
                          />
                          <Route
                            exact
                            path={'/locations'}
                            component={LocationsOverview}
                          />
                          <Route
                            exact
                            path={'/posts'}
                            component={PostsOverview}
                          />
                          <Route
                            exact
                            path={'/notifications'}
                            component={NotificationsOverview}
                          />
                        </Switch>
                      </PageContainer>
                      <MobileNav />
                      <RenderAlways />
                    </React.Fragment>
                  );
                }
              }}
            />
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default AppRoot;
