import React, { Component } from 'react';
import PatientReferralSelectUI from './PatientReferralSelectUI';
import { connect, actions, selectors } from 'util/redux';

class PatientReferralSelect extends Component {
  componentDidMount() {
    const { fetchPatientReferrals } = this.props;
    fetchPatientReferrals();
  }

  render() {
    return <PatientReferralSelectUI {...this.props} />;
  }
}

const mapStateToProps = state => {
  return {
    patientReferrals: selectors.getPatientReferrals(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchPatientReferrals: () => dispatch(actions.fetchPatientReferrals()),
  };
};

PatientReferralSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientReferralSelect);

export default PatientReferralSelect;
