import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

class Tooltip extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this._id = String.randomize(7);
  }

  render() {
    const { text } = this.props;

    return (
      <span data-tip={text} data-for={this._id}>
        {this.props.children}
        <ReactTooltip id={this._id} effect={'solid'} />
      </span>
    );
  }
}

export default Tooltip;
