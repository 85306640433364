import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_PackagesListItem.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { CardRow } from 'ui/Card';
import { SectionListItem } from 'ui/SectionList';
import { MoneyText } from 'ui/Money';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import { connect, actions } from 'util/redux';

class PackagesListItem extends Component {
  static propTypes = {
    packageTreatment: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationDialogOpen: false,
    };
    this._moreActions = [
      {
        label: locale().actions.destroy,
        onClick: this._openConfirmationDialog,
        type: 'attention',
      },
    ];
  }

  _openConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: true });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false });

  _editPackage = () => {
    const { packageTreatment, editPackage } = this.props;
    editPackage(packageTreatment);
  };

  _numberOfTreatmentsLabel = () => {
    let numberOfTreatments = this.props.packageTreatment.items.reduce(
      (total, item) => total + item.amount,
      0
    );
    return `${numberOfTreatments} ${locale().packages.number_of_treatments(
      numberOfTreatments
    )}`;
  };

  _onDestroy = () => {
    const { packageTreatment, destroyPackage } = this.props;
    destroyPackage(packageTreatment.id);
  };

  _mobile = () => {
    const { packageTreatment } = this.props;

    return (
      <SectionListItem
        title={packageTreatment.name}
        extra={<MoneyText value={packageTreatment.price} />}
        extraColor={'#46AC5A'}
        rightContent={this._numberOfTreatmentsLabel()}
        onClick={this._editPackage}
        contextMenuItems={this._moreActions}
      />
    );
  };

  _desktop = () => {
    const { packageTreatment } = this.props;

    return (
      <CardRow
        onClick={this._editPackage}
        rowSize={'small'}
        contextMenuItems={this._moreActions}
      >
        <div className={styles['wrapper']}>
          <div className={styles['name']}>{packageTreatment.name}</div>
          <div className={styles['meta']}>
            <div className={styles['treatments']}>
              {this._numberOfTreatmentsLabel()}
            </div>
            <div className={styles['price']}>
              <MoneyText value={packageTreatment.price} />
            </div>
          </div>
        </div>
      </CardRow>
    );
  };

  render() {
    const { packageTreatment } = this.props;
    const { confirmationDialogOpen } = this.state;

    return (
      <React.Fragment>
        <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._onDestroy}
          title={`${locale().packages.destroy_package_head}: ${
            packageTreatment.name
          }`}
          description={locale().packages.destroy_package_notice}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editPackage: packageTreatment =>
      dispatch(actions.editPackage(packageTreatment)),
    destroyPackage: id => dispatch(actions.destroyPackage(id)),
  };
};

PackagesListItem = connect(null, mapDispatchToProps)(PackagesListItem);

export default PackagesListItem;
