import React, { Component } from 'react';
import locale from 'util/locale';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import { FormRow, FormColumn, TextInput, FormLabel } from 'ui/Form';
import { connect, actions, selectors } from 'util/redux';

class EmployeeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...props.employee },
      isSaving: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({
        data: { ...this.props.employee },
        errors: {},
      });
    }
  }

  _changeData = (field, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [field]: value,
      },
    });
  };

  _save = () => {
    const { updateEmployee, onClose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    updateEmployee(data)
      .then(() => {
        this.setState({ isSaving: false });
        onClose();
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const { data, errors, isSaving } = this.state;

    return (
      <SidebarDialog
        title={locale().employees.edit_employee}
        open={open}
        onClose={onClose}
        onSave={this._save}
        error={errors._global}
        inProgress={isSaving}
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().employees.name} error={errors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().employees.email} error={errors.email} />
            <TextInput
              value={data.email}
              onChange={value => this._changeData('email', value)}
            />
          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn>
            <FormLabel
              value={locale().employees.employee_title}
              error={errors.title}
            />
            <TextInput
              value={data.title}
              onChange={value => this._changeData('title', value)}
            />
          </FormColumn>
        </FormRow>
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    open: selectors.isEditingEmployee(state),
    employee: selectors.getEditingEmployee(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateEmployee: data => dispatch(actions.updateEmployee(data)),
    onClose: () => dispatch(actions.finishEmployeeEditing()),
  };
};

EmployeeDialog = connect(mapStateToProps, mapDispatchToProps)(EmployeeDialog);

export default EmployeeDialog;
