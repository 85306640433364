import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import { actions as patientActions } from './_patients';
import { push } from 'connected-react-router';
import {
  entityTypes,
  getEntityById,
  receiveEntities,
  removeEntities,
} from './_entities';

const START_PATIENT_INVOICE_EDITING = 'START_PATIENT_INVOICE_EDITING';
const FINISH_PATIENT_INVOICE_EDITING = 'FINISH_PATIENT_INVOICE_EDITING';
const TOGGLE_SERVICE_FOR_INVOICING = 'TOGGLE_SERVICE_FOR_INVOICING';
const CLEAR_SERVICES_FOR_INVOICING = 'CLEAR_SERVICES_FOR_INVOICING';

export default (
  state = {
    selectedServiceIds: [],
    editingPatientInvoice: emptyPatientInvoiceData(),
    patientInvoiceDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_PATIENT_INVOICE_EDITING:
      return {
        ...state,
        editingPatientInvoice: {
          ...action.invoice,
          service_ids: state.selectedServiceIds,
        },
        patientInvoiceDialogOpen: true,
      };
    case FINISH_PATIENT_INVOICE_EDITING:
      return {
        ...state,
        editingPatientInvoice: emptyPatientInvoiceData(),
        patientInvoiceDialogOpen: false,
      };
    case TOGGLE_SERVICE_FOR_INVOICING:
      let newServiceIds;
      if (state.selectedServiceIds.includes(action.serviceId)) {
        newServiceIds = [...state.selectedServiceIds.remove(action.serviceId)];
      } else {
        newServiceIds = [...state.selectedServiceIds, action.serviceId];
      }
      return {
        ...state,
        selectedServiceIds: newServiceIds,
      };
    case CLEAR_SERVICES_FOR_INVOICING:
      return {
        ...state,
        selectedServiceIds: [],
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyPatientInvoiceData = () => ({
  date: new Date(),
  number: '',
});

const newPatientInvoice = data => {
  return {
    type: START_PATIENT_INVOICE_EDITING,
    invoice: { ...emptyPatientInvoiceData(), ...data },
  };
};

const finishPatientInvoiceEditing = () => {
  return {
    type: FINISH_PATIENT_INVOICE_EDITING,
  };
};

const showPatientInvoice = id => push(`/invoices/${id}`);

const toggleServiceForInvoicing = serviceId => {
  return {
    type: TOGGLE_SERVICE_FOR_INVOICING,
    serviceId: serviceId,
  };
};

const clearServicesForInvoicing = () => {
  return {
    type: CLEAR_SERVICES_FOR_INVOICING,
  };
};

const fetchOnePatientInvoice = id => {
  return dispatch => {
    return Api.patientInvoices.get(id).then(response => {
      if (response.isOk()) {
        const invoice = response.data();
        dispatch(receiveEntities(entityTypes.patient_invoice, [invoice]));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatientInvoice = data => {
  return dispatch => {
    return Api.patientInvoices.create(data).then(response => {
      if (response.isOk()) {
        const invoice = response.data();
        dispatch(receiveEntities(entityTypes.patient_invoice, [invoice]));
        dispatch(
          systemActions.showToast(locale().patient_invoices.invoice_saved)
        );
        dispatch(showPatientInvoice(invoice.id));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

let updatePatientInvoicePromise = Promise.resolve(() => {});
const updatePatientInvoice = data => {
  return dispatch => {
    updatePatientInvoicePromise.cancel();
    updatePatientInvoicePromise = Api.patientInvoices
      .update(data)
      .then(response => {
        if (response.isOk()) {
          const invoice = response.data();
          dispatch(receiveEntities(entityTypes.patient_invoice, [invoice]));
        } else {
          return Promise.reject(response);
        }
      });

    return updatePatientInvoicePromise;
  };
};

const destroyPatientInvoice = (id, backToPatient = false) => {
  return dispatch => {
    return Api.patientInvoices.destroy(id).then(response => {
      if (response.isOk()) {
        const invoice = response.data();
        if (backToPatient) {
          dispatch(patientActions.showPatient(invoice.patient_id, 'services'));
        }
        dispatch(removeEntities(entityTypes.patient_invoice, [invoice.id]));
        dispatch(
          systemActions.showToast(locale().patient_invoices.invoice_destroyed)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const groupPatientInvoiceServices = data => {
  return dispatch => {
    return Api.patientInvoices.group(data).then(response => {
      if (response.isOk()) {
        const invoice = response.data();
        dispatch(receiveEntities(entityTypes.patient_invoice, [invoice]));
        dispatch(
          systemActions.showToast(locale().patient_invoices.services_grouped)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const ungroupPatientInvoiceServices = id => {
  return dispatch => {
    return Api.patientInvoices.ungroup(id).then(response => {
      if (response.isOk()) {
        const invoice = response.data();
        dispatch(receiveEntities(entityTypes.patient_invoice, [invoice]));
        dispatch(
          systemActions.showToast(locale().patient_invoices.services_ungrouped)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPatientInvoice,
  finishPatientInvoiceEditing,
  toggleServiceForInvoicing,
  clearServicesForInvoicing,
  showPatientInvoice,
  fetchOnePatientInvoice,
  createPatientInvoice,
  updatePatientInvoice,
  destroyPatientInvoice,
  groupPatientInvoiceServices,
  ungroupPatientInvoiceServices,
};

// SELECTORS

const getPatientInvoice = (state, id) =>
  getEntityById(state, entityTypes.patient_invoice, id);

const getEditingPatientInvoice = state => state.editingPatientInvoice;

const isEditingPatientInvoice = state => state.patientInvoiceDialogOpen;

const getSelectedServiceIdsForInvoicing = state => state.selectedServiceIds;

export const selectors = {
  getPatientInvoice,
  getEditingPatientInvoice,
  isEditingPatientInvoice,
  getSelectedServiceIdsForInvoicing,
};
