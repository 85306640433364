import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { Card } from 'ui/Card';
import { SectionList } from 'ui/SectionList';
import ExpenseTypesListItem from './_ExpenseTypesListItem';

class ExpenseTypesList extends Component {
  static propTypes = {
    expenseTypes: PropTypes.array.isRequired,
  };

  _body = () => (
    <React.Fragment>
      {this.props.expenseTypes.map(expenseType => (
        <ExpenseTypesListItem key={expenseType.id} expenseType={expenseType} />
      ))}
    </React.Fragment>
  );

  _leftHeadline = () => locale().expense_types.title;

  _mobile = () => (
    <SectionList leftHeadline={this._leftHeadline()}>
      {this._body()}
    </SectionList>
  );

  _desktop = () => (
    <Card leftHeadline={this._leftHeadline()}>{this._body()}</Card>
  );

  render() {
    return (
      <MobileDesktopRender mobile={this._mobile} desktop={this._desktop} />
    );
  }
}

export default ExpenseTypesList;
