import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_DiagnosesDialog.module.scss';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import { SectionList, SectionListItem } from 'ui/SectionList';
import SidebarDialog from 'ui/Dialog/SidebarDialog';
import ConfirmationDialog from 'ui/Dialog/ConfirmationDialog';
import BottomSheet from 'ui/Dialog/BottomSheet';
import { FormRow, FormColumn, FormLabel, TextInput } from 'ui/Form';
import Icon from 'ui/Icon';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class DiagnosesDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: this._emptyData(),
      errors: {},
      loadingState: loadingStates.fetching,
      isSaving: false,
      bottomSheetOpen: false,
      confirmationDialogOpen: false,
      diagnoseId: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchDiagnoses } = this.props;

    if (!prevProps.open && this.props.open) {
      this.setState({
        data: this._emptyData(),
        errors: {},
        loadingState: loadingStates.fetching,
      });
      fetchDiagnoses()
        .then(() => this.setState({ loadingState: loadingStates.present }))
        .catch(response => {
          console.error(response);
          this.setState({ loadingState: loadingStates.failed });
        });
    }
  }

  _emptyData = () => ({
    name: '',
  });

  _openBottomSheet = diagnoseId =>
    this.setState({ bottomSheetOpen: true, diagnoseId });

  _closeBottomSheet = () =>
    this.setState({ bottomSheetOpen: false, diagnoseId: null });

  _openConfirmationDialog = diagnoseId =>
    this.setState({ confirmationDialogOpen: true, diagnoseId });

  _closeConfirmationDialog = () =>
    this.setState({ confirmationDialogOpen: false, diagnoseId: null });

  _changeData = (key, value) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [key]: value,
      },
    });
  };

  _createDiagnose = () => {
    const { createDiagnose } = this.props;
    const { data } = this.state;

    this.setState({ isSaving: true, errors: {} });
    createDiagnose(data)
      .then(() => {
        this.setState({
          isSaving: false,
          data: this._emptyData(),
        });
      })
      .catch(response => {
        console.error(response);
        if (response.isValidationError && response.isValidationError()) {
          this.setState({
            errors: response.validationErrors().errorPerField(),
          });
        } else {
          this.setState({
            errors: {
              _global: locale().system.global_error,
            },
          });
        }
        this.setState({ isSaving: false });
      });
  };

  _destroyDiagnose = () => {
    const { destroyDiagnose } = this.props;
    const { diagnoseId } = this.state;

    this.setState({ errors: {} });
    destroyDiagnose(diagnoseId).catch(response => {
      console.error(response);
      this.setState({
        errors: {
          _global: locale().system.global_error,
        },
      });
    });
  };

  _mobileFooter = () => {
    const { diagnoses } = this.props;
    const { bottomSheetOpen } = this.state;

    return (
      <div className={styles['diagnoses']}>
        <SectionList>
          {diagnoses.map(diagnose => (
            <SectionListItem
              key={diagnose.id}
              title={diagnose.name}
              onMoreClick={() => this._openBottomSheet(diagnose.id)}
            />
          ))}
        </SectionList>
        <BottomSheet
          open={bottomSheetOpen}
          onClose={this._closeBottomSheet}
          items={[
            {
              label: locale().actions.destroy,
              onClick: this._destroyDiagnose,
              type: 'attention',
            },
          ]}
        />
      </div>
    );
  };

  _desktopFooter = () => {
    const { diagnoses } = this.props;

    return (
      <div className={styles['diagnoses']}>
        {diagnoses.map(diagnose => (
          <div key={diagnose.id} className={styles['diagnose']}>
            <div>{diagnose.name}</div>
            <div className={styles['delete']}>
              <Icon
                icon={'delete'}
                onClick={() => this._openConfirmationDialog(diagnose.id)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { open, onClose, diagnoses } = this.props;
    const {
      loadingState,
      data,
      isSaving,
      errors,
      confirmationDialogOpen,
      diagnoseId,
    } = this.state;

    return (
      <SidebarDialog
        open={open}
        onClose={onClose}
        title={locale().diagnoses.title}
        onSave={this._createDiagnose}
        error={errors._global}
        loadingState={loadingState}
        inProgress={isSaving}
        footer={
          <MobileDesktopRender
            mobile={this._mobileFooter}
            desktop={this._desktopFooter}
          />
        }
      >
        <FormRow>
          <FormColumn>
            <FormLabel value={locale().diagnoses.name} error={errors.name} />
            <TextInput
              value={data.name}
              onChange={value => this._changeData('name', value)}
            />
          </FormColumn>
        </FormRow>
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={this._closeConfirmationDialog}
          onConfirm={this._destroyDiagnose}
          title={`${locale().diagnoses.destroy_diagnose_head}: ${
            (diagnoses.find(d => d.id === diagnoseId) || { name: '' }).name
          }`}
          description={locale().diagnoses.destroy_diagnose_notice}
        />
      </SidebarDialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    diagnoses: selectors.getDiagnoses(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDiagnoses: () => dispatch(actions.fetchDiagnoses()),
    createDiagnose: data => dispatch(actions.createDiagnose(data)),
    destroyDiagnose: id => dispatch(actions.destroyDiagnose(id)),
  };
};

DiagnosesDialog = connect(mapStateToProps, mapDispatchToProps)(DiagnosesDialog);

export default DiagnosesDialog;
