import React, { Component } from 'react';
import locale from 'util/locale';
import styles from './UserSubscriptionOverview.module.scss';
import Navigator from 'navigation/Navigator';
import MobileDesktopRender from 'ui/MobileDesktopRender';
import PermissionsRender from 'ui/PermissionsRender';
import HeaderBar from 'ui/HeaderBar';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import PlansOverview from './_PlansOverview';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class UserSubscriptionOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchSubscriptionPlans } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchSubscriptionPlans()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(() => this.setState({ loadingState: loadingStates.failed }));
  }

  _mobileHeader = () => <HeaderBar title={'Logovel'} />;

  render() {
    const { currentUser, subscriptionPlans } = this.props;
    const { loadingState } = this.state;

    const userSubscription = currentUser.organisation.subscription;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else {
      let title, intro;

      if (userSubscription.plan.trial) {
        title = locale().subscription.headline;
        intro = (
          <React.Fragment>
            <div className={styles['intro']}>
              {locale().subscription.intro_1}
            </div>
            <div className={styles['intro']}>
              {locale().subscription.intro_2}
            </div>
          </React.Fragment>
        );
      } else {
        title = locale().subscription.active_and_valid_until(
          userSubscription.valid_until.format('dd.MM.yyyy.')
        );
      }

      bodyNode = (
        <React.Fragment>
          <div className={styles['wrapper']}>
            <div className={styles['head']}>
              <div className={styles['title']}>{title}</div>
              {intro}
            </div>
            <PermissionsRender permission={'manage_subscription'}>
              <div className={styles['plans']}>
                <PlansOverview
                  subscription={userSubscription}
                  plans={subscriptionPlans}
                />
              </div>
            </PermissionsRender>
          </div>
        </React.Fragment>
      );
    }

    return (
      <Navigator title={locale().subscription.title} mountedAs={'subscription'}>
        <React.Fragment>
          <MobileDesktopRender mobile={this._mobileHeader} />
          {bodyNode}
        </React.Fragment>
      </Navigator>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: selectors.getCurrentUser(state),
    subscriptionPlans: selectors.getSubscriptionPlans(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchSubscriptionPlans: () => dispatch(actions.fetchSubscriptionPlans()),
  };
};

UserSubscriptionOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSubscriptionOverview);

export default UserSubscriptionOverview;
