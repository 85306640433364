import React, { Component } from 'react';
import locale from 'util/locale';
import PermissionsRender from 'ui/PermissionsRender';
import Navigator from 'navigation/Navigator';
import LoadingIndicator from 'ui/Activity/LoadingIndicator';
import FailureInfo from 'ui/Activity/FailureInfo';
import EmptyState from 'ui/EmptyState';
import emptyStateImg from 'media/images/locations/emptyState.png';
import LocationsList from './_LocationsList';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class LocationsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { fetchLocations } = this.props;

    this.setState({ loadingState: loadingStates.fetching });
    fetchLocations()
      .then(() => this.setState({ loadingState: loadingStates.present }))
      .catch(error => {
        console.error(error);
        this.setState({ loadingState: loadingStates.failed });
      });
  }

  render() {
    const { locations, newLocation } = this.props;
    const { loadingState } = this.state;

    let bodyNode;
    if (loadingState === loadingStates.fetching) {
      bodyNode = <LoadingIndicator />;
    } else if (loadingState === loadingStates.failed) {
      bodyNode = <FailureInfo />;
    } else {
      bodyNode = (
        <React.Fragment>
          <EmptyState
            heading={locale().locations.empty_state_heading}
            intro={locale().locations.empty_state_intro}
            image={emptyStateImg}
            actionText={locale().locations.new}
            onClick={newLocation}
          />
          {locations.length > 0 && <LocationsList locations={locations} />}
        </React.Fragment>
      );
    }

    return (
      <PermissionsRender permission={'manage_locations'} redirect={true}>
        <Navigator title={locale().locations.title} mountedAs={'locations'}>
          {bodyNode}
        </Navigator>
      </PermissionsRender>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: selectors.getLocations(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    newLocation: () => dispatch(actions.newLocation()),
    fetchLocations: () => dispatch(actions.fetchLocations()),
  };
};

LocationsOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationsOverview);

export default LocationsOverview;
