import Response from '../_response';
import {
  PatientSerializer,
  PatientNoteSerializer,
  PatientReportSerializer,
  AppointmentSerializer,
  ServiceSerializer,
  ServicePaymentSerializer,
  PatientDocumentSerializer,
} from '../_serializers';

class PatientsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query(query) {
    return this._client
      .fetch('patients', 'GET', query)
      .then(({ response, json }) => {
        let data;

        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(patient =>
              PatientSerializer.deserialize(patient)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  get(id) {
    return this._client
      .fetch(`patients/${id}`, 'GET')
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  create(data) {
    return this._client
      .fetch('patients', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  update(data) {
    return this._client
      .fetch(`patients/${data.id}`, 'PATCH', data)
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  destroy(id) {
    return this._client
      .fetch(`patients/${id}`, 'DELETE')
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  archive(id) {
    return this._client
      .fetch(`patients/${id}/archive`, 'POST')
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  activate(id) {
    return this._client
      .fetch(`patients/${id}/activate`, 'POST')
      .then(({ response, json }) => {
        return new Response(response, PatientSerializer.deserialize(json));
      });
  }

  appointments({ patientId, page }) {
    return this._client
      .fetch(`patients/${patientId}/appointments`, 'GET', { page: page })
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(appointment =>
              AppointmentSerializer.deserialize(appointment)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  services({ patientId, page }) {
    return this._client
      .fetch(`patients/${patientId}/services`, 'GET', { page: page })
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(service =>
              ServiceSerializer.deserialize(service)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  servicePayments({ patientId, page }) {
    return this._client
      .fetch(`patients/${patientId}/service_payments`, 'GET', { page: page })
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(servicePayment =>
              ServicePaymentSerializer.deserialize(servicePayment)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  notes(id) {
    return this._client
      .fetch(`patients/${id}/notes`, 'GET')
      .then(({ response, json }) => {
        let data;
        if (response.status >= 200 && response.status < 300) {
          data = {
            ...json,
            resources: json.resources.map(note =>
              PatientNoteSerializer.deserialize(note)
            ),
          };
        } else {
          data = json;
        }

        return new Response(response, data);
      });
  }

  reports(id) {
    return this._client
      .fetch(`patients/${id}/reports`, 'GET')
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(report =>
            PatientReportSerializer.deserialize(report)
          ),
        });
      });
  }

  documents(id) {
    return this._client
      .fetch(`patients/${id}/documents`, 'GET')
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(document =>
            PatientDocumentSerializer.deserialize(document)
          ),
        });
      });
  }
}

export default PatientsEndpoint;
