import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './LanguageSelect.module.scss';
import Select from './Select';
import { supportedLanguages } from 'util/locale';

class LanguageSelect extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static optionRenderer = option => (
    <div className={styles['language']}>
      <div className={styles['icon']}>{option.icon}</div>
      <div>{option.label}</div>
    </div>
  );

  _options = () =>
    Object.keys(supportedLanguages).map(ln => ({
      value: supportedLanguages[ln].value,
      label: supportedLanguages[ln].label,
      icon: supportedLanguages[ln].icon,
    }));

  render() {
    const { value, onChange } = this.props;

    return (
      <Select
        value={value}
        onChange={onChange}
        options={this._options()}
        optionRenderer={LanguageSelect.optionRenderer}
        clearable={false}
        searchable={false}
      />
    );
  }
}

export default LanguageSelect;
