import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  entityTypes,
  receiveQuery,
  receiveEntities,
  clearQuery,
  removeEntities,
  makeGetEntityList,
} from './_entities';

const START_PACKAGE_EDITING = 'START_PACKAGE_EDITING';
const FINISH_PACKAGE_EDITING = 'FINISH_PACKAGE_EDITING';

export default (
  state = {
    editingPackage: emptyPackageData(),
    packageDialogOpen: false,
  },
  action
) => {
  switch (action.type) {
    case START_PACKAGE_EDITING:
      return {
        ...state,
        editingPackage: action.packageTreatment,
        packageDialogOpen: true,
      };
    case FINISH_PACKAGE_EDITING:
      return {
        ...state,
        editingPackage: emptyPackageData(),
        packageDialogOpen: false,
      };
    default:
      return state;
  }
};

// ACTIONS

const emptyPackageData = () => ({
  name: '',
  items: [],
  price: 0,
});

const newPackage = () => {
  return {
    type: START_PACKAGE_EDITING,
    packageTreatment: emptyPackageData(),
  };
};

const editPackage = packageTreatment => {
  return {
    type: START_PACKAGE_EDITING,
    packageTreatment,
  };
};

const finishPackageEditing = () => {
  return {
    type: FINISH_PACKAGE_EDITING,
  };
};

const fetchPackages = () => {
  return dispatch => {
    dispatch(clearQuery(entityTypes.package));
    return Api.packages.query().then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.package, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPackage = data => {
  return dispatch => {
    return Api.packages.create(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveEntities(entityTypes.package, [response.data()]));
        dispatch(systemActions.showToast(locale().packages.package_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updatePackage = data => {
  return dispatch => {
    return Api.packages.update(data).then(response => {
      if (response.isOk()) {
        dispatch(receiveEntities(entityTypes.package, [response.data()]));
        dispatch(systemActions.showToast(locale().packages.package_saved));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPackage = id => {
  return dispatch => {
    return Api.packages.destroy(id).then(response => {
      if (response.isOk()) {
        dispatch(removeEntities(entityTypes.package, [response.data().id]));
        dispatch(systemActions.showToast(locale().packages.package_destroyed));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPackage,
  editPackage,
  finishPackageEditing,
  fetchPackages,
  createPackage,
  updatePackage,
  destroyPackage,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getPackages = state => getEntityList(state, entityTypes.package);

const getEditingPackage = state => state.editingPackage;

const isEditingPackage = state => state.packageDialogOpen;

export const selectors = {
  getPackages,
  getEditingPackage,
  isEditingPackage,
};
