import React, { Component } from 'react';
import locale from 'util/locale';
import PropTypes from 'prop-types';
import styles from './_ServicesList.module.scss';
import Paginator from 'navigation/Paginator';
import Accordion from 'ui/Accordion';
import EmptyState from './../_EmptyState';
import ServicesListItem from './_ServicesListItem';
import { MoneyText } from 'ui/Money';
import ActionCenter from 'util/action_center';
import treatmentsImg from 'media/images/services/treatments.png';
import { loadingStates, connect, actions, selectors } from 'util/redux';

class ServicesList extends Component {
  static propTypes = {
    patientId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loadingState: loadingStates.fetching,
    };
  }

  componentDidMount() {
    const { clearServicesForInvoicing } = this.props;
    clearServicesForInvoicing();

    ActionCenter.subscribe('createServicePayment', this._reloadServices);
    ActionCenter.subscribe('destroyServicePayment', this._reloadServices);
  }

  componentWillUnmount() {
    const { clearServicesForInvoicing } = this.props;
    clearServicesForInvoicing();

    ActionCenter.unsubscribe('createServicePayment', this._reloadServices);
    ActionCenter.unsubscribe('destroyServicePayment', this._reloadServices);
  }

  _reloadServices = paymentOrPayments => {
    const { fetchOneService } = this.props;

    const payments = Array.isArray(paymentOrPayments)
      ? paymentOrPayments
      : [paymentOrPayments];
    payments.forEach(payment => fetchOneService(payment.service_id));
  };

  _onFirstLoad = () => this.setState({ loadingState: loadingStates.present });

  _serviceMap = () => {
    const { services } = this.props;
    const serviceMap = {};

    services.forEach(service => {
      const key = service.effectively_active_from.format('yyyy-MM');

      serviceMap[key] = [...(serviceMap[key] || []), service];
    });

    return serviceMap;
  };

  _debtForMonth = services => {
    const debt = services.reduce((acc, service) => acc + service.debt, 0);

    if (debt > 0) {
      return (
        <div className={styles['debt']}>
          <MoneyText value={debt} />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const { loadingState } = this.state;
    const firstFetchCompleted = loadingState === loadingStates.present;

    const serviceMap = this._serviceMap();
    const dateKeys = Object.keys(serviceMap).sortBy('').reverse();

    return (
      <div className={styles['wrapper']}>
        <Paginator
          fetch={this.props.fetchServicesForPatient}
          onFirstLoad={this._onFirstLoad}
        >
          <Accordion
            items={dateKeys.map(dateKey => {
              const date = new Date.fromString(dateKey);
              const services = serviceMap[dateKey];

              return {
                id: dateKey,
                title: date.format('MMMM yyyy').capitalize(),
                extra: this._debtForMonth(services),
                content: (
                  <React.Fragment>
                    {services
                      .sortBy('effectively_active_from')
                      .reverse()
                      .map(service => (
                        <ServicesListItem key={service.id} service={service} />
                      ))}
                  </React.Fragment>
                ),
              };
            })}
            titleClassName={styles['groupTitle']}
          />
        </Paginator>
        {firstFetchCompleted && this.props.services.length === 0 && (
          <EmptyState
            intro={locale().services.empty_state_description}
            image={treatmentsImg}
            extraSpace={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    services: selectors.getServicesForPatient(state, patientId),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const patientId = ownProps.patientId;

  return {
    fetchServicesForPatient: ({ clearOld, page }) =>
      dispatch(actions.fetchServicesForPatient({ patientId, clearOld, page })),
    fetchOneService: id => dispatch(actions.fetchOneService(id)),
    clearServicesForInvoicing: () =>
      dispatch(actions.clearServicesForInvoicing()),
  };
};

ServicesList = connect(mapStateToProps, mapDispatchToProps)(ServicesList);

export default ServicesList;
