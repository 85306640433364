import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TextInput.module.scss';

class TextInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
    prefix: PropTypes.node,
    provideController: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    onFocus: () => {},
    onBlur: () => {},
    type: 'text',
    prefix: null,
    provideController: () => {},
  };

  constructor(props) {
    super(props);
    this._input = React.createRef();
  }

  componentDidMount() {
    const { provideController } = this.props;
    provideController({
      select: () => this._input.current.select(),
    });
  }

  _onChange = e => {
    const { onChange } = this.props;
    onChange(e.target.value);
  };

  render() {
    const { value, placeholder, onFocus, onBlur, type, prefix } = this.props;

    let prefixNode;
    if (prefix) {
      prefixNode = <div className={styles['prefix']}>{prefix}</div>;
    }

    return (
      <div className={styles['wrapper']}>
        {prefixNode}
        <input
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={this._onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={styles['input']}
          ref={this._input}
        />
      </div>
    );
  }
}

export default TextInput;
