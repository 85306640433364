import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import entities from './ducks/_entities';
import page, { selectors as fromPage } from './ducks/_page';
import user, { selectors as fromUser } from './ducks/_user';
import { selectors as fromNotifications } from './ducks/_notifications';
import organisation_stats, {
  selectors as fromOrganisationStats,
} from './ducks/_organisation_stats';
import { selectors as fromUserStats } from './ducks/_user_stats';
import { selectors as fromDiagnoses } from './ducks/_diagnoses';
import patientReferral, {
  selectors as fromPatientReferrals,
} from './ducks/_patient_referrals';
import patient, { selectors as fromPatients } from './ducks/_patients';
import service, { selectors as fromServices } from './ducks/_services';
import { selectors as fromServiceItems } from './ducks/_service_items';
import patientNote, {
  selectors as fromPatientNotes,
} from './ducks/_patient_notes';
import { selectors as fromAppointments } from './ducks/_appointments';
import servicePayment, {
  selectors as fromServicePayments,
} from './ducks/_service_payments';
import patientReport, {
  selectors as fromPatientReports,
} from './ducks/_patient_reports';
import patientDocument, {
  selectors as fromPatientDocuments,
} from './ducks/_patient_documents';
import patientInvoice, {
  selectors as fromPatientInvoices,
} from './ducks/_patient_invoices';
import treatment, { selectors as fromTreatments } from './ducks/_treatments';
import packageTreatment, { selectors as fromPackages } from './ducks/_packages';
import employee, { selectors as fromEmployees } from './ducks/_employees';
import { selectors as fromInvitations } from './ducks/_invitations';
import location, { selectors as fromLocations } from './ducks/_locations';
import { selectors as fromSubscriptionPlans } from './ducks/_subscription_plans';
import post, { selectors as fromPosts } from './ducks/_posts';
import expense, { selectors as fromExpenses } from './ducks/_expenses';
import expenseType, {
  selectors as fromExpenseTypes,
} from './ducks/_expense_types';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    entities,
    page,
    user,
    organisation_stats,
    patientReferral,
    patient,
    service,
    patientNote,
    servicePayment,
    patientReport,
    patientDocument,
    patientInvoice,
    treatment,
    packageTreatment,
    employee,
    location,
    post,
    expense,
    expenseType,
  });

export default createRootReducer;

export const getActivePage = state => fromPage.getActivePage(state.page);

export const getSearchParams = state => fromPage.getSearchParams(state.router);

export const isCurrentUserLoggedIn = state =>
  fromUser.isCurrentUserLoggedIn(state.user);

export const getCurrentUser = state => fromUser.getCurrentUser(state.user);

export const getNotifications = state =>
  fromNotifications.getNotifications(state.entities);

export const getWorkingStats = state =>
  fromUserStats.getWorkingStats(state.entities);

export const getOneWorkingStats = (state, id) =>
  fromUserStats.getOneWorkingStats(state.entities, id);

export const getIncomeStats = state =>
  fromOrganisationStats.getIncomeStats(state.organisation_stats);

export const getExpenseStats = state =>
  fromOrganisationStats.getExpenseStats(state.organisation_stats);

export const getDiagnoseStats = state =>
  fromOrganisationStats.getDiagnoseStats(state.organisation_stats);

export const getPatientReferralStats = state =>
  fromOrganisationStats.getPatientReferralStats(state.organisation_stats);

export const getProfitAndLossReport = state =>
  fromOrganisationStats.getProfitAndLossReport(state.organisation_stats);

export const getAppointmentStats = state =>
  fromOrganisationStats.getAppointmentStats(state.organisation_stats);

export const getDiagnoses = state => fromDiagnoses.getDiagnoses(state.entities);

export const getPatientReferrals = state =>
  fromPatientReferrals.getPatientReferrals(state.entities);

export const getEditingPatientReferral = state =>
  fromPatientReferrals.getEditingPatientReferral(state.patientReferral);

export const isEditingPatientReferral = state =>
  fromPatientReferrals.isEditingPatientReferral(state.patientReferral);

export const getPatients = state => fromPatients.getPatients(state.entities);

export const getPatient = (state, id) =>
  fromPatients.getPatient(state.entities, id);

export const getPatientsQuery = state =>
  fromPatients.getPatientsQuery(state.entities);

export const getEditingPatient = state =>
  fromPatients.getEditingPatient(state.patient);

export const isEditingPatient = state =>
  fromPatients.isEditingPatient(state.patient);

export const getServicesForPatient = (state, patientId) =>
  fromServices.getServicesForPatient(state.entities, patientId);

export const getService = (state, id) =>
  fromServices.getService(state.entities, id);

export const getEditingService = state =>
  fromServices.getEditingService(state.service);

export const isEditingService = state =>
  fromServices.isEditingService(state.service);

export const isAssigningService = state =>
  fromServices.isAssigningService(state.service);

export const getAssigningServiceData = state =>
  fromServices.getAssigningServiceData(state.service);

export const getItemsForService = (state, serviceId) =>
  fromServiceItems.getItemsForService(state.entities, serviceId);

export const getNotesForPatient = (state, patientId) =>
  fromPatientNotes.getNotesForPatient(state.entities, patientId);

export const getEditingPatientNote = state =>
  fromPatientNotes.getEditingPatientNote(state.patientNote);

export const isEditingPatientNote = state =>
  fromPatientNotes.isEditingPatientNote(state.patientNote);

export const getAppointments = state =>
  fromAppointments.getAppointments(state.entities);

export const getAppointmentsForPatient = (state, patientId) =>
  fromAppointments.getAppointmentsForPatient(state.entities, patientId);

export const getAppointment = (state, id) =>
  fromAppointments.getAppointment(state.entities, id);

export const getAppointmentsQuery = state =>
  fromAppointments.getAppointmentsQuery(state.entities);

export const getPaymentsForPatient = (state, patientId) =>
  fromServicePayments.getPaymentsForPatient(state.entities, patientId);

export const getPaymentsForService = (state, serviceId) =>
  fromServicePayments.getPaymentsForService(state.entities, serviceId);

export const getEditingServicePayment = state =>
  fromServicePayments.getEditingServicePayment(state.servicePayment);

export const isEditingServicePayment = state =>
  fromServicePayments.isEditingServicePayment(state.servicePayment);

export const getEditingInflow = state =>
  fromServicePayments.getEditingInflow(state.servicePayment);

export const isEditingInflow = state =>
  fromServicePayments.isEditingInflow(state.servicePayment);

export const getReportsForPatient = (state, patientId) =>
  fromPatientReports.getReportsForPatient(state.entities, patientId);

export const getPatientReport = (state, id) =>
  fromPatientReports.getPatientReport(state.entities, id);

export const getEditingPatientReport = state =>
  fromPatientReports.getEditingPatientReport(state.patientReport);

export const isEditingPatientReport = state =>
  fromPatientReports.isEditingPatientReport(state.patientReport);

export const getDocumentsForPatient = (state, patientId) =>
  fromPatientDocuments.getDocumentsForPatient(state.entities, patientId);

export const getEditingPatientDocument = state =>
  fromPatientDocuments.getEditingPatientDocument(state.patientDocument);

export const isEditingPatientDocument = state =>
  fromPatientDocuments.isEditingPatientDocument(state.patientDocument);

export const getPatientInvoice = (state, id) =>
  fromPatientInvoices.getPatientInvoice(state.entities, id);

export const getEditingPatientInvoice = state =>
  fromPatientInvoices.getEditingPatientInvoice(state.patientInvoice);

export const isEditingPatientInvoice = state =>
  fromPatientInvoices.isEditingPatientInvoice(state.patientInvoice);

export const getSelectedServiceIdsForInvoicing = state =>
  fromPatientInvoices.getSelectedServiceIdsForInvoicing(state.patientInvoice);

export const getTreatments = state =>
  fromTreatments.getTreatments(state.entities);

export const getEditingTreatment = state =>
  fromTreatments.getEditingTreatment(state.treatment);

export const isEditingTreatment = state =>
  fromTreatments.isEditingTreatment(state.treatment);

export const getPackages = state => fromPackages.getPackages(state.entities);

export const getEditingPackage = state =>
  fromPackages.getEditingPackage(state.packageTreatment);

export const isEditingPackage = state =>
  fromPackages.isEditingPackage(state.packageTreatment);

export const getEmployees = state => fromEmployees.getEmployees(state.entities);

export const getEditingEmployee = state =>
  fromEmployees.getEditingEmployee(state.employee);

export const isEditingEmployee = state =>
  fromEmployees.isEditingEmployee(state.employee);

export const getInvitations = state =>
  fromInvitations.getInvitations(state.entities);

export const getInvitationByToken = (state, token) =>
  fromInvitations.getInvitationByToken(state.entities, token);

export const getLocations = state => fromLocations.getLocations(state.entities);

export const getEditingLocation = state =>
  fromLocations.getEditingLocation(state.location);

export const isEditingLocation = state =>
  fromLocations.isEditingLocation(state.location);

export const getSubscriptionPlans = state =>
  fromSubscriptionPlans.getSubscriptionPlans(state.entities);

export const getPosts = state => fromPosts.getPosts(state.entities);

export const getEditingPost = state => fromPosts.getEditingPost(state.post);

export const isEditingPost = state => fromPosts.isEditingPost(state.post);

export const getExpenses = state => fromExpenses.getExpenses(state.entities);

export const getEditingExpense = state =>
  fromExpenses.getEditingExpense(state.expense);

export const isEditingExpense = state =>
  fromExpenses.isEditingExpense(state.expense);

export const getExpenseTypes = state =>
  fromExpenseTypes.getExpenseTypes(state.entities);

export const getEditingExpenseType = state =>
  fromExpenseTypes.getEditingExpenseType(state.expenseType);

export const isEditingExpenseType = state =>
  fromExpenseTypes.isEditingExpenseType(state.expenseType);
