import {
  AppointmentSerializer,
  DateSerializer,
  createResourceSerializer,
} from '../_serializers';
import Response from '../_response';

const PredictionSerializer = createResourceSerializer({
  date: DateSerializer,
});

class AppointmentsEndpoint {
  constructor(client) {
    this._client = client;
  }

  query(data) {
    let query = { ...data };
    if (data.from_date) {
      query.from_date = DateSerializer.serialize(data.from_date);
    }
    if (data.to_date) {
      query.to_date = DateSerializer.serialize(data.to_date);
    }

    return this._client
      .fetch('appointments', 'GET', query)
      .then(({ response, json }) => {
        return new Response(response, {
          ...json,
          resources: json.resources.map(appointment =>
            AppointmentSerializer.deserialize(appointment)
          ),
        });
      });
  }

  predict(data) {
    return this._client
      .fetch('appointments/predict', 'POST', data)
      .then(({ response, json }) => {
        return new Response(response, PredictionSerializer.deserialize(json));
      });
  }

  create(data) {
    return this._client
      .fetch('appointments', 'POST', AppointmentSerializer.serialize(data))
      .then(({ response, json }) => {
        return AppointmentsEndpoint._buildResponse(response, json);
      });
  }

  update(data) {
    return this._client
      .fetch(
        `appointments/${data.id}`,
        'PATCH',
        AppointmentSerializer.serialize(data)
      )
      .then(({ response, json }) => {
        return AppointmentsEndpoint._buildResponse(response, json);
      });
  }

  get(id) {
    return this._client
      .fetch(`appointments/${id}`, 'GET')
      .then(({ response, json }) => {
        return AppointmentsEndpoint._buildResponse(response, json);
      });
  }

  cancel(data) {
    return this._client
      .fetch(`appointments/${data.id}/cancel`, 'POST', data)
      .then(({ response, json }) => {
        return AppointmentsEndpoint._buildResponse(response, json);
      });
  }

  static _buildResponse(response, json) {
    let data;
    if (response.status >= 200 && response.status < 300) {
      data = AppointmentSerializer.deserialize(json);
    } else {
      data = json;
    }

    return new Response(response, data);
  }
}

export default AppointmentsEndpoint;
