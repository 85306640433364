import locale from 'util/locale';
import Api from 'util/api/api';
import { actions as systemActions } from './_system';
import {
  clearQuery,
  entityTypes,
  makeGetEntityList,
  receiveQuery,
  receiveEntities,
  removeEntities,
} from './_entities';

const START_PATIENT_DOCUMENT_EDITING = 'START_PATIENT_DOCUMENT_EDITING';
const FINISH_PATIENT_DOCUMENT_EDITING = 'FINISH_PATIENT_DOCUMENT_EDITING';

export default (
  state = { editingPatientDocument: null, patientDocumentDialogOpen: false },
  action
) => {
  switch (action.type) {
    case START_PATIENT_DOCUMENT_EDITING:
      return {
        ...state,
        editingPatientDocument: action.document,
        patientDocumentDialogOpen: true,
      };
    case FINISH_PATIENT_DOCUMENT_EDITING: {
      return {
        ...state,
        editingPatientDocument: emptyPatientDocumentData(),
        patientDocumentDialogOpen: false,
      };
    }
    default:
      return state;
  }
};

// ACTIONS

const emptyPatientDocumentData = () => ({
  date: new Date(),
  title: '',
  patient_id: null,
});

const newPatientDocument = data => {
  return {
    type: START_PATIENT_DOCUMENT_EDITING,
    document: { ...emptyPatientDocumentData(), ...data },
  };
};

const editPatientDocument = document => {
  return {
    type: START_PATIENT_DOCUMENT_EDITING,
    document,
  };
};

const finishPatientDocumentEditing = () => {
  return {
    type: FINISH_PATIENT_DOCUMENT_EDITING,
  };
};

const fetchDocumentsForPatient = id => {
  return dispatch => {
    dispatch(clearQuery(), entityTypes.patient_document);
    return Api.patients.documents(id).then(response => {
      if (response.isOk()) {
        const query = response.data();
        dispatch(receiveQuery(entityTypes.patient_document, query));
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const createPatientDocument = (data, onProgress) => {
  return dispatch => {
    return Api.patientDocuments.create(data, onProgress).then(response => {
      if (response.isOk()) {
        const document = response.data();
        dispatch(receiveEntities(entityTypes.patient_document, [document]));
        dispatch(
          systemActions.showToast(locale().patient_documents.document_created)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const updatePatientDocument = data => {
  return dispatch => {
    return Api.patientDocuments.update(data).then(response => {
      if (response.isOk()) {
        const document = response.data();
        dispatch(receiveEntities(entityTypes.patient_document, [document]));
        dispatch(
          systemActions.showToast(locale().patient_documents.document_saved)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

const destroyPatientDocument = id => {
  return dispatch => {
    return Api.patientDocuments.destroy(id).then(response => {
      if (response.isOk()) {
        const document = response.data();
        dispatch(removeEntities(entityTypes.patient_document, [document.id]));
        dispatch(
          systemActions.showToast(locale().patient_documents.document_destroyed)
        );
      } else {
        return Promise.reject(response);
      }
    });
  };
};

export const actions = {
  newPatientDocument,
  editPatientDocument,
  finishPatientDocumentEditing,
  fetchDocumentsForPatient,
  createPatientDocument,
  updatePatientDocument,
  destroyPatientDocument,
};

// SELECTORS

const getEntityList = makeGetEntityList();

const getDocumentsForPatient = (state, patientId) =>
  getEntityList(state, entityTypes.patient_document).filter(
    doc => doc.patient_id === patientId
  );

const getEditingPatientDocument = state => state.editingPatientDocument;

const isEditingPatientDocument = state => state.patientDocumentDialogOpen;

export const selectors = {
  getDocumentsForPatient,
  getEditingPatientDocument,
  isEditingPatientDocument,
};
