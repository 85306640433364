import supportedCurrencies from './supported_currencies';

export const formatMoney = (value, currency) => {
  const currencyData = supportedCurrencies()[currency];

  if (currencyData.format === 0) {
    return `${currencyData.sign}${value}`;
  } else {
    return `${value} ${currencyData.sign}`;
  }
};
