import { toast } from 'react-toastify';

const showToast = (message, options = {}) => {
  return () => {
    options = {
      type: 'success',
      ...options,
    };
    toast(message, options);
  };
};

const toastError = message => () =>
  toast(message, { type: 'error', autoClose: 5000 });

export const actions = {
  showToast,
  toastError,
};
